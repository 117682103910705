import { PEP_SESSION,
  PEP_SESSION_SUCCESS,
  PEP_SESSION_FAILED,
  REFRESH_REDUCERS } from './../action';

const initialState = {
  session: {},
  error: false,
  errorMessage : '',
  success : false,
  unauthorized : null,
};

export default function(state=initialState, action){
    switch(action.type){
        case PEP_SESSION:
            return {...state, loading: true,success:false,error: false,errorMessage:''};
        case PEP_SESSION_SUCCESS:
            return {...state, session: action.sessionResponse && action.sessionResponse.data ? action.sessionResponse.data.id : "", loading: false,success:true,error: false,errorMessage:''};
        case PEP_SESSION_FAILED:
            return {...state, loading: false, error: true,errorMessage:'',unauthorized: action.payload,success:false};
        case REFRESH_REDUCERS:
            return {...initialState,session: {}, loading: false,error: false,success:false,errorMessage:''};
        default:
            return state;
    }
}
