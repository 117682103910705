import React from 'react';

const LookUpFormHeader = ({ titleText, id }) => {
  return (
    <div className="page-title" id={id}>
      <h1>{titleText}</h1>
    </div>
  );
};

export default LookUpFormHeader;
