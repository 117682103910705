import isEmpty from 'lodash/isEmpty';
import _isArray from 'lodash/isArray';
import { TOW } from '../app-consts/appConstants';
export const getAccessToken = state => state.accessToken;
export const getVinSaga = state =>state.vinCapture;
export const getCurrentLocation = state => state.currentLocation;
export const getServiceDetails = state => state.serviceDetails.serviceDetails;
export const getServiceInfo = state => state.serviceInfo;
export const getServiceRequestInfo = state => state.serviceRequestInfo;
export const getCommonBenefits = state => state.commonBenefits;
export const getServiceRequestPayload = state => state.serviceRequestPayload;
export const getServiceTime = state => state.serviceTime;
export const getPartnerDetails = state => state.partnerDetails.partnerDetails;
export const getPepSession = state => state.pepSession;
export const getCustomTowToDestinationList = state => state.generalInfo.towToCustomDestinationList
export const getGoogleDestinationsResult = state => state.googleDestinationsResult;
export const getGoogleDetailsResult = state => state.googleDetailsResult;
export const isPayPerUseSelector = state => (
  (state.commonBenefits?.noBenefits && state.partnerDetails.partnerDetails.experience?.ppuEnabled)
    || (state.partnerDetails.partnerDetails.experience?.ppuEnabled && !state.partnerDetails.partnerDetails.experience?.benefitsEnabled)
);
export const hasBenefitsSelector = state => (
  state.partnerDetails.partnerDetails.experience?.benefitsEnabled && state.commonBenefits.benefits
);
export const partnerDetailsLoadedSelector = state => state.partnerDetails.loading === false && state.partnerDetails.error === false && !isEmpty(state.partnerDetails.partnerDetails.experience);
export const getRoute = state => state.partnerDetails.partnerDetails.route;
export const getDisplayCode = state => state.partnerDetails.partnerDetails.displayCode;
export const getRequestInfo = state => state.submitRequest;
export const getDestination = state => state.destination;
export const getPpuFuelPrice = state => state.generalInfo.ppuFuelPrice;
export const getCommonBenefitsStatus = state => state.commonBenefits?.status?.toUpperCase?.() || "";
export const getPlaces = state => state.nearByDestinations.places;
export const getMakemodel = state => state.makemodel;
export const getDriverList = state => state.driverList;
export const hasPepSessionSelector = state => isPayPerUseSelector(state) && state.pepSession.success === true && state.pepSession.session !== "";
export const getDuplicateRescue = state => state.generalInfo.duplicateRescue;
export const getTowCoverages = state => state.commonBenefits?.services?.find((service) => service.type === TOW)?.coverages || [];
export const getTowReason = state => state.serviceRequestPayload.towReason || "";
export const getAmazonConnectSelector = state => getPartnerDetails(state)?.amazonConnect || {};
export const getExpirationMileageByIndex = (state, index) => {
  const commonBenefits = getCommonBenefits(state);
  if(!commonBenefits?.vehicles || !_isArray(commonBenefits.vehicles) || commonBenefits.vehicles.length <= 0) {
    return false;
  }
  if(!commonBenefits.vehicles[index].expirationMileage) {
    return false;
  }
  return commonBenefits.vehicles[index].expirationMileage;
}
export const getTowDistance = state => state?.serviceRequestPayload?.towDistance?.scalar || "";
export const getBusinessName = state => state?.serviceRequestPayload?.businessName || "";
export const getPpuFlag = state => getServiceRequestPayload(state)?.serviceRequest?.ppuFlag || "N";
export const getServiceTransition = state => state?.serviceTransition || {};
