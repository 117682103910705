import React, { useState } from 'react'
import Form from "../form";
import FormField from "../form-field";
import CONSTANTS, { ZIP_LENGTH_REGEX } from "../../app-consts/appConstants";
import { regexUtil } from "../../utils/regexUtil";
import LookUpFormHeader from './Components/LookUpFormHeader';
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import {Button, Dropdown} from 'mesh-component-library';

const FirstNameLastNameZipLookup = ({ partnerDetails, dispatchCommonBenefitsLookup, showModal, closeModal}) => {
  const { displayCode } = partnerDetails;
  const [firstName, setFirstName] = useState({value:''});
  const [lastName, setLastName] = useState({value:''});
  const [zip, setZip] = useState({value:'', hasError: false});
  const getTranslatedText = useLocale();
  const continueClick = () => {
    const user = {};
    user.phoneLookup = false;
    user.details = {
      firstName: firstName.value,
      lastName: lastName.value,
      zip: zip.value,
    };
    user.lookupType = 'firstNameLastNameZip';
    dispatchCommonBenefitsLookup(user);

    localStorage.setItem('firstName', firstName.value);
    localStorage.setItem('lastName', lastName.value);
    localStorage.setItem('zip', zip.value);
  };

  const handleZipBackspace = (e) => {
    if(e.keyCode !== CONSTANTS.DELETE && e.keyCode !== CONSTANTS.BACKSPACE && e.target.value !== undefined && e.target.value.toString().length >= e.target.maxLength) {
      e.preventDefault();
    } else {
      setZip({
        ...zip,
        hasError: true,
      });
    }
  };

  const handleZipChange = (event) => {
    const { value } = event.target;
    let isValid = regexUtil(ZIP_LENGTH_REGEX, value);
    setZip({
      value,
      hasError : !isValid
    })
  };

  const validateCompleteForm = () => {
    return !(firstName.value &&
      lastName.value &&
      (!zip.hasError && zip.value.length === 5));
  };

  const onSubmit = () => {
    const content = <div>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}:</span> {firstName.value}</p>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}:</span> {lastName.value}</p>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.ZIP)}:</span> {zip.value}</p>
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  };

  const yesNoSelected = async ({target: {value}}) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }
  return (
    <React.Fragment>
      <LookUpFormHeader titleText={getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)} id={"we-need-some-info-to-find-your-benefits-name-zip"}/>
        <Form id="firstNameLastNameZipLookupForm"
              className="c-container-lookup__form"
              validateOnBlur={true}>
            <FormField
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME)}
              id="firstName"
              label={getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}
              name="firstName"
              className="firstName"
              value={firstName.value}
              onChange = {(event) => setFirstName({ value: event.target.value })}
              infoPopup={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_ACCOUNT)}
              required />

            <FormField
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME)}
              id="lastName"
              label={getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}
              name="lastName"
              className="lastName"
              value={lastName.value}
              onChange = {(event) => setLastName({ value: event.target.value })}
              required />

            <FormField
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE)}
              id="zipCode"
              label={getTranslatedText(TRANSLATION_CONSTANTS.ZIP_CODE)}
              type="number"
              name="zipCode"
              className="zipCode"
              maxLength={5}
              value={zip.value}
              onKeyDown = {handleZipBackspace}
              onChange = {handleZipChange}
              meta={getTranslatedText(TRANSLATION_CONSTANTS.AS_APPEARS_ACCOUNT)}
              hasError={zip.hasError}
              required/>

        <Button
          size='lg'
          hasUpgrade
          id="fnlnzip-lookup-continue"
          data-testid="first-last-zip-continue"
          disabled={validateCompleteForm()}
          onClick={onSubmit}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
        </Form>
    </React.Fragment>
  );
};

export default FirstNameLastNameZipLookup;
