import React from 'react';
import Provider from '../svg/Provider';
import Call from '../svg/Call';
import {TRANSLATION_CONSTANTS} from '../../app-consts/translations';
import {useLocale} from "../../contexts/LocaleContext";
import {FRIENDS_FAMILY_VERSION} from "../../app-consts/appConstants";
import {
  allowNumbersAndDashes,
  allowValidCompanyNameCharacters,
  allowValidNameCharacters
} from "../../utils/regexUtil";


const ProviderInfo = ({companyName, contactNumber, driverName, linkType}) => {

  const getTranslatedText = useLocale();
  const sanitizedNumber = allowNumbersAndDashes(contactNumber)
  const sanitizedCompanyName = allowValidCompanyNameCharacters(companyName)
  const sanitizedDriverName = allowValidNameCharacters(driverName)
  return (
    <>
      <div className="c-providerinfo">
        <Provider/>
        <div className="c-providerinfo__details">
          {sanitizedDriverName ? <h5>{sanitizedDriverName}</h5> : null}
          <p
            className="c-providerinfo__meta">{getTranslatedText(TRANSLATION_CONSTANTS.PROVIDER)}</p>
          <p className="c-providerinfo__company">{sanitizedCompanyName}</p>
        </div>
        { linkType !== FRIENDS_FAMILY_VERSION ?
          <a className="c-providerinfo__call"
             href={`tel:${sanitizedNumber}`}><Call/> {getTranslatedText(TRANSLATION_CONSTANTS.CALL)}
          </a>
          : null
        }
      </div>
    </>
  )
}

export default ProviderInfo;
