import {UPDATE_SERVICE_TRANSITION, REFRESH_REDUCERS} from './../action';

export default function (state = {}, action) {
  switch (action.type) {
    case UPDATE_SERVICE_TRANSITION:
      return {...state, ...action.payload};
    case REFRESH_REDUCERS:
      return {};
    default:
      return state;
  }
}
