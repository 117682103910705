export const COMMON_BENEFITS_START = "COMMON_BENEFITS_START";
export const BENEFITS_LOOKUP_START = "BENEFITS_LOOKUP_START";
export const COMMON_BENEFITS_SUCCESS = "COMMON_BENEFITS_SUCCESS";
export const COMMON_BENEFITS_FAILED = "COMMON_BENEFITS_FAILED";
export const NO_COMMON_BENEFITS = "NO_COMMON_BENEFITS";
export const REFRESH_REDUCERS = "REFRESH_REDUCERS";
export const LAST_SUCCESSFUL_SESSION_TYPE = "LAST_SUCCESSFUL_SESSION_TYPE";
export const NO_COMMON_BENEFITS_WITH_PREVIOUS = "NO_COMMON_BENEFITS_WITH_PREVIOUS";
export const CLEAR_PREVIOUS_BENEFITS = "CLEAR_PREVIOUS_BENEFITS";

export function commonBenefitsStart(data){
  return { type: COMMON_BENEFITS_START, payload:data };
}

export function benefitsLookupStart(data){
  return { type: BENEFITS_LOOKUP_START, payload:data };
}

export function commonBenefitsSuccess(data){
  return { type: COMMON_BENEFITS_SUCCESS, payload: data};
}

export function commonBenefitsFailed(e){
  return { type: COMMON_BENEFITS_FAILED, payload: e};
}

export function noCommonBenefits(data){
  return { type: NO_COMMON_BENEFITS,payload: data};
}

export function lastSuccessfulSessionType(data){
  return { type: LAST_SUCCESSFUL_SESSION_TYPE, payload: data};
}

export function clearCommonBenefits(){
  return { type: REFRESH_REDUCERS};
}

export function noCommonBenefitsWithPrevious(data){
  return { type: NO_COMMON_BENEFITS_WITH_PREVIOUS, payload: data};
}

export function clearPreviousBenefits(){
  return { type: CLEAR_PREVIOUS_BENEFITS};
}
