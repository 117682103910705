import React from "react";
import { useLocale } from "../../contexts/LocaleContext";
import CarAnn from "../../components/svg/CarAnn";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { ContainerQuery, DatePicker, Dropdown } from "mesh-component-library";
import StyledServiceTime from "./service-time.styles";
import {
  SERVICE_ACTION_TYPES,
  useServiceTimeReducer,
} from "./useServiceTimeReducer";
import { formatTimeAsString } from "../../utils/timeUtil";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { updateCurrentLocation, updateServiceTime as updateServiceTimeInRedux } from "../../action";
import { useHistory } from "react-router";
import { generatePath } from "../../utilities/RedirectUtils";
import { validateDateFormat, validateTimeFormat } from "../../utils/regexUtil";
import { Button } from 'mesh-component-library';

const ServiceTime = () => {
  const getTranslatedText = useLocale();
  const [state, localDispatch] = useServiceTimeReducer({
    currentDateTime: new Date(),
  });
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    selectedDate = "",
    selectedTime = "",
    timeOptions = [],
    minDate = new Date(),
    maxDate = new Date(),
  } = state;

  const confirmButtonEnabled = !isEmpty(selectedDate) && !isEmpty(selectedTime);

  const loadTimeDropdownOptions = () => {
    return timeOptions.map((timeOption) => {
      const initialTime = formatTimeAsString(timeOption[0]);
      const endTime = formatTimeAsString(timeOption[1]);
      return {
        value: initialTime,
        text: `${initialTime} - ${endTime}`,
      };
    });
  };

  const updateServiceDate = ({ date = new Date() }) => {
    localDispatch({
      type: SERVICE_ACTION_TYPES.SET_SELECTED_DATE,
      selectedDateObj: date,
    });
  };

  const updateServiceTime = (event) => {
    localDispatch({
      type: SERVICE_ACTION_TYPES.SET_SELECTED_TIME,
      selectedTime: event.target.value,
    });
  };

  const handleOnClickConfirm = () => {

    if (!validateDateFormat(selectedDate).enUS()) {
      return;
    }
    if (!validateTimeFormat(selectedTime).enUS()) {
      return;
    }

    let splitDate = selectedDate.split("/");
    let splitTimeMeridiem = selectedTime.split(" ");
    let splitTime = splitTimeMeridiem[0].split(":");
    let hours = Number(splitTime[0]);

    if(splitTimeMeridiem[1] === "PM" && hours < 12) {
      hours = hours + 12
    } else {
      hours = splitTime[0]
    }

    let requestedTime = splitDate[2] + "-" + splitDate[0] + "-" + splitDate[1] + "T" + hours + ":" + splitTime[1] +":00.000";
    dispatch(
      updateServiceTimeInRedux(requestedTime)
    );
    dispatch(updateCurrentLocation({ setFromLocationServices: false }));
    history.push(generatePath("disablementlocation"));
  };

  return (
    <ContainerQuery>
      <StyledServiceTime role="main">
        <CarAnn />
        <h1 id="when-to-schedule-time">
          {getTranslatedText(TRANSLATION_CONSTANTS.WHEN_HELP_TEXT)}
        </h1>
        <div>
          <DatePicker
            label={getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_DATE)}
            required
            value={selectedDate}
            minDate={minDate}
            maxDate={maxDate}
            isActiveOnFocus={true}
            onSubmit={updateServiceDate}
          />
          <br />
          <Dropdown
            label={getTranslatedText(TRANSLATION_CONSTANTS.PREFERRED_TIME)}
            helpText={getTranslatedText(TRANSLATION_CONSTANTS.ETA_SUGGESTION)}
            required
            options={loadTimeDropdownOptions()}
            defaultOption={{
              text: `${getTranslatedText(TRANSLATION_CONSTANTS.PICK_A_TIME)}`,
              value: "",
              disabled: true,
            }}
            value={selectedTime}
            onChange={updateServiceTime}
            data-testid="preferredTime"
          />
          <br />
          <Button
              size='lg'
              hasUpgrade
              id="date-and-time-button"
              disabled={!confirmButtonEnabled}
              onClick={handleOnClickConfirm}
              utils={{
                fullWidth: true
              }}>
              {getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_DATE_TIME)}
            </Button>
        </div>
      </StyledServiceTime>
    </ContainerQuery>
  );
};

export default ServiceTime;
