import React, {Component} from 'react';
import ReactDOM from 'react-dom';

export default class InfoPopup extends Component {
    constructor(props) {
        super(props)

        this.state = {
            popupActive: false
        }
    }

    UNSAFE_componentWillMount() {
        document.addEventListener('mousedown', this.handleClickOutside, true)
    }
    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside, true)
    }

    handleClickOutside = e => {
        const domNode = ReactDOM.findDOMNode(this);

        if(!domNode.contains(e.target)) {
          this.setState({
            popupActive : false
          });
        }

    }

    popupToggle = () => {
        this.setState ({
            popupActive: !this.state.popupActive
        })

    }
    render() {
        const {
            popupActive
        } = this.state
        return (
            <div>
                <a role="link" tabIndex="0" className="l-container-infoCircle" onClick={this.popupToggle}
                onKeyDown={this.popupToggle}>?</a>
                {popupActive &&
                    <div className="l-container-popupText" ref={node => this.node = node}>
                        <p>{this.props.popupText}</p>
                    </div>
                }
            </div>
        )
    }
}
