import React, { Component } from 'react';


class Visa extends Component {

    render() {

        return (

            <svg width="37" height="23" viewBox="0 0 37 23" xmlns="http://www.w3.org/2000/svg">
                <g fill="none">
                    <path fill="#00579F" d="M16.0761 18H13l1.924-12H18zM28 6.4946C27.4082 6.2596 26.4696 6 25.309 6c-2.9629 0-5.0493 1.5817-5.0621 3.843-.0246 1.6685 1.4938 2.5952 2.6295 3.1515 1.1608.5685 1.5553.9395 1.5553 1.4462-.0118.7782-.938 1.137-1.8017 1.137-1.1979 0-1.8397-.185-2.815-.618l-.3952-.1855L19 17.3818c.7037.321 2.0001.6057 3.3461.6182 3.1481 0 5.1976-1.5572 5.222-3.9671.0119-1.3224-.7899-2.3356-2.5185-3.1636-1.0494-.5315-1.692-.8899-1.692-1.4337.0123-.4944.5435-1.0008 1.7281-1.0008.9754-.0248 1.692.21 2.2349.4448l.2714.1234L28 6.4946z"
                    />
                    <path d="M30.7429 13.749c.2524-.6895 1.2239-3.3576 1.2239-3.3576-.0128.0256.252-.7021.4033-1.1489l.2142 1.034s.5806 2.8725.7066 3.4724h-2.548zM34.5269 6h-2.3712c-.7312 0-1.2868.2168-1.6022.9957L26 18h3.2166s.5295-1.4811.6433-1.8h3.936c.088.4212.3657 1.8.3657 1.8H37L34.5269 6zM11.7015 6l-3.055 8.1915-.3337-1.6613C7.748 10.6132 5.9767 8.5305 4 7.4949L6.7982 18h3.2987L15 6h-3.2985z"
                        fill="#00579F" />
                    <path d="M4.6865 6H.0475L0 6.223C3.6187 7.1389 6.0153 9.3465 7 12L5.9915 6.9277c-.166-.7049-.6762-.9044-1.305-.9277z"
                        fill="#FAA61A" />
                </g>
            </svg>

        )
    }
}

export default Visa; 