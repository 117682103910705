import React from 'react';
import Share from '../svg/Share';
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

const ShareButton = (props) => {
  const getTranslatedText = useLocale()
  return (
    <div className={'share-button'}
         onClick={() => props.shareRescueOnClick()}>
      <Share/>
      <p>{getTranslatedText(TRANSLATION_CONSTANTS.SHARE)}</p>
    </div>
  );
};

export default ShareButton;
