import React from "react";
import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations";
import TriageStep from "../../../components/triage-step";
import { useLocale } from "../../../contexts/LocaleContext";
import { getPartnerDetails } from "../../../selector";
import { useSelector } from "react-redux";
import {MEMBER} from "../../../app-consts/appConstants";

const LockoutTriageSteps = (props) => {
  const getTranslatedText = useLocale();
  const partnerDetails = useSelector(getPartnerDetails);

  const handleOnClickGetApp = () => {
    const isApple = /webOS|iPhone|iPad|iPod/.test(navigator.userAgent);
    const mobileStoreHref = isApple ? partnerDetails.iosAppUrl : partnerDetails.androidAppUrl;
    window.open(mobileStoreHref);
  };
  const isMemberRoute = partnerDetails?.route === MEMBER
  const lockoutMessage = isMemberRoute ? TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT_MEMBER : TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT
  let content = getTranslatedText(lockoutMessage).replace('{brand}', partnerDetails?.brand);
  return (
    <>
      <TriageStep
        step={1}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_TITLE
        )}
        content={content}
      />
      {!isMemberRoute ? <div className="d-flex flex-row ms-28px mt-3">
        <div className="ms-2">
          <button
            className="btn btn-blue btn-text-lightblue btn-bd-45BCE5"
            aria-label="Lockput Triage Steps Get App Button"
            onClick={handleOnClickGetApp}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.GET_THE_APP)}
          </button>
        </div>
      </div>: ""}
    </>
  );
};

export default LockoutTriageSteps;
