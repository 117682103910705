import React from 'react';
import { updateCurrentLocation } from '../../action';
import { connect } from 'react-redux';
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import { Button } from 'mesh-component-library';

const LocationPrimer = (props) => {
    const getTranslatedText = useLocale()
    const continueToDisablementLocationScreen = () => {
        props.setLocationPromptShown(true);
    };

    return (
        <div role="main">
            <div className="l-locationPrimer">
                <div className="arsMapCircle">
                    <img src={'/images/ars-map-circle.png'}
                        alt={'ARS Map Circle'}
                        srcSet='/images/ars-map-circle@2x.png 2x, /images/ars-map-circle@3x.png 3x' />
                </div>
                <div className="helpUs">
                    <label>{getTranslatedText(TRANSLATION_CONSTANTS.PRIMER_HELP)}</label>
                </div>
                <div className="span1">
                    <label dangerouslySetInnerHTML={{__html: getTranslatedText(TRANSLATION_CONSTANTS.SHARING_PHONE)}}></label>
                </div>
                <div className="span3">
                    <label dangerouslySetInnerHTML={{__html: getTranslatedText(TRANSLATION_CONSTANTS.CLICK_ALLOW)}}></label>
                </div>
                <div className="row row1">
                    <div className="mapDirection">
                        <img src='/images/branding-icons-auto-related-map-directions-circle.png'
                            srcSet='/images/branding-icons-auto-related-map-directions-circle@2x.png 2x,
                        /images/branding-icons-auto-related-map-directions-circle@3x.png 3x'
                            alt="mapDirection" />
                    </div>
                    <div className="locationText">
                    <label>{getTranslatedText(TRANSLATION_CONSTANTS.LOCATION)}</label>
                    </div>
                </div>
                <div className="row row2">
                    <div className="oval"></div>
                    <div className="span4">
                    <label>{getTranslatedText(TRANSLATION_CONSTANTS.PRIMER_DATA)}</label>
                    </div>
                </div>
                <div onClick={() => continueToDisablementLocationScreen()}>
                <Button
                  size='lg'
                  hasUpgrade
                  id="location-primer-shareLocation"
                  utils={{
                    fullWidth: true
                  }}>
                  {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                </Button>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        currentLocation: state.currentLocation
    };
}

export default connect(mapStateToProps,
    {
        updateCurrentLocation
    })(LocationPrimer);
