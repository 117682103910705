import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { datadogRum } from "@datadog/browser-rum";
import { useLocale } from "../../contexts/LocaleContext";
import {
  FUEL_DELIVERY,
  JUMP_START,
  LOCKOUT,
  TIRE_CHANGE,
} from "../../app-consts/appConstants";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { getPartnerDetails, getServiceInfo } from "../../selector";
import { refreshReducersToIntial, updateCurrentLocation } from "../../action";
import {
  generatePath,
  redirectNavigationUrl,
} from "../../utilities/RedirectUtils";
import JumpStartTriageSteps from "./service-triage-steps/JumpStartTriageSteps";
import LineSeparator from "../../icon-library/src/icons/LineSeparator";
import LockoutTriageSteps from "./service-triage-steps/LockoutTriageSteps";
import FuelDeliveryTriageSteps from "./service-triage-steps/FuelDeliveryTriageSteps";
import { TireChangeTriageSteps } from "./service-triage-steps/TireChangeTriageSteps";
import { Button } from 'mesh-component-library';

const ServiceTriageStepsComponents = {
  [JUMP_START]: (props) => <JumpStartTriageSteps {...props} />,
  [LOCKOUT]: (props) => <LockoutTriageSteps {...props} />,
  [FUEL_DELIVERY]: (props) => <FuelDeliveryTriageSteps {...props} />,
  [TIRE_CHANGE]: (props) => <TireChangeTriageSteps {...props} />
};

const TriageSteps = (props) => {
  const getTranslatedText = useLocale();
  const history = useHistory();
  const dispatch = useDispatch();

  const partnerDetails = useSelector(getPartnerDetails);
  const serviceInfo = useSelector(getServiceInfo);

  const { value: serviceValue = "" } = serviceInfo;

  const handleOnClickFixed = (event) => {
    event.stopPropagation();
    event.preventDefault();
    dispatch(refreshReducersToIntial());
    localStorage.clear();
    localStorage.setItem("triageEventFixedBySteps", "YES");
    let url = redirectNavigationUrl(partnerDetails);
    datadogRum.addAction(`Triage Steps Fixed ${serviceValue}`);
    history.push(generatePath(url));
  };

  const handleOnClickContinue = (event) => {
    event.stopPropagation();
    event.preventDefault();
    datadogRum.addAction(`Triage Steps Continue ${serviceValue}`);
    localStorage.setItem("serviceText", serviceValue);

    if (serviceValue === FUEL_DELIVERY) {
      dispatch(updateCurrentLocation({setFromLocationServices: false}))
      history.push(generatePath('disablementlocation'));
    } else {
      history.push(generatePath("triage"));
    }
  };

  const ServiceTriageStepsComponent =
    ServiceTriageStepsComponents[serviceValue];
  return (
    <div className="triage-steps d-flex flex-column justify-content-start p-2">
      <div id="triage-steps-header" className="triage-steps-header text-center mt-4 mb-3">
        {getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TITLE)}
      </div>
      <div className="mb-1">
        <LineSeparator />
      </div>
      <div className="triage-steps-content d-flex flex-column mx-3 mt-3">
        {ServiceTriageStepsComponent && (
          <ServiceTriageStepsComponent {...props} />
        )}
      </div>
      <div className="triage-steps-footer d-flex flex-column mt-3">
        <div id="yes-fixed-button" className="mb-3">
          <button
            className="btn btn-transparent border-light-blue"
            aria-label="Triage Steps Fixed Button"
            onClick={handleOnClickFixed}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED)}
          </button>
        </div>
        <div id="no-not-fixed-button" className="mb-3">
          <Button
            size='lg'
            hasUpgrade
            onClick={handleOnClickContinue}
            id="Triage Steps Continue Button"
            aria-label="Triage Steps Continue Button"
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_CONTINUE)}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TriageSteps;
