import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {datadogRum} from '@datadog/browser-rum';
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import { Button } from 'mesh-component-library';
const SiteDown = (props) => {
  const getTranslatedText = useLocale();

  useEffect(() => {
    document.title = 'Site Down | Roadside Assistance';
  }, [])

  const handlePhoneCall = () => {
    let partnerPhone = props.partnerDetails.phone;
    datadogRum.addAction(`User called the Customer Service from Sitedown page. Last page was ${props.history?.location?.pathname}`);
    const phone = partnerPhone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' + phone;
  }

  return (
    <div role="main">
      <div className="c-sitedown">
        <svg className="c-sitedown--exclamation" width='32' height='32' viewBox='0 0 32 32'>
          <path fill='#CF102D' fillRule='evenodd' d='M16 0c8.836 0 16 7.164 16 16s-7.164 16-16 16S0 24.836 0 16 7.164 0 16 0zm-.137 23.418c-1.226 0-2.25.993-2.25 2.25a2.25 2.25 0 0 0 4.5 0 2.251 2.251 0 0 0-2.25-2.25zm-.113-2.94c.786 0 1.509-1.27 1.61-2.825l.633-9.83C18.093 6.272 17.083 5 15.75 5c-1.333 0-2.343 1.27-2.243 2.824l.633 9.83c.101 1.554.824 2.824 1.61 2.824z'/>
        </svg>
        <h1 className="c-sitedown--title" id="error-page">{getTranslatedText(TRANSLATION_CONSTANTS.HOW_EMBARRASSING)}</h1>
        <p className="c-sitedown--body">
          {getTranslatedText(TRANSLATION_CONSTANTS.TECHNICAL_DIFFICULTIES)}
        </p>
        <Button
          size='lg'
          hasUpgrade
          tabIndex="0"
          id="call-for-help-now"
          onClick={handlePhoneCall}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CALL_HELP_NOW)}
        </Button>
        <hr/>
      </div>
    </div>
  )

}

function mapStateToProps(state) {
  return {
    partnerDetails: state.partnerDetails.partnerDetails
  };
}

export default connect(mapStateToProps, null)(SiteDown);

