import _isEmpty from "lodash/isEmpty";
import _values from "lodash/values";
import _includes from "lodash/includes";
import _isNaN from "lodash/isNaN";
import { datadogRum } from "@datadog/browser-rum";

export const experimentalMonitor = (...experiments) => {
  return (expAction = "", customAttrs = {}, ...expNames) => {
    if (_isEmpty(experiments)) {
      return;
    }
    if (!expAction || !customAttrs || _isEmpty(expNames)) {
      return;
    }
    _values(experiments)
      .filter((exp) => _includes(expNames, exp.name))
      .forEach((exp) => {
        datadogRum.addAction(
          `${exp.name}, ${exp.group}, ${expAction}`,
          customAttrs
        );
      });
  };
};
