export const UPDATE_IS_PRIMARY_LOOKUP = "UPDATE_IS_PRIMARY_LOOKUP";
export const UPDATE_PPU_FUEL_PRICE = "UPDATE_PPU_FUEL_PRICE";
export const UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST = "UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST";
export const CLEAR_TOW_TO_CUSTOM_DESTINATION_LIST = "CLEAR_TOW_TO_CUSTOM_DESTINATION_LIST";
export const UPDATE_DUPLICATE_RESCUE_IS_ACTIVE = "UPDATE_DUPLICATE_RESCUE_IS_ACTIVE";
export const CLEAR_DUPLICATE_RESCUE = "CLEAR_DUPLICATE_RESCUE";
export const UPDATE_RESCUE_DETAILS = "UPDATE_RESCUE_DETAILS";

export function updateIsPrimaryLookup(data){
    return { type: UPDATE_IS_PRIMARY_LOOKUP, payload: data};
}

export function updatePpuFuelPrice(data){
    return { type: UPDATE_PPU_FUEL_PRICE, payload: data};
}

export function updateTowToCustomDestinationList(data) {
  return {type: UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST, payload: data };
}

export function clearTowToCustomDestinationList() {
  return { type: CLEAR_TOW_TO_CUSTOM_DESTINATION_LIST };
}

export function updateDuplicateRescueIsActive(data) {
  return {type: UPDATE_DUPLICATE_RESCUE_IS_ACTIVE, payload: data };
}

export function clearDuplicateRescue() {
  return { type: CLEAR_DUPLICATE_RESCUE };
}

export function updateRescueDetails(data) {
  return { type: UPDATE_RESCUE_DETAILS, payload: data };
}
