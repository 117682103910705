import React from 'react';
import {Box, Flex, Grid, Type} from 'mesh-component-library';
import StyledCCOffer from "./cc-offer.styles";
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';

const CreditCardOffer = ({displayCode}) => {
  const getTranslatedText = useLocale();

  return (
    <StyledCCOffer>
      <Grid verticalAlign="middle">
        <Grid.Column col={9}>
          <Type
            element="h3"
            size="xl"
            weight="semibold"
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_TITLE, displayCode)}
          </Type>
          <Type element="p">
            {getTranslatedText(TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_CONTENT, displayCode)}
          </Type>
        </Grid.Column>
        <Grid.Column col={3}>
          <Flex>
            <Box hasBorder={false}>
              <p/>
            </Box>
          </Flex>
          <Flex justifyContent="end">
            <Box
              className="cc-o-box"
              hasBorder={false}>
              <a
                onClick={() => datadogRum.addAction('Pep Boys Credit Card Offer clicked')}
                target="_blank"
                className="cc-o"
                href="https://etail.mysynchrony.com/eapply/eapply.action?uniqueId=3CFA7859F753366B9F1906A2283DB94553C347EA64DFDD35&client=Pep%20Boys"
              >
                {getTranslatedText(TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_PREQUALIFY, displayCode)}
              </a>
            </Box>
          </Flex>
        </Grid.Column>
      </Grid>
    </StyledCCOffer>
  );
};

export default CreditCardOffer;
