import React, {useState} from "react";
import ModalDialog from '../modal-dialog';
import FormField from "../form-field";
import {datadogRum} from "@datadog/browser-rum";
import FormOption from "../form-option";
import { Grid, Button } from 'mesh-component-library';
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {saveRescueDetails} from "../../api";

export const CallForHelpFeedbackModal = (props) => {
  const [callForHelpButtonDisabled, setCallForHelpButtonDisabled] = useState(true)
  const [freeFormInput, setFreeFormInput] = useState("")
  const getTranslatedText = useLocale();

  const enableCallForHelp = () => {
    if(callForHelpButtonDisabled) {
      setCallForHelpButtonDisabled(false);
    }
    const text = document.getElementById("CallForHelpFeedbackInput").value;

    setFreeFormInput(text)
   }

   const callForHelp = (info) => {
    if (freeFormInput) {
       datadogRum.addAction(`CallForHelpFeedback-other`,{feedbackDetails: info});
     } else {
       datadogRum.addAction(`CallForHelpFeedback-${info}`);
     }
     if(props.omniStreamlined) {
       saveRescueDetails(props.serviceRequestPayload, props.partnerCode, 'end')
     }
    const callbackPhone = props.phone || localStorage.getItem('callbackNumber');
     window.location.href = 'tel:' + callbackPhone;
     props.closeModal();
   }

    return (
      <div className='call-for-help-feedback-modal'>
        <ModalDialog
          id="callForHelpFeedbackModal"
          title={getTranslatedText(TRANSLATION_CONSTANTS.SORRY_TO_SEE_YOU_GO)}
          hideTrigger
          showCloseBtn={true}
          isActive={true}
          onClose={props.closeModal}
          footer={<Grid verticalAlign="middle">
            <Grid.Column>
              <Button
                size='lg'
                hasUpgrade
                onClick={() => callForHelp(freeFormInput)}
                onKeyDown={() => callForHelp(freeFormInput)}
                hidden={callForHelpButtonDisabled}
                utils={{
                  fullWidth: true
                }}>
              {getTranslatedText(TRANSLATION_CONSTANTS.CALL_FOR_HELP)}
              </Button>
            </Grid.Column>
            <Grid.Column utils={{vrBottom: 'md'}}>
              <Button
                size='lg'
                hasUpgrade
                isPrimary={false}
                onClick={props.closeModal}
                onKeyDown={props.closeModal}
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.GO_BACK)}
              </Button>
            </Grid.Column>
          </Grid>
          }
         >
          <div className="c-fuelType" data-dd-privacy="allow">
            <div className="heading-2">
              <label>{getTranslatedText(TRANSLATION_CONSTANTS.CALL_FOR_HELP_DESCRIPTION)}</label>
            </div>
            <FormOption
              onClick={(event) => callForHelp(event.target.value)}
              onKeyDown={(event) => callForHelp(event.target.value)}
              value={getTranslatedText(TRANSLATION_CONSTANTS.ISSUE_IN_ROADSIDE_COVERAGE)}
              type="radio"
            >{getTranslatedText(TRANSLATION_CONSTANTS.ISSUE_IN_ROADSIDE_COVERAGE)}</FormOption>
            <br/>
            <FormOption
              onClick={(event) => callForHelp(event.target.value)}
              onKeyDown={(event) => callForHelp(event.target.value)}
              value={getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE_SERVICE_FOR_FUTURE_TIME)}
              type="radio"
            >{getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE_SERVICE_FOR_FUTURE_TIME)}</FormOption>
            <br/>
            <FormOption
              onClick={(event) => callForHelp(event.target.value)}
              onKeyDown={(event) => callForHelp(event.target.value)}
              value={getTranslatedText(TRANSLATION_CONSTANTS.TALK_TO_PERSON)}
              type="radio"
            >{getTranslatedText(TRANSLATION_CONSTANTS.TALK_TO_PERSON)}</FormOption>
            <br/>
            <FormOption
            onClick={(event) => callForHelp(event.target.value)}
            onKeyDown={(event) => callForHelp(event.target.value)}
            value={getTranslatedText(TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION_WRONG)}
            type="radio"
            >{getTranslatedText(TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION_WRONG)}</FormOption>
          </div>
          <FormField
            type="textarea"
            regex={/\n|\r/g}
            maxLength={500}
            showCharCount
            placeholder={getTranslatedText(TRANSLATION_CONSTANTS.OTHER_SPECIFY)}
            aria-label="Other, please specify"
            value={freeFormInput}
            onChange={enableCallForHelp}
            id="CallForHelpFeedbackInput"
          />
        </ModalDialog>
      </div>
    )
  }



