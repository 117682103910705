export const UPDATE_CURRENT_LOCATION = "UPDATE_CURRENT_LOCATION";
export const CLEAR_CURRENT_LOCATION = "CLEAR_CURRENT_LOCATION";
export const CLEAR_LOCATION_ERROR = "CLEAR_LOCATION_ERROR";

export function updateCurrentLocation(data){
  return { type: UPDATE_CURRENT_LOCATION, payload: data};
}

export function clearLocationError(){
  return { type: CLEAR_LOCATION_ERROR};
}

export function clearCurrentLocation(){
  return { type: CLEAR_CURRENT_LOCATION};
}

