import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import { getPartnerDetails } from "../../selector";
import { allowNumbersAndDashes } from "../../utils/regexUtil";
import Call from "../svg/Call";

const RentalExchange = ({ ...restProps }) => {
  const getTranslatedText = useLocale();

  const [showRental, setShowRental] = useState(false);

  const partnerDetails = useSelector(getPartnerDetails);
  const sanitizedNumber = allowNumbersAndDashes(partnerDetails.phone);

  useEffect(() => {
    if (partnerDetails?.experience?.displayRentalExchange && sanitizedNumber) {
      setShowRental(true);
    }
  }, []);

  return (
    showRental && (
      <div className="d-flex flex-row rental-exchange-main p-4">
        <div className="d-flex flex-column rental-exchange-section-1">
          <div className="rental-exchange-title text-start">
            {getTranslatedText(TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_TITLE)}
          </div>
          <div className="rental-exchange-content text-start mt-2">
            {getTranslatedText(TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_CONTENT)}
          </div>
        </div>
        <div className="d-flex mt-3 ms-3 me-4">
          <div className="rental-exchange-call-icon">
            <a
              className="rental-exchange-call"
              href={`tel:${sanitizedNumber}`}
              data-testid={`tel-${sanitizedNumber}`}
            >
              <Call /> {getTranslatedText(TRANSLATION_CONSTANTS.CALL)}
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default RentalExchange;
