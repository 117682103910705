import {takeEvery, put, call, select} from 'redux-saga/effects';
import {datadogRum} from "@datadog/browser-rum";
import {
  SERVICE_REQUEST_INFO_REQUESTED,
  SERVICE_REQUEST_INFO_SUCCESS,
  SERVICE_REQUEST_INFO_FAILED
} from '../action';

import {serviceRequestInfoApi} from '../api';
import * as selectors from "../selector";

export function* serviceRequestInfo(action) {
  try {
    const partnerDetails = yield select(selectors.getPartnerDetails);
    const {appId, partnerCode} = partnerDetails;
    const response = yield call(serviceRequestInfoApi, action.payload, appId, partnerCode);
    const payload = yield response.data;
    yield put({type: SERVICE_REQUEST_INFO_SUCCESS, payload: payload});
  } catch (e) {
    datadogRum.addError(new Error(`Could not retrieve service info ${e}`))
    yield put({type: SERVICE_REQUEST_INFO_FAILED});
  }
}

export function* watchserviceRequestInfoSaga() {
  yield takeEvery(SERVICE_REQUEST_INFO_REQUESTED, serviceRequestInfo);
}
