import React from 'react';

function Star({color1 = '', color2 = '', baseColor = ''}) {
  return (
    <svg
      className="star"
      viewBox="0 0 12 11"
      >
      <title>Star</title>

        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="star">

            {baseColor.includes('orange') ?
              <g>
                <polygon id="star-left" fill={color1 === 'blue' ? '#E7711C' : '#CDCDCD'} points="6 8.84065445 2.29179607 10.6619294 3 6.80440712 -1.77635684e-15 4.07249465 4.14589803 3.50968974 6 0"></polygon>
                <polygon id="star-right" fill={color2 === 'blue' ? '#E7711C' : '#CDCDCD'}  points="6 8.84065445 6 0 7.85410197 3.50968974 12 4.07249465 9 6.80440712 9.70820393 10.6619294"></polygon>
              </g>
            :
              <g>
                <polygon id="star-left" fill={color1 === 'blue' ? '#ffffff' : '#8099CF'} points="6 8.84065445 2.29179607 10.6619294 3 6.80440712 -1.77635684e-15 4.07249465 4.14589803 3.50968974 6 0"></polygon>
                <polygon id="star-right" fill={color2 === 'blue' ? '#ffffff' : '#8099CF'}  points="6 8.84065445 6 0 7.85410197 3.50968974 12 4.07249465 9 6.80440712 9.70820393 10.6619294"></polygon>
              </g>
            }
          </g>
      </g>
    </svg>
  );
}

export default Star;

