import React, { Component } from 'react';
import classnames from 'classnames'

class SlideArrow extends Component {

  render() {
    const {
      color,
      size,
      direction,
      rotate,
      className,
      onClick,
      role
    } = this.props;

    let fill = '#94a1b0';
    let width = '14';
    let height = '8';

    if(size === 'large') {
      width = '16'
      height = '9'
      fill = '#4e5969'
    }

    if (color === 'light') {
      fill = '#e3e9ed';
    } else if (color === "white") {
      fill= '#ffffff'
    } else if(color === "focus") {
      fill = '#6b7789'
    }

    let names = classnames(className, direction, size, rotate)

    return(
      <svg role={role || ''} xmlns='http://www.w3.org/2000/svg' onClick={onClick} className={names} data-testid="slideArrow" id="slideArrow" width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <path fill={fill} fillRule='evenodd' d='M7 2.258L1.463 7.804a.673.673 0 0 1-.95 0l-.317-.317a.676.676 0 0 1 0-.952L6.525.196a.673.673 0 0 1 .95 0l6.329 6.339a.676.676 0 0 1 0 .952l-.317.317a.673.673 0 0 1-.95 0L7 2.258z'
        />
      </svg>
    )}
}

export default SlideArrow;
