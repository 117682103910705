import { LockoutC, TirechangeC, JumpstartC, FueldelivC, TowC, RoadsideasstF, LockoutF, FueldelivF, TirechangeF, JumpstartF } from '../../icon-library';

const renderIcon = (icon) => {
    switch (icon) {
      case 'Tow':
        return TowC;
        case 'Lockout':
            return LockoutC;
        case 'Lock-out':
            return LockoutC;
        case 'Fuel Delivery':
            return FueldelivC;
        case 'Tire Change':
            return TirechangeC;
        case 'Jump Start':
        case 'Start':
            return JumpstartC;
      default:
        return 'check';
    }
  }

export const renderServicePanelIcon = (icon) => {
  switch (icon) {
    case "Tow":
      return RoadsideasstF;
    case "Lockout":
    case "Lock-out":
      return LockoutF;
    case "Fuel Delivery":
      return FueldelivF;
    case "Tire Change":
      return TirechangeF;
    case "Jump Start":
    case "Start":
      return JumpstartF;
    default:
      return "check";
  }
};

export default renderIcon;
