import {sendJacadaRequest} from "../api";

export const sendFuelTypeToJacada = (event) => {
  let SVC_DETL_TYPE_ID='';
  if(event.currentTarget.value==='Unleaded'){
    SVC_DETL_TYPE_ID=83;
  }else{
    SVC_DETL_TYPE_ID=82;
  }

  let eventsData=[];
  eventsData.push(
    {
      'name':'SVC_DETL_TYPE_ID',
      'value':SVC_DETL_TYPE_ID
    });
  let jacadaRequestData = {
    'externalSystemId': localStorage.getItem('conversationId'),
    'events': eventsData
  }
  sendJacadaRequest(jacadaRequestData);
}


export const sendVehicleToJacada = (vehicle) => {
  let eventsData=[{
    'name':'make_name',
    'value': vehicle.make
  },
    {
      'name':'model_name',
      'value': vehicle.model
    },
    {
      'name':'model_yr',
      'value': vehicle.year
    },
    {
      'name':'color_name',
      'value':vehicle.color
    },
    {
      'name':'equipmentClass',
      'value':vehicle.equipmentClass
    }];

  if(vehicle.vin) {
    eventsData.push({
      'name':'vehicle_idntfctn_nbr',
      'value':vehicle.vin
    })
  }

  let jacadaRequestData = {
    'externalSystemId': localStorage.getItem('conversationId'),
    'events': eventsData
  }
  sendJacadaRequest(jacadaRequestData);
}

export const sendVinToJacada = (vin) => {
  let eventsData = [];
  eventsData.push(
    {
      'name': 'vehicle_idntfctn_nbr',
      'value': vin
    }
  );
  let jacadaRequestData = {
    'externalSystemId': localStorage.getItem('conversationId'),
    'events': eventsData
  }
  sendJacadaRequest(jacadaRequestData);
};

export const sendDisablementLocationToJacada = (disablementLocation) => {
  let jacadaRequestData = {
    'externalSystemId': localStorage.getItem('conversationId'),
    'events': [
      {
        'name': 'disbl_latitd_nbr',
        'value': disablementLocation.latitude
      },
      {
        'name': 'disbl_longitd_nbr',
        'value': disablementLocation.longitude
      },
      {
        'name': 'disbl_line1_addr',
        'value': disablementLocation.addressLine1
      },
      {
        'name': 'disbl_city_name',
        'value': disablementLocation.city

      },
      {
        'name': 'disbl_st_cd',
        'value': disablementLocation.state
      },
      {
        'name': 'disbl_zip_cd',
        'value': disablementLocation.zipCode
      },
      {
        'name': 'disbl_cntry_cd',
        'value': disablementLocation.country
      }]
  }
  sendJacadaRequest(jacadaRequestData);
}

export const sendAutoDetectedDisablementLocationToJacada = (formattedAddressDetails) => {
  let jacadaRequestData = {
    'events': [{
      'name': 'disbl_line1_addr',
      'value': formattedAddressDetails.addressLine1
      },
      {
        'name': 'disbl_line2_addr',
        'value': ''
      },
      {
        'name': 'disbl_city_name',
        'value': formattedAddressDetails.city
      },
      {
        'name': 'disbl_st_cd',
        'value': formattedAddressDetails.state
      },
      {
        'name': 'disbl_zip_cd',
        'value': formattedAddressDetails.zipCode
      },
      {
        'name': 'disbl_cntry_cd',
        'value': formattedAddressDetails.country
      },
      {
        'name': 'disbl_latitd_nbr',
        'value': formattedAddressDetails.latitude
      },
      {
        'name': 'disbl_longitd_nbr',
        'value': formattedAddressDetails.longitude
      },
      {
        'name': 'location_detected',
        'value': true
      }]
  };
  sendJacadaRequest(jacadaRequestData);
}

export const sendDestinationLocationToJacada = ({
                                                  addressLine1 = '',
                                                  addressLine2 = '',
                                                  latitude = '',
                                                  longitude = '',
                                                  city = '',
                                                  state = '',
                                                  zipCode = '',
                                                  country = '',
                                                  dealerId = '',
                                                  bizName = '',
                                                }) => {
  let eventsData = [];

  eventsData.push(
    {'name': 'dest_line1_addr', 'value': addressLine1},
    {'name': 'dest_latitd_nbr', 'value': latitude},
    {'name': 'dest_longitd_nbr', 'value': longitude},
    {'name': 'dest_city_name', 'value': city},
    {'name': 'dest_st_cd', 'value': state},
    {'name': 'dest_zip_cd', 'value': zipCode},
    {'name': 'dest_cntry_cd', 'value': country}
  );

  if (addressLine2) {
    eventsData.push({'name': 'dest_line2_addr', 'value': addressLine2});
  }

  if (dealerId) {
    eventsData.push({'name': 'dealer_addr_id', 'value': dealerId});
  }

  if (bizName) {
    eventsData.push({'name': 'biz_name', 'value': bizName});
  }

  let jacadaRequestData = {
    'externalSystemId': localStorage.getItem('conversationId' || ''),
    'events': eventsData
  }
  sendJacadaRequest(jacadaRequestData);
}

export const sendAutoDetectionOfLocationToJacada = (decision) => {
  let jacadaRequest = {
    'externalSystemId': localStorage.getItem('conversationId' || ''),
    'events': [{
      'name': 'location_detected',
      'value': decision
    }]
  }
  sendJacadaRequest(jacadaRequest);
}

export const sendScheduleServiceFlag = () => {
  let jacadaRequest = {
    'externalSystemId': localStorage.getItem('conversationId' || ''),
    'events': [{
      'name': 'q_ind',
      'value': 'Y'
    }]
  }
  sendJacadaRequest(jacadaRequest);
}


export const sendScheduleServiceDateTime = (requestedTime) => {
  let jacadaRequest = {
    'externalSystemId': localStorage.getItem('conversationId' || ''),
    'events': [{
      'name': 'req_arrvl_datetime',
      'value': requestedTime
    }]
  }
  sendJacadaRequest(jacadaRequest);
}

export const sendAcDelcoEventToJacada = (jobId, phone, promoCode, partnerCode) => {
  let jacadaAcDelcoRequest = {
    'externalSystemId': localStorage.getItem('conversationId' || ''),
    'events': [{
      'name': 'original_ani_nbr',
      'value': phone
    },
    {
      'name': 'disblmnt_prmotn_cd',
      'value': promoCode
    },
    {
      'name': 'partnr_cd',
      'value': partnerCode
    },
    {
      'name': 'job_id',
      'value': jobId
    },
    {
      'name': 'authenticated',
      'value': "Y"
    }]
  }
  sendJacadaRequest(jacadaAcDelcoRequest);
}
