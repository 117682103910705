import {all, call, put, select, takeEvery} from 'redux-saga/effects'

import {
  ADD_GOOGLE_PLACE_ID_TO_DESTINATION,
  ERROR_GETTING_NEAR_BY_DESTINATIONS,
  INVALID_DESTINATION_ADDRESS,
  NO_TOW_LOCATIONS_FOUND,
  REQUEST_GOOGLE_NEAR_BY_SEARCH,
  UPDATE_DESTINATION,
  UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS,
  UPDATE_NEAR_BY_DESTINATIONS,
  UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST,
  ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS,
  REQUEST_GOOGLE_MORE_DETAILS_NEAR_BY_SEARCH,
  ADD_MORE_NEAR_BY_DESTINATIONS
} from './../action'
import {
  getGoogleNearBySearch,
  getGooglePlaceDetails,
  getGooglePlaceId,
  getGoogleRouteInfo,
  getPartnerSpecificTowToLocations,
  getOmniPartnerToLocations
} from './../api'
import {datadogRum} from "@datadog/browser-rum";
import {
  getCommonBenefits,
  getCurrentLocation,
  getCustomTowToDestinationList,
  getPartnerDetails,
  getServiceRequestPayload,
  getPlaces,
  isPayPerUseSelector
} from './../selector'
import {
  addSearchableNameForDestination,
  formatAddress,
  getDealerDisplayName,
  getLatLngAsValue,
  setOpenFromCloseAtToDefaultIfMissing
} from '../utils/mapsLocationHelper'
import titleCase from "../utils/titleCase";
import {
   determineLocale, getTranslatedTextByLocale
} from "../contexts/LocaleContext";
import {en_CA, fr_CA} from "../app-consts/appConstants";
import { formatTimeAsString, EN_US, HOUR12 } from '../utils/timeUtil';
import { TRANSLATION_CONSTANTS } from '../app-consts/translations';
import { lowerCase, startCase } from 'lodash';

export function* getNearByDestinations(action) {
  const {placeService, location, appId} = action.payload;
  let places = [];
  try{
    let destinations;
    const currentLocation = yield select(getCurrentLocation);
    const country = currentLocation.address_components.find(function (component) {
      return (component.types.includes("country"))
    });
    let countryCode = country.short_name === 'US' ? 'USA' : 'CA';
    const partnerDetails = yield select(getPartnerDetails);
    const {experience: {towToEnabled, ppuEnabled, benefitsEnabled, towToLocationType }, partnerCode, promoCode:ppuPromoCode = ""} = partnerDetails;
    if(towToEnabled){
      let result;
      if(!!towToLocationType) {
        const isPayPerUse = yield select(isPayPerUseSelector);
        const { promoCode:memberPromoCode } = yield select(getCommonBenefits);
        const promoCode = isPayPerUse ? ppuPromoCode : memberPromoCode;
        const { utc_offset } = currentLocation
        result = yield call(getOmniPartnerToLocations,
          getLatLngAsValue(location),
          appId,
          partnerCode,
          countryCode,
          towToLocationType,
          promoCode,
          isPayPerUse,
          utc_offset);
      } else {
        result = yield call(getPartnerSpecificTowToLocations, getLatLngAsValue(location), appId, partnerCode, countryCode);
      }
      if (result?.customDestinationList?.length > 0) {
        let customTowToDestinations = addSearchableNameForDestination(result.customDestinationList, partnerCode);
        yield put({type: UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST, payload: customTowToDestinations})
      }
      let towToDestinations = setOpenFromCloseAtToDefaultIfMissing(result?.destinations);
      places = [...towToDestinations];
      const nearestFiveLocations = towToDestinations?.filter((place) => place?.opening_hours?.openfrom && place?.opening_hours?.closeat).slice(0,5)
      destinations = yield all(nearestFiveLocations.map((destination) => call(getPartnerDestinationDetails, {service: placeService, destination, origin: currentLocation.geometry.location},  ppuEnabled, benefitsEnabled)));
    } else {
      let searchResult = yield call(getGoogleNearBySearch, placeService, location)
      if(searchResult?.length){
        const nearestFiveLocations = searchResult.slice(0,5).filter(d => d.vicinity?.split(",").length>1)
        destinations = yield all(nearestFiveLocations.map((destination) => call(getTowInfoDetails, {placeId: destination.place_id, origin: currentLocation.geometry.location, destination:destination})))
      }
    }

    destinations = destinations.filter(d => d)

    if(!destinations.length){
      yield put({type: NO_TOW_LOCATIONS_FOUND});
    }
    else {
      destinations.sort((a,b) => a.towInfo.distanceFromCurrentLocation - b.towInfo.distanceFromCurrentLocation);
      yield put({
        type: UPDATE_NEAR_BY_DESTINATIONS,
        payload: {
          places,
          destinations
        }
      });
    }
  } catch (error) {
    yield put({type: ERROR_GETTING_NEAR_BY_DESTINATIONS})
    datadogRum.addError(new Error(`Error in getNearByDestinations ${error}`));
  }
}

export function* addingMoreNearByDestination(action) {
  const { service, destinations } = action.payload;
  try {
    const partnerDetails = yield select(getPartnerDetails);
    const {
      experience: { towToEnabled, ppuEnabled, benefitsEnabled },
    } = partnerDetails;
    let newDestinations = [];
    if (towToEnabled) {
      const currentLocation = yield select(getCurrentLocation);
      const places = yield select(getPlaces);

      const newFiveLocations = places
        .filter(
          (place) =>
            place?.opening_hours?.openfrom && place?.opening_hours?.closeat
        )
        .slice(destinations.length, destinations.length + 5);

      newDestinations = yield all(
        newFiveLocations.map((destination) =>
          call(
            getPartnerDestinationDetails,
            { service, destination, origin: currentLocation.geometry.location },
            ppuEnabled,
            benefitsEnabled
          )
        )
      );
    }
    yield put({
      type: ADD_MORE_NEAR_BY_DESTINATIONS,
      payload: {
        newDestinations,
      },
    });
  } catch (error) {
    yield put({type: ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS})
    datadogRum.addError(new Error(`Error in addingMoreNearByDestination ${error}`));
  }
}

function* getTowInfoDetails({placeId, origin, destination}){
  try {
    const partnerDetails = yield select(getPartnerDetails);
    const commonBenefits = yield select(getCommonBenefits);

    const {serviceCost: {serviceCosts}} = partnerDetails.experience;
    let services = commonBenefits?.benefits ? commonBenefits.services : serviceCosts
    const benefitsTowCostInfo = services?.find(service => service.type === 'Tow' || service.serviceType === 'Tow')

    let destinationDetails = destination;

    let routeInfo = yield call(getGoogleRouteInfo, origin, placeId);
    destinationDetails.towInfo = setDestinationTowInfo(routeInfo?.distance.value, benefitsTowCostInfo, partnerDetails.experience, commonBenefits.benefits)
    if(destinationDetails.towInfo){
      return destinationDetails
    }
  }catch(error){
    datadogRum.addError(new Error(`Error while getting Tow Info Details: ${error}`));
  }
}

function* addGooglePlaceIdToDestination({payload}) {
  let placeIdResult = yield call(getGooglePlaceId, payload.query, payload.service);

  let newDestinationInformation = {
    service: payload.service,
    placeId: placeIdResult.place_id,
    origin: payload.origin,
    address: payload.query
  }
  yield put({type: UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS, payload: newDestinationInformation})
}

function* getPartnerDestinationDetails({service, destination, origin},  ppuEnabled, benefitsEnabled) {
  try {
    const partnerDetails = yield select(getPartnerDetails);
    const serviceRequestPayload = yield select(getServiceRequestPayload);
    const commonBenefits = yield select(getCommonBenefits);

    let benefitsTowCostInfo;
    if(serviceRequestPayload.serviceRequest.loggedIn === "N" || (!ppuEnabled && !benefitsEnabled)) {
      let {serviceCost: {serviceCosts: towServices}} = partnerDetails.experience;
      benefitsTowCostInfo = towServices.find((service) => service.serviceType === 'Tow')
    } else {
      let {services: towServices} = commonBenefits;
      benefitsTowCostInfo = towServices.find((service) => service.type === 'Tow')
    }

    let destinationName = startCase(lowerCase(destination.name));
    let query = destinationName + ' ,' + titleCase(destination.vicinity);
    if(partnerDetails.displayCode === "PEP") query = "Pep Boys, " + titleCase(destination.vicinity)
    let partnerDestinationDetails = destination;

    let placeIdResult = yield call(getGooglePlaceId, query, service);

    partnerDestinationDetails.place_id=placeIdResult.place_id;
    partnerDestinationDetails.name = getDealerDisplayName(destination.name, partnerDetails.displayCode);
    partnerDestinationDetails.bizName = getDealerDisplayName(destination.name, partnerDetails.displayCode);
    partnerDestinationDetails.dealerId = destination.reference;
    partnerDestinationDetails.isSecureFacility = destination.isSecureFacility === "true";
    if(!(partnerDestinationDetails.dealerId)){
      return
    }

    let routeInfo = yield call(getGoogleRouteInfo, origin, partnerDestinationDetails.place_id);
    partnerDestinationDetails.towInfo = setDestinationTowInfo(routeInfo.distance.value, benefitsTowCostInfo, partnerDetails.experience, commonBenefits.benefits)
    partnerDestinationDetails.operatingHours = setInitialOperatingHours(partnerDestinationDetails)

    if(partnerDestinationDetails.towInfo && partnerDestinationDetails.operatingHours){
      return partnerDestinationDetails
    }

  } catch(error){
    datadogRum.addError(new Error(`Error in getPartnerDestinationDetails: ${error}`));
  }
}

function* getDestinationDetails({service, placeId, origin, address}){
  try {
    const partnerDetails = yield select(getPartnerDetails);
    const commonBenefits = yield select(getCommonBenefits);
    const towToCustomDestinationList = yield select(getCustomTowToDestinationList);

    const {serviceCost: {serviceCosts}} = partnerDetails.experience;
    let services = commonBenefits?.benefits ? commonBenefits.services : serviceCosts
    const benefitsTowCostInfo = services?.find(service => service.type === 'Tow' || service.serviceType === 'Tow')

    let destinationDetails = yield call(getGooglePlaceDetails, {service, placeId});
    let addressDetails = formatAddress(destinationDetails, false, towToCustomDestinationList, address, partnerDetails?.partnerCode, partnerDetails?.experience?.towToLocationType)
    if(addressDetails.error){
      return
    }

    if (towToCustomDestinationList?.length > 0 && !partnerDetails?.experience?.towToLocationType) {
      destinationDetails.name = addressDetails.bizName
      let selectedDestinationByBusinessName = towToCustomDestinationList.filter((destination) => destination.searchableName === address)
      destinationDetails.isSecureFacility = selectedDestinationByBusinessName[0].isSecureFacility
    }
    destinationDetails.addressDetails = addressDetails
    let routeInfo = yield call(getGoogleRouteInfo, origin, placeId);
    destinationDetails.towInfo = setDestinationTowInfo(routeInfo?.distance.value, benefitsTowCostInfo, partnerDetails.experience, commonBenefits.benefits)

    if(destinationDetails.opening_hours?.periods){
      destinationDetails.operatingHours = setOperatingHours(destinationDetails.opening_hours.periods, destinationDetails.utc_offset_minutes)
    }

    if (destinationDetails?.photos) {
      destinationDetails.photos.forEach((photo) => {
        if (photo.getUrl) photo.url = photo.getUrl()
      })
    }

    if(destinationDetails.towInfo){
      return destinationDetails
    }
  }catch(error){
    window.datadogRum?.addError(new Error(`Error in getDestinationDetails: ${error}`))
  }
}

const setInitialOperatingHours = (partnerDestinationDetails) => {
  let operatingHours = partnerDestinationDetails.opening_hours;
  let doesNotClose = operatingHours.openfrom.replace(':', '') === '0000' && !operatingHours.closeat
  const locale = determineLocale();
  if (doesNotClose) {
    return {
      status: 'Open 24 Hours',
      text: getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_OPEN),
    }
  }
  const now = new Date();
  const utcOffsetMinutes = now.getTimezoneOffset();
  adjustTimeForTimeZones(now, utcOffsetMinutes)
  let todaysHours = {open:{day:new Date().getDay(),time:operatingHours.openfrom.replace(':', ''),hours:parseFloat(operatingHours.openfrom.split(":")[0]),minutes:parseFloat(operatingHours.openfrom.split(":")[1])},
                      close:{day:new Date().getDay(),time:operatingHours.closeat.replace(':', ''),hours:parseFloat(operatingHours.closeat.split(":")[0]),minutes:parseFloat(operatingHours.closeat.split(":")[1])}};

  if (!todaysHours) {
    return {
      status: 'Closed',
      text: getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED)
    }
  }

  let status, text
  let thirtyMinutes = 1800000
  let whenShopOpens = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todaysHours.open.hours, todaysHours.open.minutes)
  let closingTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todaysHours.close.hours, todaysHours.close.minutes)

  if (now < whenShopOpens) {
    status = 'Closed'
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT).replace("{{time}}", formatTimeAsString(new Date(whenShopOpens), EN_US, HOUR12))
  } else if ((closingTime - now) > 0 && (closingTime - now) <= thirtyMinutes) {
    status = 'Closing Soon'
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSING_SOON).replace("{{time}}", formatTimeAsString(new Date(closingTime), EN_US, HOUR12))
  } else if (closingTime > now) {
    status = 'Open'
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_OPEN).replace("{{time}}", formatTimeAsString(new Date(closingTime), EN_US, HOUR12))
  } else if (!todaysHours || now > closingTime) {
    status = 'Closed'
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED)
  }
  return { status, text }
}

// TODO - needs unit tests
const setOperatingHours = (operatingHours, utcOffsetMinutes) => {
  let doesNotClose = operatingHours.length && operatingHours[0].open.day === 0 && operatingHours[0].open.time === '0000' && !operatingHours[0].close
  const locale = determineLocale();
  if (doesNotClose){
    return {
      status: 'Open 24 Hours',
      text: getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_OPEN24),
    }
  }

  const now = new Date();
  adjustTimeForTimeZones(now, utcOffsetMinutes)

  let todaysHours = operatingHours.find((day) => day.open.day === now.getDay())
  let nextOpenDayHours = operatingHours[now.getDay() % operatingHours.length]
  let whenShopOpensNext = new Date(now.getFullYear(), now.getMonth(), now.getDate(), nextOpenDayHours.open.hours, nextOpenDayHours.open.minutes)

  if(!todaysHours){
    return {
      status: 'Closed',
      whenShopOpensOrClosesNext: whenShopOpensNext,
      text: getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT).replace("{{time}}", formatTimeAsString(new Date(whenShopOpensNext), EN_US, HOUR12)),
    }
  }

  let status, text, whenShopOpensOrClosesNext
  let thirtyMinutes = 1800000
  let whenShopOpens = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todaysHours.open.hours, todaysHours.open.minutes)
  let closingTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), todaysHours.close.hours, todaysHours.close.minutes)

  if(now < whenShopOpens){
    status = 'Closed'
    whenShopOpensOrClosesNext = whenShopOpens
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT).replace("{{time}}", formatTimeAsString(new Date(whenShopOpens), EN_US, HOUR12))
  } else if ((closingTime - now) > 0 && (closingTime - now) <= thirtyMinutes){
    status = 'Closing Soon'
    whenShopOpensOrClosesNext = closingTime
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSING_SOON).replace("{{time}}", formatTimeAsString(new Date(closingTime), EN_US, HOUR12))
  } else if (closingTime > now) {
    status = 'Open'
    whenShopOpensOrClosesNext = closingTime
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_OPEN).replace("{{time}}", formatTimeAsString(new Date(closingTime), EN_US, HOUR12))
  } else if(!todaysHours || now > closingTime){
    status = 'Closed'
    whenShopOpensOrClosesNext = whenShopOpensNext
    text = getTranslatedTextByLocale(locale, TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT).replace("{{time}}", formatTimeAsString(new Date(whenShopOpensNext), EN_US, HOUR12))
  }

  return {status, whenShopOpensOrClosesNext, text}
}

const adjustTimeForTimeZones = (now,utcOffsetMinutes) => {
  const userUtcOffset = now.getTimezoneOffset()
  let timeZoneHourDiff = (Math.abs(utcOffsetMinutes) - Math.abs(userUtcOffset)) / 60
  now.setHours(now.getHours() - timeZoneHourDiff)
}

// TODO - needs unit tests
const setDestinationTowInfo = (distanceInMeters, benefitsTowCostInfo, experience, benefits) => {
  const {
    ppuEnabled,
    overMileageEnabled,
    benefitsEnabled,
    milesBased,
    canDetermineFinalCost,
    dollarBased
  } = experience

  const locale = determineLocale()
  const isPartnerCanada = locale === en_CA || locale === fr_CA
  let displayCode = window.location.pathname.split('/')[2]

  let milesPerMeter      = 0.000621371192

  if(!distanceInMeters){
    return
  }

  let milesToDestination = (distanceInMeters * milesPerMeter).toFixed(1)

  let value = isPartnerCanada ? distanceInMeters / 1000 : milesToDestination
  let distanceUnits = isPartnerCanada ? 'kilometer' : 'mile'
  let unitsShorthand = isPartnerCanada ? 'km' : 'mi'

  if(!benefitsTowCostInfo){
    return {
      distanceFromCurrentLocation: value,
      distanceUnits,
      unitsShorthand,
    }
  }

  let overMileageDistance   = 0
  let overMileageCost       = 0
  let allowedMiles          = benefitsTowCostInfo.towIncludedQty || parseInt(benefitsTowCostInfo.towIncludedDistanceQty);
  let costPerAdditionalMile = benefitsTowCostInfo.towExtraUnitAmt || benefitsTowCostInfo.costPerMile;
  let totalTowCost = benefitsTowCostInfo.cost || 0

  let userCanSelectDestinationOutsideOfLimitsAndPaysInStore = (!ppuEnabled && !benefitsEnabled && overMileageEnabled) //PEP
  let userCanNotSelectDestinationOutsideOfLimits = milesBased && !overMileageEnabled //Miles Based AMC
  let userPaysServiceProviderDirectly = dollarBased && !canDetermineFinalCost && benefits  //Dollar based AMC when over benefits
  let userNeedsToSubmitPayment = ppuEnabled && !(userPaysServiceProviderDirectly)

  if ((userNeedsToSubmitPayment || userCanSelectDestinationOutsideOfLimitsAndPaysInStore) && milesToDestination > allowedMiles) {
    overMileageDistance = (milesToDestination - allowedMiles)
    overMileageCost = (overMileageDistance * costPerAdditionalMile)
    totalTowCost += overMileageCost
  }

  if(userCanNotSelectDestinationOutsideOfLimits && milesToDestination > allowedMiles){
    overMileageDistance = (milesToDestination - allowedMiles)
  }

  return {
    distanceFromCurrentLocation: value,
    distanceUnits,
    unitsShorthand,
    overMileageDistance: overMileageDistance.toFixed(1),
    overMileageCost: overMileageCost.toFixed(2),
    totalTowCost: totalTowCost.toFixed(2)
  }
}

export function* watchNearBySearch() {
  yield takeEvery(REQUEST_GOOGLE_NEAR_BY_SEARCH, getNearByDestinations);
}

export function* watchAddMoreNearByDestinations() {
  yield takeEvery(REQUEST_GOOGLE_MORE_DETAILS_NEAR_BY_SEARCH, addingMoreNearByDestination);
}

export function* updateDestinationWithPredictionAddress({payload}){
    let destinationDetails = yield call(getDestinationDetails, payload)
  if(destinationDetails) {
    yield put({
      type: UPDATE_DESTINATION,
      payload: destinationDetails
    });
  }
  else {
    datadogRum.addError(new Error(`Error occurred getting destination details`));
    yield put({type: INVALID_DESTINATION_ADDRESS})
  }
}

export function* watchUpdateDestinationWithPredictionAddress(){
  yield takeEvery(UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS, updateDestinationWithPredictionAddress);
}

export function* watchAddGooglePlaceIdToDestination() {
  yield takeEvery(ADD_GOOGLE_PLACE_ID_TO_DESTINATION, addGooglePlaceIdToDestination);
}
