import React, {Component} from 'react'

class DestinationPin extends Component {

  render() {
    return (
      <svg id="drop-pin" xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'>
        <path fill="#4e5969" fillRule='evenodd' d='M34 39.901V64h-4V39.901C19.893 38.898 12 30.371 12 20 12 8.954 20.954 0 32 0s20 8.954 20 20c0 10.37-7.893 18.898-18 19.901zm-2-12.209a7.692 7.692 0 1 0 0-15.384 7.692 7.692 0 0 0 0 15.384z'
        />
      </svg>
    )
  }
}

export default DestinationPin
