export const REQUESTED_SUBMIT_REQUEST = "REQUESTED_SUBMIT_REQUEST";
export const REQUESTED_SUBMIT_REQUEST_SUCCESS = "REQUESTED_SUBMIT_REQUEST_SUCCESS";
export const REQUESTED_SUBMIT_REQUEST_FAILED = "REQUESTED_SUBMIT_REQUEST_FAILED";

export function submitServiceRequest(data){
    return { type: REQUESTED_SUBMIT_REQUEST, payload: data};
}

export function submitServiceRequestSuccess(data){
    return { type: REQUESTED_SUBMIT_REQUEST_SUCCESS, payload: data};
}

export function submitServiceRequestFailed(){
    return { type: REQUESTED_SUBMIT_REQUEST_FAILED};
}
