import {TRANSLATION_CONSTANTS} from "../../../app-consts/translations";
import { french_translation } from "../translation_fr";

export const french_translation_pep = {
    ...french_translation,
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]: "Sorry. Can't find an area Pep Boys. Please call customer service.",
    [TRANSLATION_CONSTANTS.PEP_BOYS_FLEET]: "Are you a Pep Boys Fleet customer?",
    [TRANSLATION_CONSTANTS.PEP_BANNER]: "Final payment for tows will need to be paid  at the Pep Boys repair shop after your rescue. Payment for all other services will be collected at the time of submission.",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]: "Since your keys are lost or broken, would you prefer to have your vehicle towed to a nearby Pep Boys repair shop?",
    [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]: "If you’ve checked for a trunk release / hatch and still can’t reach your keys, we’ll need to tow your vehicle to a Pep Boys repair shop.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]: "You probably need a new tire or tires. We can tow your vehicle to a nearby Pep Boys repair shop.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]: "Continue if you'd like us to tow your vehicle to a Pep Boys repair shop to have it evaluated.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_TITLE]: "Maintain your vehicle and budget",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_CONTENT]: "See if you prequalify for a Pep Boys Credit Card with NO IMPACT to your credit score.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_PREQUALIFY]: "Prequalify",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "continuer vers le remorquage",
    [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]: "déverrouiller mon véhicule",
}
