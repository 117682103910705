import CurrentLocation from "../assets/currentLocation.svg";
import DestinationPin from "../assets/destinationPin.svg";
import PepBoysMapPin from "../assets/Pep-Boys-MapPin.svg";

const google = window.google;

export const disabledVehicleIcon = {
  url: CurrentLocation,
  anchor: new google.maps.Point(14,14),
  scale: 1,
  scaledSize: new google.maps.Size(28, 28)
}

export const towDestinationOptionIcon = {
  path: 'M11.9273435,16.3404255 C9.48911854,16.3404255 7.51309422,14.3644012 7.51309422,11.9273435 C7.51309422,9.48911854 9.48911854,7.51309422 11.9273435,7.51309422 C14.3644012,7.51309422 16.3404255,9.48911854 16.3404255,11.9273435 C16.3404255,14.3644012 14.3644012,16.3404255 11.9273435,16.3404255 L11.9273435,16.3404255 Z M15.7019818,0.622103343 C14.7355623,0.302297872 13.9138723,0.179744681 13.9138723,0.179744681 C13.2660912,0.0700303951 12.6054711,0 11.9273435,0 C11.2480486,0 10.5874286,0.0700303951 9.93964742,0.179744681 C9.93964742,0.179744681 9.11795745,0.302297872 8.15270517,0.622103343 C3.42215198,2.20712462 0,6.67039514 0,11.9261763 C0,16.4524742 2.59929483,19.4801216 5.35032219,22.6851793 C7.64848632,25.3615076 10.0260182,28.1300426 11.0636353,32.0178967 C11.1686809,32.4088997 11.5223343,32.6808511 11.9273435,32.6808511 C12.3323526,32.6808511 12.6860061,32.4088997 12.7898845,32.0178967 C13.8286687,28.1300426 16.2050334,25.3615076 18.5031976,22.6851793 C21.2553921,19.4801216 23.8546869,16.4524742 23.8546869,11.9261763 C23.8546869,6.67039514 20.4313678,2.20712462 15.7019818,0.622103343 L15.7019818,0.622103343 Z',
  fillColor: '#4E5968',
  strokeColor: '#FFFFFF',
  fillOpacity: 1,
  scale: 1,
  labelOrigin: new google.maps.Point(10, 45),
  anchor: new google.maps.Point(12, 30)
};

export const selectedTowDestinationIcon = {
  path: 'M11.9273435,16.3404255 C9.48911854,16.3404255 7.51309422,14.3644012 7.51309422,11.9273435 C7.51309422,9.48911854 9.48911854,7.51309422 11.9273435,7.51309422 C14.3644012,7.51309422 16.3404255,9.48911854 16.3404255,11.9273435 C16.3404255,14.3644012 14.3644012,16.3404255 11.9273435,16.3404255 L11.9273435,16.3404255 Z M15.7019818,0.622103343 C14.7355623,0.302297872 13.9138723,0.179744681 13.9138723,0.179744681 C13.2660912,0.0700303951 12.6054711,0 11.9273435,0 C11.2480486,0 10.5874286,0.0700303951 9.93964742,0.179744681 C9.93964742,0.179744681 9.11795745,0.302297872 8.15270517,0.622103343 C3.42215198,2.20712462 0,6.67039514 0,11.9261763 C0,16.4524742 2.59929483,19.4801216 5.35032219,22.6851793 C7.64848632,25.3615076 10.0260182,28.1300426 11.0636353,32.0178967 C11.1686809,32.4088997 11.5223343,32.6808511 11.9273435,32.6808511 C12.3323526,32.6808511 12.6860061,32.4088997 12.7898845,32.0178967 C13.8286687,28.1300426 16.2050334,25.3615076 18.5031976,22.6851793 C21.2553921,19.4801216 23.8546869,16.4524742 23.8546869,11.9261763 C23.8546869,6.67039514 20.4313678,2.20712462 15.7019818,0.622103343 L15.7019818,0.622103343 Z',
  fillColor: '#0033A0',
  strokeColor: '#FFFFFF',
  fillOpacity: 1,
  scale: 1.5,
  labelOrigin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(12, 30)
};

export const towDestinationOptionIconPepBoys = {
  url: DestinationPin,
  scaledSize: new google.maps.Size(33, 42),
  labelOrigin: new google.maps.Point(10, 45),
  anchor: new google.maps.Point(16, 37)
};

export const selectedTowDestinationIconPepBoys = {
  url: PepBoysMapPin,
  scale: 1,
  scaledSize: new google.maps.Size(37, 55),
  labelOrigin: new google.maps.Point(0, 0),
  anchor: new google.maps.Point(16.5, 49.5)
};
