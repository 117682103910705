import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Grid, Headline, Type, Utility } from "mesh-component-library";
import { useScreenBreakpoint } from "../../hooks/useScreenBreakpoint";
import CarIllustrationDuplicateRescue from "../../icon-library/src/icons/CarIllustrationDuplicateRescue";
import { useSelector } from "react-redux";
import { getDuplicateRescue, getPartnerDetails } from "../../selector";
import { generatePathWithSearchParams } from "../../utilities/RedirectUtils";
import { determineLocale, useLocale } from "../../contexts/LocaleContext";
import { experimentalMonitor } from "../../utils/experimentalMonitorUtil";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";

const DuplicateRescue = (props) => {
  const history = useHistory();
  const getTranslatedText = useLocale();
  const partnerDetails = useSelector(getPartnerDetails);
  const duplicateRescue = useSelector(getDuplicateRescue);
  const screenBreakpoint = useScreenBreakpoint();

  const handleOnClickDuplicateRescue = () => {
    const { usageId = "", duplicateRescueIronDoor = {} } = duplicateRescue;
    const { displayCode: partnerCode = "" } = partnerDetails;
    experimentalMonitor(duplicateRescueIronDoor)(
      "Duplicate Rescue Iron Door Redirects to SMS RT",
      { usageId },
      "duplicateRescueIronDoor"
    );
    history.push(
      generatePathWithSearchParams(
        `/wd/l/${partnerCode}/duplicaterescueactivetracker/${usageId}/${determineLocale()}`
      )
    );
  };

  return (
    <Grid verticalAlign="middle">
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
            vrTop: "md",
            vrBottom: "md",
          }}
        >
          <Headline
            title={
              <Type
                variant="darker"
                align="left"
                size={screenBreakpoint === "sm" ? "5xl" : "3xl"}
                weight="semibold"
              >
                {getTranslatedText(
                  TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_TITLE
                )}
              </Type>
            }
          />
        </Utility.Div>
      </Grid.Column>
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
          }}
        >
          <Type
            align="left"
            size={screenBreakpoint === "sm" ? "l" : "m"}
            lineHeight="md"
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_DESC)}
          </Type>
        </Utility.Div>
      </Grid.Column>
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
        utils={{
          vrTop: "md",
        }}
      >
        <Utility.Div
          utils={{
            hrLeft: "md",
            hrRight: "md",
          }}
        >
          <Button
            hasUpgrade
            shape="pill"
            size="lg"
            onClick={handleOnClickDuplicateRescue}
            isBlock
          >
            {getTranslatedText(
              TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT
            )}
          </Button>
        </Utility.Div>
      </Grid.Column>
      <Grid.Column
        col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
        offset={{ md: 4, lg: 4, xl: 4 }}
      >
        <CarIllustrationDuplicateRescue />
      </Grid.Column>
    </Grid>
  );
};

export default DuplicateRescue;
