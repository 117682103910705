import React from "react";
import Alert from "../alert";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";

export const OvercostWarning = (props) => {
  return (
    <div className="c-container-warning">
      <Alert type="warning">
        <b>After using the full amount of your roadside coverage for this service,</b>
        <p>you will owe ${props.amount}. You’ll need to pay this amount directly to your rescuer when they arrive.</p>
      </Alert>
    </div>
  )
}

const primaryLookupMessage = (primaryLookup) => {
  switch(primaryLookup) {
    case 'firstNameLastNameZipLookup':
      return TRANSLATION_CONSTANTS.FN_LN_ZIP_LOOKUP_FAILURE;
    case 'lastNameStateZipLookup':
      return TRANSLATION_CONSTANTS.NAME_STATE_ZIP_LOOKUP_FAILURE;
    default:
      return TRANSLATION_CONSTANTS.PHONE_LOOKUP_FAILURE;
  }
}

export const PrimaryLookupFailure  = ({primaryLookup = 'firstNameLastNameZipLookup'}) => {
  const getTranslatedText = useLocale();
  return (
    <div id="primary-lookup-failure-message" className="c-container-warning">
      <Alert type="warning">
        <b>{getTranslatedText(primaryLookupMessage(primaryLookup))}</b>
      </Alert>
    </div>
  );
}
