import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router";
import Icon from '../../components/icon';
import {RemovecloseF} from '../../icon-library';
import LocationPinSingle from '../../components/svg/LocationPinSingle';
import DisablementMap from '../../components/disablement-map';
import LocationPinPoint from '../../components/svg/LocationPinPoint';
import {generatePath} from './../../utilities/RedirectUtils';
import ModalDialogAlt from '../../components/modal-dialog-alt';
import {
  clearCurrentLocation,
  clearDestination,
  clearLocationError,
  clearNearByDestinations,
  updateAdditionalFields,
  updateDisablementAddress,
  updateVehicle,
  updateVehicleInfo
} from '../../action'
import AlertModal from '../../components/alertmodal';
import {FUEL_SERVICE_ID} from "../../app-consts/appConstants";
import {
  getCommonBenefits,
  getCurrentLocation,
  getPartnerDetails,
  getRequestInfo,
  getServiceInfo,
  getServiceRequestPayload,
  getServiceTime
} from "../../selector";

import {
  sendDisablementLocationToJacada,
  sendScheduleServiceDateTime
} from "../../jacada/sendJacadaEventData";
import GoogleFormField from "../../components/google-form-field";
import {INVALID_ADDRESS_ID} from "../../app-consts/errorMessageConstants";
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import LocationPrimer from '../LocationPrimer';

import {datadogRum} from "@datadog/browser-rum";
import {callToGetTimeZone, saveRescueDetails} from "../../api/api";
import { Button } from 'mesh-component-library';

const google = window.google;

const DisablementLocation = () => {

  const getTranslatedText = useLocale();
  const dispatch = useDispatch();
  const history = useHistory();

  const commonBenefits = useSelector(getCommonBenefits);
  const partnerDetails = useSelector(getPartnerDetails);
  const serviceInfo = useSelector(getServiceInfo);
  const currentLocation = useSelector(getCurrentLocation);
  const submitRequest = useSelector(getRequestInfo);
  const serviceRequestPayload = useSelector(getServiceRequestPayload);
  const serviceTime = useSelector(getServiceTime);
  const [outsideRegion, setOutsideRegion] = useState(false);
  const [placeName, setPlaceName] = useState(currentLocation.formatted_address || '');
  const [confirmDisablement, setConfirmDisablement] = useState(false);
  const [areLocationPredictionsVisible, setLocationPredictionsVisible] = useState(false);
  const [locationPredictions, setLocationPredictions] = useState([]);
  const [placeDetails, setPlaceDetails] = useState({});
  const [errorModalId, setErrorModalId] = useState("");
  const [errorModalTitle, setErrorModalTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState(currentLocation.error?.errorMessage || "");
  const [isErrorModalActive, setIsErrorModalActive] = useState(false)
  const [inputPlaceholder, setInputPlaceholder] = useState(!currentLocation.deniedUsingLocation && currentLocation.deniedUsingLocation !== undefined ? getTranslatedText(TRANSLATION_CONSTANTS.RETRIEVING_LOCATIONS) : getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION));

  const partnerCode = partnerDetails.displayCode;
  const latitude = 39.82835;
  const longitude = -98.5816684;

  const [isLocationDetectionLoading, setLocationDetectionLoading] = useState(true);
  const [isLocationPromptShown, setLocationPromptShown] = useState(false);

  useEffect(() => {
    datadogRum.addAction('User lands on disablement location');
    document.title = 'Disablement Location | Roadside Assistance';
    if (commonBenefits?.vehicles?.length === 1 && commonBenefits.vehicles[0].color && !partnerDetails?.experience?.addAVehicle && serviceInfo.id !== FUEL_SERVICE_ID) {
      //TODO - somewhere we are modifying the state in redux for commonBenefits.vehicles. This next line is a temp fix.
      let newVehicle = {...commonBenefits.vehicles[0]}
      dispatch(updateVehicle(newVehicle))
      dispatch(updateVehicleInfo({selectedVehicleIndex: 0}))
    }
    dispatch(clearNearByDestinations())
    dispatch(clearDestination())

    if(errorMessage !== "") {
      setErrorModalId(INVALID_ADDRESS_ID)
    }

    if (currentLocation?.locationPromptClicked) {
      setLocationPromptShown(true);
    }
    setLocationDetectionLoading(false);

    if(partnerDetails.omniStreamlined && (serviceInfo.service !== 'gas')) {
      saveRescueDetails(serviceRequestPayload, partnerCode, serviceInfo.service)
    }

  }, [])

  useEffect(() => {
    if (submitRequest?.success) {
      const {sessionId, callId, requestId, usageId} = submitRequest.response
      history.push(generatePath(`tracker/${sessionId}/${callId}/${requestId}/${usageId}`));
    }
  }, [submitRequest])

  const callbackRef = useCallback(inputElement => {
    if(inputElement && ((inputPlaceholder === getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION) && placeName === "") || currentLocation.deniedUsingLocation)) {
      inputElement.googleFormField.focus();
    }
  },[inputPlaceholder, placeName]);

  const updateScheduledServiceTimeStamp = (result) => {
    let timezone_standard;
    if(result?.data) {
      const utcOffsetInMinutes = (result.data?.rawOffset + result.data?.dstOffset) / 60

      let offset_hrs = Math.abs(utcOffsetInMinutes / 60),
        offset_min = Math.abs(utcOffsetInMinutes % 60);

      if (offset_hrs < 10)
        offset_hrs = '0' + offset_hrs;
      if (offset_min < 10)
        offset_min = '0' + offset_min;

      timezone_standard = '-' + offset_hrs + ':' + offset_min;
      datadogRum.addAction('Successful timezone returned from timezone API', {timezone: timezone_standard})
    } else {
      let deviceTimeZone = new Date().toString().split(" ")[5].substring(3);
      timezone_standard = deviceTimeZone.slice(0, 3) + ":" + deviceTimeZone.slice(3);
      datadogRum.addAction('Successful timezone acquired from device location', {timezone: timezone_standard})
    }

    const requestedTimeWithTZ = serviceTime + timezone_standard;
    datadogRum.addAction("Scheduled service time request", {usr: requestedTimeWithTZ})
    sendScheduleServiceDateTime(requestedTimeWithTZ)
    dispatch(updateAdditionalFields({requestscheduledatetime: requestedTimeWithTZ}))
  }

  const onConfirmVehicleLocation = async() => {
    datadogRum.addAction('Confirm disablement location');
    if(partnerDetails.experience.schedulingEnabled && !!serviceTime) {
      const timeToRescue = Date.parse(serviceTime);
      const result = await callToGetTimeZone(currentLocation.latitude, currentLocation.longitude, timeToRescue.toString().slice(0,10))
      updateScheduledServiceTimeStamp(result)
    }

    dispatch(updateDisablementAddress(currentLocation));
    sendDisablementLocationToJacada(currentLocation)
    history.push(generatePath('location'));
  }

  const deleteCurrentLocationInput = () => {
    setPlaceName('');
    setConfirmDisablement(false);
    setLocationPredictionsVisible(false);
    setPlaceDetails({});
    setInputPlaceholder(getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION))
    dispatch(clearCurrentLocation());
    if (errorModalId !== '') {
      setErrorModalId("")
      setErrorMessage("")
    }
  }

  const closeErrorModal = () => {
    setErrorModalId("")
    setErrorModalTitle("")
    setIsErrorModalActive(false)
    setErrorMessage("")
    dispatch(clearLocationError())
  }

  const openErrorModal = (errorId, errorTitle, errorMessageText) => {
    setConfirmDisablement(false)
    setErrorModalId(errorId)
    setErrorMessage(errorMessageText)
    if(errorId !== INVALID_ADDRESS_ID) {
      setErrorModalTitle(errorTitle)
      setIsErrorModalActive(true)
    }
  }

  const placeNameHandler = (event) => {
    setPlaceName(event.target.value)
    const valueForPredictions = event.target.value;
    if(valueForPredictions.length >2){
      let service = new google.maps.places.AutocompleteService();
      setLocationPredictionsVisible(true)
      service.getPlacePredictions({
        input: valueForPredictions,
        componentRestrictions: {country: partnerDetails.experience.supportedRegions}
      }, (predictions, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          return;
        }
        setLocationPredictions(predictions)
      });
    } else{
      setLocationPredictionsVisible(false)
    }
  }

  const selectCurrentLocationFromPredictions = (prediction) => {
    if(errorMessage) {
      setErrorMessage("")
      dispatch(clearLocationError());
    }
    setPlaceName(prediction.description);
    setPlaceDetails(prediction);
    setLocationPredictionsVisible(false);
  }

  const handlePhoneCall = () => {
    const phone = partnerDetails.phone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' +  phone;
  }

  const hideLocationList = () => {
    setLocationPredictionsVisible(false)
  }

  const setTranslatedPlaceholder = (inputPlaceholder) => {
    if(inputPlaceholder === getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION)) return getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION);
    return inputPlaceholder
  }

  return (
    <div id="uniphore-disablement-location-pages">
      {isLocationPromptShown ? (
    <div role="main">
      {outsideRegion ?
        <AlertModal
          title="Service Alert"
          customClass="noBenefits"
          primaryButton="Call"
          primaryButtonCallback={handlePhoneCall}
          secondaryButton="Cancel"
          secondaryButtonCallback={() => setOutsideRegion(false)}
        /> : ''}
      {
        areLocationPredictionsVisible &&
        <div role="figure"
             tabIndex="0"
             className="c-google-map--overlay"
             onClick={() => hideLocationList()}
             onKeyDown={() => hideLocationList()}/>
      }
      <div className="c-disablementContainer">
        <div className="dropNameContain">
          <div className="dropNameContain--internal">
            <LocationPinSingle locationColor={confirmDisablement ?
              '#0033A0' :
              '#B9C6D3'}/>
            <div>
              <div className="dropoffGroup">
                <GoogleFormField ref={callbackRef}
                                 hasError={errorModalId === INVALID_ADDRESS_ID}
                                 errorText={errorMessage}
                                 data-dd-privacy="allow"
                                 name="ship-address"
                                 autoComplete="off"
                                 id="current-location-form-field"
                                 data-testid="current-location-form-field"
                                 className="placename"
                                 placeholder={setTranslatedPlaceholder(inputPlaceholder)}
                                 onChange={placeNameHandler}
                                 onFocus={hideLocationList}
                                 value={placeName}
                />
                <Icon role="link"
                      ariaLabel="Delete Address"
                      tabIndex="0"
                      icon={RemovecloseF}
                      color="gray-darker"
                      className={`${(confirmDisablement || errorMessage) && 'active'} deletePlace large`}
                      onClick={deleteCurrentLocationInput}
                      onFocus={deleteCurrentLocationInput}
                      onKeyDown={(e) => {
                        if (e.keyCode === 13) deleteCurrentLocationInput()
                      }}
                      size={1.5}/>
              </div>
            </div>
          </div>
          {areLocationPredictionsVisible &&
          <div className="c-currentOptions c-currentOptions--pindrop">
            <div className="c-currentPredictions">
              <div className="c-currentPredictions__itemcontain">
                {locationPredictions.map((prediction, i) => {
                  return (
                    <a tabIndex="0"
                       key={prediction.place_id}
                       data-testid={`prediction-${i}`}
                       className="c-currentPredictions__item"
                       onClick={() => selectCurrentLocationFromPredictions(prediction)}
                    >
                      <LocationPinPoint/>
                      <p className="u-text-truncate">{prediction.description}</p>
                    </a>
                  )
                })
                }
              </div>
            </div>
          </div>
          }
        </div>
        { !submitRequest?.success &&
        <DisablementMap
          partnerCode={partnerCode}
          openErrorModal={openErrorModal}
          hideLocationList={hideLocationList}
          geolocation={true}
          setPlaceName={setPlaceName}
          prediction={placeDetails}
          setPlaceDetails={setPlaceDetails}
          setInputPlaceholder={setInputPlaceholder}
          setConfirmDisablement={setConfirmDisablement}
          locationSelected={confirmDisablement}
          setErrorMessage={setErrorMessage}
          setErrorModalId={setErrorModalId}
          mapOptions={
            {
              zoom: 4,
              streetViewControl: false,
              scrollwheel: true,
              draggable: true,
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              mapTypeControl: false,
              zoomControl: false,
              fullscreenControl: false,
              center: {
                lat: latitude,
                lng: longitude
              },
              streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
              },
              zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
              },
              styles: [
                {
                  featureType: 'poi',
                  stylers: [{visibility: 'off'}]
                },
                {
                  featureType: 'all',
                  stylers: [
                    {saturation: -20},
                    {lightness: 10}
                  ]
                }
              ]
            }
          }
        /> }

        <div className={`c-confirmDisablement ${confirmDisablement ? 'active' : ''}`}>
          <p className="u-text-bold u-align-left" aria-labelledby="confirm-vehicle-location">
            {getTranslatedText(TRANSLATION_CONSTANTS.IS_WHERE_YOUR_VEHICLE_IS)}
          </p>
              <Button
                size='lg'
                hasUpgrade
                id="confirm-vehicle-location"
                data-testid="vehicle-location-confirmation-button"
                disabled={!confirmDisablement}
                onClick={() => onConfirmVehicleLocation()}
                utils={{
                  fullWidth: true
                }}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_LOCATION)}
              </Button>
        </div>
      </div>
      {isErrorModalActive ?
        <ModalDialogAlt id={errorModalId}
                      title={errorModalTitle}
                      isSlideDrawer
                      hideTrigger
                      showCloseBtn={true}
                      isActive={isErrorModalActive}
                      onClose={closeErrorModal}
                      footer={
                        <button className="btn btn-info"
                                onClick={closeErrorModal}
                                onKeyDown={closeErrorModal}>
                        Ok
                      </button>}
        >
        <div className="text_left">
          <p>{errorMessage}</p>
        </div>
      </ModalDialogAlt> : null}
    </div>
    ) : !isLocationDetectionLoading &&
    <LocationPrimer
      setLocationPromptShown={setLocationPromptShown}
    />}
  </div>
  );
}

export default DisablementLocation;
