import React from 'react';
import { withRouter } from 'react-router-dom';
import {generatePath} from '../../utilities/RedirectUtils';
import {datadogRum} from "@datadog/browser-rum";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    const pathArray = this.props.location.pathname.split('/');
    const pageWithError = pathArray.pop();
    const partnerCode = pathArray.pop();

    datadogRum.addAction(`Error Boundary Triggered -${partnerCode}-${pageWithError} ${error.message}`);
    datadogRum.addError(new Error(`Error Boundary Triggered - error ${error}`));
    this.props.history.push(generatePath('sitedown', partnerCode));
  }

  render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
