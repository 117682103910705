import { datadogRum } from "@datadog/browser-rum";

export const migrations = {
  1: (state) => {
    datadogRum.addAction(`Redux Persisted State Migrated to Version 1`);
    return {
      ...state,
      generalInfo: {
        ...state.generalInfo,
        duplicateRescue: {
          serviceType: "",
          projectedEtaTime: "",
          projectedEtaMinute: 0,
          usageId: "",
        },
      },
    };
  },
  2: (state) => {
    datadogRum.addAction(`Redux Persisted State Migrated to Version 2`);
    return {
      ...state,
      generalInfo: {
        ...state.generalInfo,
        duplicateRescue: {
          usageId: "",
          duplicateRescueIronDoor: {
            name: "",
            group: "",
            enabled: false,
            nthRequest: 0,
            currentCount: 0,
            partnerCodes: [],
            appIds: [],
          },
        },
      },
    };
  },
};
