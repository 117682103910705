import {datadogRum} from "@datadog/browser-rum";
const google = window.google;


export function getGoogleRouteInfo(origin, placeId) {
  const destinationRouteRequest = {
    origin,
    destination: {placeId},
    travelMode: 'DRIVING',
    unitSystem: google.maps.UnitSystem.IMPERIAL
  }
  const directionService = new google.maps.DirectionsService()
  return new Promise((resolve, reject) => {
    directionService.route(destinationRouteRequest, (result, status) => {
      if (status === google.maps.DirectionsStatus.OK) {
        resolve(result.routes[0]?.legs[0])
      } else {
        datadogRum.addError(new Error("getGoogleRouteInfo non-200 status"), destinationRouteRequest)
        reject({status: status})
      }
    })
  })
}

export function getGoogleNearBySearch(placeService, location) {
  const request = {
    location,
    rankBy: google.maps.places.RankBy.DISTANCE,
    keyword: 'car repair',
  }

  return new Promise((resolve, reject) => {
    placeService.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(results);
      } else if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
        const fallbackRequest = {
          location,
          rankBy: google.maps.places.RankBy.DISTANCE,
          type: 'car_repair',
        }
        placeService.nearbySearch(fallbackRequest, (results, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            datadogRum.addError(new Error("getGoogleNearBySearch fallback non-200 status"), fallbackRequest)
            reject(status);
          }
        });
      }
      else {
        datadogRum.addError(new Error("getGoogleNearBySearch non-200 status"), request)
        reject(status);
      }
    });
  });
}

export function getGooglePlaceDetails(destinationDetailsRequest) {

  return new Promise((resolve, reject) => {
    let options = {
      placeId: destinationDetailsRequest.placeId,
      fields: googlePlaceRequestFields
    }
    destinationDetailsRequest.service.getDetails(options, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        resolve(filterPlaceDetails(place))
      } else if (status === google.maps.places.PlacesServiceStatus.INVALID_REQUEST) {
        datadogRum.addError(new Error(`getGooglePlaceDetails INVALID_REQUEST: ${destinationDetailsRequest?.placeId}`))
        resolve({});
      } else {
        datadogRum.addError(new Error(`getGooglePlaceDetails non-200 status: ${status} for placeId: ${destinationDetailsRequest?.placeId}`))
        reject(status);
      }
    });
  });
}

export function getGooglePlaceId(query, searchService) {
  const placeRequest = {
    query,
    fields: ['place_id']
  }
  return new Promise((resolve, reject) => {
    searchService.findPlaceFromQuery(placeRequest, (result, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        if (result.length > 1) {
          window.datadogRum?.addError(new Error("findPlaceFromQuery returned multiple results"), query)
        }
        resolve(result[0])
      } else {
        window.datadogRum?.addError(new Error(`getGooglePlaceId returned status: ${status} for query: ${query}`))
        reject({status: status});
      }
    });
  });
}

export const googlePlaceRequestFields = [
  "name",
  "address_components",
  "utc_offset_minutes",
  "opening_hours",
  "business_status",
  "formatted_address",
  "formatted_phone_number",
  "geometry",
  "photos",
  "place_id",
  "rating",
  "reviews",
  "user_ratings_total",
  "vicinity",
  "website",
  "types",
]

const filterPlaceDetails = (place) => {
  const openHours = {}
  if(place.hasOwnProperty('utc_offset')) delete place.utc_offset
  if(place.opening_hours?.periods) openHours.periods = place.opening_hours.periods
  if(place.opening_hours?.weekday_text) openHours.weekday_text = place.opening_hours.weekday_text
  if(typeof place.opening_hours?.isOpen === 'function') openHours.isOpen = place.opening_hours.isOpen()
  return {...place, ...{"opening_hours": openHours}}
}
