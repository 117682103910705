import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations";
import { spanish_translation } from "../translation_es";

export const spanish_translation_pep = {
    ...spanish_translation,
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]: "Lo sentimos. No encontramos un Pep Boys en la zona.",
    [TRANSLATION_CONSTANTS.PEP_BOYS_FLEET]: "¿Es usted cliente de Pep Boys Fleet?",
    [TRANSLATION_CONSTANTS.PEP_BANNER]: "El pago final para las grúas tendrá que ser pagado en el taller de reparación Pep Boys después de su rescate. El pago de todos los demás servicios se cobrará en el momento de la entrega.",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]: "Si su llave se pierde o se rompe, podemos remolcar su vehículo a un taller de reparación de Pep Boys.",
    [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]: "Podemos organizar un remolque para que un centro de servicio de Pep Boys abra la cajuela de su vehículo y pueda recuperar sus llaves.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]: "Como necesitará reemplazar al menos una llanta, podemos remolcar su vehículo a un taller de reparación de Pep Boys.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]: "Dado que su vehículo se detuvo mientras conducía, lo más probable es que la batería no sea el problema. Como alternativa, podemos remolcar su vehículo a un taller de reparación de Pep Boys.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_TITLE]: "Mantenga su vehículo y su presupuesto",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_CONTENT]: "Vea si califica para una Tarjeta de Crédito Pep Boys sin IMPACTO en su puntaje de crédito.",
    [TRANSLATION_CONSTANTS.CREDIT_CARD_OFFER_PREQUALIFY]: "Podría calificar",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "continuar para remolque",
    [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]: "desbloquea mi vehiculo",
}
