export const LOOKUP_PAGE = 'lookup';
export const MEMBER_LOOKUP_PEP = 'member-lookup-pep';
export const MEMBER_ID_LOOKUP = 'member-id-lookup';
export const CANNOT_FIND_BENEFITS = 'cannot-find-benefits';
export const CAPTURE_VIN = 'capture-vin';
export const MANUAL_VIN = 'manual-vin';
export const LICENSE_PLATE_LOOKUP = 'license-plate-lookup';
export const SERVICE_SELECTION = 'service-selection';
export const JUMPSTART_TRIAGE = 'jumpstart-triage';
export const TIRE_TRIAGE = 'tire-triage';
export const LOCKOUT_TRIAGE = 'lockout-triage';
export const DISABLEMENT_LOCATION_AND_NONTOW_LOCATION = 'disablement_location_and_nontow_location';
export const NEARBY_DESTINATIONS = 'nearby-destinations';
export const SELECT_VEHICLE = 'select-vehicle';
export const VEHICLE_INFORMATION = 'vehicle-information';
export const PAYMENT_PAGE = 'payment-page';
export const INTAKE_COMPLETED = 'intake-completed';
export const ERROR_PAGE = 'error-page';
