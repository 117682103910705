import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import FormField from '../form-field';
import {generatePath} from '../../utilities/RedirectUtils';
import {sendJacadaRequest} from '../../api';
import ButtonGroup from "../button-group";
import { getPepSession, getServiceDetails, isPayPerUseSelector } from "../../selector";
import {
  pepSession,
  updateAdditionalFields,
  updateServiceInfo,
  updateCurrentLocation,
  updateIsPrimaryLookup
} from '../../action';
import {find} from 'lodash';
import LookUpFormHeader from './Components/LookUpFormHeader';
import Form from '../form';
import { Button } from 'mesh-component-library';
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from '../../app-consts/translations';

const FirstNameLastNameCompanyLookup = ({
  partnerDetails,
  history,
  showModal,
  closeModal
}) => {
  const {displayCode, partnerCode, appId} = partnerDetails;
  const [firstName, setFirstName] = useState({value:''});
  const [lastName, setLastName] = useState({value:''});
  const [companyName, setCompanyName] = useState({value:''});
  const [isCommercial, setIsCommercial] = useState(false);
  const spaceOrLetter = new RegExp("^[a-zA-Z ]+$");
  const companyNameRegex = new RegExp("^(?=.*[a-zA-Z\\d].*)[a-zA-Z\\d!@#$%&* _.,]*$");
  const pepSessionState = useSelector(getPepSession);
  const serviceDetails = useSelector(getServiceDetails);
  const dispatch = useDispatch();
  const getTranslatedText = useLocale()
  const isPayPerUse = useSelector(isPayPerUseSelector)

  useEffect(() => {
    if(pepSessionState.loading === false) {
      if(pepSessionState.error ===true) {
        localStorage.setItem('rescueCancel','NO');
        history.push(generatePath('sitedown'));
      } else if(pepSessionState.success === true && pepSessionState.session !== "") {
        localStorage.setItem('rescueCancel','NO');
        if(isPayPerUse) {
          history.push(generatePath('home'));
        }
      }
    }
  }, [pepSessionState.loading]);

  const continueClick = () => {
    const user = {};
    user.memberId = "";
    user.appId = appId;
    user.phoneLookup = false;
    user.details = {};
    user.details.firstName = firstName.value;
    user.details.lastName = lastName.value;
    user.details.zip = null;
    user.details.isCommercial = isCommercial;
    if(isCommercial === true) {
      user.details.companyName = companyName.value;
      dispatch(updateAdditionalFields({businessName: companyName.value}));
      user.details.disblmnt_prmotn_cd='010164913';
    } else {
      user.details.companyName = null;
      user.details.disblmnt_prmotn_cd='010164899';
    }
    user.lookupType = 'firstNameLastNameCompany'

    localStorage.setItem('firstName', firstName.value);
    localStorage.setItem('lastName', lastName.value);

    let eventsData = [];
    eventsData.push(
      {
        'name': 'firstname',
        'value': user.details.firstName
      },
      {
        'name': 'lastname',
        'value': user.details.lastName
      },
      {
        'name':'disblmnt_prmotn_cd',
        'value':user.details.disblmnt_prmotn_cd
      },
      {
        'name':'cust_biz_name',
        'value':user.details.companyName ?? ""
      }
    );

    let jacadaRequestData = {
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': eventsData
    };
    sendJacadaRequest(jacadaRequestData);
    dispatch(updateIsPrimaryLookup(false))
    dispatch(pepSession(user));
  };

  const firstNameOnChange = e => {
    let inputString = e.target.value;
    if (!spaceOrLetter.test(inputString)) {
      setFirstName({ value: inputString.slice(0, inputString.length - 1) });
    } else {
      setFirstName({ value: inputString });
    }
  };

  const lastNameOnChange = e => {
    let inputString = e.target.value;
    if (!spaceOrLetter.test(inputString)) {
      setLastName({ value: inputString.slice(0, inputString.length - 1) });
    } else {
      setLastName({ value: inputString });
    }
  };

  const companyNameOnChange = e => {
    setCompanyName({value: e.target.value});
  };

  const isInvalid = () => {
    const firstNameIsValid = firstName.value.trim().length;
    const lastNameIsValid = lastName.value.trim().length;
    const companyNameIsValid = (!isCommercial ||
      (isCommercial &&
        (companyNameRegex.test(companyName.value) && companyName.value.trim().length))
    );
    return !(firstNameIsValid && lastNameIsValid && companyNameIsValid);
  };

  const commercialPickup = () => {
    setIsCommercial(true);
  };

  const noCommercialPickup = () => {
    setIsCommercial(false);
  };

  const onSubmit = () => {
    const content = <div>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}:</span> {firstName.value}</p>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}:</span> {lastName.value}</p>
      {isCommercial && <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.COMPANY_NAME)}:</span> {companyName.value}</p>}
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  };

  const yesNoSelected = async ({target: {value}}) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }

  return (
    <React.Fragment>
      <LookUpFormHeader titleText={getTranslatedText(TRANSLATION_CONSTANTS.INFO_TO_DISPATCH)} id={"we-need-some-info-to-find-your-benefits-pep"}/>
      <Form id="pep-lookup" className="c-container-lookup__form u-align-left">
        <FormField
          errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_FIRST_NAME)}
          label={getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}
          id="firstname"
          className="firstname"
          minLength={3}
          name="firstname"
          x-autocompletetype="given-name"
          autoComplete="given-name"
          value={firstName.value}
          onChange={firstNameOnChange}
          onBlur={()=>setFirstName({value: firstName.value.trim()})}
          tabIndex="0"
          required />

        <FormField
          errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_LAST_NAME)}
          label={getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}
          id="lastname"
          className="lastname u-vr-3-top"
          minLength={3}
          name="lastname"
          x-autocompletetype="family-name"
          autoComplete="family-name"
          value={lastName.value}
          onChange={lastNameOnChange}
          onBlur={()=>setLastName({value: lastName.value.trim()})}
          tabIndex="0"
          required />

        <ButtonGroup
          data-dd-privacy="allow"
          label={getTranslatedText(TRANSLATION_CONSTANTS.PEP_BOYS_FLEET, displayCode)}
          type="radio"
          name="commercial-purposes"
          required
          onChange={() => {}}
          options={[
            {
              text: getTranslatedText(TRANSLATION_CONSTANTS.YES),
              value: 'yes',
              checked: isCommercial === true,
              onClick: commercialPickup,
              onKeyDown: commercialPickup,
            },
            {
              text: getTranslatedText(TRANSLATION_CONSTANTS.NO),
              value: 'no',
              onClick: noCommercialPickup,
              onKeyDown: noCommercialPickup,
              checked: isCommercial === false,
            }
          ]} />

        {isCommercial ?
          <FormField
            errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_COMPANY_NAME)}
            label={getTranslatedText(TRANSLATION_CONSTANTS.COMPANY_NAME)}
            id="companyName"
            className="u-vr-3-top"
            minLength={1}
            name="companyname"
            value={companyName.value}
            onChange={companyNameOnChange}
            onBlur={()=>setCompanyName({value: companyName.value.trim()})}
            tabIndex="2"
            required />
          : null}
          <br/>
        <Button
          size='lg'
          hasUpgrade
          id="lookup-continue"
          data-testid="continue-company-name-form"
          disabled={isInvalid()}
          onClick={onSubmit}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default FirstNameLastNameCompanyLookup;
