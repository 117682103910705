import React, { useState } from 'react';
import {
  Grid,
  Type,
  FormField,
  Flex,
  Button,
  Icon,
  Tooltip,
  Alert
} from 'mesh-component-library';
import { QuestionIcon } from 'mesh-icon-library';
import { hasSpecialChars } from "../../app-consts/appConstants";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { sendVinToJacada } from "../../jacada/sendJacadaEventData";
import FormValidators from "../../utilities/form-validators";

const VinOnlyLookupForm = ({ dispatchCommonBenefitsLookup, partnerDetails, closeModal, showModal, setCurrentLookup }) => {
  const {
    appId,
    experience: {
      lookupMethods: {
        components: { primary, alternate },
      },
    },
  } = partnerDetails;
  const [vin, setVin] = useState('');
  const [vinHasError, setVinHasError] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [vehicleNotFound] = useState(localStorage.getItem("licensePlateLookupResultsFailure"));
  const getTranslatedText = useLocale();
  const isLicensePlateOrVinLookup = primary === "licensePlateOrVinLookup" || alternate === "licensePlateOrVinLookup";
  const displayLicensePlateLink = isLicensePlateOrVinLookup || primary !== alternate;

  const isVinValid = (vinInput) => {
    return !hasSpecialChars.test(vinInput) && vinInput.length === 17;
  }

  const vinOnBlur = ({ target: { value } }) => {
    setVinHasError(!isVinValid(value));
    sendVinToJacada(value.toUpperCase());
  }

  const vinOnChange = ({ target: { value = "" } }) => {
    setVinHasError(false);
    let vinIsValid = isVinValid(value);
    let continueButtonDisabled = !(vinIsValid);
    const formattedValue = value.toUpperCase();
    setVin(formattedValue);
    setContinueButtonDisabled(continueButtonDisabled);
  }

  const vinOnKeyPress = (event) => {
    const isAlphaNumericVal = FormValidators.allowOnlyAlphaNumericInput(event);
    setVinHasError(!isAlphaNumericVal);
  }

  const displayVehicleNotFoundMessage = () => {
    let message = !!localStorage.getItem('updateAttempts') ?
      "Sorry, we are having difficulty looking up your vehicle information."
      :
      "Sorry, we can't find a vehicle matching your license plate."

    return (
      <Grid utils={{
        vrTop: "sm",
      }}>
        <Grid.Column>
          <Alert
            title={message}
            variant="critical"
          />
        </Grid.Column>
      </Grid>
    );
  }

  const onSubmit = () => {
    const content = <div>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.VIN)}:</span> {vin}</p>
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  }

  const yesNoSelected = ({ target: { value } }) => {
    if (value === 'yes') {
      requestBenefits()
    } else {
      closeModal()
    }
  }
  const requestBenefits = () => {
    const formattedVin = vin.toUpperCase();
    localStorage.setItem('memberId', formattedVin);

    const formdata = {
      vin: formattedVin
    };

    const user = {
      phoneLookup: false,
      appId,
      formdata,
      lookupType: 'vinOnly'
    };

    dispatchCommonBenefitsLookup(user);
    closeModal();
    localStorage.setItem('vin', formattedVin);
  }

  const searchByLicensePlateNumber = () => {
    if (isLicensePlateOrVinLookup) {
      setCurrentLookup('licensePlateOrVinLookup')
    } else {
      setCurrentLookup('licensePlateLookup')
    }
  }

  return (
    <>
      {vehicleNotFound ? displayVehicleNotFoundMessage() : null}
      <Grid
        utils={{
          vrBottom: "md"
        }}>
        <Grid.Column>
          <Type
            id="please-provide-your-vehicle-info"
            element="h1"
            weight="semibold"
            align="left"
            size="6xl"
            lineHeight="md"
            utils={{
              vrTop: "md"
            }}>
            {isLicensePlateOrVinLookup
              ? getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)
              : getTranslatedText(TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO)}
          </Type>
        </Grid.Column>
      </Grid>
      <Grid utils={{
        vrBottom: "md"
      }}>
        <Grid.Column>
          <FormField
            size="md"
            id="vin"
            name="VIN"
            label={<Flex alignItems="stretch" justifyContent="between" isBlock={true}>
              <Type size="l" weight="bold" emphasis="information">
                {getTranslatedText(TRANSLATION_CONSTANTS.VIN)}
              </Type>
              <Tooltip
                id="vin-tooltip"
                size="lg"
                text={getTranslatedText(TRANSLATION_CONSTANTS.VIN_FIND_INSTRUCTIONS)}
              >
                <Icon icon={QuestionIcon} color="#0033A0"
                  size={1.2} data-testid="vin-tooltip" />
              </Tooltip>
            </Flex>}
            labelProps={{
              style: {
                width: "100%"
              }
            }}
            value={vin}
            onChange={vinOnChange}
            onKeyDown={vinOnKeyPress}
            onBlur={vinOnBlur}
            utils={{
              vrTop: "xs",
              vrBottom: "xs"
            }}
            errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_VALID_VIN)}
            hasError={vinHasError}
            minLength={17}
            maxLength={17}
            required
            data-testid="vin"
          />
        </Grid.Column>
      </Grid>
      <Grid utils={{
        vrBottom: "md"
      }}>
        <Grid.Column>
          <Button
            size='lg'
            hasUpgrade
            id="lookup-continue"
            data-testid="continue-with-vin"
            disabled={continueButtonDisabled}
            onClick={onSubmit}
            utils={{
              fullWidth: true
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
          </Button>
        </Grid.Column>
      </Grid>
      {displayLicensePlateLink && <><Grid utils={{
        vrBottom: "md"
      }}>
        <Grid.Column>
          <Type
            element="span"
            size="4xl"
            variant="darker"
            weight="normal"
          >
            - {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -
          </Type>
        </Grid.Column>
      </Grid>
        <Grid utils={{
          vrBottom: "md"
        }}>
          <Grid.Column>
            <Type
              element="span"
              size="l"
              variant="information"
              weight="semibold"
              onClick={searchByLicensePlateNumber}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.SEARCH_BY_LICENSE_PLATE)}
            </Type>
          </Grid.Column>
        </Grid>
      </>}
    </>
  )

}

export default VinOnlyLookupForm;
