import React, {useState} from 'react';
import FormField from '../form-field';
import CONSTANTS from '../../app-consts/appConstants';
import LookUpFormHeader from './Components/LookUpFormHeader';
import Form from '../form';
import ModalDialog from '../modal-dialog';
import {useSelector} from 'react-redux';
import MemberCard from "../memberCard";
import {PrimaryLookupFailure} from "../warning-alerts";
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { errorMessageTranslatedText } from '../../utils/translatedTextUtils';
import { Button } from 'mesh-component-library';

const PolicyMemberLookup = ({ dispatchCommonBenefitsLookup, appId, showModal, closeModal, currentFormValidations, currentLookup }) => {

  const partnerDetails = useSelector(state => state.partnerDetails.partnerDetails);
  const {membershipCard, membershipCustomerServiceNumber} = partnerDetails;
  const primary = partnerDetails.experience?.lookupMethods?.components?.primary;
  const noBenefits = useSelector(state => state.commonBenefits.noBenefits)
  const regex = currentFormValidations.fieldPolicyNumber?.regex || currentFormValidations.memberIdField?.regex;
  const errorMessage = currentFormValidations.fieldPolicyNumber?.errorMessage || currentFormValidations.memberIdField?.errorMessage;
  const maxLength = currentFormValidations.fieldPolicyNumber?.maxLength || currentFormValidations.memberIdField?.maxLength;
  const [policyNumber, setPolicyNumber] = useState('');
  const [lastName, setLastName] = useState({value:''});
  const isPolicyLookup = currentLookup === 'policyNumberLookup';
  const isPolicyLastNameLookup = currentLookup === 'policyNumberLastNameLookup';
  const isMemberLastNameLookup = currentLookup === 'memberNumberLastNameLookup';
  const isLastNameRequired = isMemberLastNameLookup || isPolicyLastNameLookup;
  const [policyNumberHasError, setPolicyNumberHasError] = useState(false);
  const [whatIsThisModalIsActive, setWhatIsThisModalIsActive] = useState(false);
  const getTranslatedText = useLocale();

  const formLabel = (isPolicyLookup || isPolicyLastNameLookup) ? getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER) : getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NUMBER);
  const formType = (isPolicyLookup || regex) ? 'text' : 'number';
  const memberIdToolTip = (partnerDetails.displayCode === 'A20') ? getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_MEMBER_ID):'';

  const continueClick = () => {
    const user = {};
    user.memberId = policyNumber;
    user.phoneLookup = false;
    user.lastName = isPolicyLastNameLookup || isMemberLastNameLookup ? lastName.value: '';
    user.etlAlternateId = true;
    // this can be removed once phone lookup component refactor is merged in
    user.appId = appId;
    let lookupType= '';
    if(isPolicyLookup) {
      lookupType = 'policyNumber'
    } else if (isPolicyLastNameLookup) {
      lookupType = 'policyNumberLastNameLookup'
    } else if (isMemberLastNameLookup) {
      lookupType = 'memberNumberLastNameLookup'
    } else {
      lookupType = 'memberNumber'
    }
    user.lookupType = lookupType;
    dispatchCommonBenefitsLookup(user);
  };

  const onPolicyNumberChange = (e) => {
    const policyNumber = e.target?.value?.toUpperCase();
    let isValid = true;
    if (regex) {
      const stringToRegex = new RegExp(regex);
      isValid = stringToRegex.test(policyNumber);
    }

    setPolicyNumber(policyNumber);
    setPolicyNumberHasError(!isValid);
  };

  const handlePolicyNumberError = (e) => {
    if(e.keyCode !== CONSTANTS.DELETE && e.keyCode !== CONSTANTS.BACKSPACE && e.target.value !== undefined && e.target.value.toString().length >= e.target.maxLength) {
      e.preventDefault();
    } else {
      setPolicyNumberHasError(true);
    }
  };

  const validateCompleteForm = () => {
    if (isLastNameRequired) {
      return lastName.value.length < 3 || (!policyNumber || policyNumberHasError)
    } else {
      return !policyNumber || policyNumberHasError
    }
  }

  const onSubmit = () => {
    const confirmText = isPolicyLookup ? `${getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER)}:` : `${getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NUMBER)}:`
    const content = <div>
      <p><span>{confirmText}</span> {policyNumber}</p>
      {isLastNameRequired ? <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}:</span> {lastName.value}</p> : ""}
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  };

  const yesNoSelected = async ({target: {value}}) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }

  const displayWhatIsThisModal = () => {
    const titleWhereFindNumber = isPolicyLookup ? getTranslatedText(TRANSLATION_CONSTANTS.FIND_POLICY_NUMBER) : getTranslatedText(TRANSLATION_CONSTANTS.FIND_MEMBER_NUMBER);
    const lostPolicyNumber = isPolicyLookup ? getTranslatedText(TRANSLATION_CONSTANTS.LOST_POLICY_NUMBER) : getTranslatedText(TRANSLATION_CONSTANTS.LOST_MEMBER_NUMBER);
    return (
      <ModalDialog
        id="whatsThisModal"
        title={titleWhereFindNumber}
        className="whatsThisModal"
        isSlideDrawer
        hideTrigger
        isInline
        showCloseBtn={true}
        isActive={whatIsThisModalIsActive}
        onClose={() => {setWhatIsThisModalIsActive(false)}}
      >
        <div className="c-whatsThis-svg">
          <MemberCard imageName={membershipCard}/>
          <div className="hightlight"></div>
        </div>
        <div className="c-divider u-vr-4-top"></div>
        <div className="c-whatsThis-content">
          <p className="u-vr-2-top">{lostPolicyNumber} <span className="phoneNum u-text-bold">{membershipCustomerServiceNumber}</span>.</p>
          <div className="u-vr-4-top">{getTranslatedText(TRANSLATION_CONSTANTS.MONDAY_FRIDAY)}</div>
          <div>7:00 am - 7:00 pm CST </div>
        </div>
      </ModalDialog>
    )
  }

  return (
    <React.Fragment>
      {noBenefits && <PrimaryLookupFailure primaryLookup={primary}/>}
      <LookUpFormHeader titleText={getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)} id={"we-need-some-info-to-find-your-benefits"}/>
      {whatIsThisModalIsActive && displayWhatIsThisModal()}
      <Form id="" className="c-container-lookup__form">
        {membershipCard ?
          <span role="link"
                tabIndex="0"
                className="whats-this"
                onClick={() => setWhatIsThisModalIsActive(true)}
                onKeyDown={() => setWhatIsThisModalIsActive(true)}>
            {getTranslatedText(TRANSLATION_CONSTANTS.WHAT_IS_THIS)}
          </span> : null
        }
        <FormField
          id="memberId"
          errorText={getTranslatedText(errorMessageTranslatedText(errorMessage))}
          name="memberId"
          label={formLabel}
          type={formType}
          maxLength={maxLength}
          value={policyNumber}
          onChange={onPolicyNumberChange}
          onKeyDown={handlePolicyNumberError}
          hasError={policyNumberHasError}
          infoPopup={memberIdToolTip}
          required
        />
        { isLastNameRequired ?
          <FormField
            errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_LAST_NAME)}
            label={getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}
            id="lastName"
            className="lastName"
            minLength={1}
            name="lastName"
            autoComplete="lastName"
            value={lastName.value}
            onChange={(event) => setLastName({value: event.target.value.trim()})}
            required />
          :
          null
        }
        <Button
          size='lg'
          hasUpgrade
          id="lookup-continue"
          data-testid="policy-member-continue"
          disabled={!policyNumber || policyNumberHasError || (isLastNameRequired && lastName.value.length < 1)}
          onClick={onSubmit}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
      </Form>
    </React.Fragment>
  );
};

export default PolicyMemberLookup;
