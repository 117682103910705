export const UPDATE_DESTINATION = "UPDATE_DESTINATION";
export const CLEAR_DESTINATION = "CLEAR_DESTINATION";
export const UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS = "UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS";
export const INVALID_DESTINATION_ADDRESS = "INVALID_DESTINATION_ADDRESS";
export const ADD_GOOGLE_PLACE_ID_TO_DESTINATION = "ADD_GOOGLE_PLACE_ID_TO_DESTINATION";

export function updateDestination(data){
  return { type: UPDATE_DESTINATION,  payload: data};
}

export function clearDestination(){
    return { type: CLEAR_DESTINATION };
}

export function updateDestinationWithPredictionAddress(data){
  return {type: UPDATE_DESTINATION_WITH_PREDICTION_ADDRESS, payload: data}
}

export function addGooglePlaceIdToDestination(data) {
  return {type: ADD_GOOGLE_PLACE_ID_TO_DESTINATION, payload: data};
}
