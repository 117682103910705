import {
  english_translation_pep,
  french_translation_pep,
  spanish_translation_pep,
} from "./PEP";
import { english_translation } from "./translation_en";
import { spanish_translation } from "./translation_es";
import { french_translation } from "./translation_fr";

const translations = {
  en_US: {
    global: english_translation,
    PEP: english_translation_pep,
  },
  en_CA: {
    global: english_translation,
    PEP: english_translation_pep,
  },
  fr_CA: {
    global: french_translation,
    PEP: french_translation_pep,
  },
  es_US: {
    global: spanish_translation,
    PEP: spanish_translation_pep,
  },
};

export default translations;
