import {
  SELECTED_MAKE_MODEL_YEAR
} from './../action'

const initialState = {
  make: '',
  model: '',
  year: 0,
  selectedVehicleIndex: null,
  vehicleList: {},
  vehicleID: null,
  vehiclePropID: null,
  suspendIndicator: '',
  addedVehicle: false
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SELECTED_MAKE_MODEL_YEAR:
      return {
        ...state,
        make: action.payload.make,
        model: action.payload.model,
        year: action.payload.year,
        selectedVehicleIndex: action.payload.selectedVehicleIndex,
        vehicleList: action.payload.vehicleList,
        vehicleID: action.payload.vehicleID,
        vehiclePropID: action.payload.vehiclePropID,
        suspendIndicator: action.payload.suspendIndicator,
        addedVehicle: action.payload.addedVehicle
      }
    default:
      return state
  }
}
