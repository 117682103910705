//`use strict`;
import React from 'react';
import PropTypes from 'prop-types';
import FormManager from '../form/FormManager';
import FormControl from '../form/FormControl';
import Hint from '../hint';
import CONSTANTS from '../../app-consts/appConstants';
import classnames from 'classnames';

const ERROR_CLASS = CONSTANTS.HTML_CLASS.ERROR;

export default class GoogleFormField extends FormControl {
  constructor(props) {
    super(props);

    this.state = {
      value: props.defaultValue || props.value || '',
      hasError: props.hasError === null ? false : props.hasError,
      hasWarning: props.hasWarning === null ? false : props.hasWarning,
    };
  }

 static defaultProps = Object.assign({
   'aria-describedby': '',
   className: '',
   errorText: '',
   hasError: null,
   helpText: '',
   hasWarning: null,
   onChange(event) {},
   onBlur(event) {},
   onKeyDown(event) {},
   onPaste(event) {},
   name: '',
   placeholder: '',
   required: false,
   type: 'text',
   suffix: '',
   value: null,

 }, FormControl.defaultProps)

 static propTypes = Object.assign({
   'aria-describedby': PropTypes.string,
   className: PropTypes.string,
   errorText: PropTypes.string,
   hasError: PropTypes.bool,
   hasWarning: PropTypes.bool,
   helpText: PropTypes.string,
   onChange: PropTypes.func,
   onBlur: PropTypes.func,
   onKeyDown: PropTypes.func,
   onPaste: PropTypes.func,
   name: PropTypes.string,
   placeholder: PropTypes.string,
   suffix: PropTypes.string,
   required: PropTypes.bool,
   type: PropTypes.string,
   value: PropTypes.oneOfType([
     PropTypes.string,
     PropTypes.number
   ]),
 }, FormControl.propTypes)

  validate = () => {
    const id = this.id;
    const value = this.state.value;
    const { name, required, type } = this.props;
    const valid = required ? FormManager.validate(value, this.validators) : true;
    const hasError = !valid;
    const validatorObj = this.props.onValidate({
      hasError,
      value,
    });

    this.setState({ hasError, });

    return {
      ...validatorObj,
      id,
      name,
      required,
      type,
      valid,
      value,
    };
  }


  UNSAFE_componentWillUpdate(nextProps) {

    const { value, hasError, hasWarning } = nextProps;

    this.setState({
      value: value === null ? '' : value,
      hasError: hasError === null ? this.state.hasError : hasError,
      hasWarning: hasWarning === null ? this.state.hasWarning : hasWarning,
    });
  }

  _onChange = event => {
    let value = this.props.onChange(event) || event.target.value;

    this.setState({  value: value === null ? '' : value, });
  }

  onKeyDown = event => {

    const wordCount = this.state.wordCount;

    this.props.onKeyDown(event);

    if (wordCount < 1) {
      if ( event.keyCode === 46 || event.keyCode === 8 ) {// Allow backspace and delete buttons
      } else if (event.keyCode < 48 || event.keyCode > 57 ) {//all other buttons
        event.preventDefault();
      }
    }
  }

  _onBlur = event => {
    const {
      _formValidateOnBlur,
    } = this.props;

    if(_formValidateOnBlur) {
      this.validate();
    }

    this.props.onBlur(event);
  }

  _onPaste = event => {
    const {
      preventPaste
    } = this.props;


    if(preventPaste) {
      event.preventDefault();
    }

    this.props.onPaste(event);
  }

  _hintGenerator = () => {
    const {
      errorText,
      id,
      required,
    } = this.props;

    if(!required) {
      return <Hint id={`${id}_error`} text={ errorText } icon="error-solid" type="error" />
    } else {
      return <Hint id={`${id}_error`} text={ errorText } icon="error-solid" type="error" />
    }
  }

  render() {
    const {
      className,
      defaultValue,
      hasError,
      hasWarning,
      helpText,
      errorText,
      name,
      onValidate,
      hidden,
      placeholder,
      preventPaste,
      required,
      successText,
      rows,
      type,
      validators,
      onPaste,
      _form,
      _formValidateOnBlur,
      suffix,
      ...other
    } = this.props;

    const {id, props, _onPaste} = this;

    const {
      value,
    } = this.state;

    const ariaDescribedby = `${id}_error ${id}_hint ${props['aria-describedby']}`;
    const _hasError = this.state.hasError;
    const _hasWarning = this.state.hasWarning;
    // If we're dealing with a masked input, use its onChange
    const _onChange = this._onChange;
    //If we're dealing with onBlur validation
    const _onBlur = this._onBlur;


    return (
      <div>
        <div className={ classnames(
          'c-form-field',
          { [className]: className, },
          { [ERROR_CLASS]: _hasError, },
        ) }>
            <input
              { ...other }
              id={ id }
              ref={ (input) => { this.googleFormField = input; } }
              className="c-form-field__input-gps"
              name={ name }
              required={ required }
              placeholder={ placeholder }
              type={ type || 'text' }
              onChange={ _onChange }
              onBlur={ _onBlur }
              onPaste={ _onPaste }
              value={ value }
              aria-describedby={ ariaDescribedby }
              suffix = {suffix}
               />
        </div>
        { (_hasError) ? this._hintGenerator() : '' }
        { (!_hasError || !_hasWarning ? <Hint id={`${id}_hint`} text={ helpText } /> : '') }
      </div>
    );
  }
}
