import React from "react";
import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations";
import TriageStep from "../../../components/triage-step";
import { useLocale } from "../../../contexts/LocaleContext";

const FuelDeliveryTriageSteps = () => {
  const getTranslatedText = useLocale();
  return (
    <>
      <TriageStep
        step={1}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_CONTENT
        )}
      />
      <TriageStep
        step={2}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_CONTENT
        )}
      />
      <TriageStep
        step={3}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_CONTENT
        )}
      />
      <br/>
      <div style={{textAlign: "left"}}>{getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_FINAL_CONTENT)}</div>
      <br/>
    </>
  );
};

export default FuelDeliveryTriageSteps;
