import React, {useState, useEffect} from 'react';
import Whitelogo from '../../assets/whitelogo';
import RightArrow from '../svg/RightArrow/';
import NavigateWarning from '../../components/navigatewarning';
import {datadogRum} from "@datadog/browser-rum";
import {useSelector} from 'react-redux';
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {useLocale} from "../../contexts/LocaleContext";
import { serviceTranslatedText } from '../../utils/translatedTextUtils';
import {CallForHelpFeedbackModal} from "../callforhelpfeedbackmodal";
import titleCase from "../../utils/titleCase";
import appConstants from "../../app-consts/appConstants";

const Header = (props) => {
  const {
    hasAllstateBrandHeader,
    phone,
    displayCode,
    serviceInfo,
    isHeaderBrandingEnabled,
    hideMenuButton,
    isHeaderDisabled,
    displayFeedbackModal
  } = props

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [showNavigateWarningModal, setShowNavigateWarningModal] = useState(false);
  const [showCallForHelpFeedbackModal, setShowCallForHelpFeedbackModal] = useState(false);
  const [buttonIsDisabled, setButtonIsDisabled] = useState(false);
  const getTranslatedText = useLocale();
  const submitRequest = useSelector(state => state.submitRequest);
  const serviceRequestPayload = useSelector(state => state.serviceRequestPayload);
  const partnerCode = useSelector(state => state.partnerDetails.partnerDetails.partnerCode);
  const omniStreamlined = useSelector(state => state.partnerDetails.partnerDetails.omniStreamlined);
  const isPrimaryLookup = useSelector(state => state.generalInfo.isPrimaryLookup);
  useEffect(() => {
    if(submitRequest?.success){
      setButtonIsDisabled(true)
    }
  }, [submitRequest])

  const closeNavigateWarningModal = () => {
    setIsMenuActive(false)
    setShowNavigateWarningModal(false)
  };

  const closeCallForHelpFeedbackModal = () => {
    setIsMenuActive(false)
    setShowCallForHelpFeedbackModal(false)
  };

  const toggleMenu = () => {
    setIsMenuActive(!isMenuActive)
  };

  const handlePhoneCall = () => {
    datadogRum.addAction(`${displayCode} HelpButtonClicked`)
    setIsMenuActive(false)
    if(displayFeedbackModal) {
      setShowCallForHelpFeedbackModal(true)
    } else {
      const callbackPhone = phone || localStorage.getItem('callbackNumber');
      window.location.href = 'tel:' + callbackPhone;
    }
  };

  const handleMembership = () => {
    datadogRum.addAction(`${displayCode} ManageMembershipClicked`);
    if (window.location.host === appConstants.ALLSTATE_HOST
      || window.location.host === appConstants.VANITY_HOST) {
      window.location.href = appConstants.MANAGE_ROADSIDE_URL;
    } else {
      window.location.href = appConstants.QA_MANAGE_ROADSIDE_URL;
    }
  };

  return (
    <div>
      {showNavigateWarningModal && <NavigateWarning displayCode={displayCode}
                                                    serviceRequestPayload={serviceRequestPayload}
                                                    closeModal={closeNavigateWarningModal} />}
      {showCallForHelpFeedbackModal && displayFeedbackModal &&
        <CallForHelpFeedbackModal phone={phone}
                                  serviceRequestPayload={serviceRequestPayload}
                                  omniStreamlined={omniStreamlined}
                                  partnerCode={partnerCode}
                                  closeModal={closeCallForHelpFeedbackModal} />}
      {isMenuActive && <div className="active c-menuDropdown_overlay" onClick={toggleMenu}/>}
      <header className={isHeaderBrandingEnabled && hasAllstateBrandHeader ? 'header' : 'no-branding-header'}>
        {!isHeaderDisabled && hasAllstateBrandHeader && isHeaderBrandingEnabled && <Whitelogo />}
        {!isHeaderDisabled && <h1 id="page-title" className='left'>{(serviceInfo?.serviceText && getTranslatedText(serviceTranslatedText(serviceInfo.serviceText))) || getTranslatedText(TRANSLATION_CONSTANTS.ROADSIDE_ASSISTANCE)}</h1>}
        {!hideMenuButton ? <button aria-label="Menu"
                                   data-testid="menuHamburgerButton"
                                   className={`${isMenuActive ? "active" : ""} menu`}
                                   onClick={toggleMenu}
                                   onKeyDown={(e) => { if (e.keyCode === 13) toggleMenu() }}>
          <span className="bar"/>
          <span className="bar"/>
          <span className="bar"/>
        </button>  : null}
      </header>

      {
        isMenuActive &&
        <nav className={`${isMenuActive ? "active" : ""} c-menuDropdown`}>
          <ul>
            {
              !buttonIsDisabled && !isPrimaryLookup &&
                <li>
                  <a tabIndex="0"
                     title="Home"
                     onClick={() => setShowNavigateWarningModal(true)}>
                      {getTranslatedText(TRANSLATION_CONSTANTS.HOME)}
                    <RightArrow/>
                  </a>
                </li>
            }
            {
              displayCode === "AMC" &&
                <li>
                  <a tabIndex="0"
                    id="menu-manage-membership"
                    title="Manage Membership"
                    onClick={ handleMembership }>
                      {getTranslatedText(TRANSLATION_CONSTANTS.MANAGE_MEMBERSHIP)}
                    <RightArrow />
                  </a>
                </li>
            }
            <li>
              <a tabIndex="0"
                 id="menu-call-for-help"
                 title="Call Us"
                 className="call"
                 onClick={ handlePhoneCall }>
                  {titleCase(getTranslatedText(TRANSLATION_CONSTANTS.CALL_FOR_HELP))}
                <RightArrow />
              </a>
            </li>
          </ul>
        </nav>
      }
    </div>
  );
}

export default Header;
