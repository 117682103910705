import React from 'react';
import ModalDialog from '../modal-dialog';
import {connect} from 'react-redux';
import RightArrow from '../svg/RightArrow';
import FormField from '../form-field';
import {
  updateAdditionalComments,
  updateCurrentLocation,
  updateDisablementAddress,
  clearLocationError
} from '../../action';
import {datadogRum} from "@datadog/browser-rum";
import {
  isSupportedRegion,
  formatAddress, getResultByTypeAndDistanceToCoords
} from "../../utils/mapsLocationHelper";
import Spinner from "../spinner";
import {
  INVALID_ADDRESS_ID,
  SERVICE_UNAVAILABLE_ID,
  SERVICE_UNAVAILABLE_TITLE,
  SERVICE_NOT_AVAILABLE_TEXT
} from "../../app-consts/errorMessageConstants";
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { LocaleContext } from '../../contexts/LocaleContext';
import isGMPartnerCode from "../../utils/isGMPartnerCode";
import {
  sendAutoDetectedDisablementLocationToJacada,
  sendAutoDetectionOfLocationToJacada
} from "../../jacada/sendJacadaEventData";
import { flow, keyBy, mapValues } from 'lodash';
import { Button } from 'mesh-component-library';
const google = window.google;

export class DisablementMap extends React.Component {
  static contextType = LocaleContext;
  constructor(props) {
    super(props);

    this.state = {
      mouseDown: false,
      locationDetailsText: props.serviceRequestPayload.serviceRequest.additionalComments || '',
      isActive: false,
      isDetectingLocation: false,
      displayMap: false,
      isLocationUpdated: false
    }

    this.map = null;
    this.street = null;
    this.markers = [];
  }

  componentDidMount() {
    const {
      currentLocation: {
        formatted_address,
        latitude,
        longitude,
        setFromLocationServices,
        error},
      setConfirmDisablement,
      locationSelected} = this.props;

    if (!formatted_address && !setFromLocationServices) {

      this.setState({ isDetectingLocation: true})
      this.geoLocateCurrentLocation()
    }
    if(formatted_address) {
      this.setState({displayMap: true});
      this.renderMap();
      this.addMapListeners(this.map);
      this.updateLocationOnMapByCoordinates(latitude, longitude, false, undefined);
      if(!error?.errorMessage && !locationSelected) {
        setConfirmDisablement(true);
      }
    }
  }

  componentDidUpdate() {
    const {displayMap, isLocationUpdated} = this.state;
    const {currentLocation : {
      formatted_address,
      setFromLocationServices,
      latitude,
      longitude,
      error},
      setConfirmDisablement,
      setPlaceDetails } = this.props;

    if ((latitude !== 0 && latitude !== undefined) && (longitude !== 0 && longitude !== undefined) && !isLocationUpdated) {
      this.setState({displayMap: true, isLocationUpdated: true});
      this.updateLocationOnMapByCoordinates(latitude, longitude, false, undefined);
      if(!error?.errorMessage) {
        setConfirmDisablement(true);
      }
    }
    if (!setFromLocationServices && this.props.prediction?.description !== undefined && this.props.prediction?.description !== formatted_address) {

      if (!displayMap) {
        this.setState({displayMap: true})
        this.renderMap();
        this.addMapListeners(this.map);
      }
      if (this.map) {
        this.getPredictionPlaceDetails();
        setPlaceDetails({})
      }
    }
  }

  renderMap = () => {
    if (!this.props.currentLocation.deniedUsingLocation) {
      this.setState({displayMap: true})
    }
    const mapElement = document.getElementById('map_id_1');
    this.map = new google.maps.Map(mapElement, this.props.mapOptions);
    this.service = new google.maps.places.PlacesService(this.map);
  }

  addMapListeners = (map) => {

    map.addListener("center_changed", function() {

      clearTimeout(this.animation);
      this.setState({mouseDown: true});

      this.animation = setTimeout(() => {

        this.setState({mouseDown: false});
        this.updateLocationOnMapByCoordinates(
          map.center.lat(),
          map.center.lng(),
          true,
          map.zoom
        );
      }, 400);
    }.bind(this));
  }

  geocodeLatLng = (location) => {
    new google.maps.Geocoder().geocode({location}, (results, status) => {
      this.setState({
        isDetectingLocation: false
      })
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        datadogRum.addError(new Error("Reverse geocoding error " + status));
      } else {
        const filteredResult = getResultByTypeAndDistanceToCoords(location, results);
        this.props.hideLocationList();
        this.checkFormatAndVerifyAddress(filteredResult, location)
      }
    });
  }

  geoLocateCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.props.updateCurrentLocation({
          locationPromptClicked: 'allow'
        });
        this.renderMap();
        this.addMapListeners(this.map);
        this.updateLocationOnMapByCoordinates(position.coords.latitude, position.coords.longitude, true)
      }, () => {
        this.props.updateCurrentLocation({
          locationPromptClicked: 'block'
        });
        this.blockLocation();
      }, {
        enableHighAccuracy: true,
        timeout: 4000
      });
    } else {
      datadogRum.addError(new Error('Error encountered with browser geo location'));
    }
  }

  blockLocation = () => {
    const getTranslatedText = this.context;
    this.props.updateCurrentLocation({
      deniedUsingLocation: true,
      setFromLocationServices: false
    })
    datadogRum.addError(new Error('Geolocation Disabled'));
    this.props.setInputPlaceholder(getTranslatedText(TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION));
    this.props.openErrorModal(INVALID_ADDRESS_ID, "", getTranslatedText(TRANSLATION_CONSTANTS.GEOLOCATION_DISABLED));
    this.setState({isDetectingLocation: false});
    sendAutoDetectionOfLocationToJacada(false);
  }

  getPredictionPlaceDetails = () => {
    const { prediction } = this.props;

    if (prediction.place_id) {
      this.service.getDetails({
        placeId: prediction.place_id,
        fields: [
          "address_components",
          "formatted_address",
          "geometry",
          "vicinity",
          "utc_offset"
        ]
      }, (place, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          if (place.length === 0) {
            datadogRum.addError(new Error("no results returned from reverse geocoding"));
            return
          }
          this.updateLocationOnMapByCoordinates(place.geometry?.location?.lat(), place.geometry?.location?.lng(), false)
          if (!formatAddress(place, false)?.addressLine1) {
            this.geocodeLatLng(place.geometry?.location)
          } else {
            this.checkFormatAndVerifyAddress(place);
          }
        }
      });
    }
  }

  updateLocationOnMapByCoordinates = (passedInLatitude, passedInLongitude, needsGeocoding, zoom) => {
    const {latitude, longitude} = this.props.currentLocation;
    const location = {
      lat: passedInLatitude,
      lng: passedInLongitude
    }

    if (latitude !== location.lat && longitude !== location.lng && needsGeocoding) {
      this.geocodeLatLng(location);
    } else if (!needsGeocoding) {
      this.map.setCenter(location)
    }

    if(!zoom || this.map.zoom !== zoom) {
      this.map.setZoom(17)
    }
  }

  checkFormatAndVerifyAddress = (place, location) => {
    this.props.updateCurrentLocation(place);

    this.checkIfServiceAvailable(place);

    const formattedAddressDetails = formatAddress(place, false);

    this.props.setPlaceName(place.formatted_address);

    this.props.updateCurrentLocation(formattedAddressDetails);

    this.manageAddressErrors(formattedAddressDetails);

    if (!isGMPartnerCode(this.partnerCode)) {
      let currentLocation = flow(
        x => keyBy(x, "types[0]"),
        x => mapValues(x, "short_name")
      )(place.address_components);
      sendAutoDetectedDisablementLocationToJacada(formattedAddressDetails, currentLocation, location);
    }
  }

  checkIfServiceAvailable = (disablementLocation) => {
    if (!isSupportedRegion(disablementLocation, this.props.partnerDetails?.experience?.supportedRegions)) {
      return this.props.openErrorModal(SERVICE_UNAVAILABLE_ID, SERVICE_UNAVAILABLE_TITLE, SERVICE_NOT_AVAILABLE_TEXT);
    }
  }

  manageAddressErrors = (formattedAddressDetails) => {
    if(formattedAddressDetails?.error) {
      this.props.openErrorModal(INVALID_ADDRESS_ID, "", formattedAddressDetails?.error?.errorMessage);
      if(this.props.prediction?.types !== undefined){
        datadogRum.addError(`Invalid address prediction types: ${this.props.prediction.types}`)
      }
    } else {
        this.props.setConfirmDisablement(true);
        this.props.clearLocationError();
        this.props.setErrorMessage("")
        this.props.setErrorModalId("")
    }
  }

  locationDetailsHandler = () => {
    this.setState({
      isActive: true
    })
  }

  onSaveHandler = () => {
    const text = document.getElementById("LocationDetailsInput").value;

    this.setState({
      locationDetailsText: text,
      isActive: false
    })

    this.props.updateAdditionalComments(text)
  }

  createTemplate(style, mapId, streetId, mouseDown, locationDetailsText) {
    const getTranslatedText = this.context;
    return (
      <div className={`${!this.state.displayMap ? 'map' : ''}`}>
        <div className="l-grid">
          <div className="l-grid__col l-grid__col--12">

            <div className='l-grid fullheight' style={style}>
              <div
                className="l-grid__col l-grid__col--12"
                id={mapId}>
              </div>
              <div className={`${mouseDown ? 'mouseDown' : ''} centerPin`}>
                {
                  this.props.locationSelected &&
                  <button className="c-location-details_btn"
                          onClick={this.locationDetailsHandler}>
                    <a tabIndex="0" className={locationDetailsText ?
                      "c-location-text_inputted" :
                      "c-location-text"}>{locationDetailsText ?
                      `${locationDetailsText.slice(0, 20)}...` :
                      getTranslatedText(TRANSLATION_CONSTANTS.ADD_LOCATION_DETAILS)}</a>
                    <div className="c-location-svg u-flex">
                      <RightArrow/>
                    </div>
                  </button>
                }
                <div className="centerPin__main">
                  <span className="centerPin__head"/>
                  <span className="centerPin__body"/>
                </div>
                <span className="centerPin__shadow"/>
              </div>
            </div>
          </div>
        </div>

        { this.state.isActive ?
          <div>
            <ModalDialog
              id="locationDetails"
              title={getTranslatedText(TRANSLATION_CONSTANTS.ADD_LOCATION_DETAILS)}
              className="locationDetails-modal"
              hideTrigger
              isInline
              showCloseBtn={true}
              isActive={this.state.isActive}
              onClose={(() => {
                this.setState({isActive: false})
              })}
              footer={
                <Button
                  size='lg'
                  hasUpgrade
                  id="onsave-handler"
                  onClick={this.onSaveHandler}
                  utils={{
                    fullWidth: true
                  }}>
                  {getTranslatedText(TRANSLATION_CONSTANTS.SAVE)}
                </Button>
              }>
              <div className="c-location-details__modal">
                <FormField
                  type="textarea"
                  regex={/\n|\r/g}
                  maxLength={500}
                  showCharCount
                  placeholder={getTranslatedText(TRANSLATION_CONSTANTS.EXAMPLE_STREET)}
                  aria-label={getTranslatedText(TRANSLATION_CONSTANTS.EXAMPLE_STREET)}
                  value={locationDetailsText}
                  id="LocationDetailsInput"
                />
              </div>
            </ModalDialog>
          </div>
          : null }
      </div>
    )
  }

  render() {
    const {
      style,
      streetId,
    } = this.props;

    const {
      mouseDown,
      locationDetailsText,
    } = this.state;

    const mapId = 'map_id_1';

    return (
        <div>
          {this.state.isDetectingLocation && <Spinner/>}
          {this.createTemplate(style, mapId, streetId, mouseDown, locationDetailsText)}
        </div>
        )
    }
}

function mapStateToProps(state) {
  return {
    serviceRequestPayload: state.serviceRequestPayload,
    currentLocation: state.currentLocation,
    partnerDetails : state.partnerDetails.partnerDetails,
  };
}


export default connect(mapStateToProps,
  {
    updateAdditionalComments,
    updateDisablementAddress,
    updateCurrentLocation,
    clearLocationError
  })(DisablementMap);
