import {hasSpecialChars, isOnlyAlpha} from "../../app-consts/appConstants";

export default class FormValidators {
  static required(value = '') {
    const trimmedValue = value.trim();

    return trimmedValue.trim() !== '';
  }

  static minLength(minlength, value = '') {
    const trimmedValue = value.trim();

    return trimmedValue.length >= minlength;
  }

  static maxLength(maxlength, value = '') {
    const trimmedValue = value.trim();

    return trimmedValue.length <= maxlength;
  }

  static getValidators(settings = {}) {
    const validators = [];
    const {
      maxLength,
      minLength,
    } = settings;

    if (settings.required) {
      validators.push(FormValidators.required);
    }

    if (settings.minLength) {
      validators.push(FormValidators.minLength.bind(null, minLength));
    }

    if (settings.maxLength) {
      validators.push(FormValidators.maxLength.bind(null, maxLength));
    }

    return validators;
  }

  static allowOnlyNumbersInput = (event) => {
    if (![8, 9, 13, 37, 39, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57].includes(event.keyCode)) {
      event.preventDefault()
      return false;
    }
    return true;
  }

  static allowOnlyAlphaNumericInput = (event) => {
    if (hasSpecialChars.test(event.key)) {
      event.preventDefault()
      return false;
    }
    return true;
  }

  static phoneNumberValidator = (phoneNumber) => {
    const regex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;

    let isValid = true;
    let inputOnlyNumber = phoneNumber
      .replace(/\s/g, "")
      .replace("+1", "")
      .replace("(", "")
      .replace("(", "")
      .replace(")", "")
      .replace(/-/g, "");

    if (!regex.test(inputOnlyNumber) || inputOnlyNumber.length !== 10) {
      isValid = false;
    }

    return isValid;
  }

  static allowOnlyAlphaChars = (event) => {
    if (!isOnlyAlpha.test(event.key)) {
      event.preventDefault()
    }
  }
}
