import React, { Component } from 'react';

class ThickArrow extends Component {

    render() {
        return (

            <svg width='24' height='15' id="thickArrow" viewBox='0 0 24 15' xmlns='http://www.w3.org/2000/svg'>
                <g id='Page-1' fill='none' fillRule='evenodd'>
                    <g id='keyboard-right-arrow-button' transform='rotate(-90 7.451 7.451)'
                        fill='#FFF' fillRule='nonzero'>
                        <polygon id='Shape' points='2.85098039 0 0.0509803922 2.8 9.25098039 12 0.0509803922 21.2 2.85098039 24 14.8509804 12'
                        />
                    </g>
                </g>
            </svg>

        )
    }
}

export default ThickArrow; 
