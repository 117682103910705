import {REFRESH_REDUCERS} from './../action';
import {UPDATE_VEHICLE_INFO} from '../action/vehicleDetails-action';
const initialState = {
  yearValue: '',
  vehicleValue: '',
  vehicleColor: '',
  fuelType : '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_VEHICLE_INFO:
      return Object.assign({}, state, action.payload);
    case REFRESH_REDUCERS:
            return {...initialState};
    default:
      return state;
  }
}

