import {takeEvery, put, call, select} from 'redux-saga/effects';
import {datadogRum} from "@datadog/browser-rum";
import {
  PROVIDER_INFO_REQUESTED,
  PROVIDER_INFO_SUCCESS,
  PROVIDER_INFO_FAILED
} from '../action';

import {getProviderInfoApi} from '../api';

import * as selectors from "../selector";

export function* providerInfo(action) {
  try {
    const partnerDetails = yield select(selectors.getPartnerDetails);
    const {appId, partnerCode} = partnerDetails;
    const response = yield call(getProviderInfoApi, action.payload, appId, partnerCode);
    const payload = yield response.data;
    yield put({type: PROVIDER_INFO_SUCCESS, payload: payload});
  } catch (e) {
    datadogRum.addError(new Error(`Could not get provider info ${e}`))
    yield put({type: PROVIDER_INFO_FAILED, 'payload': e});
  }
}

export function* watchProviderInfoSaga() {
  yield takeEvery(PROVIDER_INFO_REQUESTED, providerInfo);
}
