import {useRef, useEffect, useState} from "react";

export default function useInterval(callback, delay, displayCountdown) {
  const savedCallback = useRef();
  const [timeLeft, setTimeLeft] = useState(30)

  useEffect(() => {
    if (!timeLeft || !displayCountdown) return
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft-1)

    }, 1000);
    return () => clearInterval(intervalId)
  }, [timeLeft, displayCountdown]);

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      setTimeLeft(30)
      savedCallback.current();
    }

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);

  return timeLeft
}
