import * as React from 'react'
import {SUPPORTED_LANGUAGES} from "../app-consts/appConstants";
import translations from "../app-consts/translations/languages";

const determineLocaleFromPathname = (pathname) => {
  const pathVariables = pathname.split("/")
  const languagePathVariable = pathVariables[pathVariables.length - 1]
  return SUPPORTED_LANGUAGES.includes(languagePathVariable) ? languagePathVariable : false;
}

const determineLocaleFromQueryParam = (search) => {
  const searchVariables = new URLSearchParams(search);
  return searchVariables.get('language') !== null ? searchVariables.get('language') : false;
}

const determineIfTheLanguageIsEnglish = (pathname, search) => {
  const localePathParam = determineLocaleFromPathname(pathname);
  const localeQueryParam = determineLocaleFromQueryParam(search);
  if(localePathParam) return localePathParam === 'en_US';
  else if (localeQueryParam) return localeQueryParam === 'en_US';
  return true;
}

const determineLocale = () => {
  const localePathParam = determineLocaleFromPathname(window.location.pathname);
  const localeQueryParam = determineLocaleFromQueryParam(window.location.search);
  if(localePathParam) return localePathParam;
  else if (localeQueryParam) return localeQueryParam;
  return 'en_US';
}

const getTranslatedTextByLocale = (locale, key, displayCode) => {
  locale = locale || "en_US";
  if(!displayCode || !translations[locale][displayCode]) {
    return translations[locale].global[key] ? translations[locale].global[key] : key;
  }
  return translations[locale][displayCode][key] ? translations[locale][displayCode][key] : key;
};

export const LocaleContext = React.createContext()

const LocaleProvider = ({ children }) => {
  const locale = determineLocale();
  const getTranslatedText = (key, displayCode) => getTranslatedTextByLocale(locale, key, displayCode);
  return (
    <LocaleContext.Provider value={getTranslatedText}>
      {children}
    </LocaleContext.Provider>
  );
};

const useLocale = () => {
  const context = React.useContext(LocaleContext)
  if (context === undefined) {
    throw new Error('useLocale must be used within a LocaleProvider')
  }
  return context
}

const LocaleConsumer = ({children}) => {
  return (
    <LocaleContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('LocaleConsumer must be used within a LocaleProvider')
        }
        return children(context)
      }}
    </LocaleContext.Consumer>
  )
}


export {
  LocaleProvider,
  LocaleConsumer,
  useLocale,
  determineLocaleFromPathname,
  determineIfTheLanguageIsEnglish,
  determineLocale,
  getTranslatedTextByLocale,
};
