import React, { Component } from 'react';
import Amex from '../svg/PaymentCards/Amex.js';
import Discover from '../svg/PaymentCards/Discover.js';
import Mastercard from '../svg/PaymentCards/Mastercard.js';
import Visa from '../svg/PaymentCards/Visa.js';

class CreditCardIcons extends Component {

  render() {
    const creditCard = this.props.creditCard;

    if (creditCard === 'AX') {
        return (
            <Amex />
        )
    } else if (creditCard === 'DI') {
        return (
            <Discover />
        )
    } else if (creditCard === 'MC') {
        return (
            <Mastercard />
        )
    } else {
        return (
            <Visa />
        )
    }

  }

}

export default CreditCardIcons;
