import { all } from "redux-saga/effects";
import {
  watchAddGooglePlaceIdToDestination,
  watchCommonBenefitsSaga,
  watchBenefitLookupSaga,
  watchEpcsInitializeSaga,
  watchEpcsStatusSaga,
  watchGetMakeModelSaga,
  watchNearBySearch,
  watchPartnerDetailsSaga,
  watchPepSessionSaga,
  watchProviderInfoSaga,
  watchserviceRequestInfoSaga,
  watchSubmitRequestSaga,
  watchUpdateDestinationWithPredictionAddress,
  watchAddMoreNearByDestinations
} from "./saga";

const root = function* rootSaga() {
  yield all([
    watchNearBySearch(),
    watchUpdateDestinationWithPredictionAddress(),
    watchAddGooglePlaceIdToDestination(),
    watchGetMakeModelSaga(),
    watchSubmitRequestSaga(),
    watchserviceRequestInfoSaga(),
    watchProviderInfoSaga(),
    watchPartnerDetailsSaga(),
    watchEpcsInitializeSaga(),
    watchEpcsStatusSaga(),
    watchPepSessionSaga(),
    watchCommonBenefitsSaga(),
    watchBenefitLookupSaga(),
    watchAddMoreNearByDestinations(),
  ]);
};

export function initSagas(sagaMiddleWare) {
  // values(sagas).forEach(sagaMiddleWare.run.bind(sagaMiddleWare));
  sagaMiddleWare.run(root);
}
