export const StateList = [
  { text: 'ALABAMA', value: 'AL'},
  { text: 'ALASKA', value: 'AK'},
  { text: 'ARIZONA', value: 'AZ'},
  { text: 'ARKANSAS', value: 'AR'},
  { text: 'CALIFORNIA', value: 'CA'},
  { text: 'COLORADO', value: 'CO'},
  { text: 'CONNECTICUT', value: 'CT'},
  { text: 'DELAWARE', value: 'DE'},
  { text: 'DISTRICT OF COLUMBIA', value: 'DC'},
  { text: 'FLORIDA', value: 'FL'},
  { text: 'GEORGIA', value: 'GA'},
  { text: 'HAWAII', value: 'HI'},
  { text: 'IDAHO', value: 'ID'},
  { text: 'ILLINOIS', value: 'IL'},
  { text: 'INDIANA', value: 'IN'},
  { text: 'IOWA', value: 'IA'},
  { text: 'KANSAS', value: 'KS'},
  { text: 'KENTUCKY', value: 'KY'},
  { text: 'LOUISIANA', value: 'LA'},
  { text: 'MAINE', value: 'ME'},
  { text: 'MARYLAND', value: 'MD'},
  { text: 'MASSACHUSETTS', value: 'MA'},
  { text: 'MICHIGAN', value: 'MI'},
  { text: 'MINNESOTA', value: 'MN'},
  { text: 'MISSISSIPPI', value: 'MS'},
  { text: 'MISSOURI', value: 'MO'},
  { text: 'MONTANA', value: 'MT'},
  { text: 'NEBRASKA', value: 'NE'},
  { text: 'NEVADA', value: 'NV'},
  { text: 'NEW HAMPSHIRE', value: 'NH'},
  { text: 'NEW JERSEY', value: 'NJ'},
  { text: 'NEW MEXICO', value: 'NM'},
  { text: 'NEW YORK', value: 'NY'},
  { text: 'NORTH CAROLINA', value: 'NC'},
  { text: 'NORTH DAKOTA', value: 'ND'},
  { text: 'OHIO', value: 'OH'},
  { text: 'OKLAHOMA', value: 'OK'},
  { text: 'OREGON', value: 'OR'},
  { text: 'PENNSYLVANIA', value: 'PA'},
  { text: 'PUERTO RICO', value: 'PR'},
  { text: 'RHODE ISLAND', value: 'RI'},
  { text: 'SOUTH CAROLINA', value: 'SC'},
  { text: 'SOUTH DAKOTA', value: 'SD'},
  { text: 'TENNESSEE', value: 'TN'},
  { text: 'TEXAS', value: 'TX'},
  { text: 'UTAH', value: 'UT'},
  { text: 'VERMONT', value: 'VT'},
  { text: 'VIRGINIA', value: 'VA'},
  { text: 'WASHINGTON', value: 'WA'},
  { text: 'WEST VIRGINIA', value: 'WV'},
  { text: 'WISCONSIN', value: 'WI'},
  { text: 'WYOMING', value: 'WY' }
]

export const STATE_CODES = {
  'ALABAMA': 'AL',
  'ALASKA': 'AK',
  'ARIZONA': 'AZ',
  'ARKANSAS': 'AR',
  'CALIFORNIA': 'CA',
  'COLORADO': 'CO',
  'CONNECTICUT': 'CT',
  'DELAWARE': 'DE',
  'DISTRICT OF COLUMBIA': 'DC',
  'FLORIDA': 'FL',
  'GEORGIA': 'GA',
  'HAWAII': 'HI',
  'IDAHO': 'ID',
  'ILLINOIS': 'IL',
  'INDIANA': 'IN',
  'IOWA': 'IA',
  'KANSAS': 'KS',
  'KENTUCKY': 'KY',
  'LOUISIANA': 'LA',
  'MAINE': 'ME',
  'MARYLAND': 'MD',
  'MASSACHUSETTS': 'MA',
  'MICHIGAN': 'MI',
  'MINNESOTA': 'MN',
  'MISSISSIPPI': 'MS',
  'MISSOURI': 'MO',
  'MONTANA': 'MT',
  'NEBRASKA': 'NE',
  'NEVADA': 'NV',
  'NEW HAMPSHIRE': 'NH',
  'NEW JERSEY': 'NJ',
  'NEW MEXICO': 'NM',
  'NEW YORK': 'NY',
  'NORTH CAROLINA': 'NC',
  'NORTH DAKOTA': 'ND',
  'OHIO': 'OH',
  'OKLAHOMA': 'OK',
  'OREGON': 'OR',
  'PENNSYLVANIA': 'PA',
  'PUERTO RICO': 'PR',
  'RHODE ISLAND': 'RI',
  'SOUTH CAROLINA': 'SC',
  'SOUTH DAKOTA': 'SD',
  'TENNESSEE': 'TN',
  'TEXAS': 'TX',
  'UTAH': 'UT',
  'VERMONT': 'VT',
  'VIRGINIA': 'VA',
  'WASHINGTON': 'WA',
  'WEST VIRGINIA': 'WV',
  'WISCONSIN': 'WI',
  'WYOMING': 'WY'
}
