import {has} from "lodash";
import {TOW_SERVICE_ID} from '../app-consts/appConstants'
import { sanitizeVehiclePayload } from '../reducers/reducer-service-request-payload';

export const cleanServiceRequestPayload = (serviceType, serviceRequest) => {
  if (serviceType !== TOW_SERVICE_ID && serviceRequest.destinationAddress){
    delete serviceRequest.destinationAddress
  }

  if(has(serviceRequest, 'vehicles')) {
    let sanitizedVehicle = sanitizeVehiclePayload(serviceRequest.vehicles);
    serviceRequest = {
      ...serviceRequest,
      vehicles: {
        ...sanitizedVehicle
      }
    }
  }
}

export const isScheduleService = (serviceRequestPayload) => {
  const requestScheduleDateTimeIndex = serviceRequestPayload.serviceRequest?.additionalFields?.[0]?.findIndex(
    ({ key }) => key === "requestscheduledatetime"
  )
  return requestScheduleDateTimeIndex > -1;
}
