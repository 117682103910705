import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import {
  Button,
  Dropdown,
  Flex,
  FormField,
  Grid,
  SelectablePanel,
  Type,
  Utility,
} from "mesh-component-library";
import { useLocale } from "../../contexts/LocaleContext";
import { isOnlyNumbers } from "../../app-consts/appConstants";
import {
  SEARCH_BY_VIN_BUTTON_CLICKED,
  USER_CONFIRMED_VEHICLE_FOR_BENEFITS_LOOKUP,
} from "../../app-consts/dataDogConsts";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { updateVehicleInfo } from "../../action";
import FormValidators from "../../utilities/form-validators";
import { getTranslatedColors } from "../../utils/translatedTextUtils";

const VehicleConfirmationPage = (props) => {
  const dispatch = useDispatch();

  const [vehicles, setVehicles] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [mileage, setMileage] = useState("");
  const [mileageHasError, setMileageHasError] = useState(false);
  const [selectedColor, setColorSelected] = useState("");
  const isLicensePlateOrVinLookup = props.alternateLookup === "licensePlateOrVinLookup";
  const isMileageNeeded = !isLicensePlateOrVinLookup && props.alternateLookup !== "vinOnlyLookup";
  const isArsTokenPresent = !isLicensePlateOrVinLookup && props.alternateLookup === "vinOdoTokenLookupForm";
  const getTranslatedText = useLocale();

  useEffect(() => {
    let storedVehicles = JSON.parse(
      localStorage.getItem("licensePlateLookupResults")
    );
    if (storedVehicles) {
      setDisplayVinForSimilarCars(storedVehicles);
      setVehicles(storedVehicles);
    }
  }, []);

  const setDisplayVinForSimilarCars = (vehicles) => {
    vehicles.forEach((vehicle, i) => {
      for (let j = 0; j < vehicles.length; j++) {
        //every vehicle needs to be compared to the rest of the array
        if (i !== j) {
          //ignore comparing itself with itself
          let makeIsSame = vehicle.makeCode === vehicles[j].makeCode;
          let modelsSame = vehicle.modelDesc === vehicles[j].modelDesc;
          let yearIsSame = vehicle.modelYear === vehicles[j].modelYear;
          if (makeIsSame && modelsSame && yearIsSame) {
            vehicle.displayVin = true;
            vehicles[j].displayVin = true;
          }
        }
      }
    });
  };

  const mileageOnKeyPress = (event) => {
    FormValidators.allowOnlyNumbersInput(event);
  };

  const mileageInputOnChange = ({ target: { value } }) => {
    setContinueButtonDisabled(
      !selectedVehicle || !selectedColor || !value.length
    );
    setMileageHasError(!value.length);
    setMileage(value);
  };

  const mileageValidator = (event) => {
    const inputValue = event.target.value.trim();
    const isValid = inputValue.length && isOnlyNumbers.test(inputValue);
    setMileageHasError(!isValid);
    setContinueButtonDisabled(!isValid);
  };

  const vehicleOptionSelected = (event, vehicle) => {
    setSelectedVehicle(vehicle);
    setContinueButtonDisabled(!selectedColor || (isMileageNeeded && !mileage));
  };

  const vehicleColorSelected = ({ target: { value } }) => {
    if (value === getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)) {
      setColorSelected("");
      setContinueButtonDisabled(true);
      return;
    }
    setColorSelected(value);
    setContinueButtonDisabled(
      !selectedVehicle || (isMileageNeeded && !mileage) || !value
    );
  };

  const searchByVinInsteadSelected = () => {
    datadogRum.addAction(SEARCH_BY_VIN_BUTTON_CLICKED);
    if(isLicensePlateOrVinLookup) {
      props.setCurrentLookup("vinOnlyLookup");
    } else {
      props.setCurrentLookup(props.alternateLookup);
    }
  };

  const confirmVehicleSelection = () => {
    const lookupTypeWithMileage = isArsTokenPresent
      ? "vinOdoTokenLookupForm"
      : "licensePlate";
    const lookupType = isMileageNeeded ? lookupTypeWithMileage : "vinOnly";
    const addMileage = isMileageNeeded ? mileage : "";
    const addArsToken = isArsTokenPresent
      ? localStorage.getItem("ars_token")
      : "";

    const user = {
      phoneLookup: false,
      appId: props.appId,
      arsToken: addArsToken,
      formdata: {
        vin: selectedVehicle.vin,
        mileage: addMileage,
      },
      lookupType,
    };

    datadogRum.addAction(USER_CONFIRMED_VEHICLE_FOR_BENEFITS_LOOKUP);

    setContinueButtonDisabled(true);
    props.setHasUsedAlternate(true);
    props.dispatchCommonBenefitsLookup(user);
    dispatch(updateVehicleInfo({ vehicleColor: selectedColor }));
  };

  return (
    <>
      <Utility.Div id="vehicle-confirmation-page">
        <Grid
          utils={{
            vrTop: "sm",
          }}
        >
          <Grid.Column>
            <Type
              element="h1"
              weight="semibold"
              align="left"
              size="l"
              lineHeight="md"
              utils={{
                vrTop: "md",
              }}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_CONFIRM_VEHICLE)}
            </Type>
          </Grid.Column>
        </Grid>

        <Grid
          utils={{
            vrBottom: "md",
          }}
        >
          <Grid.Column>
            <Type
              element="h1"
              weight="semibold"
              align="left"
              size="l"
              lineHeight="md"
            >
              {`${getTranslatedText(TRANSLATION_CONSTANTS.FOUND)} ${
                vehicles.length
              } ${getTranslatedText(TRANSLATION_CONSTANTS.VEHICLES)}`}
            </Type>
          </Grid.Column>
        </Grid>
        <Grid
          utils={{
            vrBottom: "md",
          }}
        >
          {vehicles.map((vehicle, index) => {
            vehicle.modelYear = vehicle.modelYear || "";
            vehicle.makeCode = vehicle.makeCode || "";
            vehicle.modelDesc = vehicle.modelDesc || "";
            let yearMakeModel = `${vehicle.modelYear} ${vehicle.makeCode} ${vehicle.modelDesc}`;
            if (vehicle.displayVin) {
              yearMakeModel += ` - Vin last 8: ${vehicle["vin"].slice(-8)}`;
            }
            return (
              <SelectablePanel
                key={index}
                id={vehicle.vin}
                checked={selectedVehicle?.vin === vehicle?.vin}
                hasBorder
                showInput
                text={yearMakeModel}
                type="radio"
                hasError={!selectedVehicle}
                errorText={getTranslatedText(
                  TRANSLATION_CONSTANTS.PLEASE_CONFIRM_VEHICLE
                )}
                onChange={(event) => {
                  vehicleOptionSelected(event, vehicle);
                }}
              />
            );
          })}
        </Grid>
        {selectedVehicle && (
          <>
            <Grid
              utils={{
                vrBottom: "md",
              }}
            >
              <Grid.Column>
                <Dropdown
                  id="color"
                  name="color"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.COLOR)}
                  value={selectedColor.toUpperCase()}
                  defaultOption={{
                    text: "",
                    value: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE),
                  }}
                  options={getTranslatedColors(getTranslatedText)}
                  onChange={vehicleColorSelected}
                  required
                  sort="asc"
                />
              </Grid.Column>
            </Grid>
            {isMileageNeeded && (
              <Grid
                utils={{
                  vrBottom: "md",
                }}
              >
                <Grid.Column>
                  <FormField
                    size="md"
                    id="estimated-mileage"
                    name="estimated-mileage"
                    label={
                      <Flex justifyContent="between" isBlock={true}>
                        <Type size="l" weight="bold" emphasis="information">
                          {getTranslatedText(
                            TRANSLATION_CONSTANTS.ESTIMATED_MILEAGE
                          )}
                        </Type>
                      </Flex>
                    }
                    value={mileage}
                    utils={{
                      vrTop: "xs",
                      vrBottom: "xs",
                    }}
                    hasError={mileageHasError}
                    errorText={getTranslatedText(
                      TRANSLATION_CONSTANTS.PLEASE_ENTER_VALID_ESTIMATED_MILEAGE
                    )}
                    maxLength={7}
                    onBlur={mileageValidator}
                    onChange={mileageInputOnChange}
                    onKeyDown={mileageOnKeyPress}
                    required
                  />
                </Grid.Column>
              </Grid>
            )}
          </>
        )}
        <Grid
          utils={{
            vrBottom: "md",
          }}
        >
          <Grid.Column>
            <Button
              size="lg"
              hasUpgrade
              id="vehicle-selection-continue"
              disabled={continueButtonDisabled}
              onClick={confirmVehicleSelection}
              type="submit"
              utils={{
                fullWidth: true,
              }}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
            </Button>
          </Grid.Column>
        </Grid>
        <Grid
          utils={{
            vrBottom: "md",
          }}
        >
          <Grid.Column>
            <Type element="span" size="4xl" variant="darker" weight="normal">
              - {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -
            </Type>
          </Grid.Column>
        </Grid>
        <Grid
          utils={{
            vrBottom: "md",
          }}
        >
          <Grid.Column>
            <Type
              element="span"
              size="l"
              variant="information"
              weight="semibold"
              onClick={searchByVinInsteadSelected}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.SEARCH_BY_VIN)}
            </Type>
          </Grid.Column>
        </Grid>
      </Utility.Div>
    </>
  );
};

export default VehicleConfirmationPage;
