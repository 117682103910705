import {takeEvery, put, call,select } from 'redux-saga/effects';
import {REQUESTED_EPCS_INITIALIZE,
    REQUESTED_EPCS_INITIALIZE_SUCCESS,
    REQUESTED_EPCS_INITIALIZE_FAILED} from './../action';
import {datadogRum} from "@datadog/browser-rum";
import {initialize} from './../api';
import {getPartnerDetails} from '../selector';


export function* epcsInitializeSaga(){
    try{
        const partnerDetails = yield select(getPartnerDetails);
        let { partnerCode, promoCode } = partnerDetails;
        const response = yield call(initialize,partnerCode, promoCode);
        const payload =  yield response.data;
        yield put({ type: REQUESTED_EPCS_INITIALIZE_SUCCESS, payload : payload });
    }catch(e){
      datadogRum.addError(new Error(`Could not initialize EPCS ::: ${e}`));
      yield put({ type: REQUESTED_EPCS_INITIALIZE_FAILED });
    }
}

export function* watchEpcsInitializeSaga(){
    yield takeEvery(REQUESTED_EPCS_INITIALIZE, epcsInitializeSaga);
}
