import { css } from '@emotion/react';
import styled from '@emotion/styled';

const ccOfferStyles = css`
  text-align: left;
  padding: 1rem 1rem 1rem 1rem;
  margin: 0.3571428571rem auto 1.4285714286rem auto;
  max-width: 640px;
  background-color: #fff;
  border-radius: 0.625em;
  border: 1px solid #E1E6EB;
  width: 95%;

  .cc-o {
  color:  #0033A0;
  }

  .cc-o-box {
  padding-right: inherit;
  }

`;

const StyledCCOffer = styled('div')(ccOfferStyles);

export default StyledCCOffer;
