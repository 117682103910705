import {REFRESH_REDUCERS} from './../action';
import {UPDATE_DRIVER_LIST} from '../action/driverList-action';
const initialState = {
  driverList: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_DRIVER_LIST:
      return {...state, driverList: [...action.payload]};
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}
