import React, {Component} from 'react';

class AlertModal extends Component {
	static defaultProps = {
		title: '',
		content: '',
		primaryButton: '',
		primaryButtonCallback: '',
		secondaryButton: '',
		secondaryButtonCallback: '',
		customClass: '',
    showDiv:false
	}
  render() {
    const {
    			title,
    			content,
    			primaryButton,
    			primaryButtonCallback,
    			secondaryButton,
    			secondaryButtonCallback,
    			customClass,
          showDiv
    		} = this.props;
       return (
       <div className={`${customClass} c-alertBox__overlay`}>
         <div className="c-alertBox">
           <h2>{title}</h2>
           { showDiv ? <div>{content}</div> : <p>{content}</p> }
           <div className="l-grid">
             {
               primaryButton && secondaryButton &&
               <div className="l-grid__col l-grid__col--6 decline">
                  <span role="button" tabIndex="0" onKeyDown={secondaryButtonCallback} onClick={secondaryButtonCallback}>{secondaryButton}</span>
               </div>
             }
             <div className={`${primaryButton && secondaryButton ? `l-grid__col--6` : `l-grid__col--12`} l-grid__col accept`}>
                <span data-testid="alert-modal-continue" role="button" tabIndex="0" onKeyDown={primaryButtonCallback} onClick={primaryButtonCallback}>{primaryButton}</span>
             </div>
           </div>
         </div>
       </div>
       )
 }
}
export default AlertModal;
