import React, {useState} from "react";
import JumpStart from '../../components/svg/JumpStart';
import FormOption from '../form-option';
import Form from "../form";
import {sendJacadaRequest} from '../../api';
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import { Button } from 'mesh-component-library';
const JumpStartTriage = ({updateSoftServiceDetails}) => {
  const [btnDisabled, setButtonDisabled] = useState(true)
  const [option, setOption] = useState("")
  const getTranslatedText = useLocale();

  const handleButtonClick = () => {
    let towRequired = option === "yes";
    if (!towRequired) {
      sendJacadaRequest({
        'externalSystemId': localStorage.getItem('conversationId') || "not available",
        'events': [{'name': 'event_id', 'value': 64}]
      });
    }
    updateSoftServiceDetails(towRequired, 'Jump Start', {towReason: 'battery'});
  }

  return (
    <>
      <div className="l-container-triage">
        <div className="c-triage">
          <div className="c-triage--jumpstart">
            <JumpStart/>
          </div>
          <div className="c-triage--content">
            <h1 id="did-your-vehicle-top-while-driving">{getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE_STOP_DRIVING)}</h1>
            <div className="c-triage--content-form">
              <Form onChange={(event) => {
                setButtonDisabled(false)
                setOption(event.target.value)
              }}>

                <FormOption
                  type="radio"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.YES)}
                  id="did-your-vehicle-top-while-driving-yes"
                  errorText="Please select an option"
                  value="yes"
                  name="options"/>

                <FormOption
                  type="radio"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.NO)}
                  id="did-your-vehicle-top-while-driving-no"
                  errorText="Please select an option"
                  value="no"
                  name="options"/>
                  <br/>
                <Button
                  size='lg'
                  hasUpgrade
                  id="did-your-vehicle-top-while-driving-continue"
                  disabled={btnDisabled}
                  onClick={handleButtonClick}
                  utils={{
                    fullWidth: true
                  }}>
                  {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JumpStartTriage;
