import React from 'react';
import Icon from '../icon';
import { OkayC, RemovecloseC } from '../../icon-library';

class FeedbackConfirmation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {entryPage: 'Confirmation'};
    }

render() {
    return (
    this.state.entryPage === 'Confirmation' ?
    <div className="l-grid__col" >
    <div className="l-container-home__center">
    <div className="l-container-home__service">
    <Icon icon={OkayC} className="l-container-home__service--icon" size={1.5} color={'green-light'}/>
    <h5 className="l-container-home__service--title">We appreciate your feedback. Thank you!</h5>
    </div>
    </div>
    </div>

    :

    <div className="l-grid__col" >
    <div className="l-container-home__center">
    <div className="l-container-home__error">
    <Icon icon={RemovecloseC} className="l-container-home__error--icon" size={1.5} fill={'#cf102d'}/>
    <h5 className="l-container-home__error--title">Your rescue could not be canceled</h5>
    </div>
    </div>
    </div>
    )}
}
export default FeedbackConfirmation;

