// Partially sort an array based on input condition 
export const partialSortArrayByCondition = ({ array, condition, count = 2 }) => {
    const foundIndexes = []
    const results = []

    array.forEach((el, index) => {
        if (foundIndexes.length < count && condition(el)) {
            foundIndexes.push(index)
            results.push(array.splice(index, 1)[0])
        }
    })

    return [...results, ...array]
}