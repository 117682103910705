export const REQUESTED_MAKE_MODEL = "REQUESTED_MAKE_MODEL";
export const REQUESTED_MAKE_MODEL_SUCCESS = "REQUESTED_MAKE_MODEL_SUCCESS";
export const REQUESTED_MAKE_MODEL_FAILED = "REQUESTED_MAKE_MODEL_FAILED";
export const RESET_MAKE_MODEL = "RESET_MAKE_MODEL";

export function requestMakeModel(data){
    return { type: REQUESTED_MAKE_MODEL, request:data };
}

export function requestMakeModelSuccess(data){
    return { type: REQUESTED_MAKE_MODEL_SUCCESS, payload: data};
}

export function requestMakeModelFailed(data){
    return { type: REQUESTED_MAKE_MODEL_FAILED, payload: data};
}

export function resetMakeModel(){
    return {type: RESET_MAKE_MODEL};
}