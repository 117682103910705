import {applyMiddleware, compose, createStore} from 'redux';
import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore, createMigrate} from 'redux-persist';

import createSagaMiddleWare from 'redux-saga';

import baseReducer from './reducers';

import {initSagas} from './initSaga';
import { migrations } from './persist-migration/migration';

let store;

export function getStore(preloadedState = {isDefaultState: true}) {
  if (preloadedState.isDefaultState && store) {
    return { store };
  }
  const sagaMiddleWare = createSagaMiddleWare();
  const middleWares = [sagaMiddleWare];
  const composables = [applyMiddleware(...middleWares)];
  const composeEnhancers =
    process.env.REACT_APP_LOCAL &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
          // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
        })
      : compose;
  const enhancer = composeEnhancers(...composables);

  const persistConfig = {
    version: 2,
    key: 'root',
    storage,
    whitelist: [
      'nearByDestinations',
      'destination',
      'currentLocation',
      'serviceRequestPayload',
      'providerInfo',
      'partnerDetails',
      'epcsStatus',
      'commonBenefits',
      'serviceInfo',
      'serviceDetails',
      'submitRequest',
      'serviceRequestInfo',
      'serviceTransition',
      'generalInfo',
      'driverList'
    ],
    migrate: createMigrate(migrations, { debug: process.env.REACT_APP_LOCAL ? true : false })
  };

  const persistedReducer = persistReducer(persistConfig, baseReducer);
  if (!preloadedState.isDefaultState) {
    store = createStore(persistedReducer, preloadedState, enhancer);
  } else {
    store = createStore(persistedReducer, enhancer);
  }

  let persistor = persistStore(store);

  initSagas(sagaMiddleWare);
  return {store, persistor};
}
