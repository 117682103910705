import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { datadogRum } from "@datadog/browser-rum";
import {
  Grid,
  Type,
  FormField,
  Flex,
  Button,
  Autocomplete,
  Loader,
} from "mesh-component-library";
import _uniqueId from "lodash/uniqueId";
import _isEmpty from "lodash/isEmpty";
import _isNil from "lodash/isNil";
import {
  FIND_VEHICLE_BY_LICENSE_PLATE_FORM_SUBMITTED,
  LICENSE_PLATE_INPUT_CONTINUE_BUTTON_CLICKED,
  SEARCH_BY_VIN_BUTTON_CLICKED,
} from "../../app-consts/dataDogConsts";
import { useLocale } from "../../contexts/LocaleContext";
import { LICENSE_PLATE_LOOKUP } from "../../jacada/selectors";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import VehicleConfirmationPage from "../../screens/VehicleConfirmationPage/VehicleConfirmationPage";
import { noCommonBenefits } from "../../action";
import FormValidators from "../../utilities/form-validators";
import { StateList } from "../../utilities/StateList";
import { searchVehicleByLicensePlate } from "../../api/api";
import { PrimaryLookupFailure } from "../warning-alerts";

const SearchByLicensePlatePage = ({
  partnerDetails,
  closeModal,
  dispatchCommonBenefitsLookup,
  showModal,
  setCurrentLookup,
  setHasUsedAlternate,
  setUseAlternateLookup,
}) => {
  const dispatch = useDispatch();
  const {
    appId,
    onlyOemVehiclesSupported,
    oemVehicleMakesList,
    route,
    experience: {
      lookupMethods: {
        components: { primary, alternate },
      },
    },
  } = partnerDetails;
  const [selectedState, setSelectedState] = useState("");
  const [stateHasError, setStateHasError] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [plateNumber, setPlateNumber] = useState("");
  const [plateNumberHasError, setPlateNumberHasError] = useState(false);
  const [isSpinnerActive, setIsSpinnerActive] = useState(false);
  const [vehiclesFound, setVehiclesFound] = useState(false);
  const getTranslatedText = useLocale();
  const [id] = useState(() => _uniqueId());
  const isLicensePlateOrVinLookup = primary === "licensePlateOrVinLookup" || alternate === "licensePlateOrVinLookup";
  const noBenefits = useSelector(state => state.commonBenefits.noBenefits);

  useEffect(() => setUpPage(), [appId]);
  const setUpPage = () => {
    if (
      !!localStorage.getItem("vin") &&
      !!localStorage.getItem("ars_token") &&
      !localStorage.getItem("odo")
    ) {
      dispatch(noCommonBenefits({}));
      if (isLicensePlateOrVinLookup) {
        setCurrentLookup("vinOnlyLookup");
      } else {
        setUseAlternateLookup(true);
      }
    }
    localStorage.removeItem("licensePlateLookupResults");
    document.title = "Lookup | Roadside Assistance";
    localStorage.removeItem("gmBenefitsDetails");
  };

  const onClickSuggestion = (event, selectedState) => {
    FormValidators.allowOnlyAlphaChars(event);
    if (selectedState?.selectedItem) {
      setSelectedState(selectedState?.selectedItem);
      setStateHasError(false);
      setContinueButtonDisabled(_isEmpty(plateNumber));
    } else {
      setSelectedState("");
      setStateHasError(true);
      setContinueButtonDisabled(true);
    }
  };

  const validatePlateNumberOnBlur = ({ target: { value } }) => {
    let plateNumberIsValid = isPlateValid(value);
    setPlateNumberHasError(!plateNumberIsValid);
    setContinueButtonDisabled(!plateNumberIsValid || !selectedState);
  };

  const isPlateValid = (value) => {
    return value && value.length >= 2 && value.length <= 9;
  };

  const onLicensePlateInputChange = ({ target: { value } }) => {
    value = value.toUpperCase();
    setPlateNumber(value);
    setPlateNumberHasError(!isPlateValid(value));
    setContinueButtonDisabled(!selectedState || !isPlateValid(value));
  };

  const onContinueClick = () => {
    window.scrollTo(0, 0);
    datadogRum.addAction(LICENSE_PLATE_INPUT_CONTINUE_BUTTON_CLICKED);
    const content = (
      <div>
        <p>
          <span>License Plate:</span> {plateNumber}
        </p>
        <p>
          <span>State:</span> {selectedState?.text || ""}
        </p>
      </div>
    );
    const options = {
      titleHtml: content,
      yesNoSelected,
    };

    showModal(options);
  };

  const yesNoSelected = async ({ target: { value } }) => {
    closeModal();
    if (value === "yes") {
      setContinueButtonDisabled(true);
      await submitLicensePlate();
    }
  };

  const submitLicensePlate = async () => {
    datadogRum.addAction(FIND_VEHICLE_BY_LICENSE_PLATE_FORM_SUBMITTED);
    setContinueButtonDisabled(true);
    setIsSpinnerActive(true);

    let stateCode = selectedState?.value || "";
    let response = await searchVehicleByLicensePlate(
      route,
      plateNumber,
      stateCode
    );
    if (
      !response ||
      _isNil(response?.data?.responseRecords) ||
      _isEmpty(response?.data?.responseRecords)
    ) {
      localStorage.setItem("licensePlateLookupResultsFailure", "true");
      setIsSpinnerActive(false);
      if (isLicensePlateOrVinLookup) {
        setCurrentLookup("vinOnlyLookup");
      } else {
        setUseAlternateLookup(true);
      }
      dispatch(noCommonBenefits({}));
    }

    let vehicleList;
    if (response?.data?.responseRecords?.[0]?.vehicleCount) {
      datadogRum.addAction(
        `Num of vehicles found (${response.data.responseRecords.vehicleCount}) for license plate: ${plateNumber} and state: ${stateCode}.`
      );
      if (onlyOemVehiclesSupported && oemVehicleMakesList) {
        vehicleList = response.data.responseRecords[0].vehicles.filter(
          (vehicle) => {
            let { modelDesc, makeCode, modelYear, vin } = vehicle;
            //TODO - This needs to be updated but currently no partners are limited to only oems
            // the make only comes back as a CODE
            return (
              !!(modelDesc && makeCode && modelYear && vin) &&
              oemVehicleMakesList.includes(make?.toUpperCase())
            );
          }
        );
      } else {
        vehicleList = response.data.responseRecords[0].vehicles.filter(
          (vehicle) => {
            let { modelDesc, makeCode, modelYear, vin } = vehicle;
            return !!(modelDesc && makeCode && modelYear && vin);
          }
        );
      }
    }
    if (vehicleList?.length) {
      datadogRum.addAction("Vehicles found for license plate", {
        plateNumber,
        stateCode,
      });
      localStorage.setItem(
        "licensePlateLookupResults",
        JSON.stringify(vehicleList)
      );
      setIsSpinnerActive(false);
      setVehiclesFound(true);
    } else {
      localStorage.setItem("licensePlateLookupResultsFailure", "true");
      setIsSpinnerActive(false);
      if (isLicensePlateOrVinLookup) {
        setCurrentLookup("vinOnlyLookup");
      } else {
        setUseAlternateLookup(true);
      }
      dispatch(noCommonBenefits({}));
    }
  };

  const searchByVinInsteadSelected = () => {
    datadogRum.addAction(SEARCH_BY_VIN_BUTTON_CLICKED);
    if (isLicensePlateOrVinLookup) {
      setCurrentLookup("vinOnlyLookup");
    } else {
      setHasUsedAlternate(true);
      setCurrentLookup(alternate);
    }
  };

  return (
    <>
      {isSpinnerActive ? <Loader /> : null}
      {vehiclesFound && (
        <VehicleConfirmationPage
          dispatchCommonBenefitsLookup={dispatchCommonBenefitsLookup}
          setHasUsedAlternate={setHasUsedAlternate}
          appId={appId}
          alternateLookup={alternate}
          setCurrentLookup={setCurrentLookup}
        />
      )}
      {!vehiclesFound && (
        <React.Fragment>
          { noBenefits && alternate === "licensePlateOrVinLookup" &&
            <Grid>
              <Grid.Column>
                <PrimaryLookupFailure primaryLookup = {primary} />
              </Grid.Column>
            </Grid>
          }
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type
                id={`${LICENSE_PLATE_LOOKUP}-${id}`}
                element="h1"
                weight="semibold"
                align="left"
                size="6xl"
                lineHeight="md"
                utils={{
                  vrTop: noBenefits ? "" : "md",
                }}
              >
                {isLicensePlateOrVinLookup
                  ? getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)
                  : getTranslatedText(TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO)}
              </Type>
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Autocomplete
                id={`license-plate-states-autocomplete-${id}`}
                name="State"
                label={
                  <Type size="l" weight="bold" emphasis="information">
                    {getTranslatedText(TRANSLATION_CONSTANTS.STATE)}
                  </Type>
                }
                hasError={stateHasError}
                errorText={getTranslatedText(
                  TRANSLATION_CONSTANTS.SELECT_A_STATE
                )}
                placeholder={getTranslatedText(
                  TRANSLATION_CONSTANTS.ENTER_STATE
                )}
                value={selectedState.text}
                suggestions={StateList}
                clearBtn={true}
                onChange={onClickSuggestion}
                required
              />
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <FormField
                size="md"
                id={`license-plate-number-${id}`}
                name="License Plate Number"
                label={
                  <Flex justifyContent="between" isBlock={true}>
                    <Type size="l" weight="bold" emphasis="information">
                      {getTranslatedText(
                        TRANSLATION_CONSTANTS.LICENSE_PLATE_NUMBER
                      )}
                    </Type>
                  </Flex>
                }
                value={plateNumber}
                utils={{
                  vrTop: "xs",
                  vrBottom: "xs",
                }}
                hasError={plateNumberHasError}
                errorText={getTranslatedText(
                  TRANSLATION_CONSTANTS.INVALID_LICENSE_PLATE_NUMBER
                )}
                maxLength={9}
                minLength={2}
                onBlur={validatePlateNumberOnBlur}
                onChange={onLicensePlateInputChange}
                required
              />
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Button
                hasUpgrade
                id={`continue-with-license-plate-${id}`}
                data-testid="search-by-license-plate-continue"
                disabled={continueButtonDisabled}
                onClick={onContinueClick}
                utils={{ fullWidth: true }}
              >
                {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
              </Button>
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type element="span" size="4xl" variant="darker" weight="normal">
                - {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -
              </Type>
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type
                element="span"
                size="l"
                variant="information"
                weight="semibold"
                onClick={searchByVinInsteadSelected}
              >
                {getTranslatedText(TRANSLATION_CONSTANTS.SEARCH_BY_VIN)}
              </Type>
            </Grid.Column>
          </Grid>
        </React.Fragment>
      )}
    </>
  );
};

export default SearchByLicensePlatePage;
