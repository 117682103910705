import { determineLocale } from "../contexts/LocaleContext";
import { allowLetters } from "../utils/regexUtil";
import {datadogRum} from "@datadog/browser-rum";


export const generatePath = (pageurl, displayCode = window.location.pathname.split('/')[2]) => {
  timeCheckOfCustomerJourney();
  let userType = window.location.pathname.split('/')[1]
  let sanitizedUserType = allowLetters(userType)

  let url = `/${sanitizedUserType}/${displayCode}`;
  if (pageurl) url += `/${pageurl}`

  return generatePathWithSearchParams(url);
}

export const generatePathWithSearchParams = (url = "") => {
  let search = window.location.search
  if (search) {
    //Adding in the pageName specifically to keep the sessionId on the feedback page
    if (!hasSession(url.split("/")[3])) {
      let params = new URLSearchParams(search);
      params.delete("session");
      search = `?${params.toString()}`;
    }
    url += search
  }
  return url
}

export const generatePathWithLocale = (pageurl, displayCode = window.location.pathname.split('/')[2]) => {
  let pageUrlSplit = pageurl.split('/');
  let displayCodeNotAvail = pageUrlSplit[2] == null || pageUrlSplit[2] === 'undefined' || pageUrlSplit[2] === 'null' ? true : false;

  if (displayCodeNotAvail) {
    pageUrlSplit[2] = displayCode;
  }
  pageurl = pageUrlSplit.join('/');

  const locale = determineLocale();
  let url = `/${pageurl}/${locale}`

  return generatePathWithSearchParams(url);
}

const hasSession = (redirectToPage = "") => {
  let hasPage = (page) => {
    return ["feedback", "tracker"].includes(page)
  }
  let hasValidSession = hasSessionParam();
  const currentPage = window.location.pathname.split("/")[3];
  //From the window.location currentPage value, this below if block will remove the sessionId when
  //the customer is navigated from the feedback page to the home page
  if (hasPage(currentPage)) {
    hasValidSession = false
  }
  //When the redirectToPage is passed in (only feedback page currently) the session Id is preserved
  //in the url
  if(hasPage(redirectToPage)) {
    hasValidSession = true;
  }
  return hasValidSession;
}

export const hasSessionParam = () => {
  return window.location.search.includes('session=');
}

export const redirectNavigationUrl = (
  partnerDetails,
  userType = window.location.pathname.split("/")[1],
  displayCode = window.location.pathname.split("/")[2]
) => {
  const sanitizedUserType = allowLetters(userType);

  const vinOdoTokenLookupTraffic = (sanitizedUserType === 'member' &&
    partnerDetails &&
    partnerDetails?.experience &&
    partnerDetails.experience?.lookupMethods?.components?.alternate === "vinOdoTokenLookupForm");

  if (sanitizedUserType === "benefits") {
    return "memberlookup";
  }

  if (hasSession()) {
    return "home";
  }
  if (sanitizedUserType === "ppu" || displayCode === "verizon") {
    return "";
  }
  if (sanitizedUserType === 'OmniAssist' ||
    vinOdoTokenLookupTraffic) {
    return "lookup";
  }
  if (sanitizedUserType === "member") {
    return "memberlookup";
  }
  return "home";
};

const timeCheckOfCustomerJourney = () => {
  if (!window.localStorage.getItem("startTime")) {
    window.localStorage.setItem("startTime", new Date().toString());
  }
  if (window.localStorage.getItem("startTime")) {
    let now = new Date();
    let startTimeOfCustomerJourney = new Date(window.localStorage.getItem("startTime"));
    let elapsedSeconds = (now - startTimeOfCustomerJourney) / 1000;

    if (elapsedSeconds > 3600 && !window.localStorage.getItem("elapsedHour")) {
      window.localStorage.setItem("elapsedHour", "Y")
      datadogRum.addAction("Customer has been in application for more than an hour")
    }
    if (elapsedSeconds > 86400 && !window.localStorage.getItem("elapsedDay")) {
      window.localStorage.setItem("elapsedDay", "Y")
      datadogRum.addAction("Customer has been in application for more than a day")
    }
  }

}
