import React, {PureComponent} from 'react';

export default class JumpStart extends PureComponent {

  render() {
    return (
      <svg height="240px" width="1500px" version="1.1" viewBox="0 0 824 188">
        <defs>
          <rect height="181" id="path-1" width="824" x="0" y="0"/>
          <polygon id="path-3"
                   points="0.0021822831 0.32240625 216.658496 0.32240625 216.658496 134.400042 0.0021822831 134.400042"/>
          <polygon id="path-5"
                   points="0.20258002 0.348144461 99.0207196 0.348144461 99.0207196 85.0287094 0.20258002 85.0287094"/>
        </defs>
        <g id="Rescue" fill="none" stroke="none" strokeWidth="1">
          <g id="Jump-Start---Triage-Selected"
             transform="translate(-308.000000, -173.000000)">
            <g id="Triage_JumpStart_Desktop"
               transform="translate(308.000000, 173.000000)">
              <g id="CarIllustration">
                <g id="Illustration">
                  <g id="Background"
                     transform="translate(412.000000, 90.500000) scale(-1, 1) translate(-412.000000, -90.500000) ">
                    <mask id="mask-2" fill="white">
                      <rect height="181" width="824" x="0" y="0"/>
                    </mask>
                    <g id="Mask"/>
                    <g id="Group-511">
                      <g transform="translate(0.135348, -9.386402)">
                        <g id="Group-35" fill="none" strokeWidth="1"
                           transform="translate(0.000000, 9.818163)">
                          <path id="Fill-1"
                                d="M41.8355335,78.8081165 C41.8355335,69.742656 49.168381,62.393679 58.2139445,62.393679 C67.2591317,62.393679 74.5919792,69.742656 74.5919792,78.8081165 C74.5919792,87.8735769 67.2591317,95.2221769 58.2139445,95.2221769 C49.168381,95.2221769 41.8355335,87.8735769 41.8355335,78.8081165"
                                fill="#E4EFF9"/>
                          <path id="Fill-3"
                                d="M33.4609092,85.3150288 C33.4609092,80.6799204 37.2102974,76.9219079 41.8356088,76.9219079 C46.4609202,76.9219079 50.2103083,80.6799204 50.2103083,85.3150288 C50.2103083,89.9505142 46.4609202,93.7081496 41.8356088,93.7081496 C37.2102974,93.7081496 33.4609092,89.9505142 33.4609092,85.3150288"
                                fill="#E4EFF9"/>
                          <path id="Fill-5"
                                d="M61.2933341,82.2974194 C61.2933341,75.3605944 66.9044354,69.7371506 73.8256592,69.7371506 C80.7472592,69.7371506 86.3579843,75.3605944 86.3579843,82.2974194 C86.3579843,89.2342444 80.7472592,94.857311 73.8256592,94.857311 C66.9044354,94.857311 61.2933341,89.2342444 61.2933341,82.2974194"
                                fill="#E4EFF9"/>
                          <path id="Fill-7"
                                d="M74.5783963,95.5077044 L24.8238466,95.5077044 C22.7582027,95.5077044 21.0838648,93.8293065 21.0838648,91.759119 C21.0838648,89.6889315 22.7582027,88.0105335 24.8238466,88.0105335 L74.5783963,88.0105335 C76.6440402,88.0105335 78.3187543,89.6889315 78.3187543,91.759119 C78.3187543,93.8293065 76.6440402,95.5077044 74.5783963,95.5077044"
                                fill="#E4EFF9"/>
                          <path id="Fill-9"
                                d="M14.8017114,95.3235746 L11.5222667,95.3235746 C9.75536986,95.3235746 8.32296438,93.8880183 8.32296438,92.117235 C8.32296438,90.3464517 9.75536986,88.9108954 11.5222667,88.9108954 L14.8017114,88.9108954 C16.5686082,88.9108954 18.0010137,90.3464517 18.0010137,92.117235 C18.0010137,93.8880183 16.5686082,95.3235746 14.8017114,95.3235746"
                                fill="#E4EFF9"/>
                          <path id="Fill-11"
                                d="M97.7662831,96.1600208 L95.9926137,96.1600208 C94.2257169,96.1600208 92.7933114,94.7244646 92.7933114,92.9536813 C92.7933114,91.1828979 94.2257169,89.7473417 95.9926137,89.7473417 L97.7662831,89.7473417 C99.5331799,89.7473417 100.965585,91.1828979 100.965585,92.9536813 C100.965585,94.7244646 99.5331799,96.1600208 97.7662831,96.1600208"
                                fill="#E4EFF9"/>
                          <path id="Fill-13"
                                d="M407.077672,143.039172 C407.077672,143.039172 390.005446,121.652513 356.969818,121.652513 C356.969818,121.652513 330.803115,122.323345 318.721544,114.070876 C318.721544,114.070876 305.30991,103.967305 285.13395,111.370582 C285.13395,111.370582 254.315597,126.176382 239.903875,121.858024 C239.903875,121.858024 168.954841,104.995611 141.905818,120.624584 C141.905818,120.624584 93.7921198,130.688938 41.9105965,131.100336 C41.9105965,131.100336 -0.656716712,129.831828 0.00775086758,182.07597 L417.27646,181.665326 L407.077672,144.684009"
                                fill="#E4EFF9"/>
                          <g id="Group-17"
                             transform="translate(434.951598, 0.000000)">
                            <mask id="mask-4" fill="white">
                              <polygon
                                points="0.0021822831 0.32240625 216.658496 0.32240625 216.658496 134.400042 0.0021822831 134.400042"/>
                            </mask>
                            <g id="Clip-16"/>
                            <path id="Fill-15"
                                  d="M216.658496,67.3614125 C216.658496,104.386094 168.158383,134.400042 108.330339,134.400042 C48.5026718,134.400042 0.0021822831,104.386094 0.0021822831,67.3614125 C0.0021822831,30.3367312 48.5026718,0.32240625 108.330339,0.32240625 C168.158383,0.32240625 216.658496,30.3367312 216.658496,67.3614125"
                                  fill="#E4EFF9"/>
                          </g>
                          <path id="Fill-18"
                                d="M775.794269,120.358929 C760.688731,156.557421 707.629528,171.25085 657.283504,153.177246 C606.936728,135.104019 578.368384,91.1078206 593.473922,54.909329 C608.57946,18.7108373 661.63904,4.01740812 711.985064,22.0906352 C762.331464,40.1642394 790.899431,84.1604373 775.794269,120.358929"
                                fill="#E4EFF9"/>
                          <g id="Group-22"
                             transform="translate(724.668493, 97.664583)">
                            <mask id="mask-6" fill="white">
                              <polygon
                                points="0.20258002 0.348144461 99.0207196 0.348144461 99.0207196 85.0287094 0.20258002 85.0287094"/>
                            </mask>
                            <g id="Clip-21"/>
                            <path id="Fill-20"
                                  d="M98.5868968,85.0287094 C100.09192,56.998974 97.7090922,40.532876 91.0151269,27.2331469 C78.2988128,1.96856354 45.4328767,-6.31859688 22.5836201,5.91700312 C-0.264884018,18.152226 -6.67289498,47.5334281 8.27160548,71.5415698 L11.8881753,84.9336844 L98.5868968,85.0287094 Z"
                                  fill="#E4EFF9"/>
                          </g>
                          <path id="Fill-23"
                                d="M193.019968,105.94062 C197.104224,129.236451 165.403176,153.402591 122.212783,159.917082 C79.0227664,166.431197 40.6992413,152.827161 36.6146093,129.530953 C32.5303536,106.234745 64.231402,82.0686052 107.421795,75.5541135 C150.611811,69.039999 188.935713,82.6444115 193.019968,105.94062"
                                fill="#E4EFF9"/>
                          <path id="Fill-25"
                                d="M338.143525,92.2454056 C338.143525,129.270087 286.79177,159.284412 223.446488,159.284412 C160.101206,159.284412 108.749451,129.270087 108.749451,92.2454056 C108.749451,55.2207244 160.101206,25.2063994 223.446488,25.2063994 C286.79177,25.2063994 338.143525,55.2207244 338.143525,92.2454056"
                                fill="#E4EFF9"/>
                          <path id="Fill-27"
                                d="M422.596941,87.7216877 C422.596941,128.117871 381.699451,160.865673 331.249957,160.865673 C280.800839,160.865673 239.903348,128.117871 239.903348,87.7216877 C239.903348,47.3255044 280.800839,14.5777023 331.249957,14.5777023 C381.699451,14.5777023 422.596941,47.3255044 422.596941,87.7216877"
                                fill="#E4EFF9"/>
                          <path id="Fill-29"
                                d="M468.713437,46.2310954 C468.713437,65.1486121 450.647143,80.4842142 428.361141,80.4842142 C406.075139,80.4842142 388.008845,65.1486121 388.008845,46.2310954 C388.008845,27.3139558 406.075139,11.9783537 428.361141,11.9783537 C450.647143,11.9783537 468.713437,27.3139558 468.713437,46.2310954"
                                fill="#E4EFF9"/>
                          <path id="Fill-31"
                                d="M565.984342,120.702867 C565.984342,149.507131 524.848681,172.857262 474.105708,172.857262 C423.362358,172.857262 382.227074,149.507131 382.227074,120.702867 C382.227074,91.8989792 423.362358,68.5488479 474.105708,68.5488479 C524.848681,68.5488479 565.984342,91.8989792 565.984342,120.702867"
                                fill="#E4EFF9"/>
                          <polygon id="Fill-33" fill="#E4EFF9"
                                   points="324.577363 181.089972 743.328895 181.655597 712.289304 127.923862 423.339595 78.1515012 310.468526 108.127741"/>
                        </g>
                        <polygon id="Fill-36" fill="#C2DCF2"
                                 points="810.08644 187.483803 719.78507 126.396303 700.219774 113.57547 652.811554 115.83797 633.998769 104.148387 590.764731 108.33288 566.272742 99.2463032 545.804807 103.997553 500.051737 111.690053 447.375938 106.033803 406.740321 120.36297 384.164979 118.854637 343.765651 143.210826 310.41886 137.708803 280.557702 145.472572 276.054298 150.026984 243.310268 157.915945 211.706294 188.834139 363.517947 188.268514"/>
                        <g id="Group-423" fill="none" strokeWidth="1"
                           transform="translate(0.000000, 9.818163)">
                          <polygon id="Stroke-37"
                                   points="810.08644 177.665641 719.78507 116.578141 700.219774 103.757307 652.811554 106.019807 633.998769 94.330224 590.764731 98.5147177 566.272742 89.4281406 545.804807 94.1793906 500.051737 101.871891 447.375938 96.2156406 406.740321 110.544807 384.164979 109.036474 343.765651 133.392664 310.41886 127.890641 280.557702 135.654409 276.054298 140.208822 243.310268 148.097782 211.706294 179.015976 363.517947 178.450351"
                                   stroke="#C2DCF2" strokeLinecap="round"
                                   strokeWidth="5.64383562"/>
                          <path id="Fill-39"
                                d="M712.736183,111.829983 C710.740146,115.294247 717.945067,123.207341 728.828263,129.504633 C739.711083,135.801547 750.151427,138.098362 752.147463,134.634097 C754.143124,131.169833 746.938203,123.256739 736.055383,116.959824 C725.172187,110.662156 714.731843,108.365718 712.736183,111.829983"
                                fill="#C2DCF2"/>
                          <path id="Fill-41"
                                d="M577.327699,93.1757833 C575.515651,96.3214125 580.117635,102.383781 587.606628,106.717223 C595.095246,111.050287 602.63541,112.012981 604.447458,108.867729 C606.259505,105.7221 601.657521,99.6593542 594.168528,95.3262896 C586.679534,90.993225 579.139746,90.0305312 577.327699,93.1757833"
                                fill="#C2DCF2"/>
                          <path id="Fill-43"
                                d="M499.715778,103.506358 C499.362098,107.121079 487.958164,108.958606 474.245148,107.610533 C460.532133,106.26246 449.701988,102.239358 450.056045,98.6246375 C450.409725,95.0099167 461.813283,93.1723896 475.526299,94.5204625 C489.239691,95.8685354 500.069459,99.8916375 499.715778,103.506358"
                                fill="#C2DCF2"/>
                          <path id="Stroke-45"
                                d="M396.03325,142.082436 L407.745338,142.082436"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <g id="Group-8"
                             transform="translate(219.000000, 162.000000)">
                            <path id="Fill-364"
                                  d="M8.78085954,5.06311313 C8.78085954,7.74606104 6.98122849,9.92107771 4.76169607,9.92107771 C2.5417874,9.92107771 0.742532603,7.74606104 0.742532603,5.06311313 C0.742532603,2.38016521 2.5417874,0.205148542 4.76169607,0.205148542 C6.98122849,0.205148542 8.78085954,2.38016521 8.78085954,5.06311313"
                                  fill="#9BBEDE"/>
                            <path id="Stroke-482"
                                  d="M4.8869516,8.58058417 L4.8869516,17.9281029"
                                  stroke="#9BBEDE" strokeLinecap="round"
                                  strokeWidth="1.10543927"/>
                          </g>
                          <g id="Group-7" stroke="#9BBEDE" strokeLinecap="round"
                             strokeWidth="0.891349772"
                             transform="translate(246.000000, 154.000000)">
                            <path id="Stroke-73"
                                  d="M2.77570119,22.6572194 L12.0259478,22.6572194"/>
                            <path id="Stroke-75"
                                  d="M0.958724749,21.0815012 L13.2370773,21.0815012"/>
                            <path id="Stroke-77"
                                  d="M2.66545826,19.17987 L12.4112336,19.17987"/>
                            <path id="Stroke-79"
                                  d="M2.50028201,17.1692617 L11.8054619,17.1692617"/>
                            <path id="Stroke-81"
                                  d="M4.31725845,15.4849058 L10.5943324,15.4849058"/>
                            <path id="Stroke-83"
                                  d="M3.21607087,13.80055 L11.6953695,13.80055"/>
                            <path id="Stroke-85"
                                  d="M4.26232511,11.7899417 L10.814442,11.7899417"/>
                            <path id="Stroke-87"
                                  d="M5.1432526,9.67107271 L9.98867361,9.67107271"/>
                            <path id="Stroke-89"
                                  d="M4.37237991,7.87770208 L10.5941443,7.87770208"/>
                            <path id="Stroke-91"
                                  d="M5.1432526,5.86743313 L10.3190261,5.86743313"/>
                            <path id="Stroke-93"
                                  d="M6.40965406,3.74818708 L8.55694539,3.74818708"/>
                            <path id="Stroke-95"
                                  d="M6.24447781,2.06383125 L9.05247416,2.06383125"/>
                            <path id="Stroke-97"
                                  d="M8.13915105,0.0536 L7.32305242,0.0536"/>
                            <path id="Stroke-99"
                                  d="M7.73113936,0.162237708 L7.73113936,26.1410169"/>
                          </g>
                          <path id="Stroke-101"
                                d="M779.572968,141.473371 L788.823214,141.473371"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-103"
                                d="M777.755991,139.897653 L790.03472,139.897653"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-105"
                                d="M779.462762,137.996021 L789.208538,137.996021"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-107"
                                d="M779.297774,135.985413 L788.602954,135.985413"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-109"
                                d="M781.114751,134.301057 L787.391825,134.301057"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-111"
                                d="M780.013525,132.616701 L788.492824,132.616701"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-113"
                                d="M781.059629,130.606093 L787.611746,130.606093"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-115"
                                d="M781.940745,128.487186 L786.786166,128.487186"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-117"
                                d="M781.169872,126.693854 L787.391637,126.693854"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-119"
                                d="M781.940745,124.683585 L787.116518,124.683585"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-121"
                                d="M783.206921,122.564339 L785.354212,122.564339"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-123"
                                d="M783.04197,120.879983 L785.849966,120.879983"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-125"
                                d="M784.936455,118.869714 L784.120357,118.869714"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <path id="Stroke-127"
                                d="M784.528594,118.978351 L784.528594,144.957131"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.891349772"/>
                          <g id="Group-6" stroke="#9BBEDE" strokeLinecap="round"
                             strokeWidth="0.961333333"
                             transform="translate(232.000000, 151.000000)">
                            <path id="Stroke-129"
                                  d="M2.03520658,25.0488708 L12.0093691,25.0488708"/>
                            <path id="Stroke-131"
                                  d="M0.076043105,23.3498087 L13.3157289,23.3498087"/>
                            <path id="Stroke-133"
                                  d="M1.91653553,21.299041 L12.4249812,21.299041"/>
                            <path id="Stroke-135"
                                  d="M1.7383032,19.131566 L11.7719142,19.131566"/>
                            <path id="Stroke-137"
                                  d="M3.69750429,17.3150048 L10.4659682,17.3150048"/>
                            <path id="Stroke-139"
                                  d="M2.51011653,15.4988206 L11.6531302,15.4988206"/>
                            <path id="Stroke-141"
                                  d="M3.63816877,13.3309685 L10.7034984,13.3309685"/>
                            <path id="Stroke-143"
                                  d="M4.5881768,11.0459944 L9.81286356,11.0459944"/>
                            <path id="Stroke-145"
                                  d="M3.75702795,9.11234875 L10.4660435,9.11234875"/>
                            <path id="Stroke-147"
                                  d="M4.5881768,6.94483604 L10.1691777,6.94483604"/>
                            <path id="Stroke-149"
                                  d="M5.95375927,4.6595225 L8.26923689,4.6595225"/>
                            <path id="Stroke-151"
                                  d="M5.77552694,2.84333833 L8.80325662,2.84333833"/>
                            <path id="Stroke-153"
                                  d="M7.81848256,0.675448542 L6.93879671,0.675448542"/>
                            <path id="Stroke-155"
                                  d="M7.37867726,0.792570625 L7.37867726,28.8754748"/>
                          </g>
                          <path id="Stroke-157"
                                d="M499.494013,136.087791 L511.206101,136.087791"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-159"
                                d="M497.193473,134.092643 L512.739606,134.092643"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-161"
                                d="M499.354573,131.685042 L511.693879,131.685042"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-163"
                                d="M499.145487,129.139352 L510.927182,129.139352"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-165"
                                d="M501.446028,127.00687 L509.393677,127.00687"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-167"
                                d="M500.051775,124.873974 L510.787855,124.873974"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-169"
                                d="M501.376383,122.328661 L509.672445,122.328661"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-171"
                                d="M502.491755,119.645261 L508.626605,119.645261"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-173"
                                d="M501.515823,117.375031 L509.393489,117.375031"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-175"
                                d="M502.491755,114.829718 L509.045001,114.829718"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-177"
                                d="M504.095244,112.146657 L506.814068,112.146657"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-179"
                                d="M503.885971,110.013799 L507.441587,110.013799"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-181"
                                d="M506.284977,107.468298 L505.251779,107.468298"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-183"
                                d="M505.768378,107.605857 L505.768378,145.92582"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-185"
                                d="M767.591255,146.840549 L776.468632,146.840549"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-187"
                                d="M765.847423,145.32852 L777.630999,145.32852"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-189"
                                d="M767.485452,143.503135 L776.838416,143.503135"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-191"
                                d="M767.327086,141.573901 L776.257139,141.573901"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-193"
                                d="M769.07058,139.957307 L775.09481,139.957307"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-195"
                                d="M768.013828,138.340713 L776.151486,138.340713"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-197"
                                d="M769.018017,136.41148 L775.306002,136.41148"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-199"
                                d="M769.863501,134.377643 L774.513269,134.377643"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-201"
                                d="M769.123481,132.656823 L775.094659,132.656823"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-203"
                                d="M769.863501,130.72725 L774.830453,130.72725"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-205"
                                d="M771.078581,128.69379 L773.13971,128.69379"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-207"
                                d="M770.920215,127.077196 L773.614959,127.077196"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-209"
                                d="M772.738283,125.147586 L771.955295,125.147586"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-211"
                                d="M772.346977,125.251811 L772.346977,154.297409"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-213"
                                d="M549.739012,140.053049 L558.616389,140.053049"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-215"
                                d="M547.995368,138.54102 L559.778944,138.54102"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-217"
                                d="M549.633397,136.715635 L558.986362,136.715635"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-219"
                                d="M549.474843,134.786401 L558.405272,134.786401"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-221"
                                d="M551.218713,133.169807 L557.242567,133.169807"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-223"
                                d="M550.161773,131.553213 L558.299432,131.553213"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-225"
                                d="M551.165774,129.62398 L557.453759,129.62398"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-227"
                                d="M552.011258,127.590143 L556.661402,127.590143"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-229"
                                d="M551.271615,125.869323 L557.242793,125.869323"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-231"
                                d="M552.011258,123.93975 L556.978586,123.93975"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-233"
                                d="M553.226752,121.90629 L555.287505,121.90629"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-235"
                                d="M553.068198,120.289696 L555.763318,120.289696"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-237"
                                d="M554.886454,118.360086 L554.103089,118.360086"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-239"
                                d="M554.49496,118.464311 L554.49496,147.509909"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="0.855605479"/>
                          <path id="Stroke-241"
                                d="M514.211895,135.128152 L525.923982,135.128152"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-243"
                                d="M511.911355,133.132966 L527.457488,133.132966"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-245"
                                d="M514.072454,130.725025 L526.41176,130.725025"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-247"
                                d="M513.863369,128.179713 L525.645064,128.179713"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-249"
                                d="M516.163909,126.046854 L524.111559,126.046854"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-251"
                                d="M514.769656,123.914335 L525.505737,123.914335"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-253"
                                d="M516.094264,121.368645 L524.390327,121.368645"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-255"
                                d="M517.209637,118.685622 L523.344486,118.685622"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-257"
                                d="M516.233705,116.415391 L524.111371,116.415391"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-259"
                                d="M517.209637,113.869702 L523.762883,113.869702"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-261"
                                d="M518.813126,111.186641 L521.53195,111.186641"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-263"
                                d="M518.603852,109.054159 L522.159469,109.054159"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-265"
                                d="M521.002859,106.508658 L519.969661,106.508658"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-267"
                                d="M520.48626,106.64618 L520.48626,144.966143"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-269"
                                d="M445.923854,126.925119 L457.635942,126.925119"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-271"
                                d="M443.623352,124.718955 L459.169485,124.718955"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-273"
                                d="M445.784414,122.05652 L458.12372,122.05652"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-275"
                                d="M445.575366,119.242046 L457.357061,119.242046"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-277"
                                d="M447.875869,116.884144 L455.823518,116.884144"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-279"
                                d="M446.481653,114.525902 L457.217734,114.525902"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-281"
                                d="M448.921596,117.083734 L455.056446,117.083734"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-283"
                                d="M447.945702,114.573415 L455.823368,114.573415"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-285"
                                d="M448.921596,111.758903 L455.474842,111.758903"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-287"
                                d="M450.525085,108.792312 L453.243909,108.792312"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-289"
                                d="M450.315849,106.43426 L453.871466,106.43426"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-291"
                                d="M452.714856,103.619785 L451.681658,103.619785"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-293"
                                d="M452.198219,103.771863 L452.198219,137.802879"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.18708676"/>
                          <path id="Stroke-295"
                                d="M365.858559,149.834402 L375.466248,149.834402"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <path id="Stroke-297"
                                d="M363.97141,148.197521 L376.724598,148.197521"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.12876712"/>
                          <polygon id="Fill-341" fill="#9BBEDE"
                                   points="357.18594 161.721237 358.6349 161.721237 358.6349 150.927227 357.18594 150.927227"/>
                          <polyline id="Stroke-344"
                                    points="358.392065 153.262391 361.293749 152.630022 362.303243 149.848279"
                                    stroke="#9BBEDE" strokeWidth="0.757026484"/>
                          <path id="Fill-345"
                                d="M482.015393,112.22954 C482.015393,115.929859 479.401544,118.92918 476.177033,118.92918 C472.952898,118.92918 470.339049,115.929859 470.339049,112.22954 C470.339049,108.529599 472.952898,105.530278 476.177033,105.530278 C479.401544,105.530278 482.015393,108.529599 482.015393,112.22954"
                                fill="#9BBEDE"/>
                          <path id="Fill-346"
                                d="M475.701897,109.718467 C475.701897,111.853136 473.59035,113.583571 470.985532,113.583571 C468.380714,113.583571 466.269167,111.853136 466.269167,109.718467 C466.269167,107.583798 468.380714,105.853363 470.985532,105.853363 C473.59035,105.853363 475.701897,107.583798 475.701897,109.718467"
                                fill="#9BBEDE"/>
                          <path id="Fill-347"
                                d="M472.98947,114.904833 C473.627223,116.235183 473.140725,117.79744 471.902091,118.393608 C470.663833,118.990154 469.143008,118.395117 468.504878,117.064767 C467.867125,115.73404 468.353623,114.17216 469.592257,113.575992 C470.830515,112.979446 472.35134,113.574483 472.98947,114.904833"
                                fill="#9BBEDE"/>
                          <path id="Fill-348"
                                d="M469.204676,112.376075 C469.84243,113.706425 469.355555,115.268681 468.117297,115.86485 C466.87904,116.461396 465.358214,115.866358 464.720084,114.536008 C464.082331,113.205281 464.56883,111.643402 465.807463,111.047233 C467.045721,110.450687 468.566546,111.045725 469.204676,112.376075"
                                fill="#9BBEDE"/>
                          <path id="Fill-349"
                                d="M483.208274,114.652225 C483.846404,115.982198 483.359529,117.544454 482.121271,118.141 C480.882637,118.737169 479.361812,118.142131 478.724058,116.811781 C478.085929,115.481054 478.572804,113.919552 479.811061,113.323006 C481.049319,112.726838 482.570521,113.321875 483.208274,114.652225"
                                fill="#9BBEDE"/>
                          <path id="Fill-350"
                                d="M485.226961,111.743819 C485.864715,113.074169 485.37784,114.636425 484.139582,115.232971 C482.901325,115.82914 481.380499,115.234103 480.742369,113.903753 C480.104616,112.573025 480.591115,111.011523 481.829748,110.414977 C483.068006,109.818809 484.588831,110.413846 485.226961,111.743819"
                                fill="#9BBEDE"/>
                          <path id="Fill-351"
                                d="M476.228994,105.216846 C476.867124,106.547196 476.131544,108.228988 474.586262,108.97335 C473.040603,109.717713 471.271073,109.242211 470.632943,107.911861 C469.995189,106.581511 470.730769,104.899719 472.276052,104.155734 C473.821334,103.411371 475.591241,103.886496 476.228994,105.216846"
                                fill="#9BBEDE"/>
                          <path id="Fill-352"
                                d="M484.883063,109.749388 C484.883063,111.599736 483.025489,113.10015 480.734092,113.10015 C478.442694,113.10015 476.58512,111.599736 476.58512,109.749388 C476.58512,107.898663 478.442694,106.398248 480.734092,106.398248 C483.025489,106.398248 484.883063,107.898663 484.883063,109.749388"
                                fill="#9BBEDE"/>
                          <path id="Fill-353"
                                d="M482.070138,108.282307 C482.070138,110.772189 480.367957,112.789961 478.26845,112.789961 C476.168567,112.789961 474.466386,110.772189 474.466386,108.282307 C474.466386,105.792426 476.168567,103.774276 478.26845,103.774276 C480.367957,103.774276 482.070138,105.792426 482.070138,108.282307"
                                fill="#9BBEDE"/>
                          <polygon id="Fill-354" fill="#9BBEDE"
                                   points="475.330232 129.292071 476.779193 129.292071 476.779193 118.49806 475.330232 118.49806"/>
                          <polyline id="Stroke-355"
                                    points="470.859073 117.16624 472.625217 120.580352 475.653323 121.844336"
                                    stroke="#9BBEDE" strokeWidth="0.757026484"/>
                          <polyline id="Stroke-356"
                                    points="476.536357 120.833224 479.438041 120.200856 480.447535 117.419112"
                                    stroke="#9BBEDE" strokeWidth="0.757026484"/>
                          <path id="Fill-357"
                                d="M443.755079,124.296923 C443.755079,127.988946 440.886129,130.981857 437.347068,130.981857 C433.808007,130.981857 430.939057,127.988946 430.939057,124.296923 C430.939057,120.6049 433.808007,117.61199 437.347068,117.61199 C440.886129,117.61199 443.755079,120.6049 443.755079,124.296923"
                                fill="#9BBEDE"/>
                          <path id="Fill-358"
                                d="M438.433468,124.769748 C438.433468,127.826763 436.129279,130.305331 433.286667,130.305331 C430.444431,130.305331 428.140241,127.826763 428.140241,124.769748 C428.140241,121.713111 430.444431,119.234542 433.286667,119.234542 C436.129279,119.234542 438.433468,121.713111 438.433468,124.769748"
                                fill="#9BBEDE"/>
                          <path id="Fill-359"
                                d="M441.379965,117.179136 C442.455303,119.080013 441.215541,121.483165 438.611099,122.546163 C436.005904,123.609915 433.022573,122.930411 431.946858,121.029157 C430.871519,119.12828 432.111281,116.725128 434.7161,115.66213 C437.320918,114.598378 440.304249,115.277882 441.379965,117.179136"
                                fill="#9BBEDE"/>
                          <path id="Fill-360"
                                d="M446.809748,123.836241 C446.809748,126.829905 444.782859,129.257191 442.282263,129.257191 C439.781668,129.257191 437.754778,126.829905 437.754778,123.836241 C437.754778,120.842199 439.781668,118.414914 442.282263,118.414914 C444.782859,118.414914 446.809748,120.842199 446.809748,123.836241"
                                fill="#9BBEDE"/>
                          <polygon id="Fill-361" fill="#9BBEDE"
                                   points="437.116197 139.587954 438.837567 139.587954 438.837567 129.605427 437.116197 129.605427"/>
                          <polyline id="Stroke-362"
                                    points="432.738387 129.505047 434.665945 132.661989 437.969846 133.831702"
                                    stroke="#9BBEDE" strokeWidth="0.760412785"/>
                          <path id="Stroke-365"
                                d="M799.322743,137.290461 L799.322743,152.32515"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.30447854"/>
                          <path id="Fill-366"
                                d="M803.917916,135.809391 C803.917916,138.975382 801.794329,141.541434 799.175213,141.541434 C796.555721,141.541434 794.432133,138.975382 794.432133,135.809391 C794.432133,132.643399 796.555721,130.077347 799.175213,130.077347 C801.794329,130.077347 803.917916,132.643399 803.917916,135.809391"
                                fill="#9BBEDE"/>
                          <path id="Stroke-367"
                                d="M542.340132,132.388377 L542.340132,147.423067"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.30447854"/>
                          <path id="Fill-368"
                                d="M546.935117,130.907307 C546.935117,134.073299 544.81153,136.639351 542.192414,136.639351 C539.573298,136.639351 537.449711,134.073299 537.449711,130.907307 C537.449711,127.741316 539.573298,125.175264 542.192414,125.175264 C544.81153,125.175264 546.935117,127.741316 546.935117,130.907307"
                                fill="#9BBEDE"/>
                          <path id="Stroke-369"
                                d="M573.945611,137.667544 L573.945611,152.702234"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="1.30447854"/>
                          <path id="Fill-370"
                                d="M578.540597,136.186474 C578.540597,139.352466 576.417009,141.918518 573.797893,141.918518 C571.178777,141.918518 569.05519,139.352466 569.05519,136.186474 C569.05519,133.020482 571.178777,130.45443 573.797893,130.45443 C576.417009,130.45443 578.540597,133.020482 578.540597,136.186474"
                                fill="#9BBEDE"/>
                          <path id="Fill-371"
                                d="M388.357672,153.708707 C388.357672,157.361513 385.398044,160.322371 381.747235,160.322371 C378.09605,160.322371 375.136422,157.361513 375.136422,153.708707 C375.136422,150.056278 378.09605,147.095419 381.747235,147.095419 C385.398044,147.095419 388.357672,150.056278 388.357672,153.708707"
                                fill="#9BBEDE"/>
                          <path id="Fill-372"
                                d="M424.312667,149.435221 C424.312667,153.088028 418.548806,156.048886 411.438325,156.048886 C404.328221,156.048886 398.563984,153.088028 398.563984,149.435221 C398.563984,145.782415 404.328221,142.821934 411.438325,142.821934 C418.548806,142.821934 424.312667,145.782415 424.312667,149.435221"
                                fill="#9BBEDE"/>
                          <path id="Fill-373"
                                d="M533.473854,148.602621 C532.280747,152.053688 526.553383,153.198513 520.681536,151.159246 C514.810066,149.11998 511.017785,144.669265 512.210892,141.218199 C513.404375,137.767132 519.131363,136.622307 525.003209,138.661574 C530.87468,140.700463 534.666961,145.151555 533.473854,148.602621"
                                fill="#9BBEDE"/>
                          <path id="Fill-374"
                                d="M808.879412,153.881411 C807.685929,157.332478 801.958564,158.477303 796.087094,156.438036 C790.215624,154.398769 786.422966,149.948055 787.616073,146.496988 C788.809556,143.045921 794.536921,141.901096 800.408391,143.940363 C806.280237,145.979253 810.072519,150.430344 808.879412,153.881411"
                                fill="#9BBEDE"/>
                          <path id="Fill-375"
                                d="M487.529533,133.923597 C487.529533,138.172949 483.791433,141.617228 479.180043,141.617228 C474.569029,141.617228 470.830929,138.172949 470.830929,133.923597 C470.830929,129.674622 474.569029,126.230343 479.180043,126.230343 C483.791433,126.230343 487.529533,129.674622 487.529533,133.923597"
                                fill="#9BBEDE"/>
                          <path id="Fill-376"
                                d="M357.996696,162.507569 C357.996696,166.160376 355.15145,169.120857 351.641737,169.120857 C348.132023,169.120857 345.286778,166.160376 345.286778,162.507569 C345.286778,158.854763 348.132023,155.893905 351.641737,155.893905 C355.15145,155.893905 357.996696,158.854763 357.996696,162.507569"
                                fill="#9BBEDE"/>
                          <path id="Fill-377"
                                d="M350.210347,168.540903 C350.210347,172.193709 347.760546,175.15419 344.73846,175.15419 C341.716374,175.15419 339.26695,172.193709 339.26695,168.540903 C339.26695,164.888096 341.716374,161.927238 344.73846,161.927238 C347.760546,161.927238 350.210347,164.888096 350.210347,168.540903"
                                fill="#9BBEDE"/>
                          <path id="Fill-378"
                                d="M379.056555,159.993555 C379.056555,163.646361 376.606754,166.607219 373.585045,166.607219 C370.562959,166.607219 368.113158,163.646361 368.113158,159.993555 C368.113158,156.340749 370.562959,153.380267 373.585045,153.380267 C376.606754,153.380267 379.056555,156.340749 379.056555,159.993555"
                                fill="#9BBEDE"/>
                          <path id="Fill-379"
                                d="M384.684024,107.56238 C384.684024,110.389374 382.787695,112.681286 380.448513,112.681286 C378.109332,112.681286 376.213379,110.389374 376.213379,107.56238 C376.213379,104.735009 378.109332,102.443096 380.448513,102.443096 C382.787695,102.443096 384.684024,104.735009 384.684024,107.56238"
                                fill="#C2DCF2"/>
                          <path id="Fill-380"
                                d="M421.055346,104.294199 C421.055346,107.121569 419.159017,109.413105 416.820212,109.413105 C414.480654,109.413105 412.584701,107.121569 412.584701,104.294199 C412.584701,101.466828 414.480654,99.1752923 416.820212,99.1752923 C419.159017,99.1752923 421.055346,101.466828 421.055346,104.294199"
                                fill="#C2DCF2"/>
                          <path id="Fill-381"
                                d="M372.857517,117.48035 C374.182689,120.861279 370.058927,125.647975 363.647153,128.172171 C357.235003,130.696744 350.963197,130.002534 349.638024,126.621981 C348.313228,123.241052 352.436614,118.454356 358.848764,115.930161 C365.260538,113.405588 371.532344,114.099798 372.857517,117.48035"
                                fill="#C2DCF2"/>
                          <path id="Fill-382"
                                d="M479.77622,129.840124 C480.328563,133.429957 474.312987,137.338803 466.340129,138.570734 C458.367646,139.802665 451.456582,137.891607 450.904238,134.301774 C450.351895,130.712317 456.367471,126.803472 464.340329,125.57154 C472.313188,124.339232 479.223877,126.25029 479.77622,129.840124"
                                fill="#9BBEDE"/>
                          <path id="Fill-383"
                                d="M449.352409,138.478047 C450.286652,141.987185 444.022747,146.709776 435.361341,149.026576 C426.699558,151.342622 418.920847,150.376157 417.986228,146.866643 C417.051985,143.357128 423.31589,138.634537 431.977297,136.318114 C440.639079,134.001691 448.418166,134.968532 449.352409,138.478047"
                                fill="#9BBEDE"/>
                          <path id="Fill-384"
                                d="M300.214805,130.737921 C301.539978,134.118474 296.663704,139.201557 289.324084,142.091146 C281.984088,144.980736 274.959394,144.58329 273.634221,141.202361 C272.309425,137.821809 277.185322,132.738726 284.525319,129.849136 C291.865315,126.959169 298.889633,127.357369 300.214805,130.737921"
                                fill="#C2DCF2"/>
                          <path id="Fill-385"
                                d="M332.923316,133.921033 C332.423649,137.518408 325.68679,139.551641 317.876098,138.461493 C310.065406,137.371722 304.139002,133.572231 304.63867,129.974856 C305.137961,126.377481 311.875196,124.344624 319.685512,125.434395 C327.49658,126.524166 333.422984,130.323658 332.923316,133.921033"
                                fill="#C2DCF2"/>
                          <path id="Fill-386"
                                d="M543.651684,94.5620171 C544.187472,98.1541129 539.098237,101.893271 532.285375,102.913659 C525.472137,103.934046 519.514504,101.849152 518.979092,98.2570567 C518.443304,94.6645837 523.532539,90.9254254 530.345777,89.9050379 C537.159016,88.8850275 543.116272,90.9699212 543.651684,94.5620171"
                                fill="#C2DCF2"/>
                          <path id="Fill-387"
                                d="M570.365839,92.2995171 C570.901627,95.8916129 565.812393,99.6307712 558.999531,100.651159 C552.186292,101.671546 546.228659,99.5866525 545.693247,95.9945567 C545.157459,92.4020837 550.246694,88.6629254 557.059932,87.6425379 C563.873171,86.6225275 569.830427,88.7074212 570.365839,92.2995171"
                                fill="#C2DCF2"/>
                          <path id="Fill-388"
                                d="M479.500462,95.3699558 C479.500462,98.1973267 477.338873,100.488862 474.672349,100.488862 C472.006201,100.488862 469.844612,98.1973267 469.844612,95.3699558 C469.844612,92.542585 472.006201,90.2506725 474.672349,90.2506725 C477.338873,90.2506725 479.500462,92.542585 479.500462,95.3699558"
                                fill="#C2DCF2"/>
                          <path id="Fill-389"
                                d="M626.24,93.4845392 C626.24,96.31191 624.078787,98.6034454 621.412263,98.6034454 C618.745739,98.6034454 616.58415,96.31191 616.58415,93.4845392 C616.58415,90.6571683 618.745739,88.3652558 621.412263,88.3652558 C624.078787,88.3652558 626.24,90.6571683 626.24,93.4845392"
                                fill="#C2DCF2"/>
                          <path id="Fill-390"
                                d="M698.03576,99.713805 C698.03576,102.541176 695.874547,104.832711 693.208023,104.832711 C690.541498,104.832711 688.379909,102.541176 688.379909,99.713805 C688.379909,96.8864342 690.541498,94.5945217 693.208023,94.5945217 C695.874547,94.5945217 698.03576,96.8864342 698.03576,99.713805"
                                fill="#C2DCF2"/>
                          <path id="Fill-391"
                                d="M706.391308,102.686278 C706.391308,105.513648 704.230095,107.805184 701.563571,107.805184 C698.897047,107.805184 696.735458,105.513648 696.735458,102.686278 C696.735458,99.8589067 698.897047,97.5669942 701.563571,97.5669942 C704.230095,97.5669942 706.391308,99.8589067 706.391308,102.686278"
                                fill="#C2DCF2"/>
                          <path id="Fill-392"
                                d="M573.940645,89.7137058 C573.940645,92.5410767 571.779056,94.8326121 569.112532,94.8326121 C566.446384,94.8326121 564.284795,92.5410767 564.284795,89.7137058 C564.284795,86.886335 566.446384,84.5944225 569.112532,84.5944225 C571.779056,84.5944225 573.940645,86.886335 573.940645,89.7137058"
                                fill="#C2DCF2"/>
                          <path id="Fill-393"
                                d="M580.88339,91.5306442 C580.88339,94.358015 578.721801,96.6495504 576.055277,96.6495504 C573.389129,96.6495504 571.22754,94.358015 571.22754,91.5306442 C571.22754,88.7032733 573.389129,86.4113608 576.055277,86.4113608 C578.721801,86.4113608 580.88339,88.7032733 580.88339,91.5306442"
                                fill="#C2DCF2"/>
                          <path id="Fill-394"
                                d="M430.68753,102.534539 C430.68753,105.852118 427.776816,108.541854 424.186584,108.541854 C420.596352,108.541854 417.685638,105.852118 417.685638,102.534539 C417.685638,99.21696 420.596352,96.5272246 424.186584,96.5272246 C427.776816,96.5272246 430.68753,99.21696 430.68753,102.534539"
                                fill="#C2DCF2"/>
                          <path id="Fill-395"
                                d="M664.869045,104.671282 C664.869045,107.498653 662.972716,109.790188 660.633534,109.790188 C658.294353,109.790188 656.398024,107.498653 656.398024,104.671282 C656.398024,101.843911 658.294353,99.5523756 660.633534,99.5523756 C662.972716,99.5523756 664.869045,101.843911 664.869045,104.671282"
                                fill="#C2DCF2"/>
                          <path id="Fill-396"
                                d="M674.50104,102.911622 C674.50104,106.229202 671.590702,108.918937 668.000471,108.918937 C664.409862,108.918937 661.499148,106.229202 661.499148,102.911622 C661.499148,99.5940433 664.409862,96.9043079 668.000471,96.9043079 C671.590702,96.9043079 674.50104,99.5940433 674.50104,102.911622"
                                fill="#C2DCF2"/>
                          <path id="Fill-397"
                                d="M457.526978,95.2441985 C457.526978,98.0715694 455.046701,100.363482 451.987365,100.363482 C448.92803,100.363482 446.447753,98.0715694 446.447753,95.2441985 C446.447753,92.4168277 448.92803,90.1252923 451.987365,90.1252923 C455.046701,90.1252923 457.526978,92.4168277 457.526978,95.2441985"
                                fill="#C2DCF2"/>
                          <path id="Fill-398"
                                d="M377.911797,112.087003 C377.911797,114.914374 376.015468,117.205909 373.676287,117.205909 C371.337105,117.205909 369.440776,114.914374 369.440776,112.087003 C369.440776,109.260009 371.337105,106.968096 373.676287,106.968096 C376.015468,106.968096 377.911797,109.260009 377.911797,112.087003"
                                fill="#C2DCF2"/>
                          <path id="Fill-399"
                                d="M350.258282,127.386895 C350.258282,130.214266 347.934151,132.505801 345.067082,132.505801 C342.200014,132.505801 339.875506,130.214266 339.875506,127.386895 C339.875506,124.559901 342.200014,122.267989 345.067082,122.267989 C347.934151,122.267989 350.258282,124.559901 350.258282,127.386895"
                                fill="#C2DCF2"/>
                          <path id="Fill-400"
                                d="M332.295571,125.409772 L330.550873,122.410451 C330.773617,122.326361 330.99937,122.195136 331.175082,121.986609 C331.368101,121.758097 331.567893,121.352355 331.428302,120.71433 C331.418143,120.667195 331.400459,120.621945 331.376378,120.580088 L328.59472,115.74852 C328.509686,115.60108 328.352035,115.510203 328.180462,115.510203 L328.180086,115.510203 C328.008514,115.510203 327.850862,115.601457 327.765829,115.749651 L324.868283,120.81124 C324.839312,120.86177 324.819746,120.917955 324.811469,120.975649 C324.800181,121.052197 324.711385,121.736226 325.104572,122.188349 C325.212557,122.312786 325.389021,122.455324 325.656163,122.519428 L323.974676,125.409772 C323.889642,125.556457 323.889642,125.736703 323.974676,125.883388 L325.84843,129.104434 C325.933463,129.250742 326.091115,129.340865 326.261558,129.340865 L330.008689,129.340865 C330.179133,129.340865 330.336408,129.250742 330.421818,129.104434 L332.295571,125.883388 C332.380605,125.736703 332.380605,125.556457 332.295571,125.409772"
                                fill="#C2DCF2"/>
                          <path id="Fill-401"
                                d="M394.044852,104.15151 L392.300154,101.152189 C392.522898,101.068477 392.749027,100.936875 392.924739,100.728348 C393.117382,100.500212 393.31755,100.094094 393.177582,99.4560685 C393.167424,99.4089331 393.150116,99.3636831 393.125659,99.3218269 L390.344001,94.4902581 C390.258967,94.3428185 390.101316,94.2519415 389.929743,94.2519415 L389.929367,94.2519415 C389.758171,94.2519415 389.600143,94.3435727 389.515486,94.4913894 L386.61794,99.552979 C386.588593,99.6035081 386.569403,99.6593165 386.56075,99.7173873 C386.549462,99.7939352 386.460666,100.477964 386.853853,100.930087 C386.962214,101.054525 387.138302,101.197062 387.405444,101.260789 L385.724333,104.15151 C385.638923,104.298196 385.638923,104.478441 385.724333,104.62475 L387.59771,107.845796 C387.68312,107.992481 387.840395,108.082604 388.010839,108.082604 L391.75797,108.082604 C391.928414,108.082604 392.086065,107.992481 392.171098,107.845796 L394.044852,104.62475 C394.130262,104.478441 394.130262,104.298196 394.044852,104.15151"
                                fill="#C2DCF2"/>
                          <path id="Fill-402"
                                d="M404.582946,106.493726 L402.838249,103.494028 C403.060992,103.410315 403.286746,103.27909 403.462457,103.070563 C403.655476,102.842051 403.855268,102.436309 403.715677,101.798284 C403.705518,101.751149 403.687834,101.705899 403.663754,101.664042 L400.882095,96.8324735 C400.797062,96.685034 400.63941,96.5941569 400.467838,96.5941569 L400.467462,96.5941569 C400.295889,96.5941569 400.138238,96.685411 400.053204,96.8332277 L397.155659,101.895194 C397.126687,101.945724 397.107122,102.001532 397.098844,102.059603 C397.087557,102.136151 396.99876,102.819803 397.391947,103.272303 C397.499933,103.39674 397.676397,103.539278 397.943538,103.603005 L396.262052,106.493726 C396.177018,106.640411 396.177018,106.820657 396.262052,106.966965 L398.135805,110.188011 C398.220839,110.334696 398.37849,110.424819 398.548934,110.424819 L402.296064,110.424819 C402.466508,110.424819 402.623783,110.334696 402.709193,110.188011 L404.582946,106.966965 C404.66798,106.820657 404.66798,106.640411 404.582946,106.493726"
                                fill="#C2DCF2"/>
                          <path id="Fill-403"
                                d="M439.0085,95.9357694 L437.263802,92.9364485 C437.486546,92.852359 437.712675,92.721134 437.888387,92.5126069 C438.08103,92.2844715 438.281198,91.8783527 438.141231,91.2403277 C438.131072,91.1931923 438.113764,91.1479423 438.089684,91.106086 L435.307649,86.2745173 C435.222615,86.1270777 435.064964,86.0362006 434.893768,86.0362006 L434.893015,86.0362006 C434.721819,86.0362006 434.563791,86.1274548 434.479134,86.2752715 L431.581589,91.3372381 C431.552617,91.3877673 431.533052,91.4435756 431.524398,91.5016465 C431.51311,91.5781944 431.424314,92.2622235 431.817501,92.7143465 C431.925863,92.838784 432.10195,92.9813215 432.369092,93.0450485 L430.687981,95.9357694 C430.602571,96.0824548 430.602571,96.2627006 430.687981,96.409009 L432.561359,99.6300548 C432.646769,99.7767402 432.804043,99.8668631 432.974487,99.8668631 L436.721994,99.8668631 C436.892062,99.8668631 437.049713,99.7767402 437.135123,99.6300548 L439.0085,96.409009 C439.09391,96.2627006 439.09391,96.0824548 439.0085,95.9357694"
                                fill="#C2DCF2"/>
                          <path id="Fill-404"
                                d="M502.175926,97.0951875 L500.431228,94.0954896 C500.653972,94.0117771 500.880102,93.8805521 501.055813,93.672025 C501.248456,93.4435125 501.448624,93.0377708 501.308657,92.3997458 C501.298498,92.3526104 501.28119,92.3073604 501.256734,92.2655042 L498.475075,87.4335583 C498.390041,87.2861188 498.23239,87.1952417 498.061194,87.1952417 L498.060441,87.1952417 C497.889245,87.1956188 497.731218,87.2868729 497.64656,87.4346896 L494.749015,92.4966563 C494.719667,92.5471854 494.700478,92.6029937 494.691824,92.6610646 C494.680536,92.7376125 494.59174,93.4212646 494.984927,93.8737646 C495.093289,93.9982021 495.269376,94.1407396 495.536518,94.2044667 L493.855407,97.0951875 C493.769997,97.2414958 493.769997,97.4221187 493.855407,97.5684271 L495.728785,100.789473 C495.814195,100.936158 495.97147,101.026281 496.141913,101.026281 L499.88942,101.026281 C500.059488,101.026281 500.217139,100.936158 500.302173,100.789473 L502.175926,97.5684271 C502.261336,97.4221187 502.261336,97.2414958 502.175926,97.0951875"
                                fill="#C2DCF2"/>
                          <path id="Fill-405"
                                d="M510.955289,96.8436729 L509.210591,93.8443521 C509.433334,93.7606396 509.659464,93.6290375 509.834799,93.4205104 C510.027818,93.192375 510.227986,92.7862563 510.088019,92.1482313 C510.07786,92.1010958 510.060176,92.0558458 510.036096,92.0139896 L507.254438,87.1824208 C507.169404,87.0349813 507.011753,86.9441042 506.84018,86.9441042 L506.839804,86.9441042 C506.668607,86.9441042 506.51058,87.0357354 506.425546,87.1835521 L503.528001,92.2451417 C503.499029,92.2956708 503.47984,92.3518563 503.471186,92.40955 C503.459899,92.4860979 503.371102,93.1701271 503.76429,93.62225 C503.872651,93.7466875 504.048739,93.889225 504.315881,93.9529521 L502.634394,96.8436729 C502.54936,96.9903583 502.54936,97.1706042 502.634394,97.3169125 L504.508147,100.537958 C504.593181,100.684644 504.750832,100.774767 504.921276,100.774767 L508.668407,100.774767 C508.83885,100.774767 508.996125,100.684644 509.081535,100.537958 L510.955289,97.3169125 C511.040699,97.1706042 511.040699,96.9903583 510.955289,96.8436729"
                                fill="#C2DCF2"/>
                          <path id="Fill-406"
                                d="M548.831634,88.7993542 L547.086936,85.7996562 C547.30968,85.7159437 547.535809,85.5847187 547.711521,85.3761917 C547.904164,85.1476792 548.104332,84.7419375 547.964365,84.1039125 C547.954206,84.0567771 547.936898,84.0115271 547.912441,83.9696708 L545.130783,79.137725 C545.045749,78.9902854 544.888098,78.8994083 544.716902,78.8994083 L544.716149,78.8994083 C544.544953,78.8997854 544.386925,78.9910396 544.302268,79.1388562 L541.404723,84.2008229 C541.375375,84.2513521 541.356186,84.3071604 541.347532,84.3652312 C541.336244,84.4417792 541.247448,85.1254312 541.640635,85.5779312 C541.748997,85.7023687 541.925084,85.8449062 542.192226,85.9086333 L540.511115,88.7993542 C540.425705,88.9456625 540.425705,89.1262854 540.511115,89.2725937 L542.384492,92.4936396 C542.469902,92.640325 542.627177,92.7304479 542.797621,92.7304479 L546.545128,92.7304479 C546.715196,92.7304479 546.872847,92.640325 546.957881,92.4936396 L548.831634,89.2725937 C548.917044,89.1262854 548.917044,88.9456625 548.831634,88.7993542"
                                fill="#C2DCF2"/>
                          <path id="Fill-407"
                                d="M616.933917,91.1874229 L615.189219,88.1881021 C615.411963,88.1043896 615.638092,87.9727875 615.813804,87.7642604 C616.006447,87.536125 616.206615,87.1300063 616.066648,86.4919813 C616.056489,86.4448458 616.039181,86.3995958 616.014724,86.3577396 L613.233066,81.5261708 C613.148032,81.3787313 612.990381,81.2878542 612.819185,81.2878542 L612.818432,81.2878542 C612.647236,81.2878542 612.489208,81.3794854 612.404551,81.5273021 L609.507006,86.5888917 C609.477658,86.6394208 609.458469,86.6956063 609.449815,86.7533 C609.438527,86.8298479 609.349731,87.5138771 609.742918,87.966 C609.85128,88.0904375 610.027367,88.232975 610.294509,88.2967021 L608.613398,91.1874229 C608.527988,91.3341083 608.527988,91.5143542 608.613398,91.6606625 L610.486776,94.8817083 C610.572186,95.0283938 610.72946,95.1185167 610.899904,95.1185167 L614.647411,95.1185167 C614.817479,95.1185167 614.97513,95.0283938 615.060164,94.8817083 L616.933917,91.6606625 C617.019327,91.5143542 617.019327,91.3341083 616.933917,91.1874229"
                                fill="#C2DCF2"/>
                          <path id="Fill-408"
                                d="M682.026155,99.4832562 L680.281457,96.4839354 C680.5042,96.4002229 680.73033,96.2686208 680.905665,96.0600937 C681.098684,95.8319583 681.298476,95.4258396 681.158885,94.7878146 C681.148726,94.7406792 681.131042,94.6954292 681.107338,94.6535729 L678.325303,89.8220042 C678.24027,89.6745646 678.082618,89.5836875 677.911046,89.5836875 L677.91067,89.5836875 C677.739097,89.5836875 677.581446,89.6753187 677.496788,89.8231354 L674.599243,94.884725 C674.570271,94.9352542 674.550706,94.9914396 674.542052,95.0491333 C674.530765,95.1256812 674.441968,95.8097104 674.835155,96.2618333 C674.943517,96.3862708 675.119605,96.5288083 675.386746,96.5925354 L673.705636,99.4832562 C673.620226,99.6299417 673.620226,99.8101875 673.705636,99.9564958 L675.579013,103.177542 C675.664423,103.324227 675.821698,103.41435 675.992142,103.41435 L679.739649,103.41435 C679.909716,103.41435 680.067367,103.324227 680.152777,103.177542 L682.026155,99.9564958 C682.111565,99.8101875 682.111565,99.6299417 682.026155,99.4832562"
                                fill="#C2DCF2"/>
                          <path id="Fill-409"
                                d="M719.27547,107.77909 L717.530772,104.779769 C717.753515,104.696056 717.979645,104.564454 718.15498,104.355927 C718.347999,104.127792 718.547791,103.721673 718.4082,103.083648 C718.398041,103.036512 718.380357,102.991262 718.356653,102.949406 L715.574618,98.1178375 C715.489585,97.9703979 715.331934,97.8795208 715.160361,97.8795208 L715.159985,97.8795208 C714.988412,97.8795208 714.830761,97.9711521 714.746103,98.1189687 L711.848558,103.180558 C711.819586,103.231087 711.800021,103.287273 711.791367,103.344967 C711.78008,103.421515 711.691283,104.105544 712.084471,104.557667 C712.192832,104.682104 712.36892,104.824642 712.636061,104.888369 L710.954951,107.77909 C710.869541,107.925775 710.869541,108.106021 710.954951,108.252329 L712.828328,111.473375 C712.913738,111.62006 713.071013,111.710183 713.241457,111.710183 L716.988964,111.710183 C717.159031,111.710183 717.316682,111.62006 717.402092,111.473375 L719.27547,108.252329 C719.36088,108.106021 719.36088,107.925775 719.27547,107.77909"
                                fill="#C2DCF2"/>
                          <path id="Fill-410"
                                d="M338.960339,128.943457 L337.750677,126.843857 C337.905318,126.784655 338.06184,126.692647 338.183747,126.547093 C338.317318,126.387209 338.456156,126.102889 338.359082,125.656045 C338.351934,125.623239 338.339893,125.591564 338.323338,125.562151 L336.394651,122.179714 C336.335579,122.076393 336.226089,122.012666 336.107192,122.012666 C335.988295,122.012666 335.878805,122.07677 335.819733,122.180091 L333.810903,125.72392 C333.790586,125.759743 333.777417,125.798582 333.771397,125.839307 C333.763495,125.892853 333.701789,126.371749 333.974575,126.688122 C334.049826,126.775228 334.171733,126.875155 334.357227,126.920028 L333.19121,128.943457 C333.132138,129.046024 333.132138,129.172347 333.19121,129.274914 L334.490421,131.529872 C334.549493,131.632439 334.658607,131.695789 334.776752,131.695789 L337.375174,131.695789 C337.493318,131.695789 337.602432,131.632439 337.661128,131.529872 L338.960339,129.274914 C339.019411,129.172347 339.019411,129.046024 338.960339,128.943457"
                                fill="#C2DCF2"/>
                          <path id="Fill-411"
                                d="M312.899288,124.479583 L311.171522,121.480262 C311.392008,121.396172 311.61588,121.264947 311.790087,121.056797 C311.980848,120.828285 312.178759,120.422166 312.040673,119.784141 C312.030138,119.737005 312.013206,119.691755 311.989126,119.649899 L309.234182,114.81833 C309.150277,114.670891 308.993754,114.580014 308.824063,114.580014 L308.823687,114.580014 C308.653995,114.580014 308.497473,114.671268 308.413568,114.819462 L305.543866,119.881051 C305.51527,119.93158 305.496081,119.987766 305.487427,120.04546 C305.47614,120.122008 305.388472,120.806037 305.77752,121.25816 C305.884753,121.382597 306.059336,121.525135 306.32422,121.589239 L304.658912,124.479583 C304.574631,124.626268 304.574631,124.806514 304.658912,124.953199 L306.514605,128.174245 C306.598887,128.320553 306.754656,128.410676 306.923595,128.410676 L310.634605,128.410676 C310.803544,128.410676 310.959314,128.320553 311.043595,128.174245 L312.899288,124.953199 C312.983946,124.806514 312.983946,124.626268 312.899288,124.479583"
                                fill="#C2DCF2"/>
                          <path id="Fill-412"
                                d="M302.805966,126.138146 L301.544004,123.947669 C301.705418,123.886581 301.868713,123.790425 301.995887,123.63846 C302.135102,123.47179 302.27996,123.175402 302.178747,122.709327 C302.171222,122.675012 302.158806,122.642206 302.141122,122.611285 L300.129659,119.083671 C300.068329,118.975825 299.953947,118.909458 299.830159,118.909458 L299.829783,118.909458 C299.705995,118.909458 299.591613,118.976202 299.530283,119.084425 L297.435292,122.780219 C297.414221,122.817173 297.399924,122.858275 297.393904,122.900131 C297.385626,122.956317 297.321662,123.455575 297.605736,123.7859 C297.683997,123.876777 297.811547,123.980475 298.004943,124.027233 L296.788884,126.138146 C296.727178,126.24486 296.727178,126.37684 296.788884,126.483554 L298.143781,128.835423 C298.205487,128.942515 298.319116,129.008127 298.442528,129.008127 L301.152322,129.008127 C301.275734,129.008127 301.389363,128.942515 301.451069,128.835423 L302.805966,126.483554 C302.867672,126.37684 302.867672,126.24486 302.805966,126.138146"
                                fill="#C2DCF2"/>
                          <path id="Fill-413"
                                d="M412.590533,105.524131 L411.137058,103.334031 C411.322552,103.272944 411.511056,103.176787 411.657419,103.024823 C411.817704,102.858152 411.984762,102.561387 411.868499,102.09569 C411.859845,102.060998 411.845171,102.028192 411.824853,101.997648 L409.50787,98.4696562 C409.437134,98.3618104 409.305445,98.2954437 409.162844,98.2954437 L409.162468,98.2954437 C409.01949,98.2958208 408.888177,98.3621875 408.817441,98.4704104 L406.403761,102.166204 C406.37968,102.203535 406.363501,102.24426 406.356353,102.286494 C406.34657,102.342302 406.272824,102.84156 406.600166,103.171885 C406.690468,103.262762 406.837207,103.366837 407.059951,103.413596 L405.659527,105.524131 C405.588415,105.631223 405.588415,105.762825 405.659527,105.869917 L407.220236,108.221785 C407.290972,108.3285 407.422285,108.39449 407.564133,108.39449 L410.685927,108.39449 C410.827775,108.39449 410.958712,108.3285 411.029825,108.221785 L412.590533,105.869917 C412.661646,105.762825 412.661646,105.631223 412.590533,105.524131"
                                fill="#C2DCF2"/>
                          <path id="Fill-414"
                                d="M447.037195,93.7089792 L445.775609,91.5185021 C445.936647,91.4574146 446.099942,91.3616354 446.227116,91.2092938 C446.366331,91.0426229 446.511189,90.7462354 446.409977,90.2801604 C446.402828,90.2458458 446.390035,90.2130396 446.372727,90.1821188 L444.361264,86.6545042 C444.299558,86.5466583 444.185177,86.4802917 444.061765,86.4802917 L444.061388,86.4802917 C443.937224,86.4802917 443.823219,86.5470354 443.761889,86.6548813 L441.666521,90.3510521 C441.645451,90.3880063 441.631529,90.4287313 441.625133,90.4713417 C441.616855,90.52715 441.552892,91.0264083 441.837341,91.3567333 C441.915602,91.4476104 442.042777,91.5513083 442.236172,91.5980667 L441.020114,93.7089792 C440.958784,93.8156938 440.958784,93.9476729 441.020114,94.0547646 L442.37501,96.4062563 C442.436716,96.5133479 442.550722,96.5793375 442.673757,96.5793375 L445.383551,96.5793375 C445.506963,96.5793375 445.620592,96.5133479 445.682298,96.4062563 L447.037195,94.0547646 C447.098901,93.9476729 447.098901,93.8156938 447.037195,93.7089792"
                                fill="#C2DCF2"/>
                          <path id="Fill-415"
                                d="M471.993182,93.8012515 L470.731221,91.6111515 C470.892635,91.550064 471.055929,91.4539077 471.183104,91.3019431 C471.322319,91.1352723 471.467177,90.8388848 471.365964,90.3728098 C471.358439,90.3384952 471.346023,90.3053119 471.328339,90.2747681 L469.316876,86.7467765 C469.255546,86.6389306 469.141164,86.572564 469.017752,86.572564 L469.017,86.572564 C468.893212,86.572941 468.779206,86.6396848 468.717877,86.7475306 L466.622508,90.4433244 C466.601438,90.4806556 466.587517,90.5213806 466.58112,90.563614 C466.572843,90.6194223 466.508879,91.1186806 466.792952,91.4490056 C466.87159,91.5398827 466.998764,91.6439577 467.19216,91.690716 L465.976101,93.8012515 C465.914772,93.9083431 465.914772,94.0399452 465.976101,94.1470369 L467.330998,96.4989056 C467.392704,96.6056202 467.506709,96.6716098 467.629745,96.6716098 L470.339539,96.6716098 C470.462951,96.6716098 470.57658,96.6056202 470.638286,96.4989056 L471.993182,94.1470369 C472.054888,94.0399452 472.054888,93.9083431 471.993182,93.8012515"
                                fill="#C2DCF2"/>
                          <path id="Fill-416"
                                d="M519.652176,94.8069327 L518.390591,92.6168327 C518.551628,92.5553681 518.714923,92.459589 518.842098,92.3072473 C518.981312,92.1405765 519.126171,91.844189 519.024958,91.378491 C519.017809,91.3437994 519.005016,91.3109931 518.987708,91.2800723 L516.975869,87.7524577 C516.91454,87.6446119 516.800158,87.5782452 516.676746,87.5782452 L516.67637,87.5782452 C516.552205,87.5786223 516.4382,87.644989 516.37687,87.7528348 L514.281502,91.4490056 C514.260432,91.4859598 514.24651,91.5270619 514.240114,91.5692952 C514.231836,91.6251035 514.167873,92.1243619 514.452322,92.4546869 C514.530583,92.545564 514.657758,92.6492619 514.851153,92.6960202 L513.635095,94.8069327 C513.573765,94.9140244 513.573765,95.0456265 513.635095,95.1527181 L514.989992,97.5045869 C515.051698,97.6113015 515.165703,97.677291 515.288739,97.677291 L517.998532,97.677291 C518.121944,97.677291 518.235573,97.6113015 518.297279,97.5045869 L519.652176,95.1527181 C519.713882,95.0456265 519.713882,94.9140244 519.652176,94.8069327"
                                fill="#C2DCF2"/>
                          <path id="Fill-417"
                                d="M567.158297,85.2078617 L565.896335,83.0177617 C566.057749,82.9566742 566.221044,82.8605179 566.348218,82.7085533 C566.487433,82.5415054 566.632292,82.2451179 566.531079,81.77942 C566.523554,81.7447283 566.511137,81.7119221 566.493453,81.6813783 L564.48199,78.1533867 C564.42066,78.0455408 564.306279,77.9791742 564.182491,77.9791742 L564.182114,77.9791742 C564.058326,77.9795513 563.943944,78.0459179 563.882615,78.1541408 L561.787623,81.8499346 C561.766553,81.8872658 561.752255,81.9279908 561.746235,81.9702242 C561.737957,82.0260325 561.673994,82.5252908 561.958067,82.8556158 C562.036328,82.9464929 562.163879,83.0505679 562.357274,83.0973263 L561.141216,85.2078617 C561.07951,85.3149533 561.07951,85.4465554 561.141216,85.5536471 L562.496113,87.9055158 C562.557818,88.0122304 562.671448,88.07822 562.79486,88.07822 L565.504653,88.07822 C565.628065,88.07822 565.741694,88.0122304 565.8034,87.9055158 L567.158297,85.5536471 C567.220003,85.4465554 567.220003,85.3149533 567.158297,85.2078617"
                                fill="#C2DCF2"/>
                          <path id="Fill-418"
                                d="M606.441951,93.0470848 L605.17999,90.8569848 C605.341403,90.7958973 605.504698,90.699741 605.631873,90.5477765 C605.771087,90.3811056 605.915946,90.0847181 605.814733,89.6186431 C605.807208,89.5843285 605.794792,89.5511452 605.777108,89.5206015 L603.765645,85.9926098 C603.704315,85.884764 603.589933,85.8183973 603.466145,85.8183973 C603.341981,85.8187744 603.227599,85.8855181 603.166269,85.993364 L601.071277,89.6891577 C601.050207,89.726489 601.035909,89.767214 601.029889,89.8094473 C601.021612,89.8652556 600.957648,90.364514 601.241721,90.694839 C601.319982,90.785716 601.447533,90.889791 601.640929,90.9365494 L600.42487,93.0470848 C600.363164,93.1541765 600.363164,93.2857785 600.42487,93.3928702 L601.779767,95.744739 C601.841473,95.8514535 601.955102,95.9174431 602.078514,95.9174431 L604.788308,95.9174431 C604.911719,95.9174431 605.025349,95.8514535 605.087055,95.744739 L606.441951,93.3928702 C606.503657,93.2857785 606.503657,93.1541765 606.441951,93.0470848"
                                fill="#C2DCF2"/>
                          <path id="Fill-419"
                                d="M649.334914,97.5720848 L648.073329,95.3819848 C648.234366,95.3208973 648.398037,95.224741 648.525212,95.0727765 C648.664426,94.9061056 648.809285,94.6097181 648.708072,94.1436431 C648.700171,94.1093285 648.687754,94.0761452 648.67007,94.0456015 L646.658983,90.5176098 C646.597654,90.409764 646.483272,90.3433973 646.359484,90.3433973 L646.359108,90.3433973 C646.235319,90.3437744 646.120938,90.4105181 646.059608,90.518364 L643.964616,94.2141577 C643.94317,94.251489 643.929248,94.292214 643.922852,94.3344473 C643.914951,94.3902556 643.850611,94.889514 644.13506,95.219839 C644.213321,95.310716 644.340872,95.414791 644.533891,95.4615494 L643.318209,97.5720848 C643.256503,97.6791765 643.256503,97.8107785 643.318209,97.9178702 L644.672729,100.269739 C644.734435,100.376454 644.848441,100.442443 644.971477,100.442443 L647.68127,100.442443 C647.804682,100.442443 647.918311,100.376454 647.980017,100.269739 L649.334914,97.9178702 C649.39662,97.8107785 649.39662,97.6791765 649.334914,97.5720848"
                                fill="#C2DCF2"/>
                          <path id="Fill-420"
                                d="M656.862587,100.496479 L655.600625,98.3060021 C655.762039,98.2449146 655.925334,98.1491354 656.052508,97.9967938 C656.191723,97.8301229 656.336581,97.5337354 656.235368,97.0676604 C656.227843,97.0333458 656.215051,97.0005396 656.197743,96.9696188 L654.18628,93.4420042 C654.12495,93.3341583 654.010568,93.2677917 653.887157,93.2677917 L653.886404,93.2677917 C653.762616,93.2677917 653.648234,93.3345354 653.587281,93.4423813 L651.491913,97.1385521 C651.470842,97.1755063 651.456921,97.2162313 651.450525,97.2588417 C651.442247,97.31465 651.378283,97.8139083 651.662357,98.1442333 C651.740994,98.2351104 651.868168,98.3388083 652.061564,98.3855667 L650.845505,100.496479 C650.784176,100.603194 650.784176,100.735173 650.845505,100.842265 L652.200402,103.193756 C652.261732,103.300848 652.375737,103.366837 652.499149,103.366837 L655.208943,103.366837 C655.332355,103.366837 655.445984,103.300848 655.507314,103.193756 L656.862587,100.842265 C656.924293,100.735173 656.924293,100.603194 656.862587,100.496479"
                                fill="#C2DCF2"/>
                          <path id="Fill-421"
                                d="M282.864413,141.724131 L281.602452,139.534031 C281.763865,139.472567 281.92716,139.376787 282.054335,139.224446 C282.193549,139.058152 282.338408,138.761765 282.237195,138.29569 C282.22967,138.260998 282.217253,138.228192 282.199569,138.197271 L280.188106,134.669656 C280.126777,134.56181 280.012395,134.495444 279.888607,134.495444 L279.888231,134.495444 C279.764442,134.495444 279.650061,134.562565 279.588731,134.67041 L277.493739,138.366581 C277.472669,138.403158 277.458371,138.44426 277.452351,138.486494 C277.444073,138.542302 277.38011,139.041937 277.664183,139.371885 C277.742444,139.462762 277.869995,139.56646 278.06339,139.613219 L276.847332,141.724131 C276.785626,141.831223 276.785626,141.962825 276.847332,142.069917 L278.202229,144.421785 C278.263935,144.5285 278.377564,144.59449 278.500976,144.59449 L281.210769,144.59449 C281.334181,144.59449 281.44781,144.5285 281.509516,144.421785 L282.864413,142.069917 C282.926119,141.962825 282.926119,141.831223 282.864413,141.724131"
                                fill="#C2DCF2"/>
                          <path id="Fill-422"
                                d="M816.468452,156.590227 C819.236189,162.047377 819.376908,167.542235 816.783002,168.863912 C814.188719,170.185212 809.842213,166.832941 807.074476,161.375791 C804.306739,155.919396 804.166019,150.423783 806.759926,149.10286 C809.354209,147.781183 813.700715,151.133077 816.468452,156.590227"
                                fill="#9BBEDE"/>
                        </g>
                        <g id="Group-510" fill="none" strokeWidth="1"
                           transform="translate(0.000000, 9.818163)">
                          <path id="Fill-424"
                                d="M713.906865,66.2362335 C713.906865,61.7300877 710.532228,58.0772815 706.370087,58.0772815 C702.20757,58.0772815 698.833309,61.7300877 698.833309,66.2362335 C698.833309,70.7420023 702.20757,74.3948085 706.370087,74.3948085 C710.532228,74.3948085 713.906865,70.7420023 713.906865,66.2362335"
                                fill="#FEFEFE"/>
                          <path id="Fill-426"
                                d="M717.760551,69.470515 C717.760551,67.1665358 716.035043,65.2988421 713.90694,65.2988421 C711.778462,65.2988421 710.052953,67.1665358 710.052953,69.470515 C710.052953,71.7744942 711.778462,73.6421879 713.90694,73.6421879 C716.035043,73.6421879 717.760551,71.7744942 717.760551,69.470515"
                                fill="#FEFEFE"/>
                          <path id="Fill-428"
                                d="M696.299641,69.470515 C696.299641,67.1665358 694.574132,65.2988421 692.44603,65.2988421 C690.317551,65.2988421 688.592419,67.1665358 688.592419,69.470515 C688.592419,71.7744942 690.317551,73.6421879 692.44603,73.6421879 C694.574132,73.6421879 696.299641,71.7744942 696.299641,69.470515"
                                fill="#FEFEFE"/>
                          <path id="Fill-430"
                                d="M704.952769,67.9704775 C704.952769,64.5224275 702.371279,61.7274858 699.185898,61.7274858 C696.000894,61.7274858 693.419027,64.5224275 693.419027,67.9704775 C693.419027,71.4185275 696.000894,74.2134692 699.185898,74.2134692 C702.371279,74.2134692 704.952769,71.4185275 704.952769,67.9704775"
                                fill="#FEFEFE"/>
                          <path id="Fill-432"
                                d="M687.291176,74.9588498 L721.734752,74.9588498 C722.900769,74.9588498 723.845923,73.9358227 723.845923,72.6733477 C723.845923,71.4112498 722.900769,70.3882227 721.734752,70.3882227 L687.291176,70.3882227 C686.125159,70.3882227 685.180381,71.4112498 685.180381,72.6733477 C685.180381,73.9358227 686.125159,74.9588498 687.291176,74.9588498"
                                fill="#FEFEFE"/>
                          <path id="Fill-434"
                                d="M235.386399,101.50348 C235.386399,93.6884281 229.064926,87.3534281 221.267403,87.3534281 C213.469504,87.3534281 207.148031,93.6884281 207.148031,101.50348 C207.148031,109.318532 213.469504,115.653909 221.267403,115.653909 C229.064926,115.653909 235.386399,109.318532 235.386399,101.50348"
                                fill="#FEFEFE"/>
                          <path id="Fill-436"
                                d="M246.795299,105.202291 C246.795299,100.150882 242.709539,96.0561344 237.669217,96.0561344 C232.628896,96.0561344 228.543135,100.150882 228.543135,105.202291 C228.543135,110.253699 232.628896,114.348447 237.669217,114.348447 C242.709539,114.348447 246.795299,110.253699 246.795299,105.202291"
                                fill="#FEFEFE"/>
                          <path id="Fill-438"
                                d="M257.668901,107.672111 C257.668901,103.707457 254.462074,100.493199 250.505745,100.493199 C246.549416,100.493199 243.342589,103.707457 243.342589,107.672111 C243.342589,111.637142 246.549416,114.851024 250.505745,114.851024 C254.462074,114.851024 257.668901,111.637142 257.668901,107.672111"
                                fill="#FEFEFE"/>
                          <path id="Fill-440"
                                d="M218.612505,104.511474 C218.612505,98.5316865 213.775738,93.6839031 207.808699,93.6839031 C201.842036,93.6839031 197.004892,98.5316865 197.004892,104.511474 C197.004892,110.491261 201.842036,115.339045 207.808699,115.339045 C213.775738,115.339045 218.612505,110.491261 218.612505,104.511474"
                                fill="#FEFEFE"/>
                          <path id="Fill-442"
                                d="M207.159846,115.899843 L261.339163,115.899843 C263.119981,115.899843 264.563674,114.452974 264.563674,112.668239 C264.563674,110.883126 263.119981,109.436635 261.339163,109.436635 L207.159846,109.436635 C205.379028,109.436635 203.935334,110.883126 203.935334,112.668239 C203.935334,114.452974 205.379028,115.899843 207.159846,115.899843"
                                fill="#FEFEFE"/>
                          <path id="Fill-444"
                                d="M269.979123,115.740752 L272.806308,115.740752 C274.329391,115.740752 275.564262,114.503541 275.564262,112.976731 C275.564262,111.450298 274.329391,110.21271 272.806308,110.21271 L269.979123,110.21271 C268.456039,110.21271 267.220792,111.450298 267.220792,112.976731 C267.220792,114.503541 268.456039,115.740752 269.979123,115.740752"
                                fill="#FEFEFE"/>
                          <path id="Fill-446"
                                d="M187.17017,116.46215 L188.699273,116.46215 C190.222357,116.46215 191.457604,115.224562 191.457604,113.698129 C191.457604,112.171319 190.222357,110.934108 188.699273,110.934108 L187.17017,110.934108 C185.647087,110.934108 184.41184,112.171319 184.41184,113.698129 C184.41184,115.224562 185.647087,116.46215 187.17017,116.46215"
                                fill="#FEFEFE"/>
                          <path id="Fill-448"
                                d="M337.137832,173.519949 L353.772849,166.925138 L363.094584,162.32887 L378.076334,162.268913 L390.937506,150.022378 L418.887285,153.428572 L435.972681,142.906061 L470.575413,130.655755 L487.660809,138.314317 L522.696235,146.861288 L541.125992,167.145355 L544.07358,174.271853 C544.07358,174.271853 543.306018,180.141532 541.816045,179.928103 C504.942986,174.648936 375.041832,175.276403 337.13294,175.780186 C336.53808,175.788105 337.400082,173.554263 337.137832,173.519949"
                                fill="#9BBEDE"/>
                          <path id="Stroke-450"
                                d="M337.137832,173.519949 L353.772849,166.925138 L363.094584,162.32887 L378.076334,162.268913 L390.937506,150.022378 L418.887285,153.428572 L435.972681,142.906061 L470.575413,130.655755 L487.660809,138.314317 L522.696235,146.861288 L541.125992,167.145355 L544.07358,174.271853 C544.07358,174.271853 543.306018,180.141532 541.816045,179.928103 C504.942986,174.648936 375.041832,175.276403 337.13294,175.780186 C336.53808,175.788105 337.400082,173.554263 337.137832,173.519949 Z"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="11.157863"/>
                          <path id="Fill-452"
                                d="M818.237681,174.166194 L812.043384,159.57948 L802.205426,151.940903 L790.945974,147.812594 L781.107639,148.607109 L773.932443,154.812015 L769.066328,167.508788 L760.226576,175.788407 C760.226576,175.788407 760.097144,178.185903 762.318182,178.16818 C772.871778,178.084844 797.745667,178.480028 818.240315,178.823928 C818.582708,178.829207 818.086803,174.190705 818.237681,174.166194"
                                fill="#9BBEDE"/>
                          <path id="Stroke-454"
                                d="M818.237681,174.166194 L812.043384,159.57948 L802.205426,151.940903 L790.945974,147.812594 L781.107639,148.607109 L773.932443,154.812015 L769.066328,167.508788 L760.226576,175.788407 C760.226576,175.788407 760.097144,178.185903 762.318182,178.16818 C772.871778,178.084844 797.745667,178.480028 818.240315,178.823928 C818.582708,178.829207 818.086803,174.190705 818.237681,174.166194 Z"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="7.21432694"/>
                          <path id="Fill-456"
                                d="M607.443958,174.166194 L592.937795,159.57948 L575.264312,151.940903 L555.038311,147.812594 L537.365204,148.607109 L524.475812,154.812015 L504.919545,168.640038 L488.961788,175.788407 C488.961788,175.788407 562.884747,175.391338 607.448849,175.807261 C608.064027,175.81254 607.172678,174.190705 607.443958,174.166194"
                                fill="#9BBEDE"/>
                          <path id="Stroke-458"
                                d="M607.443958,174.166194 L592.937795,159.57948 L575.264312,151.940903 L555.038311,147.812594 L537.365204,148.607109 L524.475812,154.812015 L504.919545,168.640038 L488.961788,175.788407 C488.961788,175.788407 562.884747,175.391338 607.448849,175.807261 C608.064027,175.81254 607.172678,174.190705 607.443958,174.166194 Z"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="9.66939543"/>
                          <path id="Fill-460"
                                d="M409.553661,53.7954248 C409.553661,47.447981 414.306899,42.3023019 420.170468,42.3023019 C426.034037,42.3023019 430.787275,47.447981 430.787275,53.7954248 C430.787275,60.1428685 426.034037,65.2885477 420.170468,65.2885477 C414.306899,65.2885477 409.553661,60.1428685 409.553661,53.7954248"
                                fill="#FEFEFE"/>
                          <path id="Fill-462"
                                d="M404.12493,58.3515342 C404.12493,55.1059779 406.555166,52.4750675 409.553548,52.4750675 C412.551553,52.4750675 414.982165,55.1059779 414.982165,58.3515342 C414.982165,61.5970904 412.551553,64.2280008 409.553548,64.2280008 C406.555166,64.2280008 404.12493,61.5970904 404.12493,58.3515342"
                                fill="#FEFEFE"/>
                          <path id="Fill-464"
                                d="M434.356399,58.3515342 C434.356399,55.1059779 436.786635,52.4750675 439.785017,52.4750675 C442.783022,52.4750675 445.213634,55.1059779 445.213634,58.3515342 C445.213634,61.5970904 442.783022,64.2280008 439.785017,64.2280008 C436.786635,64.2280008 434.356399,61.5970904 434.356399,58.3515342"
                                fill="#FEFEFE"/>
                          <path id="Fill-466"
                                d="M422.166467,56.2385477 C422.166467,51.3817144 425.803731,47.4442102 430.290204,47.4442102 C434.777053,47.4442102 438.414317,51.3817144 438.414317,56.2385477 C438.414317,61.095381 434.777053,65.0328852 430.290204,65.0328852 C425.803731,65.0328852 422.166467,61.095381 422.166467,56.2385477"
                                fill="#FEFEFE"/>
                          <path id="Fill-468"
                                d="M447.046225,66.082836 L398.52617,66.082836 C396.883814,66.082836 395.552621,64.6416235 395.552621,62.8636756 C395.552621,61.0857277 396.883814,59.6441381 398.52617,59.6441381 L447.046225,59.6441381 C448.688581,59.6441381 450.02015,61.0857277 450.02015,62.8636756 C450.02015,64.6416235 448.688581,66.082836 447.046225,66.082836"
                                fill="#FEFEFE"/>
                          <path id="Fill-470"
                                d="M351.178152,70.761045 C351.178152,66.2548992 354.552413,62.6020929 358.71493,62.6020929 C362.877071,62.6020929 366.251708,66.2548992 366.251708,70.761045 C366.251708,75.2668137 362.877071,78.91962 358.71493,78.91962 C354.552413,78.91962 351.178152,75.2668137 351.178152,70.761045"
                                fill="#FEFEFE"/>
                          <path id="Fill-472"
                                d="M347.324315,73.9953265 C347.324315,71.6913473 349.049824,69.8236535 351.178302,69.8236535 C353.306405,69.8236535 355.031913,71.6913473 355.031913,73.9953265 C355.031913,76.2993056 353.306405,78.1669994 351.178302,78.1669994 C349.049824,78.1669994 347.324315,76.2993056 347.324315,73.9953265"
                                fill="#FEFEFE"/>
                          <path id="Fill-474"
                                d="M368.785188,73.9953265 C368.785188,71.6913473 370.510321,69.8236535 372.638799,69.8236535 C374.767278,69.8236535 376.49241,71.6913473 376.49241,73.9953265 C376.49241,76.2993056 374.767278,78.1669994 372.638799,78.1669994 C370.510321,78.1669994 368.785188,76.2993056 368.785188,73.9953265"
                                fill="#FEFEFE"/>
                          <path id="Fill-476"
                                d="M360.131871,72.495289 C360.131871,69.047239 362.713738,66.2522973 365.898743,66.2522973 C369.083747,66.2522973 371.665614,69.047239 371.665614,72.495289 C371.665614,75.943339 369.083747,78.7382806 365.898743,78.7382806 C362.713738,78.7382806 360.131871,75.943339 360.131871,72.495289"
                                fill="#FEFEFE"/>
                          <path id="Fill-478"
                                d="M377.793465,79.4836612 L343.349889,79.4836612 C342.183872,79.4836612 341.239094,78.4606342 341.239094,77.1981592 C341.239094,75.9360612 342.183872,74.9130342 343.349889,74.9130342 L377.793465,74.9130342 C378.959481,74.9130342 379.904636,75.9360612 379.904636,77.1981592 C379.904636,78.4606342 378.959481,79.4836612 377.793465,79.4836612"
                                fill="#FEFEFE"/>
                          <polygon id="Fill-484" fill="#9BBEDE"
                                   points="580.510634 156.043494 774.922334 156.043494 774.922334 151.718725 580.510634 151.718725"/>
                          <path id="Stroke-486"
                                d="M652.841354,154.943806 L652.841354,159.287429"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-487"
                                d="M713.036397,154.943806 L713.036397,159.287429"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-488"
                                d="M625.103143,183.321891 C625.103143,169.728036 637.938354,158.70853 653.771571,158.70853 C669.604787,158.70853 682.439998,169.728036 682.439998,183.321891"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="2.35799452"/>
                          <path id="Stroke-489"
                                d="M567.536208,183.321891 C567.536208,169.728036 580.371419,158.70853 596.204636,158.70853 C612.037476,158.70853 624.872687,169.728036 624.872687,183.321891"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="2.35799452"/>
                          <path id="Stroke-490"
                                d="M684.4968,183.321891 C684.4968,169.728036 697.332011,158.70853 713.165227,158.70853 C728.998444,158.70853 741.834031,169.728036 741.834031,183.321891"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="2.35799452"/>
                          <path id="Stroke-491"
                                d="M741.687668,183.321891 C741.687668,169.728036 754.522879,158.70853 770.356095,158.70853 C786.189311,158.70853 799.024899,169.728036 799.024899,183.321891"
                                stroke="#9BBEDE" strokeLinecap="round"
                                strokeWidth="2.35799452"/>
                          <polygon id="Fill-492" fill="#9BBEDE"
                                   points="678.845816 179.847256 688.091547 179.847256 688.091547 176.372056 678.845816 176.372056"/>
                          <polygon id="Fill-493" fill="#9BBEDE"
                                   points="737.353578 179.847256 746.59931 179.847256 746.59931 176.372056 737.353578 176.372056"/>
                          <polygon id="Fill-494" fill="#9BBEDE"
                                   points="620.278981 179.847256 629.524712 179.847256 629.524712 176.372056 620.278981 176.372056"/>
                          <path id="Stroke-495"
                                d="M683.555822,154.36453 L683.555822,178.399068"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-496"
                                d="M625.103143,154.36453 L625.103143,178.399068"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-497"
                                d="M741.920984,154.36453 L741.920984,178.399068"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-498"
                                d="M625.392334,154.36453 L634.349477,164.789376"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-499"
                                d="M684.046347,154.36453 L693.00349,164.789376"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-500"
                                d="M742.454514,154.36453 L751.139625,164.467724"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-501"
                                d="M740.449034,154.36453 L731.49189,164.789376"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-502"
                                d="M682.372648,154.36453 L673.415505,164.789376"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-503"
                                d="M624.585453,154.36453 L615.62831,164.789376"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-504"
                                d="M643.306357,153.78567 L633.89131,164.789338"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-505"
                                d="M663.651368,153.78567 L673.066791,164.789338"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-506"
                                d="M606.711463,153.78567 L616.126886,164.789338"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-507"
                                d="M723.374249,153.78567 L732.789295,164.789338"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-508"
                                d="M701.671556,154.075119 L692.662866,164.789187"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                          <path id="Stroke-509"
                                d="M759.363973,154.075119 L750.355282,164.789187"
                                stroke="#9BBEDE" strokeWidth="2.30306119"/>
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="jumper-guy"
                     transform="translate(488.000000, 98.000000)">
                    <path id="Shape"
                          d="M34.6776422,32.8727808 C34.6776422,32.8727808 33.2078746,29.4853478 30.6780826,28.2992486 C29.6313293,22.1054054 31.0878259,20.8857139 31.0878259,20.8857139 C32.2416947,21.5711495 33.2046297,22.535796 33.8880154,23.69088 L39.587927,31.0164941 L34.6776422,32.8727808 Z"
                          fill="#2F73BD"/>
                    <g id="Group" transform="translate(0.000000, 2.903040)">
                      <path id="Shape"
                            d="M11.0680474,33.6835584 C11.0680474,33.6835584 8.87459328,34.0746394 9.11264256,37.6557466 L10.904233,38.4839424 L12.490537,35.1923098 L11.0680474,33.6839731 L11.0680474,33.6835584 Z M19.0941235,13.934592 L19.0941235,14.8594176 L17.4696653,15.6946637 C17.8416691,15.9194419 21.195095,20.3569459 26.5590835,16.0791091 L24.3341107,16.1778125 L24.3341107,13.5140659 L19.0941235,13.934592 Z"
                            fill="#D7E9FC"/>
                      <path id="Shape"
                            d="M19.0941235,14.4467712 C19.0941235,14.4467712 25.5786854,15.4561997 24.8670259,17.7873408 L27.208535,16.4079821 L24.6157056,15.5403878 L24.6157056,13.5513907 C24.6157056,13.5513907 23.4934733,14.8254106 19.7033472,13.7359411 L19.0941235,14.4467712 Z"
                            fill="#B6DAFC"/>
                      <ellipse id="Oval" cx="21.9353702" cy="8.17247232"
                               fill="#D7E9FC" rx="5.41126656" ry="6.64422912"/>
                      <path id="Shape"
                            d="M29.033303,26.0618342 L28.8922982,33.4289203 C28.8781978,34.3421338 28.9026662,35.2080691 28.9532621,36.0167731 C29.0441166,37.4985197 29.2372835,38.9722142 29.5313818,40.4273203 C29.7127866,41.1167402 29.7127866,41.8413603 29.5313818,42.5307802 C20.453161,47.2034304 14.4762163,43.5883162 11.1211315,42.884951 C11.1211315,42.884951 13.4186803,35.6845824 12.7227802,33.8942362 L12.6377626,26.1526579 C12.6377626,26.1526579 10.9950566,26.0614195 8.46982656,28.4373504 C8.46982656,28.4373504 6.47087616,29.8755994 8.28900864,31.2922829 L10.904233,33.6839731 L9.52777728,36.350208 L4.34875392,33.8681088 L3.82247424,33.5919053 L1.14587136,32.2855373 C0.823481972,32.0636802 0.608880094,31.7168172 0.554252157,31.3292978 C0.49962422,30.9417785 0.60997528,30.5491075 0.8584704,30.2467738 L7.79549184,21.769897 C7.79549184,21.769897 10.7984794,18.0946483 14.0677171,17.501184 L17.1237888,16.8235315 C17.1237888,16.8235315 17.332393,16.9491917 17.6969318,17.1295949 L19.263744,16.1778125 C19.263744,16.1778125 19.5528038,16.9587302 21.8731622,18.3820493 C22.1825434,18.4139827 22.4935834,18.4305715 22.8046234,18.431401 C24.9420902,17.000617 25.004713,16.5357158 25.004713,16.5357158 L26.7859354,17.3021184 L27.3723494,17.1536486 L28.1777357,17.1370598"
                            fill="#2F73BD"/>
                      <path id="Shape"
                            d="M22.7142144,0.684288 C24.330793,1.8330624 25.4210918,3.97467648 25.4210918,6.43355136 C25.4210918,10.1029939 22.9982976,13.0777805 20.0098253,13.0777805 C19.75959,13.0753293 19.510009,13.0517447 19.263744,13.0072781 C20.0489781,13.5817033 20.9948377,13.8949105 21.9677184,13.9026586 C24.9561907,13.9026586 27.378985,10.927872 27.378985,7.25801472 C27.378985,3.90002688 25.3485158,1.13177088 22.7142144,0.684288 Z"
                            fill="#B6DAFC"/>
                      <path id="Shape"
                            d="M21.0014208,50.7422362 C21.0180096,54.3847219 22.7308032,62.3937946 22.7308032,62.3937946 C22.7308032,62.3937946 22.0933786,68.9090458 23.9227085,73.3909248 C23.9227085,73.3909248 23.8667213,74.4708557 23.7651149,76.1168794 L24.543959,78.6624307 L26.2293811,78.6881434 L29.9527373,78.7026586 L31.0915584,78.8299776 L30.2102784,59.0507366 L30.5267098,50.1699226 L30.3342797,47.9121869 L29.4550733,42.1600205 L29.769431,42.0219187 C20.6912102,46.694569 14.1726413,43.0392269 10.8175565,42.3358618 L10.6914816,43.0392269 L9.8122752,48.7913933 L9.61984512,51.049129 L9.49625856,57.2923238 C8.96074474,61.8423244 8.99613208,66.4414244 9.60159744,70.9826458 L9.03011328,79.709184 L10.6081229,79.581865 L14.331479,79.5673498 L16.0173158,79.5412224 L16.3561421,76.9960858 C16.2499738,75.2667034 15.7734605,75.4947994 16.6381517,72.5121331 C18.4674816,68.0302541 17.8304717,63.273001 17.8304717,63.273001 C18.2570529,60.5820488 18.5068348,57.8660344 18.5782118,55.1424154 L19.5100877,51.1818394 L20.5178573,50.572201 L18.4944384,50.572201"
                            fill="#2F73BD"/>
                      <path id="Shape" style={{"mixBlendMode": "multiply"}}
                            d="M28.6434662,79.0730035 L29.7818726,79.2003226 L28.4862874,59.4210816 L29.217024,50.5402675 L29.0245939,48.2825318 L28.5849907,43.849175 C28.5849907,43.849175 23.1090278,46.8177408 14.7549082,44.5824"
                            opacity="0.64" stroke="#20589B"
                            strokeWidth="2.90304"/>
                      <path id="Shape"
                            d="M12.490537,43.4705357 L11.6113306,49.2222874 L11.4189005,51.480023 L11.2957286,57.7232179 C10.7602148,62.2732185 10.7956022,66.8723184 11.4010675,71.4135398 L10.8291686,80.1400781"
                            opacity="0.27" stroke="#FFFFFF"
                            strokeLinecap="round" strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M29.2203418,42.5307802 C20.142121,47.2034304 14.8710298,43.3996186 11.515945,42.6962534 C11.2621363,41.2227533 10.381271,42.9143962 12.1243392,40.1179392 M28.8325786,24.5045606 L28.6650317,33.2505907 C28.685353,33.9829862 28.7587584,34.7128934 28.8848333,35.4345062"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M16.9525094,17.8864589 C16.9525094,17.8864589 12.490537,18.3260621 8.27698176,22.5313229 L1.27236096,31.241687"
                            opacity="0.57" stroke="#FFFFFF"
                            strokeLinecap="round" strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M24.3341107,13.934592 L24.3341107,16.1782272 M19.5337267,13.934592 L19.5337267,15.486889"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.8792064"/>
                      <ellipse id="Oval" cx="21.9353702" cy="7.73286912"
                               rx="5.41126656" ry="6.64422912" stroke="#001960"
                               strokeLinecap="round" strokeWidth="0.8792064"/>
                      <path id="Shape"
                            d="M31.0318387,82.5139354 C31.0318387,80.4880453 29.3895304,78.845737 27.3636403,78.845737 C25.3377503,78.845737 23.6954419,80.4880453 23.6954419,82.5139354 L31.0318387,82.5139354 Z"
                            fill="#20589B"/>
                      <path id="Shape"
                            d="M31.0376448,82.4471654 C31.0376448,81.1366443 30.3384902,79.9256729 29.2035456,79.2704123 C28.0686011,78.6151518 26.6702917,78.6151518 25.5353472,79.2704123 C24.4004026,79.9256729 23.701248,81.1366443 23.701248,82.4471654"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.43202816"/>
                      <path id="Shape"
                            d="M26.4052224,79.0987162 L28.3958784,80.4706099 M26.5089024,80.3714918 L28.3958784,79.2704102"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.65774592"/>
                      <path id="Shape"
                            d="M15.876311,82.7018035 C15.876311,80.6759135 14.2340027,79.0336051 12.2081126,79.0336051 C10.1822226,79.0336051 8.53991424,80.6759135 8.53991424,82.7018035 L15.876311,82.7018035 Z"
                            fill="#20589B"/>
                      <path id="Shape"
                            d="M15.8825318,82.6350336 C15.8825318,80.6091436 14.2402235,78.9668352 12.2143334,78.9668352 C10.1884434,78.9668352 8.54613504,80.6091436 8.54613504,82.6350336"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.43202816"/>
                      <path id="Shape"
                            d="M11.2501094,79.2865843 L13.2403507,80.6584781 M11.3537894,80.55936 L13.2403507,79.4582784"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.65774592"/>
                      <path id="Shape"
                            d="M19.411799,10.3128422 C18.548352,10.2112358 20.6480794,11.9435213 21.9955046,11.705472 C23.1160781,11.5080653 24.3909274,10.6852608 24.3536026,9.95784192 C22.7318504,10.3123271 21.0675675,10.4318828 19.411799,10.3128422 Z"
                            fill="#FFFFFF" stroke="#FFFFFF" strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M19.411799,10.3128422 C18.5495962,10.2016973 20.2366771,12.0754022 22.063104,11.7452851 C23.5208448,11.4819379 24.4158106,10.8250214 24.3536026,9.95784192 C22.7448263,10.3948421 21.0665191,10.5154053 19.411799,10.3128422 Z"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M24.4261786,9.67375872 C24.7044016,10.0073596 24.9023294,10.4003777 25.004713,10.8225331 M19.2085862,10.0424448 C18.9303632,10.3760457 18.7324354,10.7690637 18.6300518,11.1912192"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M15.2869939,79.9376947 L16.094039,79.9115674 L16.4324506,77.3664307 C16.3262822,75.6370483 15.849769,75.8651443 16.7144602,72.8824781 C18.5442048,68.400599 17.9071949,63.6433459 17.9071949,63.6433459 C18.3337761,60.9523937 18.583558,58.2363793 18.654935,55.5127603 L19.6792934,52.1452339 L20.5941658,50.9425459 M11.0891981,43.3726618 L9.88858368,49.1617382 L9.6961536,51.4194739 L9.57298176,57.6626688 C9.03746794,62.2126694 9.07285528,66.8117693 9.67832064,71.3529907 L9.10642176,80.079529 M26.3056896,79.0584883 L24.6202675,79.0327757 L23.8418381,76.4872243 C23.9152939,75.5705341 23.828281,74.6480565 23.5847117,73.7612698 C21.7553818,69.2798054 22.3923917,62.7641395 22.3923917,62.7641395 C22.3923917,62.7641395 20.7583949,54.6547046 20.7418061,51.0126336 M29.5313818,42.5307802 L30.4105882,48.2825318 L30.6030182,50.5402675 L29.8722816,59.4210816 L31.1678669,79.2003226 L30.0294605,79.0730035"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M30.4222003,77.0976922 C30.4222003,77.0976922 26.3600179,76.2798643 24.0114586,77.4813082 M16.2176256,76.9002854 C16.2176256,76.9002854 12.1554432,76.0820429 9.80688384,77.2834867"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M10.904233,38.4839424 L3.68229888,33.250176"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.8792064"/>
                      <path id="Shape"
                            d="M8.31969792,31.0977792 L12.6058291,35.3051136"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.54162432"/>
                      <path id="Shape"
                            d="M27.9508838,16.9587302 L27.1450829,16.975319 L25.004713,16.5357158 L21.8731622,18.3816346 L18.6300518,15.7232794 L16.8965222,16.6452019 L13.8404506,17.3228544 C10.5716275,17.915904 7.56864,21.5915674 7.56864,21.5915674 L0.63120384,30.0684442 C0.382891545,30.3708106 0.272694034,30.7634189 0.327397486,31.150836 C0.382100938,31.5382531 0.596695776,31.8850005 0.91901952,32.106793 L3.5956224,33.413161 L9.09605376,37.2588595 C8.35121664,35.240832 11.0680474,33.6839731 11.0680474,33.6839731 L8.0621568,31.1135386 C6.24402432,29.6972698 8.24297472,28.2586061 8.24297472,28.2586061 C10.7677901,25.8826752 12.4109107,25.9743283 12.4109107,25.9743283"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M20.1122611,50.7422362 L20.1122611,45.8249011"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M8.6033664,27.5332608 C8.6033664,27.5332608 5.89026816,29.1062938 5.58254592,30.8812954"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M28.8325786,35.0795059 C28.2951014,34.8447744 25.9357594,36.142848 25.7271552,40.6773965 M12.9409229,40.0631962 C13.4039411,40.0978653 13.834218,40.3150649 14.1370809,40.667005 C14.4399438,41.0189451 14.590579,41.476791 14.5558426,41.9398042 M14.9701478,39.2308531 C15.9565036,39.2842584 16.7167816,40.1204902 16.6763059,41.1074611 M25.9477862,45.5271322 L29.9038003,47.4033254 M14.784768,45.4682419 L10.8283392,47.3444352"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.65774592"/>
                      <path id="Shape"
                            d="M7.99704576,83.2127386 L16.2508032,83.2127386 M23.2844544,83.2127386 L31.5386266,83.2127386"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.44405504"/>
                      <path id="Shape"
                            d="M21.87648,0.39315456 C21.87648,0.39315456 26.3090074,0.49185792 27.101952,5.92261632 C25.4469798,5.10775706 23.6102272,4.73229459 21.7682381,4.83231744 C21.7682381,4.83231744 19.2409344,4.78752768 16.4610662,6.78150144 C16.4610662,6.78150144 16.5610138,0.9144576 21.87648,0.39315456 Z"
                            fill="#2F73BD"/>
                      <path id="Shape"
                            d="M15.9571814,7.26506496 C15.9571814,7.26506496 22.0618598,2.43482112 27.5792947,7.26506496 M16.4477952,6.68611584 C16.4477952,6.68611584 16.5481574,0.819072 21.863209,0.29776896 C21.863209,0.29776896 26.2957363,0.39647232 27.088681,5.82723072 M21.658752,0.00953856 L22.2115738,0.00953856"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.44405504"/>
                      <g id="Shape" stroke="#001960" strokeLinecap="round"
                         strokeWidth="0.5"
                         transform="translate(18.662400, 0.829440)">
                        <path
                          d="M2.65959936,0.10658304 C2.59632748,0.132819217 2.5344519,0.162303556 2.47421952,0.1949184"/>
                        <path
                          d="M2.11133952,0.41637888 C1.70230815,0.700895342 1.34798734,1.05690206 1.06541568,1.46727936"
                          strokeDasharray="0.4258595180511475,0.4258595180511475,0.4258595180511475,0.4258595180511475"/>
                        <path
                          d="M0.94846464,1.6464384 C0.543403133,2.31897305 0.310632774,3.08110367 0.27081216,3.8651904"
                          strokeDasharray="0.4258595180511475,0.4258595180511475,0.4258595180511475,0.4258595180511475,0.4258595180511475,0.4258595180511475"/>
                        <path
                          d="M0.26044416,4.08125952 C0.25795584,4.14968832 0.2571264,4.21853184 0.2571264,4.28861952"/>
                      </g>
                      <path id="Shape"
                            d="M22.2115738,0.8875008 C22.2115738,0.8875008 24.3142042,1.39926528 24.7152384,5.08902912"
                            stroke="#001960"
                            strokeDasharray="0.4147200107574463,0.4147200107574463,0.4147200107574463"
                            strokeLinecap="round" strokeWidth="0.5"/>
                      <path id="Shape"
                            d="M18.9195264,15.7597747 C18.9195264,15.7597747 19.986601,33.5458714 18.5740646,44.2365235 C18.5740646,44.2365235 13.6600474,44.040361 11.5955712,42.6962534 L12.2039654,40.1179392 C12.2039654,40.1179392 14.870615,40.3676006 15.3185126,39.8981376 C15.3185126,39.8981376 17.1474278,37.9298765 16.0898918,36.2693376 C16.0898918,36.2693376 15.7087642,35.1686707 12.8857651,34.7108198 L12.8857651,24.5638656 C13.7348998,22.2622555 13.6631459,19.7217522 12.6854554,17.4717389 C12.6854554,17.4717389 15.777193,15.5196518 18.9191117,15.75936 L18.9195264,15.7597747 Z"
                            fill="#99CCFF" opacity="0.99"/>
                      <polygon id="Shape" fill="#74A1D0"
                               points="13.5522202 35.8861363 18.4944384 35.8861363 18.4782643 33.167232 13.5360461 33.167232"/>
                      <path id="Shape"
                            d="M14.8287283,36.0375091 L15.1459891,44.3037082 L17.3286605,44.3037082 L17.3286605,35.797801 C17.3286605,35.797801 14.8183603,35.7675264 14.8287283,36.0375091 Z"
                            fill="#74A1D0"/>
                      <path id="Shape"
                            d="M15.4085069,35.9537357 L15.5175782,43.8927206 M17.148672,35.7849446 L17.2577434,44.5533696 M18.5695027,33.250176 L13.6082074,33.250176"
                            stroke="#2F73BD" strokeWidth="0.82944"/>
                      <path id="Shape"
                            d="M30.0730061,34.7108198 L30.0730061,25.2585216 C29.5108913,22.8805313 29.6792775,20.3882714 30.5561549,18.1075046 C30.5561549,18.1075046 27.8355917,16.368169 25.3298534,16.5838234 C25.3298534,16.5838234 24.0400742,34.0236288 24.6928435,44.520192 C24.6928435,44.520192 28.9516032,43.9553434 30.6502963,42.6498048 L30.6502963,41.0012928"
                            fill="#99CCFF" opacity="0.99"/>
                      <path id="Shape"
                            d="M18.620928,36.0839578 L13.78944,36.0839578"
                            stroke="#2F73BD" strokeWidth="0.82944"/>
                      <path id="Shape"
                            d="M12.6891878,34.7108198 C12.6891878,34.7108198 15.6726835,35.1844301 16.0222925,36.2991974 C16.0222925,36.2991974 16.3860019,38.9326694 15.2388864,39.8981376 C14.0410933,39.8827445 12.8464086,39.7723593 11.6660736,39.5680205 C11.6660736,39.5680205 10.907136,39.367296 11.2115405,38.1388954 C11.2115405,38.1388954 11.474473,35.9184845 12.6891878,34.7108198 Z"
                            fill="#FFFFFF"/>
                      <path id="Shape"
                            d="M12.6435686,34.9691904 C12.6435686,34.9691904 15.6270643,35.4428006 15.9766733,36.557568 C15.9766733,36.557568 16.270295,38.9326694 15.1231795,39.8981376 C13.9597215,40.0689012 12.7759439,40.0446538 11.6204544,39.826391 C11.6204544,39.826391 10.8615168,39.6256666 11.1659213,38.3972659 C11.1659213,38.3972659 11.4288538,36.1764403 12.6435686,34.9691904 Z"
                            fill="#D7E9FC"/>
                      <path id="Shape"
                            d="M12.6435686,34.8244531 C12.6435686,34.8244531 15.1235942,35.2756685 15.9766733,36.4128307 C15.9766733,36.4128307 16.8297523,38.5963315 15.2388864,39.8981376 C14.028433,39.9643588 12.8143778,39.8917242 11.6204544,39.6816538 C11.6204544,39.6816538 10.8615168,39.4809293 11.1659213,38.2525286 C11.1659213,38.2525286 11.4288538,36.0321178 12.6435686,34.8244531 Z"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="0.65774592"/>
                      <path id="Shape"
                            d="M14.9825894,17.0723635 L15.2264448,33.167232 L17.2743322,33.167232 L17.3282458,16.6447872 C17.3282458,16.6447872 14.9730509,16.591703 14.9825894,17.0719488 L14.9825894,17.0723635 Z"
                            fill="#74A1D0"/>
                      <path id="Shape"
                            d="M17.3444198,16.1782272 L17.1909734,33.167232 M15.2326656,16.4104704 L15.3496166,33.167232"
                            stroke="#2F73BD" strokeWidth="0.82944"/>
                      <path id="Shape"
                            d="M26.5151232,16.7281459 L26.0394394,32.817623 L28.0135066,32.9047142 L28.7944243,16.4013466 C28.7944243,16.4013466 26.5271501,16.2479002 26.5151232,16.7281459 Z"
                            fill="#74A1D0"/>
                      <path id="Shape"
                            d="M26.7851059,16.077865 L26.1588787,32.8230144 M28.7483904,16.1645414 L28.1217485,32.9096909"
                            stroke="#2F73BD" strokeWidth="0.81451008"/>
                      <path id="Shape"
                            d="M25.8312499,36.0557568 L25.8901402,43.926313 L27.8621338,43.8350746 L28.094377,35.741399 C28.094377,35.741399 25.8271027,35.8201958 25.8312499,36.0553421 L25.8312499,36.0557568 Z"
                            fill="#74A1D0"/>
                      <path id="Shape"
                            d="M26.2629734,35.6746291 L26.192471,43.8753024 M28.2225254,35.790336 L28.1545114,43.7844787"
                            stroke="#2F73BD" strokeWidth="0.81451008"/>
                      <polygon id="Shape" fill="#74A1D0"
                               points="25.0797773 35.7973862 29.9183155 35.7870182 30.0186778 33.0681139 25.066921 33.0784819"/>
                      <path id="Shape"
                            d="M12.8065536,34.2960998 L12.8065536,24.5638656 C13.6555568,22.2622023 13.5836543,19.7216985 12.6058291,17.4717389 C12.6058291,17.4717389 16.1218253,15.5225549 19.263744,15.7630925 C19.263744,15.7630925 19.90656,33.5458714 18.4944384,44.2365235 C18.4944384,44.2365235 13.1661158,43.625641 11.101225,42.2815334 L12.1243392,40.5326592"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.57883904"/>
                      <path id="Shape"
                            d="M29.9830118,33.1510579 L25.0752154,33.1510579 M30.0485376,35.9852544 L25.0333286,35.9852544"
                            stroke="#2F73BD" strokeWidth="0.7402752"/>
                      <path id="Shape"
                            d="M30.7448525,42.4888934 L30.3894374,25.3580544 C30.3894374,25.3580544 29.4716621,21.1959245 30.9447475,17.5032576 C30.9447475,17.5032576 27.5108659,15.5611238 25.004713,15.7763635 C25.004713,15.7763635 23.8911898,33.8627174 24.5443738,44.3592806 C24.5443738,44.3592806 29.0461594,43.794432 30.7444378,42.4888934"
                            stroke="#001960" strokeLinecap="round"
                            strokeWidth="1.39387392"/>
                    </g>
                    <path id="Shape"
                          d="M41.907456,13.9453747 L40.7740262,11.9290061 L38.9641882,11.7009101 L37.6785562,12.2670029 C36.7898112,12.9873715 37.8626918,13.6778803 37.8626918,13.6778803 C35.6642611,15.829033 37.4023526,18.3032525 37.4023526,18.3032525 C37.0606234,18.8154317 37.2476621,19.9472026 37.2476621,19.9472026 C38.9193984,21.2685005 40.1502874,20.3134003 40.1502874,20.3134003 L40.7624141,18.3455539 C41.5101542,17.9196365 41.4338458,17.4240461 41.4338458,17.4240461 C41.4338458,17.4240461 42.1413581,16.5954355 41.907456,13.9453747 Z"
                          fill="#D7E9FC"/>
                    <path id="Shape"
                          d="M39.4448486,30.6917683 L33.7445222,23.3661542 C33.0612524,22.2111207 32.0984645,21.2464787 30.9447475,20.5609882 M30.5345894,27.9745229 C33.0643814,29.1606221 34.5345638,32.548055 34.5345638,32.548055"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="1.48967424"/>
                    <path id="Shape"
                          d="M36.1370419,22.491095 L35.0077594,30.8601446 C33.5823667,36.3485491 37.4338714,35.409623 37.4338714,35.409623 C38.0891924,35.3180592 38.6783535,34.9619222 39.0640032,34.4242383 C39.4496529,33.8865544 39.5980897,33.2143112 39.4747085,32.5642291 L40.442665,20.9699021 L36.4368845,20.2404096 L36.1374566,22.491095 L36.1370419,22.491095 Z"
                          fill="#2F73BD"/>
                    <path id="Shape"
                          d="M36.1370419,22.491095 L35.0077594,30.8601446 C33.5823667,36.3485491 37.4338714,35.409623 37.4338714,35.409623 C38.0891924,35.3180592 38.6783535,34.9619222 39.0640032,34.4242383 C39.4496529,33.8865544 39.5980897,33.2143112 39.4747085,32.5642291 L40.442665,20.9699021 L36.4368845,20.2404096 L36.1374566,22.491095 L36.1370419,22.491095 Z"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="1.48967424"/>
                    <path id="Shape"
                          d="M39.8164378,23.5050854 L36.0744192,22.7527834"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="0.5"/>
                    <path id="Shape"
                          d="M41.907456,14.3518003 L40.7740262,12.3354317 L38.9641882,12.1073357 L37.8954547,12.6481306 C37.006295,13.3684992 37.8626918,13.6695859 37.8626918,13.6695859 C35.6642611,15.8207386 37.4023526,18.2949581 37.4023526,18.2949581 C37.0606234,18.8071373 37.2476621,19.9393229 37.2476621,19.9393229 C38.9193984,21.2602061 40.1502874,20.3051059 40.1502874,20.3051059 L40.7624141,18.3372595 C41.5101542,17.9113421 41.4338458,17.4157517 41.4338458,17.4157517 C41.4338458,17.4157517 42.1413581,16.5871411 41.907456,14.3518003 Z"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="0.62083584"/>
                    <rect height="6.32779776" id="Rectangle-path"
                          width="19.5129907" fill="#FF9E16" rx="1.12057344"
                          x="40.2946099" y="80.6924851"/>
                    <rect height="15.2923853" id="Rectangle-path"
                          width="17.2270541" fill="#FF9E16" rx="1.32046848"
                          x="41.4371635" y="67.1858842"/>
                    <path id="Shape"
                          d="M55.3995418,63.755735 L44.8241818,63.755735 C44.0225294,63.755735 43.3726618,63.1058674 43.3726618,62.304215 C43.3726618,61.5025627 44.0225294,60.852695 44.8241818,60.852695 L55.3995418,60.852695 C56.2011941,60.852695 56.8510618,61.5025627 56.8510618,62.304215 C56.8510618,63.1058674 56.2011941,63.755735 55.3995418,63.755735 Z"
                          fill="#FF9E16"/>
                    <path id="Shape"
                          d="M57.9405312,67.9999795 L56.6963712,67.9999795 L56.6963712,62.6608742 C56.7107533,62.338863 56.4670037,62.0635435 56.145623,62.0387942 L44.077271,62.0387942 C43.7561596,62.0639639 43.5127648,62.3390898 43.5269376,62.6608742 L43.5269376,67.9999795 L42.2827776,67.9999795 L42.2827776,62.6608742 C42.2644986,61.6504322 43.0672986,60.815728 44.0776858,60.7946342 L56.1460378,60.7946342 C57.1560985,60.8161783 57.9583994,61.6507492 57.9401165,62.6608742 L57.9401165,67.9999795 L57.9405312,67.9999795 Z"
                          fill="#FF9E16"/>
                    <g id="Group" fill="#FFFFFF" fontFamily=".AppleSystemUIFont"
                       fontSize="9.95493888" opacity="0.5"
                       transform="translate(50.135162, 72.186396) rotate(-1.000000) translate(-50.135162, -72.186396) translate(43.135162, 64.686396)">
                      <text id="+--">
                        <tspan x="0.00663552" y="10.1994803">+ -</tspan>
                      </text>
                    </g>
                    <path id="Shape"
                          d="M32.3680666,14.8096512 C28.138752,41.9999386 37.3762253,75.9621888 37.9452211,76.238807 C37.9452211,76.238807 40.7134771,82.747008 41.4981274,74.5442611"
                          stroke="#FF9E16" strokeWidth="0.82944"/>
                    <path id="Shape"
                          d="M39.136297,14.5089792 L37.2683981,7.96511232 L39.136297,14.5089792 Z M32.3680666,14.8096512 L34.1219174,8.02109952 L32.3680666,14.8096512 Z M35.520768,2.67992064 C35.520768,2.67992064 35.7003418,0.60632064 35.9205581,2.67992064 L35.9205581,4.23263232 L35.3076019,4.23263232 L35.520768,2.67992064 Z"
                          stroke="#FF9E16" strokeLinecap="round"
                          strokeWidth="2.48832"/>
                    <polyline id="Shape"
                              points="34.1219174 8.37983232 35.3076019 4.64735232 37.2683981 8.37983232"
                              stroke="#FF9E16" strokeWidth="1.24416"/>
                    <path id="Shape"
                          d="M60.2219059,72.2151936 L60.2219059,66.3564442"
                          stroke="#FF9E16" strokeLinecap="round"
                          strokeWidth="2.48832"/>
                    <path id="Shape"
                          d="M60.2219059,66.3672269 L60.2219059,61.2093542"
                          stroke="#FF9E16" strokeWidth="1.24416"/>
                    <path id="Shape"
                          d="M60.2219059,73.221719 C60.2219059,73.221719 61.868759,83.9459635 58.2499123,76.6282291"
                          stroke="#FF9E16" strokeWidth="0.82944"/>
                    <rect height="4.0041216" id="Rectangle-path"
                          width="2.43108864" fill="#FF9E16" rx="1.21554432"
                          x="59.0080205" y="60.0091546"/>
                    <path id="Shape" style={{"mixBlendMode": "multiply"}}
                          d="M57.9401165,69.2184269 L57.9401165,80.2777651 L42.1285018,80.2777651 M56.1356698,62.8868966 L44.0557056,62.8868966 M59.1842765,81.485015 L59.0631782,86.4338688 L42.335447,86.4392602"
                          opacity="0.5" stroke="#F2542A" strokeLinecap="round"
                          strokeWidth="1.24416"/>
                    <g id="Group" stroke="#001960" strokeLinecap="round"
                       strokeWidth="0.62083584"
                       transform="translate(40.227840, 60.963840)">
                      <path id="Shape"
                            d="M18.5197363,19.8431078 L18.4973414,19.8431078 L18.4973414,7.65739008 C18.4952911,6.92896643 17.9052966,6.33897191 17.176873,6.3369216 L2.59075584,6.3369216 C1.86233219,6.33897191 1.27233767,6.92896643 1.27028736,7.65739008 L1.27028736,19.8431078 L1.24789248,19.8431078 C0.62977314,19.8449299 0.129141069,20.3455619 0.12731904,20.9636813 L0.12731904,25.0503322 C0.129141069,25.6684515 0.62977314,26.1690836 1.24789248,26.1709056 L18.5197363,26.1709056 C19.1378557,26.1690836 19.6384877,25.6684515 19.6403098,25.0503322 L19.6403098,20.9636813 C19.6384877,20.3455619 19.1378557,19.8449299 18.5197363,19.8431078 Z M4.6573056,2.90677248 L15.2326656,2.90677248 C16.034318,2.90677248 16.6841856,2.25690484 16.6841856,1.45525248 C16.6841856,0.65360012 16.034318,0.00373248 15.2326656,0.00373248 L4.6573056,0.00373248 C3.85565324,0.00373248 3.2057856,0.65360012 3.2057856,1.45525248 C3.2057856,2.25690484 3.85565324,2.90677248 4.6573056,2.90677248 Z"/>
                    </g>
                    <path id="Shape"
                          d="M40.6467072,81.2216678 L59.0387098,81.2216678 L40.6467072,81.2216678 Z M39.0782362,15.6419942 C38.5228877,15.6414038 38.0351784,15.2728296 37.8830131,14.7387341 L36.0151142,8.19445248 C35.8266114,7.53366189 36.2094763,6.84517355 36.8702669,6.65667072 C37.5310575,6.46816789 38.2195458,6.85103277 38.4080486,7.51182336 L40.2755328,14.056105 C40.3822595,14.4316101 40.3067396,14.8354322 40.0715235,15.1469897 C39.8363074,15.4585472 39.4686137,15.6417872 39.0782362,15.6419942 Z M32.3116646,15.9426662 C31.9268204,15.9422328 31.5637785,15.7639638 31.3281249,15.4597063 C31.0924712,15.1554487 31.0106613,14.7593599 31.1064883,14.3866368 L32.8607539,7.59808512 C32.9656754,7.16078509 33.2984171,6.81414212 33.7310592,6.69142038 C34.1637014,6.56869864 34.6288955,6.68900194 34.9478013,7.0060806 C35.2667072,7.32315927 35.3896815,7.78765444 35.2694477,8.22099456 L33.5151821,15.009961 C33.3731963,15.5587017 32.8784766,15.9422715 32.3116646,15.943081 L32.3116646,15.9426662 Z M37.1079014,5.36481792 L33.8245632,5.36481792 L34.2272563,2.42942976 C34.2857318,1.7542656 34.4205158,0.41472 35.6493312,0.4022784 C36.8980531,0.38859264 37.0195661,1.6713216 37.1008512,2.43648 L37.1079014,2.56794624 L37.1079014,5.36481792 Z"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="0.62083584"/>
                    <path id="Shape"
                          d="M37.9278029,13.7077402 L37.9091405,16.7617382 C37.9091405,16.7617382 37.3077965,17.514455 41.261737,18.0353434 C41.261737,18.0353434 42.7348224,14.5728461 41.1111936,13.025111 C41.1111936,13.025111 40.562519,11.2608922 38.996951,11.7921485 C38.996951,11.7921485 37.7610854,12.1649818 37.4400922,12.9608294 C37.4400922,12.9608294 36.9208627,13.3809408 37.9278029,13.7077402 Z"
                          fill="#FFFFFF"/>
                    <path id="Shape"
                          d="M37.926144,13.7616538 L37.9070669,16.8156518 C37.9070669,16.8156518 37.3057229,17.5683686 41.2596634,18.089257 C41.2596634,18.089257 42.7327488,14.626345 41.10912,13.0790246 C41.10912,13.0790246 40.5604454,11.3148058 38.9948774,11.8460621 C38.9948774,11.8460621 37.7590118,12.2188954 37.4380186,13.014743 C37.4380186,13.014743 36.9187891,13.4348544 37.9257293,13.7616538 L37.926144,13.7616538 Z"
                          fill="#D7E9FC"/>
                    <path id="Shape"
                          d="M37.8626918,13.6695859 L37.8440294,16.723584 C37.8440294,16.723584 37.2426854,17.4763008 41.1966259,17.9971891 C41.1966259,17.9971891 42.7418726,16.2532915 41.8784256,14.0830618 L40.7454106,12.0666931 L38.9351578,11.8385971 L37.6495258,12.4046899 C36.7607808,13.1254733 37.8340762,13.8155674 37.8340762,13.8155674"
                          stroke="#001960" strokeLinecap="round"
                          strokeWidth="0.62083584"/>
                    <polyline id="Shape"
                              points="35.466025 1.7584128 36.0744192 2.36514816 35.1947981 2.79894528 36.0744192 3.587328 35.3076019 4.23263232 35.9205581 4.64735232"
                              stroke="#001960" strokeLinecap="round"
                              strokeWidth="0.5"/>
                  </g>
                  <g id="jumper" transform="translate(274.000000, 123.000000)">
                    <path id="Shape"
                          d="M24.7954262,17.0841667 L10.1634096,17.7316667 C10.1634096,17.7316667 2.0029106,18.6791667 3.25945946,33.7266667 C-0.371725572,34.40375 0.120582121,40.7916667 0.120582121,40.7916667 L0.120582121,44.3879167 C0.120582121,48.5504167 5.04365904,49.6291667 5.04365904,49.6291667 L27.1767152,53.14375 C25.3734655,46.0862008 28.143094,38.649539 34.1196319,34.5014934 C40.0961699,30.3534478 48.0173307,30.3701148 53.9764328,34.5432741 C59.9355349,38.7164335 62.6739741,46.1646848 60.8411642,53.2145833 L149.675676,53.2145833 C147.840969,46.1561419 150.588611,38.6996123 156.560757,34.5299019 C162.532904,30.3601914 170.465017,30.3601914 176.437164,34.5299019 C182.40931,38.6996123 185.156952,46.1561419 183.322245,53.2145833 L187.040333,53.2145833 L194.627859,51.31875 C196.633369,51.3128008 198.257681,49.6851047 198.263617,47.6754167 L199.682744,41.0729167 C199.682744,39.2045833 198.728067,37.6708333 196.914345,37.4695833 C195.936383,32.6933333 188.27526,23.96625 176.659875,22.70125 L142.390852,17.5829167 C142.390852,17.5829167 124.386694,6.05208333 100.325572,1.51666667 C100.325572,1.51666667 82.5097713,-1.95916667 53.1846154,3.09666667 C53.1846154,3.09666667 39.3891892,6.41458333 24.7954262,17.0841667 Z"
                          fill="#FFFFFF"/>
                    <path id="Shape"
                          d="M33.3983368,49.4741667 C33.3983368,55.2304167 38.0553015,59.8966667 43.7995842,59.8966667 C49.5438669,59.8966667 54.2008316,55.23 54.2008316,49.4741667 C54.2008316,43.7179167 49.5438669,39.05125 43.7995842,39.05125 C41.0409681,39.0511395 38.395306,40.1492296 36.4446698,42.1039296 C34.4940337,44.0586296 33.3982265,46.7098034 33.3983368,49.4741667 Z M155.893139,49.1583333 C155.893139,55.0066667 160.624116,59.7479167 166.460707,59.7479167 C172.296881,59.7479167 177.028274,55.00625 177.028274,49.1579167 C177.028274,43.3095833 172.296881,38.5683333 166.460707,38.5683333 C160.6244,38.5683333 155.893139,43.3094513 155.893139,49.1579167 L155.893139,49.1583333 Z"
                          fill="#FFFFFF"/>
                    <path id="Shape"
                          d="M4.95218295,31.6541667 L10.016632,31.6541667 C12.0221419,31.6482174 13.646454,30.0205214 13.6523909,28.0108333 L13.6523909,27.9158333 C13.6466822,25.9059826 12.0223036,24.2780337 10.016632,24.2720833 L6.15301455,24.2720833 C5.78212058,26.205 4.7006237,28.6191667 4.95218295,31.6541667 Z"
                          fill="#F99810"/>
                    <path id="Shape"
                          d="M190.667775,34.6708333 C189.601663,32.4241667 186.991684,29.16625 180.720582,28.4104167 C180.090897,28.3410166 179.493062,28.7035954 179.262365,29.2948067 C179.031667,29.8860179 179.225492,30.5587996 179.735135,30.9358333 C181.872261,32.5213923 183.941208,34.1972085 185.936383,35.95875 C187.138877,37.0220833 188.065281,36.7145833 189.422869,36.6733333 C189.898005,36.6639513 190.336184,36.4144488 190.587429,36.0102249 C190.838673,35.6060009 190.868904,35.1018821 190.667775,34.6704167 L190.667775,34.6708333 Z"
                          fill="#C2DCF3"/>
                    <path id="Shape"
                          d="M96.4390852,24.6195833 C96.4413695,25.4447593 97.1083483,26.1131277 97.9318087,26.1154167 L105.396674,26.1154167 C106.221082,26.1154167 106.889397,25.4457093 106.889397,24.6195833 C106.889397,23.7934574 106.221082,23.12375 105.396674,23.12375 L97.9318087,23.12375 C97.1084428,23.1262666 96.4415966,23.794502 96.4390852,24.6195833 Z M54.9663202,24.9404167 L62.4307692,24.9404167 C63.2551777,24.9404167 63.9234927,24.2707093 63.9234927,23.4445833 C63.9234927,22.6184574 63.2551777,21.94875 62.4307692,21.94875 L54.9663202,21.94875 C54.1419117,21.94875 53.4735967,22.6184574 53.4735967,23.4445833 C53.4735967,24.2707093 54.1419117,24.9404167 54.9663202,24.9404167 Z"
                          fill="#001E60"/>
                    <path id="Shape"
                          d="M93.8507277,6.25 C94.1891892,7.95 96.3197505,18.6033333 96.3197505,18.6033333 L134.664033,19.0629167 C134.664033,19.0629167 118.63368,8.85291667 94.7376299,5.29666667 C94.2615385,5.22583333 93.631185,5.14666667 93.8507277,6.25041667 L93.8507277,6.25 Z M51.6906445,18.2733333 L88.6407484,18.3558333 C88.900538,18.3564088 89.1473008,18.2419042 89.3149125,18.0430032 C89.4825241,17.8441023 89.5538479,17.5811405 89.5097713,17.3245833 L87.5484407,5.9225 C87.4770482,5.49883047 87.110269,5.18910687 86.6814969,5.19041667 C78.4182952,5.22125 68.5180873,5.39 60.783368,7.31541667 C60.6283581,7.35417077 60.4866957,7.43421989 60.3733888,7.54708333 L51.0740125,16.765 C50.8207747,17.0161369 50.7436758,17.395525 50.8787391,17.7259012 C51.0138025,18.0562773 51.3343633,18.2724174 51.6906445,18.2733333 Z"
                          fill="#C2DCF3"/>
                    <path id="Shape"
                          d="M139.128898,46.8283333 L68.7060291,46.8283333"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.56805632"/>
                    <path id="Shape"
                          d="M33.3983368,49.4741667 C33.3983368,55.2304167 38.0553015,59.8966667 43.7995842,59.8966667 C49.5438669,59.8966667 54.2008316,55.23 54.2008316,49.4741667 C54.2008316,43.7179167 49.5438669,39.05125 43.7995842,39.05125 C41.0409681,39.0511395 38.395306,40.1492296 36.4446698,42.1039296 C34.4940337,44.0586296 33.3982265,46.7098034 33.3983368,49.4741667 Z"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="5.18939136"/>
                    <path id="Shape"
                          d="M197.114345,39.92625 L191.624532,39.92625"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.56805632"/>
                    <path id="Shape"
                          d="M8.64532225,35.715678 L4.26195426,35.24875"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.17904896"/>
                    <path id="Shape"
                          d="M52.7118503,8.1525 L41.6752599,18.4216667 L31.7426195,18.4216667 C31.7426195,18.4216667 42.9367983,10.3641667 52.7114345,8.1525 L52.7118503,8.1525 Z"
                          fill="#C2DCF3"/>
                    <path id="Shape"
                          d="M94.8074844,48.9141667 L94.8074844,28.375"/>
                    <path id="Shape"
                          d="M66.9330561,6.175 L61.381289,10.4370833 C61.1068779,10.6408281 60.963372,10.9769786 61.0058308,11.3165624 C61.0482895,11.6561462 61.2701192,11.9464276 61.5862096,12.076033 C61.9023,12.2056385 62.2635636,12.1544408 62.5313929,11.9420833 L70.5675676,5.77291667 C69.3535176,5.8813422 68.1418833,6.01539975 66.9334719,6.175 L66.9330561,6.175 Z M73.029106,5.57833333 L67.6723493,9.87166667 C67.5350562,9.97574668 67.4650054,10.1463358 67.4894354,10.3171028 C67.5138653,10.4878697 67.6289208,10.6318671 67.7898641,10.6931034 C67.9508073,10.7543398 68.1322412,10.7231519 68.2636175,10.6116667 L74.6702703,5.47708333 C74.1230057,5.50732629 73.5759397,5.54107698 73.029106,5.57833333 Z"
                          fill="#E5F0FA"/>
                    <path id="Shape"
                          d="M155.893139,49.1583333 C155.893139,55.0066667 160.624116,59.7479167 166.460707,59.7479167 C172.296881,59.7479167 177.028274,55.00625 177.028274,49.1579167 C177.028274,43.3095833 172.296881,38.5683333 166.460707,38.5683333 C160.6244,38.5683333 155.893139,43.3094513 155.893139,49.1579167 L155.893139,49.1583333 Z"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="5.18939136"/>
                    <path id="Shape"
                          d="M24.9634096,48.1241667 L6.04365904,45.28"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.17904896"/>
                    <polyline id="Shape"
                              points="90.7933472 6.095 94.0191268 25.6891667 94.0191268 44.3320833"
                              stroke="#001E60" strokeLinecap="round"
                              strokeWidth="1.41543936"/>
                    <path id="Shape"
                          d="M142.421622,18.4216667 L142.421622,54.1275"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="0.943488"/>
                    <g id="Group" fill="#E5F0FA"
                       transform="translate(95.634096, 11.666667)">
                      <path id="Shape"
                            d="M4.63035343,6.98375 L7.81455301,4.43166667 C7.95184611,4.32758665 8.02189684,4.15699752 7.99746691,3.98623056 C7.97303697,3.8154636 7.85798148,3.67146622 7.69703821,3.61022989 C7.53609494,3.54899355 7.35466111,3.58018148 7.22328482,3.69166667 L3.13804574,6.96583333 L4.63035343,6.98375 Z M0.59002079,6.45666667 L6.5002079,1.92 C6.91505297,1.60174591 6.99389181,1.006751 6.67629938,0.591041667 C6.35870694,0.175332333 5.76494902,0.0963292462 5.35010395,0.414583333 L0.175883576,4.38625 L0.59002079,6.45708333 L0.59002079,6.45666667 Z"/>
                    </g>
                    <path id="Shape"
                          d="M55.0827443,8.70166667 L40.4141372,24.1095833 L40.2565489,27.90125 M97.2656965,18.9191667 L134.663617,19.3791667 M50.9762994,18.5845833 L89.6873181,18.6741667 M40.7293139,18.7375 L31.2698545,18.7375"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.41543936"/>
                    <path id="Shape"
                          d="M142.390852,17.5829167 C142.390852,17.5829167 124.386694,6.05208333 100.325572,1.51666667 C100.325572,1.51666667 82.5097713,-1.95916667 53.1846154,3.09666667 C53.1846154,3.09666667 39.3891892,6.41458333 24.7954262,17.0841667 L10.1634096,17.73125 C10.1634096,17.73125 2.0029106,18.68 3.25945946,33.7270833 C-0.371725572,34.40375 0.120582121,40.7916667 0.120582121,40.7916667 L0.120582121,44.3879167 C0.120582121,48.5504167 5.04365904,49.6291667 5.04365904,49.6291667 L27.1767152,53.14375 C25.3734655,46.0862008 28.143094,38.649539 34.1196319,34.5014934 C40.0961699,30.3534478 48.0173307,30.3701148 53.9764328,34.5432741 C59.9355349,38.7164335 62.6739741,46.1646848 60.8411642,53.2145833 L149.675676,53.2145833 C147.840969,46.1561419 150.588611,38.6996123 156.560757,34.5299019 C162.532904,30.3601914 170.465017,30.3601914 176.437164,34.5299019 C182.40931,38.6996123 185.156952,46.1561419 183.322245,53.2145833 L187.040333,53.2145833 L194.627859,51.31875 C196.633369,51.3128008 198.257681,49.6851047 198.263617,47.6754167 L199.682744,41.0729167 C199.682744,39.2045833 198.728067,37.6708333 196.914345,37.4695833"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="4.24590336"/>
                    <path id="Shape"
                          d="M191.201663,0.785833333 C185.449695,-0.456333927 179.448488,0.262237522 174.15052,2.8275 L142.517256,16.7245833"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="4.23263232"/>
                    <path id="Shape"
                          d="M191.90104,30.6745833 C187.81081,26.5684228 182.445581,23.9826622 176.69106,23.3441667 L142.517256,18.3175"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="1.41087744"/>
                    <path id="Shape"
                          d="M196.914761,36.4291667 C196.494802,34.4 194.861538,31.6566667 192.227859,29.0816667"
                          stroke="#001E60" strokeLinecap="round"
                          strokeWidth="4.23263232"/>
                  </g>
                  <g id="Road" transform="translate(2.000000, 184.000000)">
                    <path id="Stroke-3" d="M164,2 L197,2" stroke="#001E60"
                          strokeLinecap="round" strokeWidth="4"/>
                    <path id="Fill-5"
                          d="M158,2.00022235 C158,3.10439133 157.104491,4 156,4 C154.895509,4 154,3.10439133 154,2.00022235 C154,0.895608671 154.895509,0 156,0 C157.104491,0 158,0.895608671 158,2.00022235"
                          fill="#001E60"/>
                    <polyline id="Stroke-7" points="207 2 294.729803 2 820 2"
                              stroke="#001E60" strokeLinecap="round"
                              strokeWidth="4"/>
                    <polyline id="Stroke-7" points="0 2 21.1810944 2 148 2"
                              stroke="#001E60" strokeLinecap="round"
                              strokeWidth="4"/>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}
