import axios from 'axios';
import partnerCodeHandler, { splashPageHandler } from "./interceptor";

axios.interceptors.request.use(request => partnerCodeHandler(request));

axios.interceptors.response.use(splashPageHandler, error => {
  return Promise.reject(error);
})

let url = window.location.href;
let isLayer7 = url.indexOf("https://roadside.allstate.com")===-1?'true':'false';

const endpoint_url = process.env.REACT_APP_LOCAL ? "http://localhost:8883" : "";
const access_token = "/ppu/api/tokenizer?isLayer7="+isLayer7;
const api_initialize = "/payment/initialize";
const api_status = "/payment/status";

const tokenizer = {
  "properties": {
    "applicationId": {
      "type": ""
    },
    "clientId": {
      "type": ""
    },
    "cardNumber": {
      "type": ""
    },
    "creditCardExpirationDt": {
      "type": ""
    },
    "paymentMerchantId": {
      "type": ""
    },
    "postalCode": {
      "_ref": ""
    },
    "sessionId": {
      "type": ""
    },
    "token": {
      "type": ""
    },
    "validateAndTokenizeRqExt": {
      "_ref": ""
    }
  },
  "type": ""
};

export function getEPCSTokenizer(personalinfo) {
  var expDate = personalinfo.payload.cardExpiryDate;
  var ccExpDate = expDate.replace("/","");
  tokenizer.properties.cardNumber.type = personalinfo.payload.cardNumber;
  tokenizer.properties.creditCardExpirationDt.type = ccExpDate;
  tokenizer.properties.postalCode._ref = personalinfo.payload.zipCode;
  tokenizer.properties.token.type = localStorage.getItem('accessToken');
  return axios.post(`${endpoint_url}${access_token}`, tokenizer , {timeout: 30000}, { headers: {'Content-Type': 'application/json;charset=utf-8'} });
}

export function initialize(partnerCode, promoCode ){
  return axios.get(`${endpoint_url}${api_initialize}`, { headers: {'promocode' : promoCode}, partnerCode, handlerEnabled: true});
}

export function status(statusRequestBody, partnerCode, appId, omniStreamlined, towReason, towDistance, promoCode){
  let url = `${endpoint_url}${api_status}`
  let urlSearchParams = [];
  if (!!towReason) {
    urlSearchParams.push([ "towReason", towReason ])
  }
  if (!!towDistance) {
    urlSearchParams.push([ "towDistance", towDistance ])
  }
  if(urlSearchParams.length > 0) {
    url += `?${urlSearchParams.map(([key, value]) => `${key}=${value}`).join("&")}`
  }
  return axios.post(url, statusRequestBody,
    { headers:
        { 'appid': appId,
          'streamlined': omniStreamlined,
          'promocode': promoCode,
        }, partnerCode, handlerEnabled: true});
}
