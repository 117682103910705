import React from 'react';
import {datadogRum} from '@datadog/browser-rum';

import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { useLocale } from '../../contexts/LocaleContext';
import {generatePath, redirectNavigationUrl} from './../../utilities/RedirectUtils';
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {refreshReducersToIntial} from "../../action";
import {getPartnerDetails} from "../../selector";
import {saveRescueDetails} from "../../api";

const NavigateWarning = ({closeModal, displayCode, serviceRequestPayload}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getTranslatedText = useLocale()
  const partnerDetails = useSelector(getPartnerDetails)

  const resetUserExperienceToBeginning = () => {
    datadogRum.addAction('User has reset their experience');
    if(partnerDetails?.omniStreamlined) {
      saveRescueDetails(serviceRequestPayload, partnerDetails.partnerCode, 'end')
    }
    let conversationId = localStorage.getItem('conversationId')
    let jobId = localStorage.getItem('jobId')
    localStorage.clear()
    if (conversationId) localStorage.setItem('conversationId', conversationId)
    if (jobId) localStorage.setItem('jobId', jobId)

    let routeScreen = window.location.pathname.split('/')[3];
    let url = redirectNavigationUrl(partnerDetails);

    url === routeScreen ? history.go(0) : history.push(generatePath(url, displayCode));
    closeModal();
    dispatch(refreshReducersToIntial())
  }

  return (
    <div className="ghr-modal">
      <div className="ghr-modal-section">
        {getTranslatedText(TRANSLATION_CONSTANTS.LEAVE_PAGE_INFO)}
      </div>
      <div className="ghr-btn">
        <button className="btn-no" onClick={closeModal}>{getTranslatedText(TRANSLATION_CONSTANTS.NO)}</button>
        <button className="btn-yes" onClick={resetUserExperienceToBeginning}>{getTranslatedText(TRANSLATION_CONSTANTS.YES)}</button>
      </div>
    </div>
  );
}
export default NavigateWarning
