import {takeEvery, put, call, select, all} from 'redux-saga/effects';
import {datadogRum} from "@datadog/browser-rum";
import {
  REQUESTED_PARTNER_DETAILS,
  requestPartnerDetailsSuccess,
  requestPartnerDetailsFailed,
  updateServiceDetails,
  updateAppId
} from '../action';
import {getPartnerDetailsApi} from '../api';
import * as selectors from "../selector";

export function* getPartnerDetails({partnerCode}) {
  try {
    const {displayCode, route} = yield select(selectors.getPartnerDetails);
    const partnerCodeChanged = partnerCode !== undefined && partnerCode !== displayCode;
    const partner = partnerCodeChanged ? partnerCode : displayCode;
    const response = yield call(getPartnerDetailsApi, route, partner);
    const serviceCost = response.data.experience?.serviceCost || {};
    yield all([
      put(updateServiceDetails(serviceCost)),
      put(requestPartnerDetailsSuccess(response.data)),
      put(updateAppId(response.data?.appId))
    ])
  } catch (e) {
    datadogRum.addError(new Error(`Could not get partner details ${e}`))
    yield put(requestPartnerDetailsFailed(e));
  }
}

export function* watchPartnerDetailsSaga() {
  yield takeEvery(REQUESTED_PARTNER_DETAILS, getPartnerDetails);
}
