
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

export const english_translation = {
    [TRANSLATION_CONSTANTS.CLOCK_UPDATED]: "This page was last updated at {{h:mm:ss A}}",
    [TRANSLATION_CONSTANTS.WELL_TAKE_IT_FROM_HERE]: "Ok, we'll take it from here",
    [TRANSLATION_CONSTANTS.TRACK_AND_MANAGE]: "Track and manage your rescue from this page",
    [TRANSLATION_CONSTANTS.TRACKING_YOUR_RESCUE]: "Track Your Rescue",
    [TRANSLATION_CONSTANTS.FINDING_NEARBY_RESCUER]: "We’re finding a nearby rescuer",
    [TRANSLATION_CONSTANTS.WELL_LET_YOU_KNOW]: "We’ll let you know as soon as we assign someone to help.",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE]: "cancel rescue",
    [TRANSLATION_CONSTANTS.REQUEST_SUMMARY]: "Request Summary",
    [TRANSLATION_CONSTANTS.REFERENCE_NUMBER]: "Reference Number",
    [TRANSLATION_CONSTANTS.PICKUP_LOCATION]: "Pick-up location",
    [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION]: "Disablement Location",
    [TRANSLATION_CONSTANTS.DESTINATION_LOCATION]: "Drop-off location",
    [TRANSLATION_CONSTANTS.EXCHANGE_VEHICLE]: "Exchange vehicle",
    [TRANSLATION_CONSTANTS.NEW_VEHICLE_PICKUP_LOCATION]: "Replacement vehicle pick-up location",
    [TRANSLATION_CONSTANTS.NEW_VEHICLE_DROP_0FF_LOCATION]: "Replacement vehicle drop-off location",
    [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_PICKUP_LOCATION]: "Disabled vehicle pick-up location",
    [TRANSLATION_CONSTANTS.DISABLED_VEHICLE_DROP_0FF_LOCATION]: "Disabled vehicle drop-off location",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_LOCATION]: "Passenger drop-off location",
    [TRANSLATION_CONSTANTS.PROVIDER]: "Assigned Provider",
    [TRANSLATION_CONSTANTS.CALL]: "Call",
    [TRANSLATION_CONSTANTS.WEVE_FOUND_A_RESCUER]: "We’ve found a rescuer",
    [TRANSLATION_CONSTANTS.NOTIFY_OF_PROVIDER_FOUND]: "A service provider has been found. We'll let you know when they are on the way.",
    [TRANSLATION_CONSTANTS.RESCUER_ON_WAY]: "Rescuer is on the way",
    [TRANSLATION_CONSTANTS.STAY_WITH_VEHICLE]: "Your rescuer is on the way. Continue to stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.RESCUER_ARRIVED]: "Rescuer has arrived",
    [TRANSLATION_CONSTANTS.RESCUER_WITH_VEHICLE]: "Help has arrived. Confirm your service provider matches the information below.",
    [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED]: "Mission Accomplished!",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETED]: "Your rescue has been completed",
    [TRANSLATION_CONSTANTS.VEHICLE_DROPPED_OFF]: "Your vehicle has been dropped off",
    [TRANSLATION_CONSTANTS.BACK_ON_THE_ROAD]: "Now it’s all about getting you back on the road.",
    [TRANSLATION_CONSTANTS.ASSIGNED]: "Assigned",
    [TRANSLATION_CONSTANTS.EN_ROUTE]: "En Route",
    [TRANSLATION_CONSTANTS.ON_SITE]: "On Site",
    [TRANSLATION_CONSTANTS.COMPLETE]: "Complete",
    [TRANSLATION_CONSTANTS.FINDING_PROVIDER]: "Finding provider",
    [TRANSLATION_CONSTANTS.PROVIDER_ASSIGNED]: "Provider assigned",
    [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT]: "Picking up replacement",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE]: "Provider en route",
    [TRANSLATION_CONSTANTS.PROVIDER_ARRIVED]: "Provider arrived",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF]: "Passenger drop off",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE]: "Rescue complete",
    [TRANSLATION_CONSTANTS.PICKING_REPLACEMENT_DESC]: "Your service provider is on the way to pick up your replacement vehicle. They'll be en route to your location soon.",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_DESC]: "Your service provider is on the way with your replacement vehicle. Stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.PROVIDER_EN_ROUTE_PASSENGER]: "Your service provider is on the way. Continue to stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.PASSENGER_DROP_OFF_DESC]: "The passenger has been dropped off at the passenger drop-off location.",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_DESC]: "Your rescue is complete and your vehicle was dropped off at the rental company.",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_REVERSE_TOW]: "Your rescue is complete and your vehicle has been delivered.",
    [TRANSLATION_CONSTANTS.RESCUE_COMPLETE_PASSENGER_DESC]: "Your rescue is complete and your vehicle has been dropped off.",
    [TRANSLATION_CONSTANTS.REPLACEMENT_VEHICLE_DELIVERY]: "Replacement vehicle delivery",
    [TRANSLATION_CONSTANTS.DROPPED_OFF]: "Dropped Off",
    [TRANSLATION_CONSTANTS.SERVICE_INCOMPLETE]: "Service Incomplete",
    [TRANSLATION_CONSTANTS.GONE_ON_ARRIVAL]: "The rescuer was on site and was unable to contact you or couldn't locate the vehicle. We will contact you shortly to see if you still need service.",
    [TRANSLATION_CONSTANTS.RESCUE_STEP]: "Rescue Step",
    [TRANSLATION_CONSTANTS.ROADSIDE_ASSISTANCE]: "Roadside Assistance",
    [TRANSLATION_CONSTANTS.PROVIDED_BY]: "Provided by",
    [TRANSLATION_CONSTANTS.PRIVACY_POLICY]: "Privacy Policy",
    [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION]: "SMS Terms of Service",
    [TRANSLATION_CONSTANTS.NOTICE_AT_COLLECTION]: "Notice at Collection",
    [TRANSLATION_CONSTANTS.WEBSITE_ACCESSIBILITY]: "Website Accessibility",
    [TRANSLATION_CONSTANTS.CALCULATED_NO_GPS]: "Sometimes there are unexpected delays but we’re targeting the above ETA. Continue to stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.CALCULATED_GPS]: "Based on current location and traffic in the area, we’re targeting the above ETA. Continue to stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.ETA_UPDATED]: "Quick update, your rescuer is now set to arrive in {projectedETAMinute}. We’ll continue to work with them to ensure they remain on track.",
    [TRANSLATION_CONSTANTS.MOBILE_APP_LABEL]: "Download the {appName} app to connect with us later!",
    [TRANSLATION_CONSTANTS.SERVICE_CANCELLED]: "Service Cancelled",
    [TRANSLATION_CONSTANTS.HELP_TEXT]: "For help, open the {appName} app and Call Driver Care.",
    [TRANSLATION_CONSTANTS.CANCEL_ERROR]: "Sorry, we are unable to cancel your service",
    [TRANSLATION_CONSTANTS.RESCUE_STATUS_ERROR]: "Sorry, we are unable to retrieve your rescue information at this time. We'll check again in {timeLeft} seconds.",
    [TRANSLATION_CONSTANTS.FAMILY_AND_FRIENDS]: "Let family and friends track your rescue and see live updates.",
    [TRANSLATION_CONSTANTS.SHARE]: "Share",
    [TRANSLATION_CONSTANTS.TEXT]: "Text",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_CONFIRMATION_HEADER]: "Are you sure you want to cancel?",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_BODY]: "If so, what is the reason for cancellation?",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT1]: "Service no longer needed",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT2]: "Unacceptable ETA",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_AFTER_CONFIRMATION_OPTION_TEXT3]: "Other",
    [TRANSLATION_CONSTANTS.CANCEL_RESCUE_BUTTON_TEXT]:" yes, cancel",
    [TRANSLATION_CONSTANTS.VIN_FIND_INSTRUCTIONS]: "The VIN is often on the driver's side door or door frame. Also, check your insurance card or vehicle registration.",
    [TRANSLATION_CONSTANTS.HOW_HELP_TEXT]: "How can we help you?",
    [TRANSLATION_CONSTANTS.TOW]: "Tow",
    [TRANSLATION_CONSTANTS.LOCKOUT]: "Lockout",
    [TRANSLATION_CONSTANTS.FUEL_DELIVERY]: "Fuel Delivery",
    [TRANSLATION_CONSTANTS.TIRE_CHANGE]: "Tire Change",
    [TRANSLATION_CONSTANTS.JUMP_START]: "Jump Start",
    [TRANSLATION_CONSTANTS.MORE_OPTIONS]: "More options",
    [TRANSLATION_CONSTANTS.CONFIRM_DROP_OFF_LOCATION]: "confirm drop-off location",
    [TRANSLATION_CONSTANTS.LOCATION_IS]: "Because this location is",
    [TRANSLATION_CONSTANTS.INSTRUCTIONS_CUSTOMER_SERVICE]: "you'll need to speak with a Customer Service Representative to request your service.",
    [TRANSLATION_CONSTANTS.CALL_CUSTOMER_SERVICE]: "Call Customer Service",
    [TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE]: "Estimated Amount Due",
    [TRANSLATION_CONSTANTS.MILES]: "miles",
    [TRANSLATION_CONSTANTS.MILE]: "mile",
    [TRANSLATION_CONSTANTS.KILOMETERS]: "kilometers",
    [TRANSLATION_CONSTANTS.KILOMETER]: "kilometer",
    [TRANSLATION_CONSTANTS.MILES_INCLUDED]: "Miles Included",
    [TRANSLATION_CONSTANTS.KILOMETERS_INCLUDED]: "Kilometers Included",
    [TRANSLATION_CONSTANTS.ADDITIONAL_MILES]: "Additional Miles",
    [TRANSLATION_CONSTANTS.ADDITIONAL_KILOMETERS]: "Additional Kilometers",
    [TRANSLATION_CONSTANTS.OVER2]: "over",
    [TRANSLATION_CONSTANTS.ESTIMATED_MINIMUM_DUE]: "Estimated Minimum Due",
    [TRANSLATION_CONSTANTS.CONFIRM_CONTACT]: "confirm contact",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE]: "confirm vehicle",
    [TRANSLATION_CONSTANTS.COLOR]: "Color",
    [TRANSLATION_CONSTANTS.FUEL_TYPE]: "Fuel Type",
    [TRANSLATION_CONSTANTS.UNLEADED]: "Unleaded",
    [TRANSLATION_CONSTANTS.DIESEL]: "Diesel",
    [TRANSLATION_CONSTANTS.DELIVER_UP_TO]: "We will deliver up to",
    [TRANSLATION_CONSTANTS.COST_FUEL_NOT_INCLUDED]: "Cost of fuel is not included.",
    [TRANSLATION_CONSTANTS.ADD_CONTACT]: "Add Contact",
    [TRANSLATION_CONSTANTS.NAME]: "Name",
    [TRANSLATION_CONSTANTS.EMAIL]: "Email",
    [TRANSLATION_CONSTANTS.PHONE_NUMBER]: "Phone Number",
    [TRANSLATION_CONSTANTS.PROVIDE_PHONE_NUMBER]: "Please provide your phone number to allow the Roadside Assistance Center to contact you or to confirm service.",
    [TRANSLATION_CONSTANTS.SEND_TEXT_MESSAGES]: "Send text messages about my rescue status.",
    [TRANSLATION_CONSTANTS.CONFIRM]: "confirm",
    [TRANSLATION_CONSTANTS.SUBMIT_REQUEST]: "submit request",
    [TRANSLATION_CONSTANTS.SCHEDULE_A_RIDE]: "Need a ride?",
    [TRANSLATION_CONSTANTS.HAVE_LYFT_READY]: "Have a Lyft ready for you once you’ve been helped.",
    [TRANSLATION_CONSTANTS.SCHEDULE]: "Schedule",
    [TRANSLATION_CONSTANTS.HOW_EMBARRASSING]: "How embarrassing",
    [TRANSLATION_CONSTANTS.TECHNICAL_DIFFICULTIES]: "We're having technical difficulties that we're working on getting fixed as soon as possible. Please check back later.",
    [TRANSLATION_CONSTANTS.CALL_HELP_NOW]: "call for help now",
    [TRANSLATION_CONSTANTS.CALL_FOR_ASSISTANCE]: "call for assistance",
    [TRANSLATION_CONSTANTS.ENTER_INFO_AS_ON_ACCOUNT]: "Please enter your last name exactly as it appears on your account (O'Connor vs. OConnor)",
    [TRANSLATION_CONSTANTS.SORRY_NOT_FIND_BENEFITS]: "Sorry, we can’t find your benefits information",
    [TRANSLATION_CONSTANTS.CAPTURE_ACTUAL_MILEAGE]: "Capture actual mileage (not trip mileage) on your dashboard or information center.",
    [TRANSLATION_CONSTANTS.ENTER_CURRENT_LOCATION]: "Enter Current Location",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_LOCATION]: "confirm vehicle location",
    [TRANSLATION_CONSTANTS.GEOLOCATION_DISABLED]: "It seems location services are turned off, please enter an address",
    [TRANSLATION_CONSTANTS.HOME]: "Home",
    [TRANSLATION_CONSTANTS.CALL_FOR_HELP]: "call for help",
    [TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO]: "Please provide your vehicle info",
    [TRANSLATION_CONSTANTS.STATE]: "State",
    [TRANSLATION_CONSTANTS.ENTER_STATE]: "Enter State",
    [TRANSLATION_CONSTANTS.SELECT_A_STATE]: "Please select a State",
    [TRANSLATION_CONSTANTS.LICENSE_PLATE_NUMBER]: "License Plate Number",
    [TRANSLATION_CONSTANTS.INVALID_LICENSE_PLATE_NUMBER]: "Invalid license plate number",
    [TRANSLATION_CONSTANTS.CONTINUE]: "continue",
    [TRANSLATION_CONSTANTS.OR]: "OR",
    [TRANSLATION_CONSTANTS.SEARCH_BY_VIN]: "Search By VIN",
    [TRANSLATION_CONSTANTS.VIN]: "VIN",
    [TRANSLATION_CONSTANTS.ENTER_VALID_VIN]: "Please enter a valid VIN",
    [TRANSLATION_CONSTANTS.ODOMETER]: "Odometer",
    [TRANSLATION_CONSTANTS.ENTER_VALID_MILEAGE]: "Please enter a valid mileage",
    [TRANSLATION_CONSTANTS.SEARCH_BY_LICENSE_PLATE]: "Search By License Plate",
    [TRANSLATION_CONSTANTS.ALL_RIGHT_RESERVED]: "All Rights Reserved. Terms and Conditions Apply.",
    [TRANSLATION_CONSTANTS.IS_INFORMATION_CORRECT]: "Is this information correct?",
    [TRANSLATION_CONSTANTS.YES]: "Yes",
    [TRANSLATION_CONSTANTS.NO]: "No",
    [TRANSLATION_CONSTANTS.IS_WHERE_YOUR_VEHICLE_IS]: "Is this where your vehicle is located?",
    [TRANSLATION_CONSTANTS.ADD_LOCATION_DETAILS]: "Add Location Details",
    [TRANSLATION_CONSTANTS.EXAMPLE_STREET]: "Example: Street name, nearby stores, landmarks, etc.",
    [TRANSLATION_CONSTANTS.SAVE]: "save",
    [TRANSLATION_CONSTANTS.CHARACTERS_REMAINING]: "Characters remaining",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND]: "It looks as though you haven't signed up for roadside benefits yet. You can either pay for just today's service, or call us to ask about a membership.",
    [TRANSLATION_CONSTANTS.NO_BENEFITS_AVAILABLE]: "No Benefits Available",
    [TRANSLATION_CONSTANTS.DID_NOT_PURCHASE_COVERAGE]: "You didn't purchase coverage",
    [TRANSLATION_CONSTANTS.COVERAGE_UP_TO]: "Coverage up to",
    [TRANSLATION_CONSTANTS.TELL_WHICH_BEST_DESCRIBES_SITUATION]: "Please tell us which best describes your situation.",
    [TRANSLATION_CONSTANTS.STUCK_IN_DITCH]: "I'm stuck in a ditch",
    [TRANSLATION_CONSTANTS.CAR_NEEDS_RECHARGING]: "My electric car needs recharging",
    [TRANSLATION_CONSTANTS.SELECT_TOW_LOCATION]: "Select Tow Location",
    [TRANSLATION_CONSTANTS.SELECT_OR_ENTER_TOW_DESTINATION]: "Select or Enter Tow Destination",
    [TRANSLATION_CONSTANTS.NO_REPAIR_CENTERS_FOUND]: "Can't find a repair center.",
    [TRANSLATION_CONSTANTS.SELECT_ONE]: "Select One",
    [TRANSLATION_CONSTANTS.BLACK]: "Black",
    [TRANSLATION_CONSTANTS.BLUE]: "Blue",
    [TRANSLATION_CONSTANTS.BROWN]: "Brown",
    [TRANSLATION_CONSTANTS.BURGUNDY]: "Burgundy",
    [TRANSLATION_CONSTANTS.GOLD]: "Gold",
    [TRANSLATION_CONSTANTS.GREEN]: "Green",
    [TRANSLATION_CONSTANTS.GREY]: "Grey",
    [TRANSLATION_CONSTANTS.ORANGE]: "Orange",
    [TRANSLATION_CONSTANTS.PURPLE]: "Purple",
    [TRANSLATION_CONSTANTS.RED]: "Red",
    [TRANSLATION_CONSTANTS.SILVER]: "Silver",
    [TRANSLATION_CONSTANTS.WHITE]: "White",
    [TRANSLATION_CONSTANTS.YELLOW]: "Yellow",
    [TRANSLATION_CONSTANTS.WHICE_DRIVER_NEEDS_HELP]: "Please select which driver needs help today.",
    [TRANSLATION_CONSTANTS.VEHICLE]: "Vehicle",
    [TRANSLATION_CONSTANTS.PAYMENT]: "Payment",
    [TRANSLATION_CONSTANTS.COVERED_BY_BENEFITS]: "Covered By Benefits",
    [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED]: "The final cost will be determined once your rescuer has been assigned. Any amount exceeding your benefits must be paid directly to the rescuer.",
    [TRANSLATION_CONSTANTS.TOTAL]: "Total",
    [TRANSLATION_CONSTANTS.WHERE_ARE_YOUR_KEYS]: "Where are your keys?",
    [TRANSLATION_CONSTANTS.IN_MY_VEHICLE]: "In my vehicle",
    [TRANSLATION_CONSTANTS.IN_MY_TRUNK]: "In my trunk",
    [TRANSLATION_CONSTANTS.LOST_BROKEN]: "Lost / Broken",
    [TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_INFORMATION]: "Please confirm your vehicle information.",
    [TRANSLATION_CONSTANTS.WE_ARE_SORRY]: "We’re sorry",
    [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTINUE_PPU]: "The vehicle you selected does not currently have roadside coverage. Please continue as a pay-per-use guest.",
    [TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE]: "Coverage for this vehicle is suspended. Contact our representative to request service.",
    [TRANSLATION_CONSTANTS.WHICH_TIRE_IS_FLAT]: "Which tire is flat?",
    [TRANSLATION_CONSTANTS.HAVE_A_SPARE_TIRE]: "Do you have a spare tire?",
    [TRANSLATION_CONSTANTS.LEAVE_PAGE_INFO]: "If you leave this page the information you have entered will be deleted and your service request will not be processed. Do you still want to leave this page?",
    [TRANSLATION_CONSTANTS.VEHICLE_STOP_DRIVING]: "Did your vehicle stop while driving?",
    [TRANSLATION_CONSTANTS.BATTER_MOST_LIKELY_PROBLEM]: "A jump start may not work.",
    [TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS]: "No worries, it happens all the time",
    [TRANSLATION_CONSTANTS.SERVICE_CENTERS_EQUIPPED]: "Service centers are equipped to unlock trunks.",
    [TRANSLATION_CONSTANTS.WITHOUT_A_SPARE_TOW_VEHICLE]: "We'll need to tow your vehicle",
    [TRANSLATION_CONSTANTS.DEFAULT]: "Default",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN]: "Since your key is lost or broken, we can tow your vehicle to your home or a repair shop.",
    [TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED]: "Since your key is lost or broken, we can tow your vehicle to a repair shop.",
    [TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH]: "We can arrange a tow to have a service center unlock your trunk to retrieve your keys.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE]: "Since you’ll need to replace at least one tire, we can tow your vehicle to your home or a repair shop.",
    [TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED]: "Since you’ll need to replace at least one tire, we can tow your vehicle to a repair shop.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE]: "Since your vehicle stopped while driving, your battery most likely isn't the problem. Instead, we can tow your vehicle to your home or a repair shop.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED]: "Since your vehicle stopped while driving, your battery most likely isn't the problem. Instead, we can tow your vehicle to a repair shop.",
    [TRANSLATION_CONSTANTS.CONTINUE_TO_TOW]: "continue to tow",
    [TRANSLATION_CONSTANTS.STARTS_AT]: "Starts at",
    [TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE]: "unlock my vehicle",
    [TRANSLATION_CONSTANTS.SORRY_YOU_HAVE_REACHED]: "Sorry, you’ve reached your",
    [TRANSLATION_CONSTANTS.LIMIT]: "limit",
    [TRANSLATION_CONSTANTS.CAN_STILL_HELP_THANK]: "We can still help, Contact our representative to request service. Thank you.",
    [TRANSLATION_CONSTANTS.CAN_STILL_HELP]: "We can still help. Contact our representative to request service",
    [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS]: "You’ve used up all of your benefits. We can still help. Contact our representative to request service.",
    [TRANSLATION_CONSTANTS.VEHICLE_WEIGHT_LIMIT]: "Vehicle Weight Limit",
    [TRANSLATION_CONSTANTS.WEIGHT_CLASS_SPECIAL_SERVICE]: "This weight class requires special service. Please contact a representative.",
    [TRANSLATION_CONSTANTS.RUN_INTO_ISSUES_BENEFITS]: "We’ve run into an issue with your benefits",
    [TRANSLATION_CONSTANTS.ENTER_FIRST_LAST_NAME]: "Please enter your first and last name.",
    [TRANSLATION_CONSTANTS.SELECT_A_DRIVER]: "Please select a driver.",
    [TRANSLATION_CONSTANTS.ENTER_VALID_EMAIL_ADDRESS]: "Please enter a valid email address.",
    [TRANSLATION_CONSTANTS.ENTER_PHONE_NUMBER]: "Please enter your phone number.",
    [TRANSLATION_CONSTANTS.PAYMENT_INFO]: "Payment Info",
    [TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE]: "Please enter a 5 digit zip code.",
    [TRANSLATION_CONSTANTS.DRIVER]: "Driver",
    [TRANSLATION_CONSTANTS.BILLING_ZIP]: "Billing Zip",
    [TRANSLATION_CONSTANTS.CONTACT_PAYMENT]: "contact/payment",
    [TRANSLATION_CONSTANTS.TAKE_UP_5_DAYS_CREDIT_REFUND]: "Please note that it can take up to 5 days for your credit card refund to be processed.",
    [TRANSLATION_CONSTANTS.CANCEL_REQUEST_SUCCESFULLY]: "Your cancel request has been successfully processed.",
    [TRANSLATION_CONSTANTS.APPRECIATE_FEEDBACK]: "We appreciate your feedback. Thank you!",
    [TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST]: "Continue as Guest",
    [TRANSLATION_CONSTANTS.USE_PPU_OPTION_TO_PAY]: "Use our pay-per-use option to pay for the roadside help you need today.",
    [TRANSLATION_CONSTANTS.MISSION_ACCOMPLISHED_SHARE_FEEDBACK]: "Mission accomplished! Please share your feedback on the next screen.",
    [TRANSLATION_CONSTANTS.QUICK_UPDATE]: "Quick update, your rescuer is now set to arrive in",
    [TRANSLATION_CONSTANTS.WE_WILL_CONTINUE_TO_WORK]: " We’ll continue to work with them to ensure they remain on track.",
    [TRANSLATION_CONSTANTS.WE_CAN_HELP]: "We can help",
    [TRANSLATION_CONSTANTS.GIVE_US_A_CALL]: "Please give us a call so we can get you on your way.",
    [TRANSLATION_CONSTANTS.LOOKS_LIKE_CAN_NOT_CHARGE]: "Looks like we can't charge your vehicle — but we can help",
    [TRANSLATION_CONSTANTS.CONTINUE_LIKE_US_TO_TOW]: "Continue if you'd like us to tow your vehicle to your home or a nearby charging station.",
    [TRANSLATION_CONSTANTS.INCLUDED]: "Included",
    [TRANSLATION_CONSTANTS.ADDITIONAL]: "Additional",
    [TRANSLATION_CONSTANTS.OVER]: "over",
    [TRANSLATION_CONSTANTS.WEBSITE]: "Website",
    [TRANSLATION_CONSTANTS.REVIEW_SUMMARY]: "Review Summary",
    [TRANSLATION_CONSTANTS.REVIEWS]: "Reviews",
    [TRANSLATION_CONSTANTS.REPAIR_SHOP_NOT_BEEN_REVIEWED]: "This repair shop has not been reviewed.",
    [TRANSLATION_CONSTANTS.SHARE_YOUR_FEEBACK]: "Please share your feedback below.",
    [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_RESCUER]: "How was your experience with the rescuer?",
    [TRANSLATION_CONSTANTS.HOW_WAS_YOUR_EXPERIENCE_SITE]: "How was your experience with our site?",
    [TRANSLATION_CONSTANTS.SHARE_ANY_ADDITIONAL_COMMENTS]: "Please share any additional comments below",
    [TRANSLATION_CONSTANTS.SUBMIT]: "Submit",
    [TRANSLATION_CONSTANTS.SKIP]: "Skip",
    [TRANSLATION_CONSTANTS.BENEFITS]: "Benefits",
    [TRANSLATION_CONSTANTS.FINAL_COST_WILL_BE_DETERMINED_PROVIDER]: "Total cost for the service will be finalized once a service provider is assigned. The amount could exceed your benefit limit and require an out of pocket payment to the service provider.",
    [TRANSLATION_CONSTANTS.SERVICE_NOT_AVAILABLE]: "Service is not yet available",
    [TRANSLATION_CONSTANTS.WE_SORRY_SERVICE_NOT_AVAILABLE]: "We are sorry, service is not yet available in this area through this app.",
    [TRANSLATION_CONSTANTS.ENTER_ADDITIONAL_DETAILS_CURRENT_LOCATION]: "Please enter additional details about your current location or drop-off location",
    [TRANSLATION_CONSTANTS.I_E_STREET_NAME]: "i.e. street name, number and ZIP code",
    [TRANSLATION_CONSTANTS.OK]: "Ok",
    [TRANSLATION_CONSTANTS.SMS_TERMS_CONDITION_LINK]: "https://roadsidemobile.com/tac/sms",
    [TRANSLATION_CONSTANTS.SECURITY_FACILITY]: "but since this is a secure facility you can continue with your tow request",
    [TRANSLATION_CONSTANTS.THIS_LOCATION_IS]: "This location is",
    [TRANSLATION_CONSTANTS.CLOSED]: "closed",
    [TRANSLATION_CONSTANTS.CLOSING_SOON]: "closing soon",
    [TRANSLATION_CONSTANTS.SUSPENDED_VEHICLE_TO_CALL_CENTER]: "You've selected a vehicle which does not have coverage for {serviceType}. We can still help. Contact our representative to request service.",
    [TRANSLATION_CONSTANTS.POLICY_NUMBER]: "Policy Number",
    [TRANSLATION_CONSTANTS.MEMBER_NUMBER]: "Member Number",
    [TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER]: "Motor Vehicle Asset (MVA No.)",
    [TRANSLATION_CONSTANTS.FIND_POLICY_NUMBER]: "You can find your policy number on the front of your card.",
    [TRANSLATION_CONSTANTS.FIND_MEMBER_NUMBER]: "You can find your membership number on the front of your card.",
    [TRANSLATION_CONSTANTS.LOST_POLICY_NUMBER]: "If you have lost your policy number and/or card, please call our Customer Service Center at",
    [TRANSLATION_CONSTANTS.LOST_MEMBER_NUMBER]: "If you have lost your membership number and/or card, please call our Customer Service Center at",
    [TRANSLATION_CONSTANTS.MONDAY_FRIDAY]: "Monday - Friday",
    [TRANSLATION_CONSTANTS.WHAT_IS_THIS]: "What is this?",
    [TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS]: "We need some info to find your benefits",
    [TRANSLATION_CONSTANTS.PHONE_LOOKUP_FAILURE]: "Sorry, we could not locate your benefits with your phone number.",
    [TRANSLATION_CONSTANTS.FN_LN_ZIP_LOOKUP_FAILURE]: "Sorry, we could not locate your benefits with your first name, last name, and zip code.",
    [TRANSLATION_CONSTANTS.NAME_STATE_ZIP_LOOKUP_FAILURE]: "Sorry, we could not locate your benefits with your name, state and zip code.",
    [TRANSLATION_CONSTANTS.FIRST_NAME]: "First Name",
    [TRANSLATION_CONSTANTS.LAST_NAME]: "Last Name",
    [TRANSLATION_CONSTANTS.ZIP]: "Zip",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME]: "Please enter first name.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME]: "Please enter last name.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_ACCOUNT]: "Please enter your information exactly as it appears on your account (e.g. Michael Smith vs. Mike Smith)",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_MVA]: "Your MVA (Motor Vehicle Asset) number can be found on the key tag",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_MEMBER_ID]: "Your member id can be found in the signup email or by logging into your course account and clicking on profile.",
    [TRANSLATION_CONSTANTS.ZIP_CODE]: "ZIP Code",
    [TRANSLATION_CONSTANTS.AS_APPEARS_ACCOUNT]: "(As It Appears On Your Account)",
    [TRANSLATION_CONSTANTS.UP_TO]: "Up to",
    [TRANSLATION_CONSTANTS.FUEL_PRICE]: "Fuel Price",
    [TRANSLATION_CONSTANTS.USED_ALL_YOUR_BENEFITS_TO_PAY]: "You’ve used up all of your benefits. To pay for today’s service, choose from the options below.",
    [TRANSLATION_CONSTANTS.VEHICLE_INFO]: "Please enter your vehicle information.",
    [TRANSLATION_CONSTANTS.ADD_VEHICLE]: "Add Vehicle",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_CAR_MODEL]: "Please enter a car model date that's after 1970 and before",
    [TRANSLATION_CONSTANTS.YEAR]: "Year",
    [TRANSLATION_CONSTANTS.MAKE_MODEL]: "Make/Model",
    [TRANSLATION_CONSTANTS.YYYY]: "YYYY",
    [TRANSLATION_CONSTANTS.ADD]: "add vehicle",
    [TRANSLATION_CONSTANTS.ERROR_MEMBERSHIP_NUMBER]: "Please enter your 9 or 10 digit membership number.",
    [TRANSLATION_CONSTANTS.ERROR_POLICY_NUMBER]: "Please enter a policy number starting with 4 characters and ending with 9 digits. Example: XXXX123456789",
    [TRANSLATION_CONSTANTS.PLEASE_CONFIRM_VEHICLE]: "Please confirm your vehicle OR start a new search by VIN.",
    [TRANSLATION_CONSTANTS.FOUND]: "Found",
    [TRANSLATION_CONSTANTS.VEHICLES]: "vehicle(s)",
    [TRANSLATION_CONSTANTS.PLEASE_SELECT_OPTION]: "Please select an option",
    [TRANSLATION_CONSTANTS.ESTIMATED_MILEAGE]: "Estimated mileage",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_VALID_ESTIMATED_MILEAGE]: "Please enter a valid mileage",
    [TRANSLATION_CONSTANTS.MEMBERSHIP_2_DAY_WAIT_PERIOD]: "Your membership is not yet eligible for roadside coverage due to 2 day wait period. To pay for today's service, choose from the options below",
    [TRANSLATION_CONSTANTS.CLAIMS_USED_UP]: "Claims Used Up",
    [TRANSLATION_CONSTANTS.ETA_CALCULATING]: "ETA: Calculating",
    [TRANSLATION_CONSTANTS.FINDING_RESCUER_DESC]: "We're looking for a service provider close to your location. This can take up to 15 minutes. Stay in a safe location until help arrives.",
    [TRANSLATION_CONSTANTS.FINDING_RESCUER]: "Finding rescuer",
    [TRANSLATION_CONSTANTS.START_OVER_ALERT_MESSAGE]: "For best experience please start over by clicking the Request Service button on the roadside assistance page.",
    [TRANSLATION_CONSTANTS.APP_REDIRECTION_ALERT_MESSAGE]: "For best experience please start over by clicking the Continue to Allstate button in the Avis App.",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND_PRICING_INFO]: "Pay out of pocket or use your personal roadside service to request help. <br/><br/> <u>Click here for pricing details.</u>",
    [TRANSLATION_CONSTANTS.WE_CANNOT_SERVICE_TITLE]: "Sorry, we cannot service a",
    [TRANSLATION_CONSTANTS.REQUEST]: "request in the app",
    [TRANSLATION_CONSTANTS.PRIMER_HELP]: "Help us find you fast",
    [TRANSLATION_CONSTANTS.SHARING_PHONE]: "Sharing your phone’s location is the <strong>quickest</strong> and <strong>most accurate</strong> way for Roadside assistance to find you.",
    [TRANSLATION_CONSTANTS.CLICK_ALLOW]: "Click <strong>Allow</strong> on the next screen to share the following permission",
    [TRANSLATION_CONSTANTS.LOCATION]: "Location",
    [TRANSLATION_CONSTANTS.PRIMER_DATA]: "Your data and location will be shared with your service provider and will only be used for this rescue.",
    [TRANSLATION_CONSTANTS.MOTOR_VEHICLE_ALERT_MESSAGE]: "We are unable to verify your rental agreement. Please call ",
    [TRANSLATION_CONSTANTS.FURTHER_ASSISTANCE]: " for further assistance.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TITLE]: "Let's fix the problem.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_TITLE]: "Check lights",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_CONTENT]: "Turn on interior lights or headlights. If the lights don't turn on, then the battery is likely the problem.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_TITLE]: "Check dashboard",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_CONTENT]: "Step on the brake pedal while starting the engine. Check the dash and gauges. If they do not light up, <strong>call to request a tow service</strong>.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_TITLE]: "Make sure the vehicle is in park",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_CONTENT]: "Put vehicle in park, step on brake pedal and try starting engine.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_TITLE]: "Check steering wheel",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_CONTENT]: "Move the steering wheel to both the right and left. You might have to apply slight pressure to move it.<br/><br/> Then try to start the engine while stepping on the brake pedal.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_TITLE]: "Test start button",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_CONTENT]: "Hold the key fob next to the start button and press it. Step on the brake pedal while starting the engine.<br/><br/> If the engine started, the key fob battery may need to be replaced. You can find replacement key fob batteries at the local pharmacy chain store.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED]: "Yes, that fixed it",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_CONTINUE]: "continue for help",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED_ALERT_MESSAGE]: "Your roadside event is now complete. Thank you for using roadside to assist in your needs.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_TITLE]: "Check engine",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_ONE_CONTENT]: "If the vehicle shut off while driving, put the vehicle in park, step on the brake pedal and try starting the engine.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_TITLE]: "Check lights",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_TWO_CONTENT]: "Turn on interior lights or headlights. If the lights turn on, put vehicle in park, step on brake pedal and try starting engine. If engine cranks, but will not start, fuel may be empty.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_TITLE]: "Check fuel gauge",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_STEP_THREE_CONTENT]: "See if the fuel gauge reads very low or empty.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_FUEL_DELIVERY_FINAL_CONTENT]: "If after checking these steps you need fuel delivery, up to three gallons of fuel will be delivered to the vehicle.",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_TITLE]: "Try remote unlock",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT]: "The {brand} app is the easiest way to unlock your vehicle.<br/><br/> Simply download the app and follow the instructions to try to remotely unlock the vehicle.",
    [TRANSLATION_CONSTANTS.TRIAGE_LOCKOUT_CONTENT_MEMBER]: "Unlock your vehicle using the {brand} app. <br/><br/> Return to the MyCar tab in the app to unlock your vehicle remotely.",
    [TRANSLATION_CONSTANTS.GET_THE_APP]: "Get the App",
    [TRANSLATION_CONSTANTS.START_AT_PRICING_INFO]: "starting at ",
    [TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_TITLE]: "Need a rental exchange?",
    [TRANSLATION_CONSTANTS.RENTAL_EXCHANGE_CONTENT]: "Once the vehicle service is complete, you can exchange your rental vehicle either at the rental location or a nearby location. If you require assistance, call a Roadside Services agent for help.",
    [TRANSLATION_CONSTANTS.MANAGE_MEMBERSHIP]: "Manage Membership",
    [TRANSLATION_CONSTANTS.SEE_MORE]: "See more",
    [TRANSLATION_CONSTANTS.WHEN_HELP_TEXT]: "When can we help you?",
    [TRANSLATION_CONSTANTS.GET_HELP_NOW]: "Get help now",
    [TRANSLATION_CONSTANTS.SCHEDULE_LATER]: "Schedule later time",
    [TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION]: "By clicking <strong>Confirm</strong> below, you agree that your location and contact information will be shared with your service provider.",
    [TRANSLATION_CONSTANTS.COMPLIANCE_CONFIRMATION_SCHEDULED_SERVICE]: "By clicking <strong>continue</strong> below, you agree to receive text messages about your rescue status.",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CONFIRMED]: "Appointment Confirmed",
    [TRANSLATION_CONSTANTS.HAPPEN_NEXT]: "Here's What Happens Next",
    [TRANSLATION_CONSTANTS.APPOINTMENT_SCHEDULED]: "Your appointment is scheduled. We'll send you a confirmation text a couple hours before the start of your appointment. You'll also receive text updates when service is on the way.",
    [TRANSLATION_CONSTANTS.PAYMENT_ISSUE]: "If there is an issue with your payment, a customer service representative will contact you directly.",
    [TRANSLATION_CONSTANTS.NEED_CHANGES]: "Need to make changes?",
    [TRANSLATION_CONSTANTS.ANYTHING_HAPPENS]: "If anything changes, you can cancel your request by clicking Cancel Rescue below and submit a new request with the correct rescue details.",
    [TRANSLATION_CONSTANTS.VEHICLE_LOCATION]: "Vehicle Location",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED]: "Appointment Cancelled",
    [TRANSLATION_CONSTANTS.APPOINTMENT_CANCELLED_ERROR]: "Sorry, we are unable to cancel your appointment",
    [TRANSLATION_CONSTANTS.APPOINTMENT_DETAILS_ERROR]: "Sorry, we are unable to retrieve your appointment confirmation at this time. Please call in for details.",
    [TRANSLATION_CONSTANTS.APPOINTMENT_DETAILS_RESPONSE_ERROR]: "Sorry, we are unable to retrieve your appointment information at this time. We'll check again in {timeLeft} seconds.",
    [TRANSLATION_CONSTANTS.CANCEL]: "Cancel",
    [TRANSLATION_CONSTANTS.ALLSTATE_FOOTER_TEXT]: "Allstate Insurance Company",
    [TRANSLATION_CONSTANTS.SERVICE_DATE]: "Service Date",
    [TRANSLATION_CONSTANTS.PREFERRED_TIME]: "Preferred Time",
    [TRANSLATION_CONSTANTS.PICK_A_TIME]: "Pick a Time",
    [TRANSLATION_CONSTANTS.ETA_SUGGESTION]: "Estimated time of arrival will be provided as soon as provider is booked on the day of service.",
    [TRANSLATION_CONSTANTS.CONFIRM_DATE_TIME]: "confirm date & time",
    [TRANSLATION_CONSTANTS.CLOSING_SOON_OPEN_UNTIL]: "Closing Soon • Open until {time} today",
    [TRANSLATION_CONSTANTS.OPEN_UNTIL]: "Open until {time} today",
    [TRANSLATION_CONSTANTS.TOW_DESTINATION_CLOSED]: "Closed",
    [TRANSLATION_CONSTANTS.A_WEEK_AGO]: "a week ago",
    [TRANSLATION_CONSTANTS.X_WEEKS_AGO]: "{reviewTime} weeks ago",
    [TRANSLATION_CONSTANTS.A_MONTH_AGO]: "a month ago",
    [TRANSLATION_CONSTANTS.X_MONTHS_AGO]: "{reviewTime} months ago",
    [TRANSLATION_CONSTANTS.A_YEAR_AGO]: "a year ago",
    [TRANSLATION_CONSTANTS.X_YEARS_AGO]: "{reviewTime} years ago",
    [TRANSLATION_CONSTANTS.SORRY_TO_SEE_YOU_GO]: "Sorry to see you go",
    [TRANSLATION_CONSTANTS.CALL_FOR_HELP_DESCRIPTION]: "Before we connect you, please select an option below or provide more detail in the box",
    [TRANSLATION_CONSTANTS.ISSUE_IN_ROADSIDE_COVERAGE]: "There is an issue with my roadside coverage",
    [TRANSLATION_CONSTANTS.SCHEDULE_SERVICE_FOR_FUTURE_TIME]: "I want to schedule a service for a future time",
    [TRANSLATION_CONSTANTS.TALK_TO_PERSON]: "I just want to talk to a person",
    [TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION_WRONG]: "My disablement location is wrong",
    [TRANSLATION_CONSTANTS.OTHER_SPECIFY]: "Other, please specify",
    [TRANSLATION_CONSTANTS.GO_BACK]: "go back",
    [TRANSLATION_CONSTANTS.ETA]: "ETA",
    [TRANSLATION_CONSTANTS.CONTACT]: "Contact",
    [TRANSLATION_CONSTANTS.INFO_TO_DISPATCH]: "We need some info to dispatch a rescuer",
    [TRANSLATION_CONSTANTS.AVERAGE_ESTIMATED_CHARGES]: "Average Estimated Charges",
    [TRANSLATION_CONSTANTS.WINCH]: "Winch",
    [TRANSLATION_CONSTANTS.TOW_SERVICES]: "Tow Services",
    [TRANSLATION_CONSTANTS.LOST_KEY_TOWING]: "Lost Key & Towing",
    [TRANSLATION_CONSTANTS.KEY_COST_AVG_TOW_COST]: "key cost + avg tow cost",
    [TRANSLATION_CONSTANTS.ADDITIONAL_COSTS]: "Costs may vary based on Service Provider market rates, and tow mileage if applicable.",
    [TRANSLATION_CONSTANTS.SERVICE_CHARGES]: "Service charges will be billed to your rental agreement.",
    [TRANSLATION_CONSTANTS.COMPANY_NAME]: "Company Name",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_FIRST_NAME]: "Please enter your first name.",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_LAST_NAME]: "Please enter your lastname.",
    [TRANSLATION_CONSTANTS.WHAT_IS_YOUR_FUEL_TYPE]: "What is your fuel type?",
    [TRANSLATION_CONSTANTS.OUT_OF_POCKET]: "Out-of-pocket {{price}}",
    [TRANSLATION_CONSTANTS.PER_RESCUE]: "/ rescue",
    [TRANSLATION_CONSTANTS.SERVICE_COVERAGE_UPTO]: "Covered up to",
    [TRANSLATION_CONSTANTS.SERVICE_COVERED]: "Covered",
    [TRANSLATION_CONSTANTS.SERVICE_BASED_BANNER]: "Your benefits offer a maximum coverage per service. Any service labeled \"out-of-pocket\" is not covered.",
    [TRANSLATION_CONSTANTS.PROVIDE_MESSAGE]: "Provide one of the following",
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS]: "Sorry. Can't find a repair center. You may try to search or select again or call the customer service.",
    [TRANSLATION_CONSTANTS.NO_SUGGESTIONS_CALL_CENTER]: "Sorry. Can't find a repair center. Please call customer service.",
    [TRANSLATION_CONSTANTS.RETRIEVING_LOCATIONS]: "Getting Location…",
    [TRANSLATION_CONSTANTS.ENTER_MANUAL_LOCATION]: "Sorry. Can't find a repair center. You may enter a location manually or call customer service.",
    [TRANSLATION_CONSTANTS.LIVE_CHAT]: "live chat",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE]: "We’re unable to find your benefits information.",
    [TRANSLATION_CONSTANTS.ENTER_COMPANY_NAME]: "Please enter your company name.",
    [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SIGNATURE_CLUB]: "Signature Motor Club.",
    [TRANSLATION_CONSTANTS.FOOTER_COMPANY_SLOGAN]: "Allstate. You're in good hands.",
    [TRANSLATION_CONSTANTS.STATUS_OPEN24]: "Open 24 Hours",
    [TRANSLATION_CONSTANTS.STATUS_CLOSED]: "Closed",
    [TRANSLATION_CONSTANTS.STATUS_CLOSING_SOON]: "Closing Soon • Open until {{time}} today",
    [TRANSLATION_CONSTANTS.STATUS_OPEN]: "Open until {{time}} today",
    [TRANSLATION_CONSTANTS.STATUS_CLOSED_OPENSAT]: "Closed • Opens at {{time}}",
    [TRANSLATION_CONSTANTS.PROVIDER_TEN_MINS_ALERT]: "Your service provider is about 10 minutes away from your location. Stay with your vehicle until help arrives.",
    [TRANSLATION_CONSTANTS.TOWING_CHARGES_NOT_APPLY]: "Towing charges will not apply if there is a vehicle mechanical defect.",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_TITLE]: "Rescue in progress",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_DESC]: "View the rescue tracker or chat with a representative to check the status of your rescue. Your current rescue must be completed before requesting a new one",
    [TRANSLATION_CONSTANTS.DUPLICATE_RESCUE_SMS_RT_BUTTON_TEXT]: "view rescue tracker",
    [TRANSLATION_CONSTANTS.PETA_ALERT]: "Based on your location and time of day, your provider's estimated arrival is between {{petaFrom}} - {{petaTo}}",
    [TRANSLATION_CONSTANTS.VAS_FUEL_COVERED]: "Your roadside benefits cover the cost of fuel.",
    [TRANSLATION_CONSTANTS.VAS_FUEL_NOT_COVERED]: "Fuel Delivery service does not include the price of the fuel and you would have to pay that to the service provider.",
    [TRANSLATION_CONSTANTS.COVERS_UPTO]: "Covers up to",
    [TRANSLATION_CONSTANTS.SERVICE_FEE_REDUCTION_REASON]: "* Coverage amounts reflect a $0.55 service fee.",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_ACCIDENT]: "I was involved in an accident",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_VANDALISM]: "My car has been vandalized",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_TIRE_WITHOUT_SPARE]: "I have a flat",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_MECHANICAL_KEY]: "I’m locked out of my car",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_BATTERY]: "My vehicle won't start/battery",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_OTHER]: "Something else",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_TITLE]: "Please tell us which best describes your tow situation.",
    [TRANSLATION_CONSTANTS.TOW_TRIAGE_REASON_LABEL]: "What do you need help with?",
    [TRANSLATION_CONSTANTS.PEP_BOYS_OFFERS]: "Pep Boys offers",
    [TRANSLATION_CONSTANTS.PEP_BOYS_COUPON]: "Explore exclusive coupon savings.",
    [TRANSLATION_CONSTANTS.CONTRACT_CANCELLED_MESSAGE]: "Your coverage was cancelled but we can still help. Use our pay-per-use option to get roadside help today.",
    [TRANSLATION_CONSTANTS.CONTRACT_EXPIRED_MESSAGE]: "Your coverage is expired but we can still help. Use our pay-per-use option to get roadside help today.",
    [TRANSLATION_CONSTANTS.CONTRACT_NOT_ACTIVE_YET_MESSAGE]: "Your coverage is not active yet but we can still help. Use our pay-per-use options to get roadside help today.",
    [TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE_POLICY]: "We couldn't find your benefits with the details you provided. Try again using your policy number.",
    [TRANSLATION_CONSTANTS.NEW_JOB_DATA_DRIVEN_ETA_DESC]: "Please note the estimated arrival time and stay in a safe location until help arrives.",
    [TRANSLATION_CONSTANTS.BOOKED_DATA_DRIVEN_ETA_DESC]: "Your assigned provider is on schedule to arrive at the estimated time.",
    [TRANSLATION_CONSTANTS.BOOKED_NOT_DATA_DRIVEN_ETA_DESC]: "We've updated the arrival time with a more accurate estimate based on the provider's status.",
    [TRANSLATION_CONSTANTS.CARD_RETRY_MESSAGE]: "We’re unable to process your payment. Please edit your card information.",
    [TRANSLATION_CONSTANTS.TOW_REASON_NOT_COVERED_MSG]: "The tow reason you selected isn’t covered by your roadside benefits. Use our pay-per-use guest option to get roadside help today.",
    [TRANSLATION_CONSTANTS.RENTAL_AGREEMENT_NUMBER]: "Rental Agreement (RA) Number",
    [TRANSLATION_CONSTANTS.SEARCH_BY_RENTAL_AGREEMENT]: "search by rental agreement",
    [TRANSLATION_CONSTANTS.SEARCH_BY_MOTOR_VEHICLE_ASSET]: "search by motor vehicle asset",
    [TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_RA]: "Enter only the digits on your rental agreement number",
    [TRANSLATION_CONSTANTS.ERROR_RA_NUMBER]: "Please enter only the 8 or 9 digits on your rental agreement number",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_TITLE]: "Visually inspect the tire",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_CONTENT]: "If the tire pressure light comes on due to a significant temperature change and the tire isn't visibly flat or damaged, you can drive it a short distance to a service station for air.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_TITLE]: "Utilize Tire Inflation Kit",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_CONTENT]: "If the sidewall of the flat tire is undamaged, you can use a tire inflation kit. Check your car's trunk; the kit might be in the side panel or under the trunk floor.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_TITLE]: "Confirm a working spare is available",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_CONTENT]: "You can find a spare tire in the trunk, side panel, or under the rear of the vehicle. An internet search might help you locate it.",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_TITLE]: "Check the size of the spare tire",
    [TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_CONTENT]: "If your spare tire is not full-sized, consider driving to the nearest {brand} location to exchange your vehicle once the tire change has been performed.",
}
