import React, { Component } from "react";

class CarAnn extends Component {

  render() {
    return (
      <div className="c-home-svg">
        <svg id="home-svg" viewBox="0 0 1440 226">
          <defs>
            <polygon
              id="path-1"
              points="356.06 176.656 0.314 176.656 0.314 0.057 356.06 0.057"
            />
            <polygon
              id="path-3"
              points="312.683 144.572 0.05 144.572 0.05 0.03 312.683 0.03"
            />
            <polygon
              id="path-5"
              points="258.518 109.404 0.54 109.404 0.54 0.486 258.518 0.486 258.518 109.404"
            />
            <polygon
              id="path-7"
              points="376.99 170.486 0.33 170.486 0.33 0.542 376.99 0.542"
            />
            <polygon
              id="path-9"
              points="0.229 0.5 0.229 138.402 299.47 138.402 299.47 0.5 0.23 0.5"
            />
            <path
              d="M0.571,0.804 C0.315936968,1.10538377 0.214104592,1.50754786 0.295,1.894 L3.76,18.448 C3.883,19.035 4.398,19.459 5,19.47 L47.07,20.186 L47.092,20.186 C47.615,20.186 48.087,19.872 48.285,19.388 C48.485,18.899 48.365,18.336 47.979,17.972 L30.759,1.727 C30.5308124,1.51216818 30.2322198,1.38775457 29.919,1.377 L1.603,0.35 L1.559,0.349 C1.183,0.349 0.817,0.514 0.571,0.804"
              id="path-11"
            />
            <path
              d="M0.571,0.804 C0.315936968,1.10538377 0.214104592,1.50754786 0.295,1.894 L3.76,18.448 C3.883,19.035 4.398,19.459 5,19.47 L47.07,20.186 L47.092,20.186 C47.615,20.186 48.087,19.872 48.285,19.388 C48.485,18.899 48.365,18.336 47.979,17.972 L30.759,1.727 C30.5308124,1.51216818 30.2322198,1.38775457 29.919,1.377 L1.603,0.35 L1.559,0.349 C1.183,0.349 0.817,0.514 0.571,0.804"
              id="path-13"
            />
            <path
              d="M7.785,0.995 L0.152,16.767 C-0.0382260119,17.1605505 -0.0144708978,17.6239637 0.215,17.996 C0.445,18.368 0.85,18.598 1.289,18.606 L39.796,19.343 L39.821,19.343 C40.189,19.343 40.539,19.186 40.785,18.912 C41.0350108,18.631431 41.1490958,18.2551699 41.097,17.883 L38.807,1.518 C38.7184056,0.884072629 38.1770856,0.411802959 37.537,0.41 L8.953,0.27"
              id="path-15"
            />
            <path
              d="M7.785,0.995 L0.152,16.767 C-0.0382260119,17.1605505 -0.0144708978,17.6239637 0.215,17.996 C0.445,18.368 0.85,18.598 1.289,18.606 L39.796,19.343 L39.821,19.343 C40.189,19.343 40.539,19.186 40.785,18.912 C41.0350108,18.631431 41.1490958,18.2551699 41.097,17.883 L38.807,1.518 C38.7184056,0.884072629 38.1770856,0.411802959 37.537,0.41 L8.953,0.27"
              id="path-17"
            />
          </defs>
          <g id="Page-1" fill="none" fillRule="evenodd">
            <g id="illustration-(1)">
              <path
                d="M783.746,131.658 C783.746,178.788 716.202,216.993 632.883,216.993 C549.563,216.993 482.019,178.787 482.019,131.658 C482.019,84.528 549.563,46.322 632.883,46.322 C716.202,46.322 783.746,84.528 783.746,131.658"
                id="Shape"
                fill="#E5F0FA"
                fillRule="nonzero"
              />
              <polygon
                id="Shape"
                fill="#E5F0FA"
                fillRule="nonzero"
                points="361.013 218.37 1048.598 219.296 997.631 131.379 523.18 99.3 356.927 149.217"
              />
              <g id="Group" transform="translate(597.937 37.662)">
                <g id="Clipped">
                  <mask id="mask-2" fill="#fff">
                    <use xlinkHref="#path-1" />
                  </mask>
                  <path
                    d="M356.06,144.312 C356.06,223.981 276.424,288.566 178.187,288.566 C79.95,288.566 0.314,223.981 0.314,144.312 C0.314,64.642 79.95,0.057 178.187,0.057 C276.424,0.057 356.06,64.642 356.06,144.312"
                    id="Shape"
                    fill="#E5F0FA"
                    fillRule="nonzero"
                    mask="url(#mask-2)"
                  />
                </g>
              </g>
              <g id="Group" transform="translate(796.253 69.746)">
                <g id="Clipped">
                  <mask id="mask-4" fill="#fff">
                    <use xlinkHref="#path-3" />
                  </mask>
                  <path
                    d="M306.05,228.12 C281.247,306.01 194.125,337.628 111.457,298.737 C28.789,259.847 -18.12,165.177 6.683,87.285 C31.486,9.393 118.609,-22.225 201.277,16.667 C283.945,55.557 330.853,150.227 306.05,228.119"
                    id="Shape"
                    fill="#E5F0FA"
                    fillRule="nonzero"
                    mask="url(#mask-4)"
                  />
                </g>
              </g>
              <g id="Group" transform="translate(258.143 104.914)">
                <g id="Clipped">
                  <mask id="mask-6" fill="#fff">
                    <use xlinkHref="#path-5" />
                  </mask>
                  <path
                    d="M257.937,69.377 C264.643,119.505 212.59,171.506 141.672,185.523 C70.755,199.541 7.827,170.267 1.122,120.138 C-5.586,70.01 46.467,18.01 117.385,3.992 C188.302,-10.025 251.229,19.249 257.935,69.377"
                    id="Shape"
                    fill="#E5F0FA"
                    fillRule="nonzero"
                    mask="url(#mask-6)"
                  />
                </g>
              </g>
              <g id="Group" transform="translate(334.134 43.832)">
                <g id="Clipped">
                  <mask id="mask-8" fill="#fff">
                    <use xlinkHref="#path-7" />
                  </mask>
                  <path
                    d="M376.99,144.796 C376.99,224.466 292.672,289.051 188.66,289.051 C84.648,289.05 0.329,224.465 0.329,144.796 C0.329,65.126 84.648,0.542 188.659,0.542 C292.672,0.542 376.991,65.127 376.991,144.796"
                    id="Shape"
                    fill="#E5F0FA"
                    fillRule="nonzero"
                    mask="url(#mask-8)"
                  />
                </g>
              </g>
              <g id="Group" transform="translate(195.127 75.915)">
                <g id="Clipped">
                  <mask id="mask-10" fill="#fff">
                    <use xlinkHref="#path-9" />
                  </mask>
                  <path
                    d="M299.471,157.89 C299.471,244.815 232.318,315.282 149.481,315.282 C66.643,315.282 -0.509,244.815 -0.509,157.891 C-0.51,70.966 66.642,0.5 149.48,0.5 C232.317,0.5 299.47,70.965 299.47,157.89"
                    id="Shape"
                    fill="#E5F0FA"
                    fillRule="nonzero"
                    mask="url(#mask-10)"
                  />
                </g>
              </g>
              <path
                d="M733.998,132.187 C733.998,172.894 704.334,205.892 667.74,205.892 C631.147,205.892 601.482,172.894 601.482,132.187 C601.482,91.48 631.147,58.48 667.74,58.48 C704.334,58.48 733.998,91.48 733.998,132.186"
                id="Shape"
                fill="#E5F0FA"
                fillRule="nonzero"
              />
              <g
                id="Group"
                transform="translate(0 62)"
                fill="#E5F0FA"
                fillRule="nonzero"
              >
                <path
                  d="M714.145,93.721 C714.145,93.721 684.231,58.728 626.345,58.728 C626.345,58.728 580.495,59.826 559.325,46.323 C559.325,46.323 535.825,29.792 500.472,41.905 C500.472,41.905 446.472,66.131 421.219,59.065 C421.219,59.065 296.9,31.474 249.504,57.046 C249.504,57.046 165.198,73.513 74.29,74.186 C74.29,74.186 -0.297,72.111 0.867,157.594 L732.016,156.922 L714.145,96.413 L714.145,93.721 Z M1180.4,157.024 C1182.87,111.161 1178.958,84.219 1167.967,62.458 C1147.087,21.119 1093.121,7.56 1055.603,27.58 C1018.085,47.6 1007.564,95.673 1032.103,134.956 L1038.041,156.868 L1180.399,157.024 L1180.4,157.024 Z"
                  id="Shape"
                />
                <path
                  d="M1441.703,157.024 C1449.163,105.203 1437.352,74.761 1404.174,50.172 C1341.147,3.464 1178.25,-11.857 1065.001,10.764 C951.753,33.384 919.994,87.704 994.065,132.089 L1011.99,156.848 L1441.703,157.024 Z"
                  id="Shape"
                />
              </g>
              <g
                id="cloud4"
                transform="translate(774 73)"
                fill="#FFF"
                fillRule="nonzero"
              >
                <path
                  d="M47.535,14.192 C47.535,6.819 41.995,0.842 35.161,0.842 C28.326,0.842 22.785,6.819 22.785,14.192 C22.785,21.564 28.325,27.542 35.161,27.542 C41.995,27.542 47.535,21.564 47.535,14.192"
                  id="Shape"
                />
                <path
                  d="M53.863,19.484 C53.863,15.714 51.03,12.658 47.535,12.658 C44.041,12.658 41.208,15.714 41.208,19.484 C41.208,23.254 44.041,26.309 47.535,26.309 C51.03,26.309 53.863,23.254 53.863,19.484 M18.625,19.484 C18.625,15.714 15.792,12.658 12.298,12.658 C8.803,12.658 5.969,15.714 5.969,19.484 C5.969,23.254 8.803,26.309 12.298,26.309 C15.792,26.309 18.625,23.254 18.625,19.484"
                  id="Shape"
                />
                <path
                  d="M32.833,17.03 C32.833,11.388 28.594,6.815 23.364,6.815 C18.134,6.815 13.895,11.388 13.895,17.03 C13.895,22.671 18.135,27.244 23.365,27.244 C28.595,27.244 32.833,22.671 32.833,17.03"
                  id="Shape"
                />
                <path
                  d="M3.833,28.464 L60.389,28.464 C62.304,28.464 63.855,26.79 63.855,24.724 C63.855,22.66 62.304,20.985 60.389,20.985 L3.833,20.985 C1.919,20.985 0.367,22.66 0.367,24.725 C0.367,26.79 1.919,28.464 3.833,28.464"
                  id="Shape"
                />
              </g>
              <g
                id="cloud3"
                transform="translate(580 57)"
                fill="#FFF"
                fillRule="nonzero"
              >
                <path
                  d="M34.483,9.617 C34.483,4.399 30.247,0.169 25.023,0.169 C19.797,0.169 15.562,4.399 15.562,9.617 C15.562,14.834 19.797,19.064 25.022,19.064 C30.247,19.064 34.482,14.834 34.482,9.617"
                  id="Shape"
                />
                <path
                  d="M42.127,12.086 C42.1262047,10.4651804 41.4814369,8.9110907 40.3345943,7.76574848 C39.1877517,6.62040627 37.6328192,5.97767344 36.012,5.979 C34.3911808,5.97767344 32.8362483,6.62040627 31.6894057,7.76574848 C30.5425631,8.9110907 29.8977953,10.4651804 29.897,12.086 C29.8977953,13.7068196 30.5425631,15.2609093 31.6894057,16.4062515 C32.8362483,17.5515937 34.3911808,18.1943266 36.012,18.193 C37.6328192,18.1943266 39.1877517,17.5515937 40.3345943,16.4062515 C41.4814369,15.2609093 42.1262047,13.7068196 42.127,12.086"
                  id="Shape"
                />
                <path
                  d="M49.413,13.735 C49.4122046,12.4627564 48.9060449,11.2429366 48.0058702,10.3438872 C47.1056955,9.44483772 45.8852436,8.9402041 44.613,8.941 C41.963,8.941 39.813,11.088 39.813,13.735 C39.8137954,15.0072436 40.3199551,16.2270634 41.2201298,17.1261128 C42.1203045,18.0251623 43.3407564,18.5297959 44.613,18.529 C47.263,18.529 49.413,16.382 49.413,13.735 M23.243,11.625 C23.243,7.632 20.003,4.395 16.005,4.395 C12.007,4.395 8.765,7.632 8.765,11.625 C8.765,15.617 12.007,18.854 16.005,18.854 C20.002,18.854 23.243,15.617 23.243,11.624"
                  id="Shape"
                />
                <path
                  d="M15.57,19.229 L51.872,19.229 C53.0641544,19.2290005 54.0308962,18.2631539 54.032,17.071 C54.0317348,16.4983976 53.8040151,15.9493528 53.3989365,15.5446493 C52.9938579,15.1399458 52.4446024,14.9127347 51.872,14.913 L15.57,14.913 C14.377,14.913 13.41,15.879 13.41,17.071 C13.4111038,18.2631539 14.3778456,19.2290005 15.57,19.229 M57.661,19.123 L59.555,19.123 C60.5405911,19.0770965 61.3160698,18.2646595 61.3160698,17.278 C61.3160698,16.2913405 60.5405911,15.4789035 59.555,15.433 L57.661,15.432 C56.9873587,15.4098178 56.3551744,15.7565407 56.011787,16.3365147 C55.6683995,16.9164886 55.6683995,17.6375114 56.011787,18.2174853 C56.3551744,18.7974593 56.9873587,19.1441822 57.661,19.122 M2.176,19.604 L3.2,19.604 C4.19532821,19.5712251 4.9852464,18.7548677 4.9852464,17.759 C4.9852464,16.7631323 4.19532821,15.9467749 3.2,15.914 L2.176,15.914 C1.50235866,15.8918178 0.87017438,16.2385407 0.526786962,16.8185147 C0.183399545,17.3984886 0.183399545,18.1195114 0.526786962,18.6994853 C0.87017438,19.2794593 1.50235866,19.6261822 2.176,19.604"
                  id="Shape"
                />
              </g>
              <g
                id="cloud1"
                transform="translate(105 53)"
                fill="#E5F0FA"
                fillRule="nonzero"
              >
                <path
                  d="M48.295,24.163 C48.295,11.18 58.835,0.655 71.834,0.655 C84.834,0.655 95.374,11.18 95.374,24.163 C95.374,37.146 84.834,47.671 71.834,47.671 C58.834,47.671 48.295,37.146 48.295,24.163"
                  id="Shape"
                />
                <path
                  d="M36.259,33.482 C36.259,26.844 41.649,21.462 48.295,21.462 C54.943,21.462 60.331,26.844 60.331,33.482 C60.331,40.122 54.943,45.502 48.295,45.502 C41.648,45.502 36.259,40.122 36.259,33.482 M76.26,29.16 C76.26,19.226 84.324,11.173 94.271,11.173 C104.219,11.173 112.283,19.226 112.283,29.16 C112.283,39.095 104.219,47.148 94.271,47.148 C84.324,47.148 76.26,39.095 76.26,29.16"
                  id="Shape"
                />
                <path
                  d="M95.353,48.08 L23.846,48.08 C22.4210837,48.0810612 21.0541214,47.5159646 20.045897,46.509053 C19.0376726,45.5021413 18.4707954,44.1359164 18.47,42.711 C18.4710605,41.2862569 19.0380547,39.9202889 20.0462504,38.9135935 C21.054446,37.9068981 22.4212569,37.3419387 23.846,37.343 L95.353,37.343 C96.7777431,37.3419387 98.144554,37.9068981 99.1527496,38.9135935 C100.160945,39.9202889 100.72794,41.2862569 100.729,42.711 C100.728205,44.1359164 100.161327,45.5021413 99.153103,46.509053 C98.1448786,47.5159646 96.7779163,48.0810612 95.353,48.08 M9.442,47.816 L4.729,47.816 C3.51015696,47.8170613 2.34082756,47.3338269 1.47831891,46.4726311 C0.615810252,45.6114352 0.130795301,44.4428432 0.13,43.224 C0.130795301,42.0051568 0.615810252,40.8365648 1.47831891,39.9753689 C2.34082756,39.1141731 3.51015696,38.6309387 4.729,38.632 L9.442,38.632 C10.6606699,38.6312041 11.8297423,39.1145556 12.6920346,39.9757227 C13.554327,40.8368898 14.0392046,42.0053301 14.04,43.224 C14.0392046,44.4426699 13.554327,45.6111102 12.6920346,46.4722773 C11.8297423,47.3334444 10.6606699,47.8167959 9.442,47.816 M128.679,49.014 L126.129,49.014 C123.591642,49.0151049 121.533656,46.9593579 121.532,44.422 C121.532795,43.2033301 122.017673,42.0348898 122.879965,41.1737227 C123.742258,40.3125556 124.91133,39.8292041 126.13,39.83 L128.679,39.83 C129.89767,39.8292041 131.066742,40.3125556 131.929035,41.1737227 C132.791327,42.0348898 133.276205,43.2033301 133.277,44.422 C133.276205,45.6406699 132.791327,46.8091102 131.929035,47.6702773 C131.066742,48.5314444 129.89767,49.0147959 128.679,49.014"
                  id="Shape"
                />
              </g>
              <g
                id="cloud5"
                transform="translate(1280)"
                fill="#E5F0FA"
                fillRule="nonzero"
              >
                <path
                  d="M28.382,23.4 C28.382,17.656 33.045,13 38.796,13 C44.546,13 49.209,17.656 49.209,23.4 C49.209,29.143 44.547,33.8 38.796,33.8 C33.045,33.8 28.382,29.143 28.382,23.4 M69,21.02 C69,13.83 74.837,8 82.037,8 C89.238,8 95.075,13.83 95.075,21.02 C95.075,28.211 89.238,34.04 82.037,34.04 C74.837,34.04 69,28.211 69,21.02"
                  id="Shape"
                />
                <path
                  d="M41.332,16.638 C41.332,7.448 48.792,0 57.992,0 C67.194,0 74.653,7.449 74.653,16.638 C74.653,25.828 67.194,33.277 57.993,33.277 C48.792,33.277 41.333,25.827 41.333,16.638"
                  id="Shape"
                />
                <path
                  d="M87.21,33.737 L20.049,33.737 C17.261,33.737 15,31.333 15,28.368 C15,25.403 17.26,23 20.049,23 L87.209,23 C89.998,23 92.259,25.403 92.259,28.368 C92.259,31.333 89.998,33.737 87.209,33.737 M111.312,34.184 L106.598,34.184 C105.37933,34.1847959 104.210258,33.7014444 103.347965,32.8402773 C102.485673,31.9791102 102.000795,30.8106699 102,29.592 C102.000795,28.3733301 102.485673,27.2048898 103.347965,26.3437227 C104.210258,25.4825556 105.37933,24.9992041 106.598,25 L111.312,25 C112.53067,24.9992041 113.699742,25.4825556 114.562035,26.3437227 C115.424327,27.2048898 115.909205,28.3733301 115.91,29.592 C115.909205,30.8106699 115.424327,31.9791102 114.562035,32.8402773 C113.699742,33.7014444 112.53067,34.1847959 111.312,34.184 M7.752,34.142 L5.202,34.142 C2.66464157,34.1436576 0.606208259,32.088358 0.604,29.551 C0.604530122,28.3319834 1.08942804,27.1631346 1.95196546,26.3017224 C2.81450288,25.4403103 3.98398379,24.9569387 5.203,24.958 L7.752,24.958 C8.97084302,24.9572041 10.140067,25.4406929 11.002388,26.3020763 C11.8647091,27.1634596 12.3494698,28.3321568 12.35,29.551 C12.3477917,32.088358 10.2893584,34.1436576 7.752,34.142"
                  id="Shape"
                />
              </g>
              <g
                id="cloud2"
                transform="translate(335 92)"
                fill="#FFF"
                fillRule="nonzero"
              >
                <path
                  d="M11.832,9.968 C11.832,4.631 15.842,0.304 20.791,0.304 C25.738,0.304 29.749,4.631 29.749,9.968 C29.749,15.305 25.739,19.632 20.791,19.632 C15.843,19.632 11.831,15.305 11.831,9.968"
                  id="Shape"
                />
                <path
                  d="M7.251,13.8 C7.251,11.07 9.302,8.858 11.832,8.858 C14.362,8.858 16.412,11.07 16.412,13.799 C16.412,16.529 14.362,18.741 11.832,18.741 C9.302,18.741 7.252,16.528 7.252,13.799 M32.761,13.8 C32.761,11.07 34.812,8.858 37.342,8.858 C39.872,8.858 41.922,11.07 41.922,13.799 C41.922,16.529 39.872,18.741 37.342,18.741 C34.812,18.741 32.761,16.528 32.761,13.799"
                  id="Shape"
                />
                <path
                  d="M22.475,12.022 C22.475,7.939 25.544,4.628 29.33,4.628 C33.116,4.628 36.185,7.938 36.185,12.022 C36.185,16.106 33.116,19.417 29.33,19.417 C25.544,19.417 22.475,16.107 22.475,12.022"
                  id="Shape"
                />
                <path
                  d="M43.469,20.3 L2.527,20.3 C1.141,20.3 0.017,19.088 0.017,17.593 C0.017,16.098 1.141,14.886 2.527,14.886 L43.469,14.886 C44.855,14.886 45.979,16.098 45.979,17.593 C45.979,19.088 44.855,20.3 43.469,20.3"
                  id="Shape"
                />
              </g>
              <path
                d="M1030.33,152.81 L1045.519,152.81 M1027.347,150.232 L1047.508,150.232 M1030.149,147.12 L1046.151,147.12 M1029.878,143.831 L1045.158,143.831 M1032.862,141.075 L1043.168,141.075 M1031.053,138.319 L1044.976,138.319 M1032.771,135.03 L1043.53,135.03 M1034.218,131.562 L1042.174,131.562 M1032.952,128.628 L1043.168,128.628 M1034.218,125.339 L1042.716,125.339 M1036.297,121.872 L1039.823,121.872 M1036.026,119.116 L1040.636,119.116 M1039.137,115.826 L1037.797,115.826 M1038.467,116.004 L1038.467,158.51"
                id="Shape"
                stroke="#C3DDF4"
                strokeWidth="1.239"
                strokeLinecap="round"
              />
              <path
                d="M1062.759,145.967 L1062.759,170.567"
                id="Shape"
                stroke="#C3DDF4"
                strokeWidth="1.814"
                strokeLinecap="round"
              />
              <path
                d="M1070.304,143.543 C1070.304,148.723 1066.817,152.923 1062.517,152.923 C1058.215,152.923 1054.728,148.723 1054.728,143.543 C1054.728,138.363 1058.215,134.163 1062.517,134.163 C1066.817,134.163 1070.304,138.363 1070.304,143.543"
                id="Shape"
                fill="#C3DDF4"
                fillRule="nonzero"
              />
              <path
                d="M1006.95,156.068 L1022.228,156.068 M1009.933,153.312 L1020.24,153.312 M1008.125,150.556 L1022.047,150.556 M1009.842,147.266 L1020.602,147.266 M1011.289,143.798 L1019.245,143.798 M1010.023,140.864 L1020.239,140.864 M1011.289,137.575 L1019.788,137.575 M1013.369,134.108 L1016.895,134.108 M1013.097,131.352 L1017.707,131.352 M1016.208,128.062 L1014.868,128.062 M1015.538,128.24 L1015.538,160.258 M919.997,192.29 L935.276,192.29 M922.98,189.533 L933.287,189.533 M921.172,186.777 L935.095,186.777 M922.89,183.488 L933.648,183.488 M924.336,180.02 L932.292,180.02 M923.07,177.087 L933.287,177.087 M924.336,173.797 L932.835,173.797 M926.415,170.33 L929.941,170.33 M926.145,167.574 L930.755,167.574 M929.255,164.284 L927.915,164.284 M928.586,164.462 L928.586,196.48 M903.584,196.83 L910.711,196.83 M902.212,193.54 L912.971,193.54 M903.66,190.072 L911.615,190.072 M902.393,187.139 L912.609,187.139 M903.66,183.85 L912.157,183.85 M905.738,180.382 L909.264,180.382 M905.467,177.626 L910.077,177.626 M908.578,174.336 L907.238,174.336 M907.908,174.514 L907.908,200.979"
                id="Shape"
                stroke="#C3DDF4"
                strokeWidth="1.239"
                strokeLinecap="round"
              />
              <g id="Group" transform="translate(89 91)">
                <path
                  d="M318.741,63.753 L337.972,63.753 M314.964,60.489 L340.49,60.489 M318.512,56.549 L338.773,56.549 M318.169,52.384 L337.514,52.384 M321.946,48.895 L334.996,48.895 M319.657,45.405 L337.285,45.405 M321.832,41.24 L335.454,41.24 M323.663,36.85 L333.737,36.85 M322.06,33.135 L334.996,33.135 M323.663,28.97 L334.423,28.97 M326.296,24.58 L330.76,24.58 M325.953,21.09 L331.79,21.09 M329.892,29.265 L328.195,29.265 M329.043,18.385 L329.043,68.745 M488.622,53.946 L507.853,53.946 M484.845,50.681 L510.371,50.681 M488.393,46.741 L508.654,46.741 M488.05,42.576 L507.395,42.576 M491.827,39.087 L504.877,39.087 M489.538,35.597 L507.166,35.597 M491.713,31.432 L505.335,31.432 M493.544,27.042 L503.618,27.042 M491.942,23.327 L504.877,23.327 M493.544,19.162 L504.304,19.162 M496.177,14.772 L500.642,14.772 M495.834,11.282 L501.672,11.282 M499.773,7.118 L498.076,7.118 M498.925,7.343 L498.925,70.042"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.569"
                  strokeLinecap="round"
                />
                <path
                  d="M571.124,60.434 L585.7,60.434 M568.26,57.959 L587.61,57.959 M570.95,54.973 L586.308,54.973 M570.69,51.816 L585.354,51.816 M573.553,49.17 L583.444,49.17 M571.818,46.526 L585.18,46.526 M573.466,43.369 L583.791,43.369 M574.855,40.041 L582.49,40.041 M573.64,37.225 L583.445,37.225 M574.855,34.069 L583.011,34.069 M576.85,30.741 L580.234,30.741 M576.59,28.096 L581.016,28.096 M579.576,24.94 L578.289,24.94 M578.933,25.11 L578.933,72.635"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.189"
                  strokeLinecap="round"
                />
                <path
                  d="M512.789,52.375 L532.019,52.375 M509.011,49.11 L534.538,49.11 M512.56,45.17 L532.82,45.17 M512.216,41.006 L531.562,41.006 M515.994,37.516 L529.044,37.516 M513.705,34.027 L531.333,34.027 M515.88,29.862 L529.502,29.862 M517.71,25.472 L527.784,25.472 M516.108,21.757 L529.044,21.757 M517.71,17.592 L528.471,17.592 M520.344,13.202 L524.808,13.202 M520,9.712 L525.839,9.712 M523.94,5.547 L522.243,5.547 M523.091,5.772 L523.091,68.472"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.569"
                  strokeLinecap="round"
                />
                <path
                  d="M400.66,38.953 L419.892,38.953 M396.883,35.344 L422.41,35.344 M400.432,30.987 L420.692,30.987 M400.088,26.382 L419.434,26.382 M403.866,22.524 L416.916,22.524 M401.577,18.666 L419.205,18.666 M405.583,22.85 L415.656,22.85 M403.98,18.743 L416.916,18.743 M405.583,14.138 L416.343,14.138 M408.216,9.284 L412.68,9.284 M407.872,5.426 L413.711,5.426 M411.811,0.82 L410.115,0.82 M410.963,1.07 L410.963,56.75"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.65"
                  strokeLinecap="round"
                />
                <path
                  d="M269.195,76.437 L284.97,76.437 M266.096,73.76 L287.036,73.76 M269.007,70.528 L285.627,70.528 M268.725,67.11 L284.595,67.11 M271.824,64.248 L282.529,64.248 M284.407,61.386 L269.946,61.386 M282.905,57.97 L271.73,57.97 M273.232,54.368 L281.496,54.368 M271.918,51.32 L282.529,51.32 M273.232,47.904 L282.06,47.904 M275.392,44.303 L279.055,44.303 M275.11,41.44 L279.9,41.44 M278.342,38.023 L276.95,38.023 M277.646,38.208 L277.646,89.642"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.569"
                  strokeLinecap="round"
                />
                <path
                  d="M265.931,67.969 C265.931,74.023 261.639,78.931 256.345,78.931 C251.051,78.931 246.759,74.023 246.759,67.969 C246.759,61.915 251.051,57.007 256.345,57.007 C261.639,57.007 265.931,61.915 265.931,67.969 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M255.564,63.86 C255.564,67.353 252.097,70.185 247.82,70.185 C243.543,70.185 240.076,67.353 240.076,63.86 C240.076,60.367 243.543,57.536 247.82,57.536 C252.097,57.536 255.564,60.367 255.564,63.86"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M251.11,72.347 C252.158,74.523 251.36,77.079 249.325,78.055 C247.292,79.031 244.795,78.057 243.747,75.88 C242.7,73.703 243.499,71.148 245.533,70.172 C247.566,69.196 250.063,70.17 251.111,72.347"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M244.896,68.21 C245.943,70.385 245.144,72.942 243.111,73.918 C241.078,74.893 238.581,73.919 237.533,71.743 C236.485,69.566 237.284,67.01 239.318,66.034 C241.351,65.059 243.848,66.032 244.896,68.209 M267.89,71.933 C268.938,74.11 268.138,76.665 266.105,77.641 C264.071,78.617 261.574,77.644 260.527,75.467 C259.479,73.29 260.278,70.734 262.312,69.759 C264.345,68.782 266.842,69.756 267.89,71.933"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M271.205,67.175 C272.252,69.351 271.452,71.907 269.419,72.883 C267.386,73.858 264.889,72.885 263.841,70.709 C262.794,68.531 263.593,65.976 265.626,65 C267.66,64.024 270.156,64.998 271.205,67.175 M256.43,56.495 C257.478,58.672 256.27,61.423 253.733,62.641 C251.195,63.859 248.289,63.081 247.241,60.904 C246.194,58.728 247.402,55.976 249.939,54.759 C252.477,53.541 255.383,54.319 256.43,56.495"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M270.64,63.91 C270.64,66.939 267.59,69.394 263.827,69.394 C260.065,69.394 257.015,66.939 257.015,63.911 C257.015,60.883 260.065,58.428 263.827,58.428 C267.59,58.428 270.64,60.883 270.64,63.911"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M266.021,61.51 C266.021,65.584 263.226,68.886 259.779,68.886 C256.331,68.886 253.536,65.584 253.536,61.51 C253.536,57.437 256.331,54.135 259.779,54.135 C263.226,54.135 266.021,57.437 266.021,61.51 Z M254.954,95.887 L257.333,95.887 L257.333,78.225 L254.953,78.225 L254.954,95.887 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M247.613,76.047 L250.513,81.632 L255.485,83.702 M256.935,82.046 L261.699,81.012 L263.357,76.46"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.052"
                />
                <path
                  d="M459.922,14.908 C459.922,20.962 455.631,25.87 450.336,25.87 C445.042,25.87 440.75,20.962 440.75,14.908 C440.75,8.854 445.042,3.946 450.336,3.946 C455.631,3.946 459.922,8.854 459.922,14.908"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M449.556,10.8 C449.556,14.292 446.089,17.124 441.812,17.124 C437.535,17.124 434.067,14.292 434.067,10.799 C434.067,7.306 437.535,4.475 441.812,4.475 C446.089,4.475 449.556,7.306 449.556,10.799"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M445.102,19.286 C446.149,21.462 445.35,24.018 443.317,24.994 C441.283,25.97 438.787,24.996 437.738,22.819 C436.691,20.642 437.49,18.087 439.524,17.111 C441.557,16.135 444.054,17.109 445.102,19.286"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M438.888,15.148 C439.935,17.324 439.135,19.88 437.102,20.857 C435.069,21.832 432.572,20.858 431.524,18.682 C430.477,16.505 431.276,13.949 433.309,12.973 C435.343,11.998 437.839,12.971 438.888,15.148 M461.881,18.872 C462.929,21.048 462.13,23.604 460.096,24.58 C458.063,25.556 455.566,24.583 454.518,22.406 C453.47,20.229 454.27,17.673 456.303,16.697 C458.336,15.721 460.834,16.695 461.881,18.872"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M465.196,14.114 C466.243,16.29 465.444,18.846 463.41,19.822 C461.377,20.797 458.88,19.824 457.832,17.648 C456.785,15.47 457.584,12.915 459.618,11.939 C461.651,10.963 464.148,11.937 465.196,14.114 M450.421,3.434 C451.469,5.611 450.261,8.362 447.724,9.58 C445.186,10.798 442.28,10.02 441.233,7.843 C440.185,5.667 441.393,2.915 443.931,1.698 C446.468,0.48 449.374,1.258 450.421,3.434"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M464.631,10.85 C464.631,13.878 461.581,16.333 457.819,16.333 C454.056,16.333 451.006,13.878 451.006,10.85 C451.006,7.822 454.056,5.367 457.819,5.367 C461.581,5.367 464.631,7.822 464.631,10.85"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M460.012,8.45 C460.012,12.523 457.217,15.825 453.77,15.825 C450.322,15.825 447.527,12.523 447.527,8.449 C447.527,4.376 450.322,1.074 453.77,1.074 C457.217,1.074 460.012,4.376 460.012,8.449 L460.012,8.45 Z M448.945,42.826 L451.325,42.826 L451.325,25.164 L448.945,25.164 L448.945,42.826 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M441.604,22.986 L444.504,28.571 L449.476,30.641 M450.926,28.985 L455.691,27.951 L457.348,23.399"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.052"
                />
                <path
                  d="M397.1,34.653 C397.1,40.693 392.389,45.591 386.578,45.591 C380.767,45.591 376.056,40.694 376.056,34.653 C376.056,28.613 380.766,23.715 386.578,23.715 C392.388,23.715 397.1,28.612 397.1,34.653"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M388.362,35.427 C388.362,40.429 384.578,44.484 379.911,44.484 C375.244,44.484 371.461,40.429 371.461,35.427 C371.461,30.425 375.244,26.369 379.911,26.369 C384.578,26.369 388.361,30.425 388.361,35.427"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M393.2,23.006 C394.965,26.117 392.93,30.049 388.653,31.789 C384.376,33.529 379.477,32.417 377.711,29.306 C375.945,26.196 377.981,22.263 382.258,20.524 C386.535,18.784 391.433,19.895 393.2,23.006"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M402.115,33.899 C402.115,38.797 398.787,42.769 394.681,42.769 C390.575,42.769 387.247,38.797 387.247,33.899 C387.247,29.001 390.575,25.029 394.681,25.029 C398.787,25.029 402.115,29.001 402.115,33.899 Z M386.199,59.673 L389.025,59.673 L389.025,43.338 L386.2,43.338 L386.199,59.673 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <polyline
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.057"
                  points="379.01 43.174 382.175 48.34 387.6 50.253"
                />
                <path
                  d="M312.283,49.46 C312.283,55.436 308.26,60.28 303.298,60.28 C298.336,60.28 294.313,55.436 294.313,49.46 C294.313,43.483 298.336,38.638 303.298,38.638 C308.26,38.638 312.283,43.483 312.283,49.46"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M558.975,47.893 L558.975,72.492"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.814"
                  strokeLinecap="round"
                />
                <path
                  d="M566.52,45.469 C566.52,50.649 563.033,54.849 558.732,54.849 C554.432,54.849 550.945,50.649 550.945,45.469 C550.945,40.289 554.432,36.089 558.732,36.089 C563.032,36.089 566.52,40.289 566.52,45.469"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M610.87,56.53 L610.87,81.13"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.814"
                  strokeLinecap="round"
                />
                <path
                  d="M618.416,54.106 C618.416,59.286 614.929,63.486 610.628,63.486 C606.328,63.486 602.841,59.286 602.841,54.106 C602.841,48.926 606.328,44.728 610.628,44.728 C614.928,44.728 618.416,48.926 618.416,54.106"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M303.578,52.255 L303.578,80.637"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="2.092"
                  strokeLinecap="round"
                />
                <path
                  d="M178.75,118.689 L193.938,118.689 M175.766,116.11 L195.926,116.11 M178.568,113 L194.571,113 M178.297,109.71 L193.576,109.71 M181.28,106.954 L191.587,106.954 M179.472,104.198 L193.395,104.198 M181.19,100.908 L191.949,100.908 M182.637,97.44 L190.593,97.44 M181.371,94.507 L191.587,94.507 M182.637,91.217 L191.135,91.217 M184.716,87.75 L188.242,87.75 M184.445,84.994 L189.055,84.994 M187.556,81.705 L186.216,81.705 M186.886,81.882 L186.886,124.389"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.239"
                  strokeLinecap="round"
                />
                <path
                  d="M154.546,117.694 L170.923,117.694 M151.329,114.914 L173.068,114.914 M154.35,111.559 L171.605,111.559 M154.058,108.012 L170.533,108.012 M157.275,105.04 L168.389,105.04 M155.325,102.068 L170.338,102.068 M157.178,98.52 L168.778,98.52 M158.737,94.782 L167.317,94.782 M157.373,91.619 L168.389,91.619 M158.737,88.072 L167.901,88.072 M160.98,84.333 L164.782,84.333 M160.687,81.361 L165.659,81.361 M164.042,77.814 L162.597,77.814 M163.32,78.006 L163.32,123.956"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.336"
                  strokeLinecap="round"
                />
                <path
                  d="M129.498,104.627 C129.498,109.017 126.543,112.576 122.898,112.576 C119.254,112.576 116.299,109.017 116.299,104.627 C116.299,100.238 119.254,96.679 122.899,96.679 C126.543,96.679 129.499,100.238 129.499,104.627"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <g strokeLinecap="round" id="Shape" stroke="#C3DDF4">
                  <path
                    d="M58.833,40.964 L74.113,40.964 M61.817,38.208 L72.124,38.208 M60.008,35.452 L73.931,35.452 M61.726,32.163 L72.486,32.163 M63.173,28.696 L71.128,28.696 M61.907,25.762 L72.123,25.762 M63.173,22.472 L71.671,22.472 M65.252,19.005 L68.778,19.005 M64.981,16.249 L69.591,16.249 M68.092,12.96 L66.752,12.96 M67.422,13.137 L67.422,45.155"
                    strokeWidth="1.239"
                    transform="translate(0 78)"
                  />
                  <path
                    d="M86.598,40.878 L102.976,40.878 M83.381,38.098 L105.121,38.098 M86.403,34.742 L103.659,34.742 M86.111,31.195 L102.586,31.195 M89.328,28.223 L100.442,28.223 M87.378,25.252 L102.391,25.252 M89.23,21.705 L100.831,21.705 M90.79,17.965 L99.37,17.965 M89.426,14.802 L100.442,14.802 M90.79,11.255 L99.954,11.255 M93.033,7.516 L96.834,7.516 M92.74,4.545 L97.712,4.545 M96.095,0.997 L94.65,0.997 M95.372,1.19 L95.372,47.139"
                    strokeWidth="1.336"
                    transform="translate(0 78)"
                  />
                  <path
                    d="M0.833,40.964 L16.113,40.964 M3.817,38.208 L14.124,38.208 M2.008,35.452 L15.931,35.452 M3.726,32.163 L14.486,32.163 M5.173,28.696 L13.128,28.696 M3.907,25.762 L14.123,25.762 M5.173,22.472 L13.671,22.472 M7.252,19.005 L10.778,19.005 M6.981,16.249 L11.591,16.249 M10.092,12.96 L8.752,12.96 M9.422,13.137 L9.422,45.155"
                    strokeWidth="1.239"
                    transform="translate(0 78)"
                  />
                  <path
                    d="M28.598,40.878 L44.976,40.878 M25.381,38.098 L47.121,38.098 M28.403,34.742 L45.659,34.742 M28.111,31.195 L44.586,31.195 M31.328,28.223 L42.442,28.223 M29.378,25.252 L44.391,25.252 M31.23,21.705 L42.831,21.705 M32.79,17.965 L41.37,17.965 M31.426,14.802 L42.442,14.802 M32.79,11.255 L41.954,11.255 M35.033,7.516 L38.834,7.516 M34.74,4.545 L39.712,4.545 M38.095,0.997 L36.65,0.997 M37.372,1.19 L37.372,47.139"
                    strokeWidth="1.336"
                    transform="translate(0 78)"
                  />
                </g>
                <path
                  d="M123.104,110.383 L123.104,125.677"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.537"
                  strokeLinecap="round"
                />
              </g>
              <g id="Group" transform="translate(1087 101)">
                <path
                  d="M107.622,65.946 L126.853,65.946 M103.845,62.681 L129.371,62.681 M107.393,58.741 L127.654,58.741 M107.05,54.576 L126.395,54.576 M110.827,51.087 L123.877,51.087 M108.538,47.597 L126.166,47.597 M110.713,43.432 L124.335,43.432 M112.544,39.042 L122.618,39.042 M110.942,35.327 L123.877,35.327 M112.544,31.162 L123.304,31.162 M115.177,26.772 L119.642,26.772 M114.834,23.282 L120.672,23.282 M118.773,19.118 L117.076,19.118 M117.925,19.343 L117.925,82.042"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.569"
                  strokeLinecap="round"
                />
                <path
                  d="M270.124,104.434 L284.7,104.434 M267.26,101.959 L286.61,101.959 M269.95,98.973 L285.308,98.973 M269.69,95.816 L284.354,95.816 M272.553,93.17 L282.444,93.17 M270.818,90.526 L284.18,90.526 M272.466,87.369 L282.791,87.369 M273.855,84.041 L281.49,84.041 M272.64,81.225 L282.445,81.225 M273.855,78.069 L282.011,78.069 M275.85,74.741 L279.234,74.741 M275.59,72.096 L280.016,72.096 M278.576,68.94 L277.289,68.94 M277.933,69.11 L277.933,116.635"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.189"
                  strokeLinecap="round"
                />
                <path
                  d="M131.789,64.375 L151.019,64.375 M128.011,61.11 L153.538,61.11 M131.56,57.17 L151.82,57.17 M131.216,53.006 L150.562,53.006 M134.994,49.516 L148.044,49.516 M132.705,46.027 L150.333,46.027 M134.88,41.862 L148.502,41.862 M136.71,37.472 L146.784,37.472 M135.108,33.757 L148.044,33.757 M136.71,29.592 L147.471,29.592 M139.344,25.202 L143.808,25.202 M139,21.712 L144.839,21.712 M142.94,17.547 L141.243,17.547 M142.091,17.772 L142.091,80.472"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.569"
                  strokeLinecap="round"
                />
                <path
                  d="M29.66,38.953 L48.892,38.953 M25.883,35.344 L51.41,35.344 M29.432,30.987 L49.692,30.987 M29.088,26.382 L48.434,26.382 M32.866,22.524 L45.916,22.524 M30.577,18.666 L48.205,18.666 M34.583,22.85 L44.656,22.85 M32.98,18.743 L45.916,18.743 M34.583,14.138 L45.343,14.138 M37.216,9.284 L41.68,9.284 M36.872,5.426 L42.711,5.426 M40.811,0.82 L39.115,0.82 M39.963,1.07 L39.963,56.75"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.65"
                  strokeLinecap="round"
                />
                <path
                  d="M88.922,31.908 C88.922,37.962 84.631,42.87 79.336,42.87 C74.042,42.87 69.75,37.962 69.75,31.908 C69.75,25.854 74.042,20.946 79.336,20.946 C84.631,20.946 88.922,25.854 88.922,31.908"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M78.556,27.8 C78.556,31.292 75.089,34.124 70.812,34.124 C66.535,34.124 63.067,31.292 63.067,27.799 C63.067,24.306 66.535,21.475 70.812,21.475 C75.089,21.475 78.556,24.306 78.556,27.799"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M74.102,36.286 C75.149,38.462 74.35,41.018 72.317,41.994 C70.283,42.97 67.787,41.996 66.738,39.819 C65.691,37.642 66.49,35.087 68.524,34.111 C70.557,33.135 73.054,34.109 74.102,36.286"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M67.888,32.148 C68.935,34.324 68.135,36.88 66.102,37.857 C64.069,38.832 61.572,37.858 60.524,35.682 C59.477,33.505 60.276,30.949 62.309,29.973 C64.343,28.998 66.839,29.971 67.888,32.148 M90.881,35.872 C91.929,38.048 91.13,40.604 89.096,41.58 C87.063,42.556 84.566,41.583 83.518,39.406 C82.47,37.229 83.27,34.673 85.303,33.697 C87.336,32.721 89.834,33.695 90.881,35.872"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M94.196,31.114 C95.243,33.29 94.444,35.846 92.41,36.822 C90.377,37.797 87.88,36.824 86.832,34.648 C85.785,32.47 86.584,29.915 88.618,28.939 C90.651,27.963 93.148,28.937 94.196,31.114 M79.421,20.434 C80.469,22.611 79.261,25.362 76.724,26.58 C74.186,27.798 71.28,27.02 70.233,24.843 C69.185,22.667 70.393,19.915 72.931,18.698 C75.468,17.48 78.374,18.258 79.421,20.434"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M93.631,27.85 C93.631,30.878 90.581,33.333 86.819,33.333 C83.056,33.333 80.006,30.878 80.006,27.85 C80.006,24.822 83.056,22.367 86.819,22.367 C90.581,22.367 93.631,24.822 93.631,27.85"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M89.012,25.45 C89.012,29.523 86.217,32.825 82.77,32.825 C79.322,32.825 76.527,29.523 76.527,25.449 C76.527,21.376 79.322,18.074 82.77,18.074 C86.217,18.074 89.012,21.376 89.012,25.449 L89.012,25.45 Z M77.945,59.826 L80.325,59.826 L80.325,42.164 L77.945,42.164 L77.945,59.826 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M70.604,39.986 L73.504,45.571 L78.476,47.641 M79.926,45.985 L84.691,44.951 L86.348,40.399"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.052"
                />
                <path
                  d="M26.1,34.653 C26.1,40.693 21.389,45.591 15.578,45.591 C9.767,45.591 5.056,40.694 5.056,34.653 C5.056,28.613 9.766,23.715 15.578,23.715 C21.388,23.715 26.1,28.612 26.1,34.653"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M17.362,35.427 C17.362,40.429 13.578,44.484 8.911,44.484 C4.244,44.484 0.461,40.429 0.461,35.427 C0.461,30.425 4.244,26.369 8.911,26.369 C13.578,26.369 17.361,30.425 17.361,35.427"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M22.2,23.006 C23.965,26.117 21.93,30.049 17.653,31.789 C13.376,33.529 8.477,32.417 6.711,29.306 C4.945,26.196 6.981,22.263 11.258,20.524 C15.535,18.784 20.433,19.895 22.2,23.006"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M31.115,33.899 C31.115,38.797 27.787,42.769 23.681,42.769 C19.575,42.769 16.247,38.797 16.247,33.899 C16.247,29.001 19.575,25.029 23.681,25.029 C27.787,25.029 31.115,29.001 31.115,33.899 Z M15.199,59.673 L18.025,59.673 L18.025,43.338 L15.199,43.338 L15.199,59.673 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <polyline
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.057"
                  points="8.01 43.174 11.175 48.34 16.6 50.253"
                />
                <path
                  d="M257.975,91.893 L257.975,116.492"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.814"
                  strokeLinecap="round"
                />
                <path
                  d="M265.52,89.469 C265.52,94.649 262.033,98.849 257.732,98.849 C253.432,98.849 249.945,94.649 249.945,89.469 C249.945,84.289 253.432,80.089 257.732,80.089 C262.032,80.089 265.52,84.289 265.52,89.469"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M299.87,90.53 L299.87,115.13"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="1.814"
                  strokeLinecap="round"
                />
                <path
                  d="M307.416,88.106 C307.416,93.286 303.929,97.486 299.628,97.486 C295.328,97.486 291.841,93.286 291.841,88.106 C291.841,82.926 295.328,78.728 299.628,78.728 C303.928,78.728 307.416,82.926 307.416,88.106"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
              </g>
              <g id="Group" transform="translate(881 155)">
                <path
                  d="M212.961,52.623 L202.791,28.756 L186.637,16.258 L168.149,9.503 L119.276,10.803 L88.456,20.956 L69.931,41.73 L0.249,58.362 C0.249,58.362 0.036,59.201 3.683,59.172 C21.012,59.035 179.313,59.682 212.966,60.244 C213.528,60.253 212.714,52.664 212.961,52.624"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M212.961,52.623 L202.791,28.756 L186.637,16.258 L168.149,9.503 L119.276,10.803 L88.456,20.956 L69.931,41.73 L0.249,58.362 C0.249,58.362 -0.462,60.273 3.185,60.244 C20.513,60.107 176.678,60.244 212.965,60.244 C213.529,60.244 212.714,52.664 212.961,52.624 L212.961,52.623 Z"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="7.846"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M197.595,18.433 C195.635,24.08 186.231,25.953 176.59,22.616 C166.95,19.28 160.722,11.997 162.681,6.35 C164.641,0.704 174.045,-1.17 183.686,2.167 C193.327,5.504 199.554,12.787 197.596,18.433"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M210.056,22.865 C214.601,31.795 214.832,40.785 210.573,42.947 C206.313,45.11 199.176,39.624 194.631,30.696 C190.087,21.767 189.856,12.776 194.115,10.614 C198.375,8.452 205.512,13.937 210.056,22.865"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
              </g>
              <g id="Group" transform="translate(1017 152)">
                <path
                  d="M11.21,31.756 L27.363,19.258 L45.851,12.503 L117.629,0.832 L179.82,19.258 L223.844,36.18 L233.871,63.244008 C233.871,63.244008 213.964,62.201 210.317,62.172 C192.988,62.035 34.687,62.682 1.034,63.244 C0.472,63.253 1.286,55.664 1.039,55.624 L11.209,31.756 L11.21,31.756 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M1.039,55.623 L11.209,31.756 L27.363,19.258 L45.851,12.503 L117.871,1.035 L172.605,19.258 L223.352,36.961 L235.098,63.244 C235.098,63.244 214.462,63.274 210.815,63.244 C193.487,63.107 37.322,63.244 1.035,63.244 C0.471,63.244 1.286,55.664 1.039,55.624 L1.039,55.623 Z"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="7.846"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M16.405,21.433 C18.365,27.08 27.769,28.953 37.41,25.616 C47.05,22.28 53.278,14.997 51.319,9.35 C49.359,3.704 39.955,1.83 30.314,5.167 C20.673,8.504 14.446,15.787 16.404,21.433"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M3.944,25.865 C-0.601,34.795 -0.832,43.785 3.427,45.947 C7.687,48.11 14.824,42.624 19.369,33.696 C23.913,24.767 24.144,15.776 19.885,13.614 C15.625,11.452 8.488,16.937 3.944,25.865"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
              </g>
              <g id="Group" transform="translate(310 127)">
                <path
                  d="M1.036,79.566 L28.35,68.776 L43.656,61.255 L68.256,61.157 L89.374,41.119 L135.267,46.692 L163.321,29.475 L220.138,9.431 L248.192,21.962 L305.72,35.947 L335.981,69.137 L340.821,80.796 C340.821,80.796 339.561,90.401 337.114,90.051 C276.569,81.413 63.274,82.441 1.027,83.264 C0.051,83.277 1.467,79.622 1.036,79.566"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M1.036,80.566 L28.35,69.776 L43.656,62.255 L68.256,62.157 L89.374,42.119 L135.267,47.692 L163.321,30.475 L220.138,10.431 L248.192,22.962 L305.72,36.947 L333.708,66.49 L340,76.914 C338.345,74.214 342.906,84.264 340,84.264 L2.112,84.264 C1.134,84.264 1.466,80.622 1.036,80.566 Z"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="15.512"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M421.056,56.756 L392.036,44.258 L358.826,37.503 L329.806,38.803 L308.642,48.956 L276.531,71.581 L250.328,83.277 C250.328,83.277 371.708,82.628 444.883,83.308 C445.893,83.318 444.429,80.664 444.874,80.623 L421.056,56.756 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M445.402,82.92 L421.582,59.053 L392.563,46.555 L359.353,39.8 L330.333,41.1 L309.169,51.253 L277.058,73.878 L250.856,85.574 C250.856,85.574 372.236,84.925 445.41,85.605 C446.42,85.615 444.956,82.961 445.402,82.92 Z"
                  id="Shape"
                  stroke="#C3DDF4"
                  strokeWidth="13.443"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M85.138,47.15 C85.138,53.128 80.278,57.972 74.284,57.972 C68.288,57.972 63.429,53.128 63.429,47.151 C63.429,41.174 68.289,36.329 74.284,36.329 C80.278,36.329 85.138,41.174 85.138,47.151 L85.138,47.15 Z M144.175,40.159 C144.175,46.135 134.711,50.979 123.036,50.979 C111.361,50.979 101.896,46.135 101.896,40.159 C101.896,34.182 111.361,29.337 123.036,29.337 C134.711,29.337 144.176,34.182 144.176,40.159 L144.175,40.159 Z M302.412,42.979 C292.771,39.643 286.544,32.359 288.503,26.713 C290.463,21.066 299.866,19.193 309.508,22.53 C319.148,25.866 325.376,33.149 323.417,38.796 C321.457,44.442 312.053,46.316 302.412,42.979 Z M247.977,14.778 C247.977,21.73 241.839,27.366 234.267,27.366 C226.696,27.366 220.557,21.73 220.557,14.778 C220.557,7.825 226.696,2.19 234.267,2.19 C241.839,2.19 247.977,7.825 247.977,14.778 Z M35.285,61.547 C35.285,67.524 30.614,72.368 24.851,72.368 C19.088,72.368 14.416,67.524 14.416,61.548 C14.416,55.57 19.088,50.726 24.851,50.726 C30.614,50.726 35.285,55.57 35.285,61.547 Z"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
                <path
                  d="M22.5,71.419 C22.5,77.396 18.478,82.24 13.516,82.24 C8.553,82.24 4.531,77.396 4.531,71.419 C4.531,65.442 8.553,60.599 13.516,60.599 C18.478,60.599 22.5,65.442 22.5,71.419 M69.865,57.434 C69.865,63.41 65.843,68.255 60.881,68.255 C55.919,68.255 51.897,63.41 51.897,57.435 C51.897,51.457 55.919,46.613 60.881,46.613 C65.843,46.613 69.865,51.457 69.865,57.434 M235.246,8.097 C236.153,13.971 226.276,20.366 213.184,22.381 C200.094,24.398 188.745,21.271 187.838,15.397 C186.931,9.523 196.808,3.127 209.9,1.112 C222.992,-0.904 234.339,2.223 235.246,8.097 M185.29,22.23 C186.824,27.972 176.54,35.7 162.317,39.49 C148.095,43.28 135.322,41.698 133.787,35.955 C132.254,30.214 142.539,22.486 156.761,18.695 C170.983,14.905 183.756,16.487 185.291,22.23"
                  id="Shape"
                  fill="#C3DDF4"
                  fillRule="nonzero"
                />
              </g>
              <g id="Group" transform="translate(2 222)">
                <path
                  d="M532,2 L537.115,2 M551,2 L567.351,2"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <path
                  d="M546,2 C546,3.1045695 545.104569,4 544,4 C542.895431,4 542,3.1045695 542,2 C542,0.8954305 542.895431,0 544,0 C545.104569,0 546,0.8954305 546,2"
                  id="Shape"
                  fill="#006"
                  fillRule="nonzero"
                />
                <path
                  d="M579,2 L1437.976,2 M0,2 L5.115,2 M19,2 L35.351,2"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <path
                  d="M14,2 C14,3.1045695 13.1045695,4 12,4 C10.8954305,4 10,3.1045695 10,2 C10,0.8954305 10.8954305,0 12,0 C13.1045695,0 14,0.8954305 14,2"
                  id="Shape"
                  fill="#006"
                  fillRule="nonzero"
                />
                <path
                  d="M44,2 L524.976,2"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="4"
                  strokeLinecap="round"
                />
              </g>
              <g id="Group" transform="translate(644 138)">
                <path
                  d="M141.718,65.165 C141.718,54.373 151.208,43.091 165.468,43.572 C176.302,43.938 186.127,54.373 186.127,65.165 C186.127,65.231 186.117,65.295 186.117,65.362 L187.293,65.362 L187.32,65.334 L192.229,65.334 C194.196,65.334 195.805,63.731 195.805,61.774 L195.805,49.122 C195.805,47.614 194.848,46.326 193.511,45.809 C192.545,33.327 176.866,31.309 176.866,31.309 L140.761,25.756 L115.671,2.807 C72.201,-1.641 21.678,1.314 21.678,1.314 C14.77,1.011 10.521,10.747 10.521,10.747 L3.72,27.52 L4.235,44.918 C-1.632,47.546 1.888,57.768 1.888,57.768 C2.768,63.318 7.755,63.61 7.755,63.61 L18.467,64.414 C18.866,53.974 27.959,44.484 38.537,44.117 C53.31,43.604 61.807,54.373 61.807,65.165 L141.718,65.165 Z"
                  id="Shape"
                  fill="#FFF"
                  fillRule="nonzero"
                />
                <path
                  d="M8.55,37.198 L12.825,37.198 C15.08,37.198 16.925,35.36 16.925,33.116 L16.925,33.01 C16.925,30.764 15.08,28.928 12.825,28.928 L8.55,28.928 L8.55,37.198 Z"
                  id="inbon"
                  fill="#F2542A"
                  fillRule="nonzero"
                />
                <path
                  d="M8.55,37.198 L12.825,37.198 C15.08,37.198 16.925,35.36 16.925,33.116 L16.925,33.01 C16.925,30.764 15.08,28.928 12.825,28.928 L8.55,28.928 L8.55,37.198 Z"
                  id="inboff"
                  fill="#FF9316"
                  fillRule="nonzero"
                />
                <path
                  d="M89.108,31.123 L89.108,31.125 C89.126,31.511 89.288,31.859 89.552,32.111 C89.576,32.134 89.61,32.145 89.635,32.167 C89.749,32.261 89.866,32.352 90.005,32.41 C90.177,32.482 90.365,32.523 90.563,32.523 L97.905,32.523 C98.104,32.523 98.292,32.483 98.465,32.41 C98.605,32.352 98.723,32.26 98.838,32.164 C98.862,32.144 98.893,32.134 98.916,32.111 C99.2063107,31.8386967 99.3719193,31.4590265 99.374,31.061 C99.369601,30.2526573 98.7133545,29.5995379 97.905,29.599 L90.563,29.599 C89.7550357,29.6000873 89.0993964,30.2530469 89.095,31.061 C89.095,31.083 89.107,31.101 89.108,31.123 M39.052,30.478 C39.052,30.891 39.23,31.262 39.51,31.528 C39.533,31.552 39.567,31.563 39.592,31.584 C39.706,31.679 39.824,31.77 39.962,31.827 C40.134,31.899 40.322,31.94 40.521,31.94 L47.863,31.94 C48.061,31.94 48.249,31.9 48.421,31.827 C48.561,31.77 48.677,31.679 48.791,31.584 C48.816,31.563 48.85,31.552 48.874,31.528 C49.154,31.262 49.331,30.891 49.331,30.478 C49.3266036,29.6700469 48.6709643,29.0170873 47.863,29.016 L40.521,29.016 C39.7126455,29.0165379 39.056399,29.6696573 39.052,30.478"
                  id="Shape"
                  fill="#006"
                  fillRule="nonzero"
                />
                <path
                  d="M188.642,59.287 C188.649702,60.7894294 189.869551,62.0034696 191.372,62.004 L192.602,62.004 L192.602,56.57 L191.372,56.57 C189.871,56.57 188.642,57.793 188.642,59.287"
                  id="inon"
                  fill="#F2542A"
                  fillRule="nonzero"
                />
                <path
                  d="M191.372,62.004 L192.602,62.004 L192.602,56.57 L191.372,56.57 C189.871,56.57 188.642,57.793 188.642,59.287 C188.649702,60.7894294 189.869551,62.0034696 191.372,62.004 Z"
                  id="inoff"
                  fill="#FF9316"
                  fillRule="nonzero"
                />
                <path
                  d="M196.254,48.15 L189.931,48.15 M12.47,46.15 L4.47,46.15"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="1.54"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M184.725,42.494 L189.077,42.494 C187.132,38.692 186.11,33.797 175.228,34.624 C175.228,34.624 180.446,38.731 184.725,42.494"
                  id="Shape"
                  fill="#C1DEFA"
                  fillRule="nonzero"
                />
                <path
                  d="M60.258,44.7 L54.503,38.046 L25.294,37.876 L19.624,44.033 M186.631,48.138 L175.463,38.388 L153.813,37.704 L145.309,45.915"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="1.54"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M130.599,26.598 L113.378,10.351 C113.150016,10.1358547 112.851299,10.0113895 112.538,10.001 L84.222,8.974 C83.8270018,8.96126208 83.4475097,9.12820918 83.19,9.428 C82.9347995,9.729313 82.83295,10.1315447 82.914,10.518 L86.38,27.074 C86.503,27.66 87.017,28.084 87.62,28.094 L129.69,28.812 L129.712,28.812 C130.239251,28.8164527 130.715286,28.4971379 130.911185,28.0076108 C131.107085,27.5180837 130.982761,26.9585168 130.598,26.598 L130.599,26.598 Z M76.542,10.056 C76.4520987,9.42308568 75.911264,8.95209103 75.272,8.95 L46.687,8.808 L46.68,8.808 C46.1862593,8.80806665 45.735868,9.0899495 45.52,9.534 L37.887,25.305 C37.697,25.699 37.72,26.162 37.95,26.534 C38.18,26.906 38.584,27.136 39.023,27.144 L77.531,27.882 L77.555,27.882 C77.923,27.882 78.274,27.725 78.519,27.45 C78.769,27.17 78.884,26.794 78.832,26.422 L76.542,10.056 Z M35.847,9.47 C35.6136497,9.09266674 35.2026492,8.86185669 34.759,8.859 L20.558,8.758 L20.548,8.758 C20.041124,8.75768252 19.5810372,9.05423506 19.372,9.516 L12.702,24.319 C12.5257184,24.7102866 12.5572595,25.1638772 12.786,25.527 C13.016,25.89 13.412,26.115 13.843,26.127 L27.189,26.454 L27.224,26.454 C27.711636,26.4542802 28.1577529,26.1795682 28.377,25.744 L35.902,10.714 C36.1007794,10.3178841 36.0799618,9.84702763 35.847,9.47 Z"
                  id="Shape"
                  fill="#C1DEFA"
                  fillRule="nonzero"
                />
                <path
                  d="M83.712,54.981 L82.853,27.271 L79.76,9.481 M40.758,9.48 L32.683,26.244 L32.511,34.112"
                  id="Shape"
                  stroke="#006"
                  strokeWidth="1.54"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <g id="Clipped">
                  <g transform="translate(81.932 6.999)">
                    <mask id="mask-12" fill="#fff">
                      <use xlinkHref="#path-11" />
                    </mask>
                    <path
                      d="M12.925,11.142 L1.264,20.035"
                      id="Shape"
                      stroke="#FFF"
                      strokeWidth="2.054"
                      strokeLinecap="round"
                      mask="url(#mask-12)"
                    />
                  </g>
                  <g transform="translate(81.932 6.999)">
                    <mask id="mask-14" fill="#fff">
                      <use xlinkHref="#path-13" />
                    </mask>
                    <path
                      d="M14.662,14.276 L6.784,20.548"
                      id="Shape"
                      stroke="#FFF"
                      strokeWidth="1.027"
                      strokeLinecap="round"
                      mask="url(#mask-14)"
                    />
                  </g>
                  <g transform="translate(37.39 6.999)">
                    <mask id="mask-16" fill="#fff">
                      <use xlinkHref="#path-15" />
                    </mask>
                    <path
                      d="M19.271,-2.096 L7.611,6.797"
                      id="Shape"
                      stroke="#FFF"
                      strokeWidth="2.054"
                      strokeLinecap="round"
                      mask="url(#mask-16)"
                    />
                  </g>
                  <g transform="translate(37.39 6.999)">
                    <mask id="mask-18" fill="#fff">
                      <use xlinkHref="#path-17" />
                    </mask>
                    <path
                      d="M22.04,-0.502 L14.16,5.77"
                      id="Shape"
                      stroke="#FFF"
                      strokeWidth="1.027"
                      strokeLinecap="round"
                      mask="url(#mask-18)"
                    />
                  </g>
                </g>
                <g id="Shape">
                  <path
                    d="M86.837,27.46 L130.969,28.176 M37.817,26.51 L78.386,27.246 M12.817,25.51 L27.413,25.878"
                    stroke="#006"
                    strokeWidth="1.54"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M140.711,24.697 L115.671,3.322 C72.201,-1.125 21.677,1.828 21.677,1.828 C14.77,1.526 10.521,11.262 10.521,11.262 L3.72,28.035 L4.235,45.433 C-1.632,48.061 1.888,58.283 1.888,58.283 C2.768,63.833 7.755,64.125 7.755,64.125 L18.467,64.93 C18.866,54.489 27.958,45 38.537,44.632 C53.31,44.119 61.807,54.888 61.807,65.68 L141.718,65.68 C141.718,54.888 151.208,43.606 165.468,44.087 C176.302,44.453 186.126,54.888 186.126,65.68 C186.126,65.747 186.117,65.81 186.116,65.877 L187.293,65.877 L187.32,65.849 L192.229,65.849 C194.196,65.849 195.805,64.247 195.805,62.289 L195.805,49.637 C195.805,48.13 194.847,46.842 193.511,46.325 C193.567,41.038 191.639,37.418 189.077,34.945"
                    stroke="#006"
                    strokeWidth="4.621"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M26.109,67.664 C26.109,75.468 32.463,81.794 40.302,81.794 C48.142,81.794 54.496,75.468 54.496,67.664 C54.496,59.86 48.141,53.534 40.302,53.534 C32.463,53.534 26.109,59.86 26.109,67.664 M150.222,67.552 C150.222,75.356 156.577,81.682 164.416,81.682 C172.256,81.682 178.61,75.356 178.61,67.552 C178.61,59.748 172.255,53.422 164.416,53.422 C156.576,53.422 150.222,59.748 150.222,67.552"
                    fill="#FFF"
                    fillRule="nonzero"
                  />
                  <path
                    d="M26.109,67.612 C26.109,75.416 32.463,81.742 40.302,81.742 C48.142,81.742 54.496,75.416 54.496,67.612 C54.496,59.807 48.141,53.482 40.302,53.482 C32.463,53.482 26.109,59.807 26.109,67.612 Z M150.222,67.5 C150.222,75.304 156.577,81.63 164.416,81.63 C172.256,81.63 178.61,75.304 178.61,67.5 C178.61,59.696 172.255,53.37 164.416,53.37 C156.576,53.37 150.222,59.696 150.222,67.5 Z"
                    stroke="#006"
                    strokeWidth="7.701"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <g
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  id="Shape"
                  stroke="#006"
                >
                  <path
                    d="M44.659,0.129 C37.813,-0.447 31.849,3.741 31.849,3.741 L0.711,22.697"
                    strokeWidth="4.621"
                    transform="translate(140 2)"
                  />
                  <path
                    d="M48.435,34.499 C43.504,29.736 36.504,28.743 36.504,28.743 L0.4,24.206"
                    strokeWidth="1.54"
                    transform="translate(140 2)"
                  />
                </g>
              </g>
              <g id="Group" transform="translate(601 186)">
                <polyline
                  id="Shape"
                  stroke="#006"
                  strokeWidth="3.608"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="15.576 26.938 15.576 34.982 26.65 34.982 4.501 34.982"
                />
                <path
                  d="M8.285,21.64 L22.726,21.64 L15.306,8.658 L8.286,21.641 L8.285,21.64 Z M27.938,27.724 L3.214,27.724 C2.14821691,27.7221243 1.16344334,27.1549374 0.627,26.234 C0.0847844989,25.308354 0.0650701371,24.1668166 0.575,23.223 L12.27,1.595 C12.7915076,0.623156991 13.8040758,0.0156928259 14.907,0.013 L15.576,0.013 C16.6537007,0.0153440237 17.6473356,0.595568824 18.179,1.533 L30.541,23.16 C31.079,24.101 31.08,25.26 30.543,26.202 C30.0113419,27.1406705 29.016776,27.7217566 27.938,27.724 Z"
                  id="Shape"
                  fill="#F9FAFD"
                  fillRule="nonzero"
                />
                <path
                  d="M8.285,21.64 L22.726,21.64 L15.306,8.658 L8.286,21.641 L8.285,21.64 Z M27.938,27.724 L3.214,27.724 C2.14821691,27.7221243 1.16344334,27.1549374 0.627,26.234 C0.0847844989,25.308354 0.0650701371,24.1668166 0.575,23.223 L12.27,1.595 C12.7915076,0.623156991 13.8040758,0.0156928259 14.907,0.013 L15.576,0.013 C16.6537007,0.0153440237 17.6473356,0.595568824 18.179,1.533 L30.541,23.16 C31.079,24.101 31.08,25.26 30.543,26.202 C30.0113419,27.1406705 29.016776,27.7217566 27.938,27.724 Z"
                  id="Shape"
                  stroke="#F9FAFD"
                  strokeWidth="2.577"
                />
                <polygon
                  id="Shape"
                  stroke="#FF9316"
                  strokeWidth="6.018"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  points="14.907 3.054 15.576 3.054 27.938 24.682 3.214 24.682"
                />
              </g>
            </g>
          </g>
        </svg>
      </div>
    );
  }
}

export default CarAnn;
