import React, { Component } from 'react';


class PaymentCards extends Component {

    render() {

        const {
            cardType,
        } = this.props;

        return(

            <svg width="120" height="18" id="paymentcards" className={cardType} viewBox="0 0 120 18" xmlns="http://www.w3.org/2000/svg">
                <g id="Express" fill="none" fillRule="evenodd">
                    <g id="Add-ContactPayment-Entered" transform="translate(-239 -389)" fillRule="nonzero">
                        <g id="Group-2" transform="translate(239 389)">
                            <g id="discover" transform="translate(94)">
                                <path d="M1.95619048,0 L23.673619,0 C24.7495238,0 25.6298095,0.9 25.6298095,2.00009302 L25.6298095,15.999907 C25.6298095,17.1 24.7495238,18 23.673619,18 L1.95660317,18 C0.880285714,18 0,17.1 0,15.999907 L0,2.00009302 C0,0.9 0.880285714,0 1.95619048,0 Z"
                                id="Shape" fill="#DEE3E7" />
                                <path d="M9.58698413,18 L23.673619,18 C24.7495238,18 25.6298095,17.1 25.6298095,15.999907 L25.6298095,10.599907 L9.58698413,18 Z"
                                id="Shape" fill="#F68026" />
                                <path d="M4.40225397,4.09981395 L21.2279683,4.09981395 C21.9130476,4.09981395 22.4016825,4.60004651 22.4016825,5.29995349 C22.4016825,5.99986047 21.9126349,6.50009302 21.2279683,6.50009302 L4.40225397,6.50009302 C3.7171746,6.50009302 3.22812698,5.99986047 3.22812698,5.29995349 C3.22812698,4.60004651 3.7171746,4.09981395 4.40225397,4.09981395 Z"
                                id="Shape" fill="#FFF" />
                            </g>
                            <g id="amex" transform="translate(62)">
                                <path d="M1.99996875,0 L24.0000313,0 C25.1070625,0 26,0.9 26,2.00004545 L26,15.9995455 C26,17.1 25.1070625,18 24.0000312,18 L1.99996875,18 C0.8929375,18 0,17.1 0,15.9999545 L0,2.00045455 C0,0.9 0.8929375,0 1.99996875,0 Z"
                                id="Shape" fill="#1171CC" />
                                <path d="M6.9996875,11.0769545 C6.4081875,11.0769545 6.0003125,11.5155 6.0003125,12.042 L6.0003125,13.0070455 C6.0003125,13.6210909 6.4081875,13.9720909 6.9996875,13.9720909 L26,13.9720909 L26,11.0769545 L6.9996875,11.0769545 Z M0,4.99990909 L0,8.30004545 L18.6001563,8.30004545 C19.2001875,8.30004545 19.5999375,7.80013636 19.5999375,7.29981818 L19.5999375,6.00013636 C19.5999375,5.4 19.2001875,4.99990909 18.6001563,4.99990909 L0,4.99990909 Z"
                                id="Shape" fill="#FFF" />
                            </g>
                            <g id="master-card" transform="translate(30)">
                                <path d="M1.95619048,0 L23.673619,0 C24.7495238,0 25.6298095,0.9 25.6298095,2.00009302 L25.6298095,15.999907 C25.6298095,17.1 24.7495238,18 23.673619,18 L1.95660317,18 C0.880285714,18 0,17.1 0,15.999907 L0,2.00009302 C0,0.9 0.880285714,0 1.95619048,0 Z"
                                id="Shape" fill="#F0F1EA" />
                                <g id="Group" transform="translate(5.603 4.909)">
                                    <ellipse id="Oval" fill="#F79F1B" cx="10.369" cy="4.1" rx="4.011" ry="4.1"
                                    />
                                    <ellipse id="Oval" fill="#EA001B" cx="4.011" cy="4.1" rx="4.011" ry="4.1"
                                    />
                                </g>
                                <path d="M12.8151111,11.499907 C12.3260635,10.8 12.0326349,10.0000465 12.0326349,9 C12.0326349,7.99995349 12.3256508,7.2 12.8151111,6.50009302 C13.3041587,7.2 13.5975873,7.99995349 13.5975873,9 C13.5975873,10.0000465 13.3041587,10.8 12.8151111,11.499907 Z"
                                id="Shape" fill="#D6765F" />
                            </g>
                            <g id="visa">
                                <path d="M1.95619048,0 L23.673619,0 C24.7495238,0 25.6298095,0.9 25.6298095,2.00009302 L25.6298095,15.999907 C25.6298095,17.1 24.7495238,18 23.673619,18 L1.95660317,18 C0.880285714,18 0,17.1 0,15.999907 L0,2.00009302 C0,0.9 0.880285714,0 1.95619048,0 Z"
                                id="Shape" fill="#FFF" />
                                <path d="M0,13.099814 L0,15.999907 C0,17.1 0.880285714,18 1.95619048,18 L23.673619,18 C24.7495238,18 25.6298095,17.1 25.6298095,15.999907 L25.6298095,13.099814 L0,13.099814 Z"
                                id="Shape" fill="#FDA428" />
                                <path d="M1.95619048,0 C0.880698413,0 0,0.9 0,2.00009302 L0,4.90018605 L25.6302222,4.90018605 L25.6302222,2.00009302 C25.6302222,0.9 24.7495238,0 23.673619,0 L1.95660317,0 L1.95619048,0 Z"
                                id="Shape" fill="#1A2360" />
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

    )}
}

export default PaymentCards; 