import React, {Component } from 'react'; 

class ClockSVG extends Component {

 render() {
    return(
    
<svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g id='Symbols' fill='none' fillRule='evenodd'>
        <g id='05031clocktimeS' fill='#0033A0' fillRule='nonzero'>
            <path d='M12,2 C17.5138889,2 22,6.48611111 22,12 C22,17.5138889 17.5138889,22 12,22 C6.48611111,22 2,17.5138889 2,12 C2,6.48611111 6.48611111,2 12,2 Z M12,19.5 C16.1361111,19.5 19.5,16.1361111 19.5,12 C19.5,7.86388889 16.1361111,4.5 12,4.5 C7.86388889,4.5 4.5,7.86388889 4.5,12 C4.5,16.1361111 7.86388889,19.5 12,19.5 Z M16.0625,12 C16.5777778,12 17,12.4222222 17,12.9375 L17,13.5625 C17,14.0777778 16.5777778,14.5 16.0625,14.5 L12.3125,14.5 L11.6875,14.5 C11.1722222,14.5 10.75,14.0777778 10.75,13.5625 L10.75,12.9375 L10.75,6.6875 C10.75,6.17222222 11.1722222,5.75 11.6875,5.75 L12.3125,5.75 C12.8277778,5.75 13.25,6.17222222 13.25,6.6875 L13.25,12 L16.0625,12 Z'
            id='05031clocktimeSRGB' />
        </g>
    </g>
</svg>
)
}
}

export default ClockSVG;

