import { REQUESTED_SUBMIT_REQUEST, REQUESTED_SUBMIT_REQUEST_SUCCESS,REQUESTED_SUBMIT_REQUEST_FAILED,REFRESH_REDUCERS } from './../action';

const initialState = {
  request: {},
  success:false,
  fail:false,
  response : {}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUESTED_SUBMIT_REQUEST:
      return {...state, request: action.payload};
    case REQUESTED_SUBMIT_REQUEST_SUCCESS:
      return {...state, request: {},response : action.payload,success:true};
    case REQUESTED_SUBMIT_REQUEST_FAILED:
      return {...state, request: {}, response: action.payload, fail:true};
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}