import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Grid,
  Headline,
  SelectablePanel,
  Type,
  Utility,
} from "mesh-component-library";
import _isEmpty from "lodash/isEmpty";
import { ContinueAsPpuModal } from "../continue-as-ppu-modal/ContinueAsPpuModal";
import { useLocale } from "../../contexts/LocaleContext";
import { useScreenBreakpoint } from "../../hooks/useScreenBreakpoint";
import {
  getCommonBenefits,
  getPartnerDetails,
  getPpuFlag,
  getServiceInfo,
  getTowCoverages,
} from "../../selector";
import {
  clearCCardInfo,
  clearPreviousBenefits,
  commonBenefitsSuccess,
  noCommonBenefits,
  noCommonBenefitsWithPrevious,
  updateCCardInfo,
  updateCurrentLocation,
  updateLoggedInFlag,
  updatePpuFlag,
  updateTowReason,
} from "../../action";
import { generatePath } from "../../utilities/RedirectUtils";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";

const TowTriage = (props) => {
  const getTranslatedText = useLocale();
  const history = useHistory();
  const screenBreakpoint = useScreenBreakpoint();
  const dispatch = useDispatch();

  const [selectedReason, setSelectedReason] = useState("");
  const [showContinueAsPpuModal, setShowContinueAsPpuModal] = useState(false);

  const towCoverages = useSelector(getTowCoverages);
  const { displayCode = "" } = useSelector(getPartnerDetails);
  const ppuFlag = useSelector(getPpuFlag);
  const serviceInfo = useSelector(getServiceInfo);
  const commonBenefits = useSelector(getCommonBenefits);

  useEffect(() => {
    const previousBenefits = commonBenefits?.previousBenefits;
    if(!!previousBenefits && previousBenefits?.benefits) {
      dispatch(updatePpuFlag("N"));
      dispatch(updateLoggedInFlag("Y"));
      dispatch(clearCCardInfo({}));
      dispatch(commonBenefitsSuccess(previousBenefits));
      dispatch(clearPreviousBenefits());
    }
  }, []);

  const routeToDisablementLocation = () => {
    dispatch(updateTowReason(selectedReason));
    dispatch(updateCurrentLocation({ setFromLocationServices: false }));
    history.push(generatePath("disablementlocation"));
  };

  const handleOnClickContinueToTow = () => {
    if (_isEmpty(towCoverages) || ppuFlag === "Y") {
      routeToDisablementLocation();
      return;
    }
    const hasCoveredListed = towCoverages.every(({ status }) => status === "covered");
    const hasNotCoveredListed = towCoverages.every(({ status }) => status === "notCovered");
    const coverageSelectedReason = towCoverages.find(({ reason }) => reason === selectedReason);

    if (hasCoveredListed) {
      if (!!coverageSelectedReason) {
        routeToDisablementLocation();
      } else {
        setShowContinueAsPpuModal(true);
      }
      return;
    }

    if (hasNotCoveredListed) {
      if (!!coverageSelectedReason) {
        setShowContinueAsPpuModal(true);
      } else {
        routeToDisablementLocation();
      }
      return;
    }
  };

  const handleOnClickContinueAsGuest = () => {
    dispatch(updatePpuFlag("Y"));
    dispatch(updateLoggedInFlag("N"));
    dispatch(updateCCardInfo({ transactionAmount: serviceInfo.originalCost }));
    dispatch(noCommonBenefitsWithPrevious({
        message: "",
        response: commonBenefits,
      })
    );
    setShowContinueAsPpuModal(false);
    routeToDisablementLocation();
    return;
  };

  const handleOnClickCancel = () => {
    setShowContinueAsPpuModal(false);
    return;
  };

  const towTriageReasons = [
    {
      id: "accident",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_ACCIDENT),
    },
    {
      id: "vandalism",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_VANDALISM),
    },
    {
      id: "tire_standard_without_spare",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_TIRE_WITHOUT_SPARE),
    },
    {
      id: "mechanical_key",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_MECHANICAL_KEY),
    },
    {
      id: "battery",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_BATTERY),
    },
    {
      id: "other",
      reason: getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_OTHER),
    },
  ];

  return (
    <>
      <Grid verticalAlign="middle" id="tow-triage-page">
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Utility.Div
            utils={{
              hrLeft: "md",
              hrRight: "md",
              vrTop: "md",
              vrBottom: "md",
            }}
          >
            <Headline
              title={
                <Type
                  variant="darker"
                  align="left"
                  size={screenBreakpoint === "sm" ? "7xl" : "3xl"}
                  weight="semibold"
                >
                  {getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_TITLE)}
                </Type>
              }
            />
          </Utility.Div>
        </Grid.Column>
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Utility.Div
            utils={{
              hrLeft: "md",
              hrRight: "md",
            }}
          >
            <Type align="left" weight="bold">
              {getTranslatedText(TRANSLATION_CONSTANTS.TOW_TRIAGE_REASON_LABEL)}
            </Type>
          </Utility.Div>
        </Grid.Column>
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Utility.Div
            utils={{
              hrLeft: "md",
              hrRight: "md",
            }}
          >
            {towTriageReasons.map(({ id: reasonId, reason }, index) => (
              <SelectablePanel
                key={index}
                id={reasonId}
                checked={selectedReason === reasonId}
                hasBorder
                showInput
                text={reason}
                type="radio"
                onChange={(event) => {
                  setSelectedReason(event.currentTarget.id);
                }}
              />
            ))}
          </Utility.Div>
        </Grid.Column>
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
          utils={{
            vrTop: "md",
          }}
        >
          <Utility.Div
            utils={{
              hrLeft: "md",
              hrRight: "md",
            }}
          >
            <Button
              hasUpgrade
              shape="pill"
              size="lg"
              onClick={handleOnClickContinueToTow}
              isBlock
              disabled={_isEmpty(selectedReason)}
            >
              {getTranslatedText(
                TRANSLATION_CONSTANTS.CONTINUE_TO_TOW,
                displayCode
              )}
            </Button>
          </Utility.Div>
        </Grid.Column>
      </Grid>
      <ContinueAsPpuModal
        active={showContinueAsPpuModal}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TOW_REASON_NOT_COVERED_MSG
        )}
        primaryButton={{
          value: getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST),
          onClick: handleOnClickContinueAsGuest,
        }}
        closeButton={{
          onClick: handleOnClickCancel,
        }}
      />
    </>
  );
};

export default TowTriage;
