import React from 'react';
import Icon from '../icon';
import {OkayC, RemovecloseC} from '../../icon-library';

class ServiceCancelled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {entryPage: 'Confirmation'};
  }

  //TODO: confirm with Kyle whether this else block in the ternary should ever be used
  // since even comparing to master that state.entryPage is never changed so it will always be Confirmation
  render() {
    const {messageText = ""} = this.props
    return (
      this.state.entryPage === 'Confirmation' ?
        <div className="l-grid__col">
          <div className="l-container-home__center">
            <div className="l-container-home__service">
              <Icon icon={OkayC} className="l-container-home__service--icon"
                    size={1.5} color={'green-light'}/>
              <h5 className="l-container-home__service--title">
                {messageText}
              </h5>
            </div>
          </div>
        </div>
        :
        <div className="l-grid__col">
          <div className="l-container-home__center">
            <div className="l-container-home__error">
              <Icon icon={RemovecloseC}
                    className="l-container-home__error--icon" size={1.5}
                    fill={'#cf102d'}/>
              <h5 className="l-container-home__error--title">Your rescue could
                not be canceled
              </h5>
            </div>
          </div>
        </div>
    )
  }
}

export default ServiceCancelled;

