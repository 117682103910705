import React, { Component } from 'react';
import { connect } from 'react-redux';
import {find} from 'lodash';
import {sendJacadaRequest} from '../../api';
import {generatePath} from '../../utilities/RedirectUtils';
import HelpContinue from '../../components/help-continue';
import {
  updateServiceInfo,
  updateCurrentLocation,
  updateServiceTransition,
  updateTowReason
} from '../../action';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import { LocaleContext } from '../../contexts/LocaleContext';
import { DISPLAY_TRIAGE_STEPS_STATUSES, FUEL_DELIVERY } from "../../app-consts/appConstants";
import { getTowCoverages } from '../../selector';
import {
  UNABLE_TO_COMPLETE_SERVICE
} from '../../app-consts/errorMessageConstants';

class HelpStuck extends Component {
  static contextType = LocaleContext;
  constructor(props) {
    super(props);

    this.gotoLocation = this.gotoLocation.bind(this);
    this.renderBasedOnPathParams = this.renderBasedOnPathParams.bind(this);
  }

  gotoLocation() {
    let payload = "Tow";

    const {
      serviceInfo, partnerDetails: {
        partnerDetails: {
          experience
        }
      }
    } = this.props;
    let service = find(this.props.serviceDetails?.serviceDetails, ['serviceText', payload]);
    serviceInfo['value'] = payload;
    serviceInfo['cost'] = service.cost;
    localStorage.setItem('serviceText',payload);
    this.props.updateServiceInfo(service);

    let jacadaRequestData={};
    let eventsData=[];
    eventsData.push(
      {
        'name':'svc_id',
        'value':45
      },
      {
        'name':'event_id',
        'value':52

      }
      );
      jacadaRequestData = {
        'externalSystemId': localStorage.getItem('conversationId'),
        'events': eventsData
      }
      sendJacadaRequest(jacadaRequestData);

      if (experience?.schedulingEnabled) {
        this.props.history.push(generatePath('ScheduleService'));
      } else if (this.props.commonBenefits?.experience?.towTriageEnabled && this.props.towCoverages.every(({ status }) => status === "covered")) {
        this.props.updateServiceTransition({
          errorMessageContent: UNABLE_TO_COMPLETE_SERVICE,
        })
        this.props.history.push(generatePath("ServiceTransition"));
      } else {
        if (this.props.commonBenefits?.experience?.towTriageEnabled) {
          this.props.updateTowReason("ev_out_of_charge");
        }
        this.props.updateCurrentLocation({setFromLocationServices: false})
        this.props.history.push(generatePath('disablementlocation'));
      }
  }

  renderBasedOnPathParams() {
    const getTranslatedText = this.context;
    const partnerCode = this.props.match.params.partnercode;
    if(this.props.match.params.type==='stuck')
        return (
          <HelpContinue
            className={'l-container-home'}
            partnerCode={partnerCode}
            header={getTranslatedText(TRANSLATION_CONSTANTS.WE_CAN_HELP)}
            content={getTranslatedText(TRANSLATION_CONSTANTS.GIVE_US_A_CALL)}
            call='true'
            partnerPhone={this.props.partnerDetails?.partnerDetails?.phone}
          />
        );

    if(this.props.match.params.type==='recharge' && !DISPLAY_TRIAGE_STEPS_STATUSES.includes(this.props.commonBenefits?.status)) {
      return (
        <HelpContinue
          className={'l-container-home'}
          partnerCode={partnerCode}
          header={getTranslatedText(TRANSLATION_CONSTANTS.LOOKS_LIKE_CAN_NOT_CHARGE)}
          content={getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_LIKE_US_TO_TOW)}
          handleClick={this.gotoLocation}
          btntext={getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        />
      );
    } else {
      return (
        <HelpContinue
          className={'l-container-home'}
          partnerCode={partnerCode}
          header={getTranslatedText(TRANSLATION_CONSTANTS.LOOKS_LIKE_CAN_NOT_CHARGE)}
          content={getTranslatedText(TRANSLATION_CONSTANTS.GIVE_US_A_CALL)}
          call='true'
          partnerPhone={this.props.partnerDetails?.partnerDetails?.phone}
        />
      );
    }

  }

  componentDidMount() {
    document.title = 'Help | Roadside Assistance';
  }

  render() {
    return (
      <div>
      {this.renderBasedOnPathParams()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    serviceInfo: state.serviceInfo,
    partnerDetails : state.partnerDetails,
    serviceDetails : state.serviceDetails,
    commonBenefits: state.commonBenefits,
    towCoverages: getTowCoverages(state)
  };
}

export default connect(mapStateToProps,{
  updateServiceInfo,
  updateCurrentLocation,
  updateServiceTransition,
  updateTowReason
})(HelpStuck);
