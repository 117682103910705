import React, { Component } from 'react';

class LocationPinPoint extends Component {

    render() {
        return (
            <svg id="location-pin-point" xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'>
                <path fill='#4E5969' fillRule='evenodd' stroke='#FFF' d='M32 31.5c4.22 0 7.64-3.405 7.64-7.602 0-4.2-3.42-7.604-7.64-7.604-4.222 0-7.642 3.404-7.642 7.604 0 4.198 3.42 7.602 7.642 7.602zm7.119-28.833h.002C48.225 5.706 54.5 14.213 54.5 23.897c0 7.111-2.444 11.33-9.992 20.08-6.38 7.395-8.866 11.096-10.434 16.936A2.144 2.144 0 0 1 32 62.5c-.972 0-1.823-.65-2.076-1.588-1.5-5.593-4.084-9.555-9.722-16.111l-.712-.826c-.806-.935-1.16-1.347-1.6-1.864-5.937-6.997-8.39-11.683-8.39-18.215 0-9.685 6.274-18.191 15.381-21.229a23.217 23.217 0 0 1 3.37-.83A22.392 22.392 0 0 1 32 1.5c1.205 0 2.432.115 3.738.335l.278.047a23.29 23.29 0 0 1 3.102.785z'
                />
            </svg>

        )
    }
}

export default LocationPinPoint; 
