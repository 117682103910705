import React, {useEffect} from 'react'
import Icon from '../../components/icon';
import { CallconnectS } from '../../icon-library';
import StarRating from '../../components/starrating';
import {useSelector} from 'react-redux'
import SlideArrow from '../../components/svg/SlideArrow';
import WebsiteIcon from '../../components/svg/WebsiteIcon';
import ClockSVG from '../../components/svg/ClockIcon/Clock';
import DetailedPin from '../../components/svg/DetaliedPin';
import {PEP} from "../../app-consts/appConstants";
import { useLocale } from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import { getTextByUnits } from '../../utils/translatedTextUtils';

const DetailedLocationReview = (props) => {

  const {
    displayCode,
    destination: {
      towInfo,
      rating,
      photos,
      name,
      formatted_address,
      user_ratings_total,
      url,
      website,
      formatted_phone_number,
      operatingHours,
      reviews,
    },
    isActive,
    closeDetailedLocationReview,
  } = props;

  const serviceInfo = useSelector(state => state.serviceInfo)
  const commonBenefits = useSelector(state => state.commonBenefits)
  const {benefits, experience: {overMileageEnabled, passThruEnabled}} = commonBenefits
  const getTranslatedText = useLocale();

  useEffect(()=>{
    document.title = 'Detailed Location | Roadside Assistance';
  },[])

  let isPep = displayCode === PEP;
  const displayRating = Math.round( rating * 10 ) / 10;

  return (
    <div className={`location ${!isActive ? 'location--close' : ''}`} >
      <div className="scrollLocationArea">

        {
          photos ?
          <div className="location__photo" style={{ backgroundImage: `url(${photos[0].url})` }}>
            <div role="link"
                 tabIndex="0"
                 onClick={()=>closeDetailedLocationReview()}
                 onKeyDown={closeDetailedLocationReview}>
              <SlideArrow direction="down" size="large" />
            </div>
          </div>
          : null
        }

        <div role="link"
             tabIndex="0"
             className="location__detailed--blue--container"
             onClick={closeDetailedLocationReview}
             onKeyDown={closeDetailedLocationReview} >
          <div className="location__detailed--handle location__detailed--blue">
            <SlideArrow color="light" direction="down" />
          </div>
          <div className={`${towInfo?.overMileageDistance > 0 && 'location__detailed--extraMiles'} location__detailed`}>
            <div className="location__detailed--left">
              <h2 className="dark">{name}</h2>
              {formatted_address}
              <StarRating starRating={rating} reviews={user_ratings_total} />
            </div>
            <div className="location__detailed--right">
              {!benefits && !passThruEnabled ? <p className="price">${towInfo?.totalTowCost}</p> : null}
              <p>{towInfo?.distanceFromCurrentLocation} {towInfo?.unitsShorthand}</p>
            </div>
          </div>

          {(!benefits || (benefits && overMileageEnabled)) && towInfo?.overMileageDistance > 0 &&
            <div>
              <div className="location__detailed--extraCost">
                <p>{serviceInfo.towIncludedDistanceQty} {getTextByUnits(towInfo.distanceUnits, TRANSLATION_CONSTANTS.MILE, TRANSLATION_CONSTANTS.KILOMETER)} {getTranslatedText(TRANSLATION_CONSTANTS.INCLUDED)}</p>
                <p>${isPep ? serviceInfo.originalCost : serviceInfo.cost}</p>
                <p>{towInfo?.overMileageDistance} {getTranslatedText(TRANSLATION_CONSTANTS.ADDITIONAL)} {getTextByUnits(towInfo.distanceUnits, TRANSLATION_CONSTANTS.MILES, TRANSLATION_CONSTANTS.KILOMETERS)}</p>
                <p>${(towInfo?.totalTowCost - parseFloat(isPep ? serviceInfo.originalCost : serviceInfo.cost)).toFixed(2)}</p>
              </div>
              <p className="location__detailed--extraCostDesc">+ ${serviceInfo.costPerMile}/{getTextByUnits(towInfo.distanceUnits, TRANSLATION_CONSTANTS.MILE, TRANSLATION_CONSTANTS.KILOMETER).toLowerCase()} {getTranslatedText(TRANSLATION_CONSTANTS.OVER)}  {serviceInfo.towIncludedDistanceQty} {getTextByUnits(towInfo.distanceUnits, TRANSLATION_CONSTANTS.MILES, TRANSLATION_CONSTANTS.KILOMETERS).toLowerCase()}</p>
              <div className="location__detailed--extraCostTotal">
                <div className="location__detailed--extraCostTotalInner">
                  <p>{getTranslatedText(TRANSLATION_CONSTANTS.ESTIMATED_AMOUNT_DUE)}</p>
                  <p>${towInfo?.totalTowCost}</p>
                </div>
              </div>
            </div>
          }

        </div>

        <div className="location__breakdown">
          <div className="location__breakdown--address">
            <DetailedPin />
            <p><a href={url}>{formatted_address}</a></p>
          </div>
          {website &&
            <div className="location__breakdown--url">
              <WebsiteIcon size="small" />
              <p><a href={website} target="_blank" rel="noreferrer">{getTranslatedText(TRANSLATION_CONSTANTS.WEBSITE)}</a></p>
            </div>
          }
          {formatted_phone_number &&
            <div className="location__breakdown--phone">
              <Icon icon={CallconnectS} color="brand" size={1.15} />
              <p><a href={`tel:${formatted_phone_number}`}>{formatted_phone_number}</a></p>
            </div>
          }
          {operatingHours &&
            <div className="location__breakdown--opentime">
              <ClockSVG />
              <p>{operatingHours?.text}</p>
            </div>
          }
        </div>

        <div className="location__gallery">
          {photos ?
            photos.map((photo, i) => {
              return (
                <img key={i}
                     className="detailedPhotos"
                     aria-hidden="true"
                     src={photo.url}
                     height={100}
                     width={photo.width*(100/photo.height)}
                     alt={`LocationImage ${i}`}
                     />)
            })
            : null
          }
        </div>

        {
          rating ?
          <div className="location__summary">
            <h4 className="location__summary--title">{getTranslatedText(TRANSLATION_CONSTANTS.REVIEW_SUMMARY)}</h4>
            <div className="location__summary--c">
              <h1 className="location__summary--rating"> {displayRating}</h1>
              <StarRating className="location__summary--starRating"
                          starRating={rating}
                          reviews={user_ratings_total ? user_ratings_total + " reviews" : ""}
                          baseColor="orange" />
            </div>
            <hr />
          </div>
          : null
        }

        <div className="location__reviews">
          <h4 className="location__reviews--title">{getTranslatedText(TRANSLATION_CONSTANTS.REVIEWS)}</h4>
          <div>
            {reviews ?
              reviews.map((review, i) => {
                return (
                  <div className="location__reviews--details" key={i}>
                    <img className="location__reviews--details--photo" src={review.profile_photo_url} alt={review.author_name} />
                    <h2 className="location__reviews--details--name">{review.author_name}</h2>
                    <h5 className="location__reviews--details--reviewTime">{review.relative_time_description}</h5>
                    <StarRating className="location__reviews--details--starRating" starRating={review.rating} baseColor="orange" />
                    <p className="location__reviews--details--review">{review.text}</p>
                    <hr className="location__reviews--details--break" />
                  </div>
                )
              })
              :
              <div className="location__reviews--unavailable">
                <h5 className="location__reviews--unavailable--reason">{getTranslatedText(TRANSLATION_CONSTANTS.REPAIR_SHOP_NOT_BEEN_REVIEWED)}</h5>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailedLocationReview
