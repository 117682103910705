import {
  REQUESTED_PARTNER_DETAILS,
  REQUESTED_PARTNER_DETAILS_SUCCESS,
  REQUESTED_PARTNER_DETAILS_FAILED,
  REFRESH_REDUCERS,
  UPDATE_PARTNER_DETAILS_EXPERIENCE,
  UPDATE_PARTNER_DETAILS_PHONE,
  REFRESH_REDUCER_PARTNER_DETAILS
} from '../action';

const getInitialState = () => {
  const defaultRoute = 'member';
  const defaultDisplayCode = 'amc';

  const routePathParam = window.location.pathname.split('/')[1];
  const displayCodePathParam = window.location.pathname.split('/')[2];
  let route = routePathParam || defaultRoute;
  let displayCode = displayCodePathParam || defaultDisplayCode;

  if (routePathParam === 'cu' || routePathParam === 'aarp' || routePathParam === 'ghr') {
    route = "ppu";
    displayCode = routePathParam;
  }
  if(displayCodePathParam === "M56"){
    displayCode = "MF";
   }
  if(displayCodePathParam === "K06"){
    displayCode = "DS";
   }
  return {
    partnerDetails: {
      route,
      displayCode,
    },
    loading: false,
    error: false,
  };
};

export default function(state = getInitialState(), action = {}){
  switch(action.type){
        case REQUESTED_PARTNER_DETAILS:
            return {...state, loading: true};
        case REQUESTED_PARTNER_DETAILS_SUCCESS:
            return {...state, partnerDetails: action.payload, loading: false};
        case UPDATE_PARTNER_DETAILS_EXPERIENCE:
          let partnerDetails = {...state.partnerDetails}
          partnerDetails.experience = action.payload
          return {...state, partnerDetails};
    case UPDATE_PARTNER_DETAILS_PHONE:
      return {
        ...state,
        partnerDetails: {
          ...state.partnerDetails,
        phone: action.payload
        }
      };
        case REQUESTED_PARTNER_DETAILS_FAILED:
            return {...state, loading: false, error: true, unauthorized: action.payload};
        case REFRESH_REDUCERS:
            return {...getInitialState()};
        case REFRESH_REDUCER_PARTNER_DETAILS:
            return {...getInitialState()};
        default:
            return state;
    }
}
