import React, { Component } from 'react';

class Call extends Component {

    render() { 
        const {
            color
        } = this.props;
        let iconColor;
        if(color === 'gray') {
            iconColor="#4e5969"
        } else {
            iconColor="#0033A0"
        }
        return(

            <svg id="callicon" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                <path fill={iconColor} fillRule='evenodd' d='M15.303 11.2c-.59-.774-1.505-1.654-2.119-1.764-.401-.069-.902.112-1.415.525-.434.35-1.028.828-1.783 1.084-.467.097-1.108.232-1.973-.6A102.36 102.36 0 0 1 5.52 7.908c-.81-.874-.65-1.492-.543-1.912.3-.753.81-1.313 1.215-1.757.417-.457.623-.946.565-1.34C6.67 2.29 5.805 1.36 5.038.751 4.216.102 3.533-.104 2.674.047c-.396.069-.634.308-.994.67l-.044.045C-.037 2.435-.01 3.924.002 4.484c.015.766.347 2.091 1.235 3.517 1.072 1.722 1.902 2.618 2.875 3.612l.075.076c.976.99 1.86 1.837 3.575 2.954 1.418.921 2.757 1.298 3.538 1.344.098.005.225.013.376.013.725 0 2.015-.165 3.483-1.46l.05-.044c.383-.338.636-.56.722-.947.187-.825 0-1.528-.628-2.35'
                />
            </svg>

    )}
}

export default Call; 
