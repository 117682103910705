import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Icon from '../icon';
import generateID from './../../utilities/generateID';
import ErrorIcon from '../svg/errorIcon';

export default class Alert extends React.Component {

  state = {
    isActive: this.props.isActive,
    isExpanded: false
  };

  static propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onDismiss: PropTypes.func,
    alertMessages: PropTypes.array,
    supportText: PropTypes.string,
  };

  static defaultProps = {
    type: 'info',
    className: '',
    dismissible: false,
    isActive: true,
    toggleExpand: false,
    onDismiss(event) {},
    onToggle(event) {},
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const {
      isActive,
    } = nextProps;

    if(isActive !== this.state.isActive) {
      this.setState({
        isActive: isActive,
      });
    }
  }

  UNSAFE_componentWillMount() {
    const { toggleExpand } = this.props;

    if (toggleExpand !== this.state.isExpanded) {
      this.setState({ isExpanded: toggleExpand });
    }
  }

  componentDidUpdate(prevProps) {
    const { toggleExpand } = this.props;

    if (prevProps.toggleExpand !== toggleExpand) {
      this.setState({ isExpanded: toggleExpand });
    }
  }

  _onDismiss = event => {
    this.setState({
      isActive: false,
    }, this.props.onDismiss(event))
  }

  _toggleBody = event => {
    this.setState({
      isExpanded: !this.state.isExpanded,
    }, this.props.onToggle(event))
  }

  render() {
    const {
      type,
      children,
      className,
      dismissible,
      isActive,
      onDismiss,
      onToggle,
      title,
      toggleBody,
      alertMessages,
      supportText,
      toggleExpand,
      ...other
    } = this.props;

    const _isActive = this.state.isActive;

    const isExpanded = this.state.isExpanded;

    const {
      _onDismiss,
      _toggleBody
    } = this;

    let icon;

    switch (type) {
      case 'error':
        icon = <ErrorIcon />;
        break;
      case 'warning':
        icon = <Icon icon="flag-circle" size={ 1.5 } color="alert-orange" />;
        break;
      case 'confirmation':
        icon = <Icon icon="check-circle" size={ 1.5 } color="alert-green" />;
        break;
      case 'info':
        icon = <Icon icon="info-circle" size={ 1.5 } color="alert-blue" />;
        break;
      default:
        break;
    }

    let alert = (
      <div
        { ...other }
        role="alert"
        aria-hidden={ _isActive }
        className={ classnames(
          `c-alert c-alert--${type} animated ${className}`,
          { 'c-alert--is-dismissible': dismissible }
        ) }>
        {createDismissBtn(
          dismissible,
          _onDismiss,
          type,
        )}
        <div className="c-alert__icon">
            { icon }
        </div>
        <div>
          { toggleBody ?
            <div className="c-alert__title">
              <span className="u-hr">
                { alertMessages && !title ? getMessageCount(alertMessages, type) : title }
                { supportText && !title ? supportText : null }
              </span>
            </div> : null
          }

          { alertMessages
            ? <div className="c-alert__bd">
                { children }
                <ul className="c-list c-list--flush">
                  { (isExpanded ? alertMessages : alertMessages.filter((message, i) => i < 3 ? message: null )).map(
                    message => <li className="c-list__item" key={ generateID('message-') }>{ message }</li>
                  ) }
                </ul>
              </div>
            : <div className={`c-alert__bd ${ toggleBody && !isExpanded ? 'is-hidden' : ''}`}>
                { children }
              </div>
          }

          { toggleBody || (alertMessages && alertMessages.length > 3)
            ? <button
                type="button"
                onClick={ _toggleBody }
                className="c-btn c-btn--phony c-alert__expand u-vr-top"
                aria-label="Dismiss Alert">
                { isExpanded ? 'View Less' : 'View All' }
              </button>
            : null
          }

        </div>
        <div className="c-alert__close">
          {this.props.closeAlert?<span className="u-h6 c-form-upload__info__delete u-float--right">
            <Icon
              className="u-hr-left c-form-upload__upload-list-btn c-form-upload__upload-list-btn--delete"
              onClick={ (e) => this.props.closeAlert() }
              icon="cross"
              size={ 0.75 }
              alt={ 'Remove file' }
              id="removeFile" />
          </span>:""}
        </div>
      </div>
    );

    return (
      <div>
        { _isActive ? alert : null }
      </div>
    );
  }
}

function getMessageCount(alertMessages, type) {
  if(alertMessages.length > 1) {
    return (
      <b>{alertMessages.length} {type}s. </b>
    )
  }
  return(
    <b className="u-text-capitalize">{type}. </b>
  )
}


function createDismissBtn(
  dismissible,
  _onDismiss,
  type,
  ) {
  if(!dismissible) {
    return;
  }

  return(
    <button
      onClick={ _onDismiss }
      className="c-btn c-btn--phony c-alert__btn"
      aria-label="Dismiss Alert">
      <Icon
        icon="cross"
        color={ type === 'error' ?  'alert-red' :
                type === 'warning' ?  'alert-orange' :
                type === 'confirmation' ?  'alert-green' :
                'alert-blue'
        }
        size={ 1 } />
    </button>
  )
}
