import {
  UPDATE_IS_PRIMARY_LOOKUP,
  UPDATE_PPU_FUEL_PRICE,
  UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST,
  CLEAR_TOW_TO_CUSTOM_DESTINATION_LIST,
  REFRESH_REDUCERS,
  UPDATE_DUPLICATE_RESCUE_IS_ACTIVE,
  CLEAR_DUPLICATE_RESCUE,
  UPDATE_RESCUE_DETAILS
} from '../action';

const initialState = {
  isPrimaryLookup: false,
  ppuFuelPrice: 0,
  towToCustomDestinationList: [],
  duplicateRescue: {
    usageId: "",
    duplicateRescueIronDoor: {
      name: "",
      group: "",
      enabled: false,
      nthRequest: 0,
      currentCount: 0,
      partnerCodes: [],
      appIds: []
    }
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_IS_PRIMARY_LOOKUP:
      return {
        ...state,
        isPrimaryLookup: action.payload
      };
    case UPDATE_PPU_FUEL_PRICE:
      return {
        ...state,
        ppuFuelPrice: action.payload
      };
    case UPDATE_TOW_TO_CUSTOM_DESTINATION_LIST:
      return {
        ...state,
      towToCustomDestinationList: action.payload
      };
    case CLEAR_TOW_TO_CUSTOM_DESTINATION_LIST:
      return {
        ...state,
        towToCustomDestinationList: []
      }
    case UPDATE_DUPLICATE_RESCUE_IS_ACTIVE:
      return {
        ...state,
      duplicateRescue: action.payload
      };
      case UPDATE_RESCUE_DETAILS:
      return {
        ...state,
      rescueDetailsSent: action.payload
      };
    case CLEAR_DUPLICATE_RESCUE:
      return {
        ...state,
        duplicateRescue: {
          ...initialState.duplicateRescue
        }
      }
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}
