import React, { useEffect, useState } from "react";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import Alert from "../alert";
import { useLocale } from "../../contexts/LocaleContext";
import CONSTANTS from "../../app-consts/appConstants";

const StartOverAlertMessage = ({
  enhancedAuthPartner,
  benefitsLoading,
  isAlternateLookup,
  primaryLookup
}) => {
  const getTranslatedText = useLocale();
  const [startOverAlert, setStartOverAlert] = useState(false);

  useEffect(() => {
      if (enhancedAuthPartner &&
        (localStorage.getItem('feedbackConfirmation') === 'YES'
          || localStorage.getItem('rescueCancel') === 'YES')) {
        setStartOverAlert(true);
      }
  }, [enhancedAuthPartner]);

  useEffect(() => {
    if (isAlternateLookup && !benefitsLoading) {
      setStartOverAlert(false);
    }
  }, [benefitsLoading]);

  return (
    startOverAlert && (
      <div style={{ margin: "1rem" }}>
        <Alert type="info">
          {primaryLookup === CONSTANTS.MOTOR_NUMBER_LOOKUP ? getTranslatedText(TRANSLATION_CONSTANTS.APP_REDIRECTION_ALERT_MESSAGE) : getTranslatedText(TRANSLATION_CONSTANTS.START_OVER_ALERT_MESSAGE)}
        </Alert>
      </div>
    )
  );
};

export default StartOverAlertMessage;
