
import {
  REFRESH_REDUCERS,
  UPDATE_SERVICE_DETAILS
} from './../action';

import {
  FueldelivF,
  JumpstartF,
  LockoutF,
  RoadsideasstF,
  TirechangeF
} from '../icon-library';

const initialState = {
  serviceDetails : [
      {id: 1, serviceType: "Tow", serviceText: "Tow", cost: "",  eta: "", icon: RoadsideasstF, iconSize: 2, service: "tow", value: "Tow"},
      {id: 2, serviceType: "Lockout", serviceText: "Lockout", cost: "",  eta: "", icon: LockoutF, iconSize: 1.5, service: "lockout", value: "Lockout"},
      {id: 3, serviceType: "Gas", serviceText: "Fuel Delivery", cost: "",  eta: "", icon: FueldelivF, iconSize: 1.5, service: "gas", value: "Fuel Delivery"},
      {id: 4, serviceType: "Changetire", serviceText: "Tire Change", cost: "",  eta: "", icon: TirechangeF, iconSize: 1.5, service: "tire", value: "Tire Change"},
      {id: 5, serviceType: "Battery", serviceText: "Jump Start", cost: "",  eta: "", icon: JumpstartF, iconSize: 1.5, service: "battery", value: "Jump Start"}
  ],
  loading: false,
  error: false,
};

function replaceServiceCost(serviceCosts = []) {
    serviceCosts = serviceCosts || [];
    return initialState.serviceDetails.reduce((accumulator, serviceDetail) => {
        let serviceCostByType = serviceCosts.find(serviceCost => {
            if(!serviceCost?.serviceType) {
                return false;
            }
            return serviceCost?.serviceType === serviceDetail?.serviceType
        }) || {};
        let mergedService = {
            ...serviceDetail,
            ...serviceCostByType,
        };
        mergedService.originalCost = Math.abs(mergedService.cost).toFixed(2);
        mergedService.exactCost = Math.abs(mergedService.cost).toFixed(2);
        mergedService.cost = Math.ceil(mergedService.cost);
        accumulator.push(mergedService);
        return accumulator;
    }, []);
}

export default function(state=initialState, action){
    switch(action.type){
        case UPDATE_SERVICE_DETAILS:
            let serviceDetails = replaceServiceCost(action.payload.serviceCosts);
            return {...state, serviceDetails: [...serviceDetails], loading: false};
        case REFRESH_REDUCERS:
            return {...initialState};
        default:
            return state;
    }
}
