import { cookieMatcher } from "./cookieUtils";

const getUserTypeFromUrl = () => {
  let cookieMatches = cookieMatcher("acc-mgmt-token");
  let token =  cookieMatches ? cookieMatches[2] : undefined;
  // TODO: was previously the below line, needs COVERAGE!!! Also validate the behavior is essentially the same (with the minor
  // difference due to OmniAssist needing to be a user type
  // return window.location.pathname && window.location.pathname.split('/')[1]?  window.location.pathname.split('/')[1] : token ? "benefits" : "ppu";
  if (window.location.pathname) {
    const base = window.location.pathname.split('/')[1];
    if (base) {
      return base;
    }
  }
  return token ? 'benefits' : 'ppu';
}

export default getUserTypeFromUrl;
