import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  FormField,
  Flex,
  Type,
  Button,
  Tooltip,
  Icon,
  Alert,
} from "mesh-component-library";
import { QuestionIcon } from "mesh-icon-library";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { getCommonBenefits } from "../../selector";
import { errorMessageTranslatedText } from "../../utils/translatedTextUtils";
import CONSTANTS from "../../app-consts/appConstants";

const RentalAgreementLookup = ({
  partnerDetails,
  dispatchCommonBenefitsLookup,
  showModal,
  closeModal,
  enhancedAuthPartner,
  setCurrentLookup,
  setAlternateLookupAttempted,
  alternateLookupAttempted,
  primaryLookupAttempted,
  currentLookup
}) => {
  const {
    appId,
    experience: {
      lookupMethods: {
        components: { primary },
      },
      lookupMethodRules: { primary:primaryRule, alternate:alternateRule }
    },
  } = partnerDetails;
  const isPrimaryComponent = primary === currentLookup;
  const regex = isPrimaryComponent ? primaryRule?.rentalAgreementNumber?.regex : alternateRule?.rentalAgreementNumber?.regex;
  const errorMessage = isPrimaryComponent ? primaryRule?.rentalAgreementNumber?.errorMessage : alternateRule?.rentalAgreementNumber?.errorMessage;
  const maxLength = isPrimaryComponent ? primaryRule?.rentalAgreementNumber?.maxLength : alternateRule?.rentalAgreementNumber?.maxLength;
  const commonBenefits = useSelector(getCommonBenefits);
  const [rentalAgreementNumber, setRentalAgreementNumber] = useState("");
  const [rentalAgreementNumberHasError, setRentalAgreementNumberHasError] = useState(false);
  const getTranslatedText = useLocale();

  const showErrorMessage = commonBenefits.errorMessage === CONSTANTS.INVALID_MVA_NUMBER;

  const continueClick = () => {
    let urlParams = new URLSearchParams(window.location.search);
    const arsTokenParameter =
      urlParams.get("ARS_TOKEN") || urlParams.get("ars_token");
    const user = {};
    user.rentalAgreementNumber = rentalAgreementNumber;
    user.phoneLookup = false;
    user.appId = appId;
    user.lookupType = "rentalAgreementLookup";
    if (enhancedAuthPartner) {
      user.arsToken = arsTokenParameter;
    }
    setAlternateLookupAttempted(alternateLookupAttempted + 1);
    dispatchCommonBenefitsLookup(user);
  };

  const onRentalAgreementNumberChange = (e) => {
    const localRentalAgreementNumber = e.target?.value;
    let isValid = true;
    if (regex && localRentalAgreementNumber) {
      const stringToRegex = new RegExp(regex);
      isValid = stringToRegex.test(localRentalAgreementNumber);
    }
    setRentalAgreementNumber(localRentalAgreementNumber);
    setRentalAgreementNumberHasError(!isValid);
  };

  const onSubmit = () => {
    const confirmText = `${getTranslatedText(
      TRANSLATION_CONSTANTS.RENTAL_AGREEMENT_NUMBER
    )}:`;
    const content = (
      <div>
        <p>
          <span>{confirmText}</span> {rentalAgreementNumber}
        </p>
      </div>
    );
    const options = {
      titleHtml: content,
      yesNoSelected,
    };

    showModal(options);
  };

  const yesNoSelected = async ({ target: { value } }) => {
    closeModal();
    if (value === "yes") {
      continueClick();
    }
  };

  const searchByMvaNumber = () => {
    setCurrentLookup("motorNumberLookup");
  };

  const handleOnKeyDownRaNumber = (e) => {
    if (
      e.keyCode !== CONSTANTS.DELETE &&
      e.keyCode !== CONSTANTS.BACKSPACE &&
      e.target.value !== undefined &&
      (e.keyCode === CONSTANTS.EXPONENTIAL ||
        e.keyCode === CONSTANTS.DECIMALPOINT)
    ) {
      e.preventDefault();
    }
  };

  const displayNotFoundMessage = () => {
    return (
      showErrorMessage && (
        <Grid
          utils={{
            vrTop: "sm",
          }}
        >
          <Grid.Column>
            <Alert
              title={
                "Sorry, we are having difficulty looking up your information."
              }
              variant="critical"
            />
          </Grid.Column>
        </Grid>
      )
    );
  };

  return (
    <>
      {displayNotFoundMessage()}
      <Grid verticalAlign="middle">
        <Grid.Column>
          <Type
            id="we-need-some-info-to-find-your-benefits"
            element="h1"
            weight="thin"
            variant="darker"
            align="left"
            size="4xl"
            utils={{
              vrBottom: "lg",
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)}
          </Type>
        </Grid.Column>
        <Grid.Column
          utils={{
            vrBottom: "sm",
          }}
        >
          <FormField
            size="lg"
            type="number"
            inputMode="numeric"
            id="rentalAgreementNumberId"
            name="rentalAgreementNumberId"
            label={
              <Flex
                alignItems="stretch"
                justifyContent="between"
                isBlock={true}
              >
                <Type size="l" weight="bold" emphasis="information">
                  {getTranslatedText(
                    TRANSLATION_CONSTANTS.RENTAL_AGREEMENT_NUMBER
                  )}
                </Type>
                <Tooltip
                  id="mva-tooltip"
                  size="lg"
                  text={getTranslatedText(
                    TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_RA
                  )}
                >
                  <Icon icon={QuestionIcon} color="#0033A0" size={1.2} />
                </Tooltip>
              </Flex>
            }
            labelProps={{
              style: {
                width: "100%",
              },
            }}
            errorText={getTranslatedText(
              errorMessageTranslatedText(errorMessage)
            )}
            value={rentalAgreementNumber}
            onKeyDown={handleOnKeyDownRaNumber}
            onChange={onRentalAgreementNumberChange}
            hasError={rentalAgreementNumberHasError}
            required
            maxLength={maxLength}
            data-testid="ra-field"
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            size="lg"
            hasUpgrade
            id="lookup-continue"
            disabled={!rentalAgreementNumber || rentalAgreementNumberHasError}
            onClick={onSubmit}
            data-testid="rental-agreement-number-continue"
            utils={{
              fullWidth: true,
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
          </Button>
        </Grid.Column>
      </Grid>
      {primaryLookupAttempted < 2 && (
        <>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type element="span" size="4xl" variant="darker" weight="normal">
                - {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -
              </Type>
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type
                element="span"
                size="lg"
                variant="information"
                weight="bold"
                onClick={searchByMvaNumber}
              >
                {getTranslatedText(
                  TRANSLATION_CONSTANTS.SEARCH_BY_MOTOR_VEHICLE_ASSET
                )}
              </Type>
            </Grid.Column>
          </Grid>
        </>
      )}
    </>
  );
};

export default RentalAgreementLookup;
