const translatePartnerCode = (partnercode) => {
    switch(partnercode){
        case 'chevrolet':return('CHE');
        case 'buick':return('BUC');
        case 'cadillac':return('CAD');
        case 'gmc':return('GMC');
        case 'flc':return('FLC');
        case 'aarp':return('AAR');
        case 'verizon':return('VZW');
        case 'jj':return('JJ');
        default:return(partnercode.toUpperCase());
      }
 }

 export default translatePartnerCode;
