import React from "react";
import Icon from '../../icon'
import StarRating from "../../starrating";
import { MappointF } from "../../../icon-library";
import { useSelector } from "react-redux";


export const DropdownListMember = ({ destination, index, onClick }) => {
    const userHasBenefits = useSelector(state => state.commonBenefits.benefits)
    const { experience: { towToEnabled, passThruEnabled } } = useSelector(state => state.partnerDetails.partnerDetails)

    return (
        <a
            key={`near-by-destination-${index}`}
            data-testid={`near-by-destination-${index}`}
            className="dropoffModal__promoted"
            onClick={() => onClick(destination)}
            onKeyDown={(e) => { if (e.key === "Enter") selectNewDestinationValue(destination) }}
            tabIndex={0}
        >
            <Icon icon={MappointF} color="gray-light" className="dropoffPin" size={1} />
            <div>
                {destination.isPreferredDealer && <em>Sponsored</em>}
                <p className="title">{destination.name}</p>
                <p className="address">{destination.vicinity}</p>
                {towToEnabled && <div>{destination.operatingHours?.text}</div>}
                <StarRating starRating={destination.rating} reviews={destination.user_ratings_total} baseColor="orange" />
            </div>
            <div>
                {!userHasBenefits && !passThruEnabled ? <p className="price">${destination.towInfo.totalTowCost}</p> : null}
                <p className="miles">{destination.towInfo.distanceFromCurrentLocation} {destination.towInfo.unitsShorthand}</p>
            </div>
        </a>
    )
}