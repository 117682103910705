import {UPDATE_SERVICE_TIME} from "../action/serviceTime-action";

const reducerServiceTime = (state = "", action) => {
  switch (action.type) {
    case UPDATE_SERVICE_TIME:
      return action.payload
    default:
      return state
  }
}

export default reducerServiceTime;
