import React from 'react';
import PropTypes from 'prop-types';

const rplSysIconColors = {
  'blue': '#1666af',
  'button': '#0075c9',
  'action': '#0075c9',
  'selection': '#406f9a',
  'brand': '#0033A0',
  'alert-orange': '#ff8400',
  'alert-yellow': '#ffd200',
  'alert-green': '#95d600',
  'alert-blue': '#57c1e8',
  'alert-red': '#cf102d',
  'black': '#222',
  'gray-darker': '#6b7789',
  'gray-dark': '#444',
  'gray': '#6d6d6d',
  'gray-light': '#B9C6D3',
  'gray-lighter': '#dcdcdc',
  'white': '#fff',
  'green-light': '#72B325',
  'teal-light': '#49c3b1',
};

const rplSysIcons = {
  'calendar': 'M57 8h-3v-2c0-3.5-2.5-6-6-6s-6 2.5-6 6v2h-20v-2c0-3.5-2.5-6-6-6s-6 2.5-6 6v2h-3c-4 0-7 3-7 7v42c0 4 3 7 7 7h50c4 0 7-3 7-7v-42c0-4-3-7-7-7zm-1 48h-48v-32h48v32zm-9.5-22h-12c-1.5 0-2.5 1-2.5 2.5v9c0 1.5 1 2.5 2.5 2.5h12c1.5 0 2.5-1 2.5-2.5v-9c0-1.5-1-2.5-2.5-2.5z',
  'check-solid': 'M54.496 7.724l-33.107 31.161c-1.026.966-2.707.966-3.733 0l-8.154-7.674c-1.026-.966-2.707-.966-3.733 0l-5.001 4.709c-1.026.966-1.026 2.547 0 3.513l16.888 15.891c1.026.966 2.707.966 3.733 0l41.84-39.381c1.027-.966 1.027-2.547 0-3.514l-5.001-4.706c-1.025-.966-2.707-.966-3.733 0z',
  'check-circle': 'M32 0c-17.638 0-32 14.362-32 32 0 17.638 14.362 32 32 32 17.638 0 32-14.362 32-32 0-17.638-14.362-32-32-32zm20.661 20.409l-26.457 26.457c-.504.504-1.008.504-1.512.504s-1.008-.252-1.512-.504l-12.094-12.346c-.756-.756-.756-2.016 0-3.024l2.016-2.016c.756-.756 2.268-.756 3.024 0l8.819 8.819 22.929-22.929c.756-.756 2.268-.756 3.024 0l2.016 2.016c.504.504.504 1.008.504 1.512s-.252 1.008-.756 1.512z',
  'check-outline': 'M32 0c-17.6 0-32 14.4-32 32s14.4 32 32 32 32-14.4 32-32-14.4-32-32-32zm0 60.16c-15.36 0-28.16-12.48-28.16-28.16s12.8-27.84 28.16-27.84 28.16 12.48 28.16 28.16-12.8 27.84-28.16 27.84zm17.28-38.72l-1.92-1.92c-.64-.64-1.6-.64-2.24 0l-20.16 20.8-7.68-8c-.64-.64-1.6-.64-2.24 0l-1.92 1.92c-.32 0-.32.32-.32.96 0 .32 0 .64.32.96l10.56 11.2c.64.32.96.32 1.28.32.32 0 .64 0 .96-.32l23.04-24c.96-.32.96-1.6.32-1.92z',
  'checkmark': 'M63.144 12.241l-1.384-1.384a2.945 2.945 0 0 0-4.153 0L23.695 44.769 6.394 27.467a2.945 2.945 0 0 0-4.153 0L.857 28.851a2.944 2.944 0 0 0 0 4.152l19.378 19.379 1.384 1.384a2.945 2.945 0 0 0 4.153 0l1.384-1.384v-.001l35.988-35.987a2.945 2.945 0 0 0 0-4.153',
  'chevron': 'M24.065 31.742l25.29-25.29c1.032-1.032 1.032-3.097 0-4.129l-1.548-1.548c-1.032-1.032-3.097-1.032-4.129 0l-27.355 27.355-1.548 1.548c-1.032 1.032-1.032 3.097 0 4.129l29.419 29.419c1.032 1.032 3.097 1.032 4.129 0l1.548-1.548c1.032-1.032 1.032-3.097 0-4.129l-25.806-25.806z',
  'cross': 'M.814 57.6l25.675-25.6-25.675-25.6c-1.093-1.113-1.093-2.226 0-3.339l2.185-2.226c1.093-1.113 2.185-1.113 3.824 0l25.129 25.6 25.675-25.6c1.093-1.113 2.185-1.113 3.278 0l2.185 2.226c1.093 1.113 1.093 2.226 0 3.339l-25.675 25.6 25.675 25.6c1.093 1.113 1.093 2.226 0 3.339l-2.185 2.226c-1.093 1.113-2.185 1.113-3.278 0l-25.675-25.6-25.675 25.6c-1.093 1.113-2.185 1.113-3.824 0l-2.185-2.226c-.546-1.67-.546-2.226.546-3.339z',
  'cross-outline': 'M32 0c-17.6 0-32 14.4-32 32s14.4 32 32 32 32-14.4 32-32-14.4-32-32-32zm0 60.16c-15.36 0-28.16-12.48-28.16-28.16 0-15.36 12.48-28.16 28.16-28.16 15.36 0 28.16 12.48 28.16 28.16 0 15.36-12.8 28.16-28.16 28.16zm13.76-18.88c1.28 1.28 1.28 3.2 0 4.48-.64.64-1.6.96-2.24.96-.64 0-1.6-.32-2.24-.96l-9.28-9.28-8.96 8.96c-.64.64-1.6.96-2.24.96-.64 0-1.6 0-2.24-.64-1.28-1.28-1.28-3.2 0-4.48l8.96-9.28-8.96-8.96c-1.28-1.28-1.28-3.2 0-4.48 1.28-1.28 3.2-1.28 4.48 0l8.96 8.96 8.96-8.96c1.28-1.28 3.2-1.28 4.48 0 1.28 1.28 1.28 3.2 0 4.48l-8.96 8.96 9.28 9.28z',
  'ellipsis': 'M51.2 32.4c0 3.535 2.865 6.4 6.4 6.4s6.4-2.865 6.4-6.4-2.865-6.4-6.4-6.4-6.4 2.865-6.4 6.4zm-25.6 0c0 3.535 2.865 6.4 6.4 6.4s6.4-2.865 6.4-6.4S35.535 26 32 26s-6.4 2.865-6.4 6.4zM1 32.4c0 3.535 2.865 6.4 6.4 6.4s6.4-2.865 6.4-6.4S10.935 26 7.4 26 1 28.865 1 32.4z',
  'ellipsis-vertical': 'M32.4 12.8c3.535 0 6.4-2.865 6.4-6.4s-2.865-6.4-6.4-6.4-6.4 2.865-6.4 6.4 2.865 6.4 6.4 6.4zm0 25.6c3.535 0 6.4-2.865 6.4-6.4s-2.865-6.4-6.4-6.4-6.4 2.865-6.4 6.4 2.865 6.4 6.4 6.4zm0 25.6c3.535 0 6.4-2.865 6.4-6.4s-2.865-6.4-6.4-6.4-6.4 2.865-6.4 6.4 2.865 6.4 6.4 6.4z',
  'flag-circle': 'M32 0c-17.638 0-32 14.362-32 32 0 17.638 14.362 32 32 32 17.638 0 32-14.362 32-32 0-17.638-14.362-32-32-32zm.756 53.669c-.756.504-1.764.252-2.016-.504l-20.157-32c0-.252-.252-.756 0-1.26 0-.252.252-.504.504-.756s.504-.252.756-.252c.504 0 .756.252 1.008.756.756 1.008 13.606 21.165 13.606 21.417l6.551 10.331c.504 1.008.252 2.016-.252 2.268zm8.063-19.15c-7.559-2.52-11.591-2.016-15.37 2.016-3.276-5.039-8.567-13.354-10.583-16.882 3.024-3.276 6.047-4.535 13.858-1.764 6.299 2.268 12.598.252 14.866-.756l10.583 16.882c-2.016.756-7.811 2.52-13.354.504z',
  'info': 'M26.788 64h9.162V16.664h-9.162V64zm4.501-64c3.005 0 5.373 2.369 5.373 5.373 0 3.001-2.369 5.289-5.373 5.289-3.001 0-5.289-2.289-5.289-5.289C26 2.368 28.289 0 31.289 0z',
  'info-circle': 'M32 0c-17.638 0-32 14.362-32 32 0 17.638 14.362 32 32 32 17.638 0 32-14.362 32-32 0-17.638-14.362-32-32-32zm4.535 53.921h-8.819v-31.496h8.819v31.496zm-4.535-34.772c-3.024 0-4.787-2.016-4.787-4.535 0-2.52 1.764-4.535 4.787-4.535 3.024 0 5.039 2.016 5.039 4.535 0 2.52-2.016 4.535-5.039 4.535z',
  'plus-outline': 'M50.963 32c0 1.422-.948 2.37-2.37 2.37h-14.222v14.222c0 1.422-.948 2.37-2.37 2.37-1.422 0-2.37-.948-2.37-2.37v-14.222h-14.222c-1.422 0-2.37-.948-2.37-2.37 0-1.422.948-2.37 2.37-2.37h14.222v-14.222c0-1.422.948-2.37 2.37-2.37 1.422 0 2.37.948 2.37 2.37v14.222h14.222c1.422 0 2.37.948 2.37 2.37zm13.037 0c0 17.541-14.459 32-32 32s-32-14.459-32-32 14.459-32 32-32 32 14.459 32 32zm-3.793.237c0-15.644-12.8-27.97-28.207-27.97s-28.207 12.089-28.207 27.733 12.8 28.207 28.207 28.207 28.207-12.326 28.207-27.97z',
  'quote': 'M37.65 6v26.355h15.06c0 8.317-6.743 15.06-15.06 15.06v11.295c14.556 0 26.355-11.799 26.355-26.355v-26.355h-26.355zm-37.65 26.355h15.06c0 8.317-6.743 15.06-15.06 15.06v11.295c14.556 0 26.355-11.799 26.355-26.355v-26.355h-26.355v26.355z',
  'error-solid': 'M31.914 53.913c-2.679 0-4.789-2.112-4.789-4.792 0-2.677 2.11-4.789 4.789-4.789 2.626 0 4.789 2.112 4.789 4.789 0 2.679-2.163 4.792-4.789 4.792zm-.027-38.473c2.478 0 4.354 2.057 4.169 4.566l-1.177 15.896c-.186 2.512-1.532 4.569-2.992 4.569-1.458 0-2.804-2.057-2.989-4.569l-1.179-15.896c-.186-2.51 1.691-4.566 4.169-4.566zm30.369 32.804l-22.977-39.96c-1.959-3.408-4.542-5.285-7.277-5.285-2.735 0-5.319 1.876-7.28 5.285l-22.977 39.96c-1.959 3.408-2.279 6.594-.901 8.974 1.375 2.38 4.299 3.692 8.229 3.692h45.857c3.933 0 6.853-1.312 8.232-3.692 1.375-2.38 1.055-5.565-.904-8.974z',
  'error-circle': 'M32 0c-17.574 0-32 14.426-32 32 0 17.574 14.426 32 32 32 17.574 0 32-14.164 32-32s-14.164-32-32-32zm-.525 31.738c1.049 0 1.836-1.574 1.836-3.41l.787-9.18c.262-1.836-1.049-3.41-2.623-3.41-1.574 0-2.885 1.574-2.623 3.41l.787 9.18c0 1.836 1.049 3.41 1.836 3.41zm.525 4.984c-1.836 0-3.148 1.311-3.148 3.148 0 1.836 1.311 3.148 3.148 3.148 1.574 0 3.148-1.311 3.148-3.148-.262-1.836-1.574-3.148-3.148-3.148zm20.984 9.18c-1.049 1.574-2.885 2.623-5.508 2.623h-31.475c-2.623 0-4.721-.787-5.508-2.623-1.049-1.574-.787-3.934.525-6.033l15.738-27.279c1.311-2.361 3.148-3.672 4.984-3.672 1.836 0 3.672 1.311 4.984 3.672l15.738 27.279c1.311 2.098 1.574 4.459.525 6.033z',
  'error': 'M32.31 51.836c3.316 0 6.084 2.684 6.084 6.084 0 3.396-2.768 6.08-6.084 6.08s-6.084-2.684-6.084-6.08c0-3.4 2.768-6.084 6.084-6.084zm.001-8.422c2.204 0 4.232-3.564 4.516-7.924l1.776-27.571c.28-4.356-2.552-7.92-6.292-7.92-3.74 0-6.572 3.564-6.292 7.92l1.776 27.571c.284 4.36 2.312 7.924 4.516 7.924z',
  'clock': 'M43.331 56.414l-15.041-15.041v-37.891c0-1.917-1.564-3.482-3.482-3.482h-2.321c-1.917 0-3.486 1.564-3.486 3.482v40.231c0 1.337.678 2.187.915 2.428l16.847 16.843c1.356 1.356 3.57 1.356 4.926 0l1.643-1.643c1.356-1.356 1.356-3.57 0-4.926',
  'clock-circle': 'M9,0 C4.0375,0 0,4.0375 0,9 C0,13.9625 4.0375,18 9,18 C13.9625,18 18,13.9625 18,9 C18,4.0375 13.9625,0 9,0 L9,0 Z M9,15.75 C5.2775,15.75 2.25,12.7225 2.25,9 C2.25,5.2775 5.2775,2.25 9,2.25 C12.7225,2.25 15.75,5.2775 15.75,9 C15.75,12.7225 12.7225,15.75 9,15.75 L9,15.75 Z M10.125,9 L10.125,4.21875 C10.125,3.755 9.745,3.375 9.28125,3.375 L8.71875,3.375 C8.255,3.375 7.875,3.755 7.875,4.21875 L7.875,9.84375 L7.875,10.40625 C7.875,10.87 8.255,11.25 8.71875,11.25 L9.28125,11.25 L12.65625,11.25 C13.12,11.25 13.5,10.87 13.5,10.40625 L13.5,9.84375 C13.5,9.38 13.12,9 12.65625,9 L10.125,9 Z',
  'edit-outline': 'M32 0c-17.6 0-32 14.4-32 32s14.4 32 32 32 32-14.4 32-32-14.4-32-32-32zm0 60.2c-15.4 0-28.2-12.5-28.2-28.2s12.8-27.8 28.2-27.8 28.2 12.5 28.2 28.2-12.8 27.8-28.2 27.8zm-13.1-17.3l1.4-3.8 4.7 4.6-3.8 1.5-2.3-2.3zm27.9-18.1l1.7-1.7c1.5-1.5 1.9-3.8 1-5.2-.7-1.2-2.3-2.7-3.5-3.4-1.4-.9-3.7-.4-5.2 1l-1.7 1.7c.1 0 7.7 7.6 7.7 7.6zm-8.9-6.3l-18.5 18.4c-.1.1-.2.2-.2.4l-4.2 10.4c-.1.3 0 .8.2 1 .2.2.4.3.7.3.1 0 .2 0 .3-.1l10.4-4.1c.1 0 .2-.1.4-.2l16.6-16.6 1.9-1.9-7.6-7.6z',
  'sort': 'M32.043 56.872l18.227-17.503c.86-.826 2.268-.826 3.128 0l1.043 1.001c.86.826.86 2.178 0 3.004l-20.833 20.006c-.86.826-2.268.826-3.128 0l-20.833-20.006c-.86-.826-.86-2.178 0-3.004l1.043-1.001c.86-.826 2.268-.826 3.128 0l18.227 17.503zm0-49.744l-18.227 17.503c-.86.826-2.268.826-3.128 0l-1.043-1.001c-.86-.826-.86-2.178 0-3.004l20.833-20.006c.86-.826 2.268-.826 3.128 0l20.833 20.006c.86.826.86 2.178 0 3.004l-1.043 1.001c-.86.826-2.268.826-3.128 0l-18.227-17.503z',
  'arrow': 'M5.33052804,33.892786 C6.78933119,35.3515892 7.90671233,35.3515892 9.36551548,33.892786 L27.8289086,15.4293929 L27.8289086,53.9080973 L27.8200405,53.9080973 L27.8244745,61.1444704 C27.8200405,63.2107387 28.6093017,64 30.67557,64 L33.8414833,64 C35.9033175,64 36.6925788,63.2107387 36.6925788,61.1444704 L36.6925788,60.2842643 L36.7014469,60.2354897 L36.6970128,15.4249589 L54.8367201,33.5646661 C56.2955233,35.0234693 57.4129044,35.0234693 58.8717076,33.5691002 L61.1109039,31.3299039 C62.569707,29.8666667 62.569707,28.7537196 61.1064698,27.2949164 L34.9100898,1.09410236 C33.4468526,-0.364700788 31.0835028,-0.364700788 29.6202656,1.09410236 L3.09576576,27.6230363 C1.63696261,29.0818394 1.63252856,30.1947865 3.09576576,31.6535897 L5.33052804,33.892786',
  'documentDOC': 'M4.498 11.425h3.016c2.555 0 3.017 1.78 3.017 4.508 0 3.29-.691 4.77-3.133 4.77h-2.9v-9.278zm1.84 7.9h.817c1.279 0 1.535-.818 1.535-3.34 0-2.104-.167-3.183-1.573-3.183h-.781v6.523h.002zm8.01-7.898c2.766 0 3.106 1.999 3.106 4.818 0 2.82-.34 4.822-3.107 4.822-2.761 0-3.104-2.003-3.104-4.822 0-2.82.343-4.82 3.104-4.82v.002zm0 8.262c1.087 0 1.268-.95 1.268-3.444 0-2.481-.18-3.442-1.269-3.442-1.088 0-1.264.96-1.264 3.442 0 2.494.176 3.445 1.264 3.445v-.002zm7.735-4.95c0-1.352-.269-1.934-1.102-1.934-.931 0-1.15.946-1.15 3.57 0 2.806.383 3.315 1.177 3.315.666 0 1.15-.353 1.15-2.353H24c0 2-.486 3.73-2.904 3.73-2.762 0-3.106-2.003-3.106-4.822 0-2.82.344-4.82 3.106-4.82 2.635 0 2.826 1.962 2.826 3.315h-1.84l.001-.001zm-6.091 8.011h3.198v.406c0 1.564-1.259 2.844-2.8 2.844H2.8C1.26 26 0 24.72 0 23.156V2.844C0 1.279 1.26 0 2.8 0h8.282c.878 0 2.108.517 2.73 1.149l4.247 4.317c.624.633 1.131 1.88 1.131 2.774v.697h-6.396c-1.101 0-2-.911-2-2.03V3.25H3.2v19.5h12.794-.002z',
  'documentXLS': 'M16 22.75h3.2v.406c0 1.565-1.26 2.844-2.8 2.844H2.8C1.26 26 0 24.721 0 23.156V2.844C0 1.28 1.26 0 2.8 0h8.288c.878 0 2.109.517 2.731 1.149l4.25 4.317c.624.633 1.131 1.88 1.131 2.774v.697h-6.4c-1.1 0-2-.911-2-2.03V3.25H3.2v19.5H16zM4.256 11.355h2.035l1.178 3.003 1.165-3.003h2.01l-2.126 4.59 2.279 4.692H8.723l-1.318-3.146-1.306 3.146H4.077l2.278-4.693-2.099-4.589zm7.258 9.282v-9.282h1.843v7.748h3.264v1.534h-5.107zm7.41-2.899v.274c0 .216.018.413.052.591s.098.327.192.448c.094.122.22.217.378.287.157.069.356.104.595.104.281 0 .535-.094.761-.28.227-.186.34-.474.34-.864 0-.209-.028-.388-.084-.54a1.066 1.066 0 0 0-.275-.41 1.95 1.95 0 0 0-.505-.331c-.21-.1-.468-.201-.775-.305a6.578 6.578 0 0 1-1.062-.456 2.87 2.87 0 0 1-.749-.578 2.08 2.08 0 0 1-.442-.767 3.277 3.277 0 0 1-.14-1.008c0-.918.251-1.603.755-2.053.503-.451 1.194-.676 2.073-.676.41 0 .788.045 1.133.136.346.091.644.238.896.442.252.204.448.464.589.78.14.316.211.695.211 1.137v.26h-1.766c0-.442-.077-.782-.23-1.02-.154-.238-.41-.357-.769-.357-.204 0-.375.03-.512.09a.863.863 0 0 0-.326.241.834.834 0 0 0-.166.345c-.03.13-.045.264-.045.402 0 .287.06.527.179.722.12.195.375.375.768.54l1.42.623c.35.157.637.319.858.488.222.169.4.351.532.546.132.195.224.41.275.643.051.235.077.494.077.78 0 .98-.28 1.693-.839 2.139-.559.446-1.337.67-2.336.67-1.04 0-1.785-.23-2.233-.69-.448-.459-.672-1.117-.672-1.976v-.377h1.843z',
  'document': 'M16 22.75h3.2v.406c0 1.565-1.26 2.844-2.8 2.844H2.8C1.26 26 0 24.721 0 23.156V2.844C0 1.28 1.26 0 2.8 0h8.288c.878 0 2.109.517 2.731 1.149l4.25 4.317c.624.633 1.131 1.88 1.131 2.774v.697h-6.4c-1.1 0-2-.911-2-2.03V3.25H3.2v19.5H16zM4.256 11.355h2.035l1.178 3.003 1.165-3.003h2.01l-2.126 4.59 2.279 4.692H8.723l-1.318-3.146-1.306 3.146H4.077l2.278-4.693-2.099-4.589zm7.258 9.282v-9.282h1.843v7.748h3.264v1.534h-5.107zm7.41-2.899v.274c0 .216.018.413.052.591s.098.327.192.448c.094.122.22.217.378.287.157.069.356.104.595.104.281 0 .535-.094.761-.28.227-.186.34-.474.34-.864 0-.209-.028-.388-.084-.54a1.066 1.066 0 0 0-.275-.41 1.95 1.95 0 0 0-.505-.331c-.21-.1-.468-.201-.775-.305a6.578 6.578 0 0 1-1.062-.456 2.87 2.87 0 0 1-.749-.578 2.08 2.08 0 0 1-.442-.767 3.277 3.277 0 0 1-.14-1.008c0-.918.251-1.603.755-2.053.503-.451 1.194-.676 2.073-.676.41 0 .788.045 1.133.136.346.091.644.238.896.442.252.204.448.464.589.78.14.316.211.695.211 1.137v.26h-1.766c0-.442-.077-.782-.23-1.02-.154-.238-.41-.357-.769-.357-.204 0-.375.03-.512.09a.863.863 0 0 0-.326.241.834.834 0 0 0-.166.345c-.03.13-.045.264-.045.402 0 .287.06.527.179.722.12.195.375.375.768.54l1.42.623c.35.157.637.319.858.488.222.169.4.351.532.546.132.195.224.41.275.643.051.235.077.494.077.78 0 .98-.28 1.693-.839 2.139-.559.446-1.337.67-2.336.67-1.04 0-1.785-.23-2.233-.69-.448-.459-.672-1.117-.672-1.976v-.377h1.843z',
  'documentJPG': 'M9.669 11.425v6.68c0 1.973-.885 2.78-2.754 2.78-1.944 0-2.416-1.26-2.416-2.714v-.405h1.688v.286c0 .988.154 1.456.82 1.456.665 0 .819-.468.819-1.456v-6.627h1.843zm1.58 0h3.225c1.868 0 2.507 1.39 2.507 2.78 0 1.925-1.163 2.796-2.904 2.796h-.984v3.701H11.25v-9.277zm1.844 4.198h.819c.728 0 1.227-.439 1.227-1.39 0-.91-.358-1.43-1.278-1.43h-.768v2.82zm8.3-1.261c0-.69-.303-1.559-1.049-1.559-.894 0-1.253.935-1.253 3.247 0 2.236.205 3.64 1.269 3.64.434 0 1.136-.483 1.136-1.482v-.949h-1.214v-1.376h2.98v5.002h-1.356v-.859h-.024c-.436.729-1.063 1.04-1.92 1.04-2.266 0-2.712-1.715-2.712-4.923 0-3.119.715-4.718 3.068-4.718 2.023 0 2.842 1.053 2.842 2.937h-1.766zM16 22.75h3.2v.406C19.2 24.72 17.94 26 16.4 26H2.8C1.26 26 0 24.72 0 23.156V2.844C0 1.279 1.26 0 2.8 0h8.288c.878 0 2.109.515 2.731 1.149l4.25 4.316c.624.632 1.131 1.882 1.131 2.774v.698h-6.4c-1.1 0-2-.913-2-2.03V3.25H3.2v19.5H16z',
  'documentPNG': 'M3.25 22.75H19.5v.325C19.5 24.7 18.2 26 16.575 26H2.925A2.913 2.913 0 0 1 0 23.075V2.925C0 1.3 1.3 0 2.925 0h8.45c.813 0 2.113.487 2.762 1.137l4.388 4.388c.488.65.975 1.787.975 2.762v.65H13c-1.137 0-1.95-.974-1.95-1.95V3.25h-7.8v19.5zm5.688-11.213c.324.163.487.488.812.65.162.325.325.65.325.976.163.325.163.65.163.974 0 .488 0 .813-.163 1.3-.162.325-.325.65-.65.813l-.975.488c-.325.162-.65.162-1.137.162h-.975v3.738h-1.95v-9.263h3.25c.487 0 .975 0 1.3.162zm-.976 3.738c.325-.325.488-.65.488-1.138 0-.487-.162-.975-.325-1.137-.162-.162-.487-.325-.975-.325h-.813V15.6h.813c.325 0 .65-.162.813-.325zm7.313 2.437h.162v-6.337h1.626v9.262H14.95l-2.275-6.5v6.5h-1.788v-9.262H13l2.275 6.337zm5.85-2.112h3.087v5.037h-1.3v-.812c-.162.325-.487.65-.812.813-.325.162-.65.162-1.138.162-.65 0-1.137-.163-1.462-.325-.325-.325-.65-.488-.813-.975-.325-.325-.487-.813-.487-1.462-.162-.65-.162-1.3-.162-2.113s0-1.463.162-2.113c0-.65.325-1.137.488-1.462.325-.325.65-.65.975-.813a3.245 3.245 0 0 1 1.462-.325c.975 0 1.788.163 2.113.65.487.488.65 1.138.65 2.113H22.1v-.487c0-.163 0-.325-.163-.488 0 0 .163 0 0-.162-.162-.163-.325-.163-.487-.163-.488 0-.813.325-.975.813-.163.487-.325 1.3-.325 2.437v1.462c0 .488 0 .813.163 1.138 0 .163.162.488.325.65.162.162.324.325.65.325 0 0 .162-.163.325-.163l.325-.325c.162-.162.325-.325.325-.487.162-.162.162-.325.162-.65V16.9h-1.3v-1.3z',
  'documentPDF': 'M16 22.75h3.2v.406c0 1.565-1.26 2.844-2.8 2.844H2.8C1.26 26 0 24.721 0 23.156V2.844C0 1.28 1.26 0 2.8 0h8.288c.878 0 2.109.517 2.731 1.149l4.25 4.317c.624.633 1.131 1.88 1.131 2.774v.697h-6.4c-1.1 0-2-.911-2-2.03V3.25H3.2v19.5H16zM4.5 11.425h3.225c1.865 0 2.507 1.39 2.507 2.78 0 1.925-1.168 2.796-2.904 2.796h-.987v3.701H4.5v-9.277zm1.84 4.198h.82c.731 0 1.227-.439 1.227-1.39 0-.91-.355-1.43-1.28-1.43H6.34v2.82h.002zm4.908-4.198h3.018c2.556 0 3.019 1.78 3.019 4.508 0 3.29-.691 4.77-3.135 4.77h-2.9v-9.278h-.002zm1.84 7.9h.82c1.28 0 1.535-.818 1.535-3.34 0-2.104-.168-3.183-1.574-3.183h-.781v6.523zm5.66-7.898h5.103v1.53h-3.264v2.185h3.072v1.532h-3.072v4.03h-1.84v-9.28l.002.003z',
  'documentTIF': 'M6.25 22.75H22.5v.325C22.5 24.7 21.2 26 19.575 26H5.925A2.913 2.913 0 0 1 3 23.075V2.925C3 1.3 4.3 0 5.925 0h8.45c.813 0 2.113.487 2.762 1.137l4.388 4.388c.488.65.975 1.787.975 2.762v.65H16c-1.137 0-1.95-.974-1.95-1.95V3.25h-7.8v19.5zm5.037-2.113H9.5v-7.8H7.388v-1.462H13.4v1.463h-2.113v7.8zm2.925-9.262h1.95v9.262h-1.95v-9.262zm3.25 0h5.2v1.463h-3.25v2.275H22.5v1.462h-3.087v4.063h-1.95v-9.263z',
  'documentGIF': 'M5.25 22.75H21.5v.325C21.5 24.7 20.2 26 18.575 26H4.925A2.913 2.913 0 0 1 2 23.075V2.925C2 1.3 3.3 0 4.925 0h8.45c.813 0 2.113.487 2.762 1.137l4.388 4.388c.488.65.975 1.787.975 2.762v.65H15c-1.137 0-1.95-.974-1.95-1.95V3.25h-7.8v19.5zm7.15-2.113h-1.138v-.812c-.162.325-.487.65-.812.813-.325.162-.65.162-1.137.162-.65 0-1.138-.163-1.463-.325-.325-.325-.65-.488-.813-.975-.324-.325-.487-.813-.487-1.462-.162-.65-.162-1.3-.162-2.113s0-1.463.162-2.113c0-.487.163-.974.487-1.462.163-.325.488-.65.976-.813a3.245 3.245 0 0 1 1.462-.325c.975 0 1.787.163 2.112.65.488.488.65 1.138.65 2.113H10.45v-.487c0-.163 0-.325-.163-.488l-.325-.325c-.162-.163-.325-.163-.487-.163-.488 0-.813.325-.975.813-.163.488-.325 1.3-.163 2.6v1.462c0 .488.163.813.163 1.138 0 .325.162.488.487.65.163.162.325.325.65.325 0 0 .163-.163.325-.163l.325-.325c.163-.162.325-.325.325-.487.163-.162.163-.325.163-.65V16.9h-1.3v-1.3H12.4v5.037zm1.3-9.262h1.95v9.262H13.7v-9.262zm8.45 1.463H18.9v2.275h3.088v1.462H18.9v4.063h-1.787v-9.263h5.037v1.463z',
};

export const rplSysIconsViewboxes = {
  'clock-circle': '0 0 18 18',
};

const IconRequiredProps = {
  className: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
  alt: PropTypes.string,
};

function Icon({
    className = '',
    ariaLabel = '',
    icon = 'checkCircle',
    size = 1,
    color = 'inherit',
    alt = '',
    direction,
    viewBox,
    ...other
}) {

  const relativeSize = 16 * size;

  const svgPathRegex = /^M.?[0-9]+/;

  const customIcon = svgPathRegex.test(icon);

  const customSvgViewBox = customIcon && viewBox ? viewBox : '0 0 64 64';
  const svgViewBox = rplSysIconsViewboxes[icon] || customSvgViewBox;

  return (
    
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      className={ className }
      aria-label={ ariaLabel }
      width={`${relativeSize}px`}
      height={`${relativeSize}px`}
      viewBox={svgViewBox}
      { ...other }>
      <title>{ alt }</title>
      { customIcon ?
        <path d={icon} fill={rplSysIconColors[color]} /> :
        <path d={rplSysIcons[icon]} fill={rplSysIconColors[color]} />
      }
    </svg>

  );
}

Icon.propTypes = IconRequiredProps;

export default Icon