import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Star from '../svg/star';

const StarRatingRequiredProps = {
  className: PropTypes.string,
};

function StarRating ({className = '', starRating = '', reviews = '', baseColor = '', ...other}) {
  const rating = (Math.round([starRating] * 2));
  const ratingArray = [...Array(10)];
  ratingArray.fill('blue', 0, rating);

  const displayRating = Math.round( starRating * 10 ) / 10;

  if (!reviews) {
    return null;
  }


  return (
    <div role="figure" className={ classnames(
          'starrating',
          { [className]: className, },
          { [baseColor]: baseColor, },
        )
      }
      { ...other }>
      {displayRating !== 0 ?
        <span className="reviewrating">{displayRating} </span>
      : ''}

      <Star color1={ratingArray[0]} color2={ratingArray[1]} baseColor={[baseColor]} />
      <Star color1={ratingArray[2]} color2={ratingArray[3]} baseColor={[baseColor]} />
      <Star color1={ratingArray[4]} color2={ratingArray[5]} baseColor={[baseColor]} />
      <Star color1={ratingArray[6]} color2={ratingArray[7]} baseColor={[baseColor]} />
      <Star color1={ratingArray[8]} color2={ratingArray[9]} baseColor={[baseColor]} />
      {reviews &&
        <span className="reviewno"> ({[reviews]})</span>
      }

    </div>
  );
}

StarRating.propTypes = StarRatingRequiredProps;

export default StarRating

