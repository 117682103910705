export const REQUESTED_EPCS_INITIALIZE = "REQUESTED_EPCS_INITIALIZE";
export const REQUESTED_EPCS_INITIALIZE_SUCCESS = "REQUESTED_EPCS_INITIALIZE_SUCCESS";
export const REQUESTED_EPCS_INITIALIZE_FAILED = "REQUESTED_EPCS_INITIALIZE_FAILED";

export function requestEpcsInitialize(){
    return { type: REQUESTED_EPCS_INITIALIZE };
}

export function requestEpcsInitializeFailed(){
    return { type: REQUESTED_EPCS_INITIALIZE_FAILED};
}
