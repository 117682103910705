import React from 'react';
import LyftLogo from'../svg/Lyft';
import {useLocale} from "../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";

const Lyft = ({className, handleClick}) => {
    const getTranslatedText = useLocale();
    
    return (
            <div>
              <button className={`c-lyft ${className}`}
                      data-testid="lyftButton"
                      onClick={handleClick}>
                  <LyftLogo />
                  <p>{getTranslatedText(TRANSLATION_CONSTANTS.SCHEDULE)}</p>
              </button>
            </div>
        )
    }

export default Lyft;
