export const REQUESTED_EPCS_STATUS = "REQUESTED_EPCS_STATUS";
export const REQUESTED_EPCS_STATUS_SUCCESS = "REQUESTED_EPCS_STATUS_SUCCESS";
export const REQUESTED_EPCS_STATUS_FAILED = "REQUESTED_EPCS_STATUS_FAILED";
export const UPDATED_EPCS_STATUS = "UPDATED_EPCS_STATUS";
export const REFRESH_EPCS_REDUCERS = "REFRESH_EPCS_REDUCERS";
export const UPDATE_EPCSSTATUS_INCOMPLETE = "UPDATE_EPCSSTATUS_INCOMPLETE";
export const UPDATE_EPCSSTATUS_RETRY = "UPDATE_EPCSSTATUS_RETRY";
export function requestEpcsStatus(requestdata){
    return { type: REQUESTED_EPCS_STATUS,  payload: requestdata};
}

export function requestEpcsStatusSuccess(data){
    return { type: REQUESTED_EPCS_STATUS_SUCCESS, payload: data};
}

export function updatedEpcsStatus(){
    return { type: UPDATED_EPCS_STATUS};
}

export function requestEpcsStatusFailed(){
    return { type: REQUESTED_EPCS_STATUS_FAILED};
}

export function refreshEpcsStatus(){
    return { type: REFRESH_EPCS_REDUCERS};
}

export function updateEpcsStatusIncomplete(){
    return { type: UPDATE_EPCSSTATUS_INCOMPLETE};
}

export function updateEpcsStatusRetry(data){
    return { type: UPDATE_EPCSSTATUS_RETRY, payload: data};
}
