import React from 'react';

const ImageLink = ({href, label, imageAltText, imageSrc}) =>{
  return(
    <div className='image-link'>
      <aside className='image-link__label'>{label}</aside>
      <a data-testid="mobileAppLink" href={href} target="_blank" rel="noreferrer">
        <img src={imageSrc} alt={imageAltText}/>
      </a>
    </div>
  )
}

export default ImageLink;
