import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {getPartnerDetails} from '../../selector';
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {useLocale} from "../../contexts/LocaleContext";

const PartnerBrand = ({isRescueTrackerStandalone}) => {
  const partnerDetails = useSelector(getPartnerDetails);
  const getTranslatedText = useLocale()
  const {image, brand, displayCode, headerColor, fontColor, name} = partnerDetails;
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const multipleLogos = image?.split(',');
    if (multipleLogos?.length > 1) {
      let newLogos = []
      multipleLogos.forEach((logo,i) => {
        newLogos.push(<img key={`${i}`} src={`/images/${logo}`} alt={`${brand}`}/>)
        setLogos(newLogos)
      })
    } else if (image) {
      setLogos([<img key="1" src={`/images/${image}`} alt={`${brand}`}/>])
    } else if (isRescueTrackerStandalone){
      setLogos([<img key="rt-1" className='rescue-tracker-partner-logo' src={`/images/rt-partner-logos/${displayCode}/header.png`} alt={`${name}`}/>])
    }
  },[partnerDetails]);

  return (
    <div>
      <div style={{backgroundColor: `${headerColor}`}} className={`brandingMast ${displayCode}`}>
        {partnerDetails.isHeaderBrandingEnabled ? <p style={{color: `${fontColor}`}}>{getTranslatedText(TRANSLATION_CONSTANTS.PROVIDED_BY)}</p> : null}
        {logos}
      </div>
    </div>
  )
};

export default PartnerBrand
