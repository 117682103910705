import React from "react"
import TriageStep from "../../../components/triage-step"
import { useLocale } from "../../../contexts/LocaleContext"
import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations"
import { useSelector } from "react-redux"
import { getPartnerDetails } from "../../../selector"

export const TireChangeTriageSteps = () => {
    const partnerDetails = useSelector(getPartnerDetails)
    const getTranslatedText = useLocale()
    return (
        <>
            <TriageStep
                step={1}
                title={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_TITLE)}
                content={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_ONE_CONTENT)}
            />
            <TriageStep
                step={2}
                title={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_TITLE)}
                content={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_TWO_CONTENT)}
            />
            <TriageStep
                step={3}
                title={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_TITLE)}
                content={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_THREE_CONTENT)}
            />
            <TriageStep
                step={4}
                title={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_TITLE)}
                content={getTranslatedText(TRANSLATION_CONSTANTS.TRIAGE_STEPS_TIRE_CHANGE_STEP_FOUR_CONTENT).replace('{brand}', partnerDetails.brand)}
            />
        </>
    )
}