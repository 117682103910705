const nameHandler = (name) => {

    const nameArray = name.trim().split(' ');

    if (nameArray.length === 2) {
        return {firstName: nameArray[0], lastName: nameArray[1]};
    }
    else {
        const lastName = nameArray.pop();
        const firstName = nameArray.join(' ');
        return { firstName, lastName }
    }

}

export default nameHandler;
