import { datadogRum } from "@datadog/browser-rum";
import _isEqual from "lodash/isEqual";
import _isEmpty from "lodash/isEmpty";
import {
  BENEFITS,
  en_CA,
  en_US,
  MEMBER,
  OMNI_ASSIST,
  PPU,
  RESCUE_WLEB,
  RESCUE_WLEM,
  RESCUE_WLEOA,
  RESCUE_WLEP,
} from "../app-consts/appConstants";
import { TRANSLATION_CONSTANTS } from "../app-consts/translations";
import { determineLocale, getTranslatedTextByLocale } from "../contexts/LocaleContext";
import getUserTypeFromUrl from "./getUserTypeFromUrl";
import { EN_US, formatTimeAsString } from "./timeUtil";

export const getRescueTrackerLinkType = () => {
  const userType = getUserTypeFromUrl();
  let linkType = "";
  switch (userType) {
    case BENEFITS:
      linkType = RESCUE_WLEB;
      break;
    case MEMBER:
      linkType = RESCUE_WLEM;
      break;
    case OMNI_ASSIST:
      linkType = RESCUE_WLEOA;
      break;
    case PPU:
      linkType = RESCUE_WLEP;
      break;
    default:
      linkType = "";
      break;
  }
  if (linkType) {
    return linkType;
  } else {
    let errorMessage = `Failure Type: HowEmbarrassing-MakeRescueTracker, Reason: Invalid WLE linkType : ${
      linkType ? linkType : "EMPTY"
    }`;
    datadogRum.addError(errorMessage);
    return errorMessage;
  }
};

export const getUserTypeByLinkType = (linkType) => {
  let userType = "";
  switch (linkType) {
    case RESCUE_WLEB:
      userType = BENEFITS;
      break;
    case RESCUE_WLEM:
      userType = MEMBER;
      break;
    case RESCUE_WLEOA:
      userType = OMNI_ASSIST;
      break;
    case RESCUE_WLEP:
      userType = PPU;
      break;
    default:
      userType = "";
      break;
  }
  return userType;
};

export const disablementLocationTextByService = (serviceText = "") => {
  switch(serviceText) {
    case 'Tow':
      return TRANSLATION_CONSTANTS.PICKUP_LOCATION;
    case 'Lockout':
    case 'Lock-out':
    case 'Fuel Delivery':
    case 'Tire Change':
    case 'Jump Start':
    case 'Start':
      return TRANSLATION_CONSTANTS.DISABLEMENT_LOCATION;
    default:
      return "";
  }
};

export const getUpdatedClock = (text = "") => {
  if (!text || !text.includes("{{h:mm:ss A}}")) {
    return "";
  }

  let clockIn24Time = formatTimeAsString(new Date(), EN_US, {
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  });

  return text.replace("{{h:mm:ss A}}", clockIn24Time);
};
