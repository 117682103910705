import React, {Component} from 'react'

class LocationPinSingle extends Component {

  render() {
    return (
      <svg id="location-pin-single" viewBox="0 0 20 20">
        <g fill="none" stroke="none" strokeWidth="1">
          <g id="LocationSVG" transform="translate(1.000000, 2.000000)">
            <g id="Location" transform="translate(1.000000, 0.000000)">
              <circle id="Oval-8" cx="8" cy="8" fill={this.props.locationColor} r="4.63157895"/>
            </g>
          </g>
        </g>
      </svg>
    )
  }
}

export default LocationPinSingle
