export function isCreatedNoContentStatus(status) {
  return [201,204].filter(statusCode => statusCode === status).length === 1;
}

export function is4xxClientError(status) {
  return [400,401,403,404].filter(statusCode => statusCode === status).length === 1;
}

export function is5xxServerError(status) {
  return [500,501,502,503].filter(statusCode => statusCode === status).length === 1;
}


