import {
  REFRESH_EPCS_REDUCERS,
  REFRESH_REDUCERS,
  REQUESTED_EPCS_STATUS,
  REQUESTED_EPCS_STATUS_FAILED,
  REQUESTED_EPCS_STATUS_SUCCESS,
  UPDATE_EPCSSTATUS_INCOMPLETE,
  UPDATED_EPCS_STATUS,
  UPDATE_EPCSSTATUS_RETRY,
} from './../action';

const initialState = {
  request: {},
  response: {},
  loading: false,
  error: false,
  success:false,
  completed: false,
  retry: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUESTED_EPCS_STATUS:
        return {...state, request: action.payload, loading: true};
    case REQUESTED_EPCS_STATUS_SUCCESS:
        return {...state, response: action.payload, loading: false, success: true, retry: 0};
    case REQUESTED_EPCS_STATUS_FAILED:
        return {...state, loading: false, error: true};
    case UPDATED_EPCS_STATUS:
        return {...state, completed: true};
    case UPDATE_EPCSSTATUS_INCOMPLETE:
        return {...state, completed: false};
    case UPDATE_EPCSSTATUS_RETRY:
        return {...state, response: action.payload, loading: false, retry: state.retry + 1};
    case REFRESH_EPCS_REDUCERS:
        return {...initialState, retry: state.retry};
    case REFRESH_REDUCERS:
        return {...initialState};
    default:
        return state;
  }
}
