import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const LoaderRequiredProps = {
  className: PropTypes.string,
};

function Loader ({className = '', ...other}) {
  return (
    <div role="alert" aria-live="polite" aria-busy="true" className={ classnames(
          'c-loader',
          { [className]: className, },
        )
      }
      { ...other }>
      <svg aria-hidden="true" className="c-loader__icon" viewBox="25 25 50 50">
        <circle className="c-loader__path" cx="50" cy="50" r="20" fill="none" strokeWidth="6" strokeMiterlimit="10"/>
      </svg>
    </div>
  );
}

Loader.propTypes = LoaderRequiredProps;

export default Loader

