export const UPDATE_SESSION_ID = "UPDATE_SESSION_ID";
export const UPDATE_DISABLEMENT_ADDRESS = "UPDATE_DISABLEMENT_ADDRESS";
export const CLEAR_DISABLEMENT_ADDRESS = "CLEAR_DISABLEMENT_ADDRESS";
export const UPDATE_DESTINATION_ADDRESS = "UPDATE_DESTINATION_ADDRESS";
export const REMOVE_DESTINATION_ADDRESS = "REMOVE_DESTINATION_ADDRESS";
export const UPDATE_ADDITIONAL_COMMENTS = "UPDATE_ADDITIONAL_COMMENTS";
export const UPDATE_FIRST_NAME = "UPDATE_FIRST_NAME";
export const UPDATE_LAST_NAME = "UPDATE_LAST_NAME";
export const UPDATE_OPERATOR_ID = "UPDATE_OPERATOR_ID";
export const UPDATE_VEHICLE = "UPDATE_VEHICLE";
export const UPDATE_PPU_FLAG = "UPDATE_PPU_FLAG";
export const UPDATE_LOGGED_IN_FLAG = "UPDATE_LOGGED_IN_FLAG";
export const UPDATE_CCARD_INFO = "UPDATE_CCARD_INFO";
export const CLEAR_CCARD_INFO = "CLEAR_CCARD_INFO";
export const UPDATE_SMS_OPT_IN = "UPDATE_SMS_OPT_IN";
export const UPDATE_EMAIL = "UPDATE_EMAIL";
export const UPDATE_CONTACT_NUMBER = "UPDATE_CONTACT_NUMBER";
export const UPDATE_SERVICE_TYPE_OPTIONS = "UPDATE_SERVICE_TYPE_OPTIONS";
export const UPDATE_ADDITIONAL_FIELDS = "UPDATE_ADDITIONAL_FIELDS";
export const REMOVE_ADDITIONAL_FIELD = "REMOVE_ADDITIONAL_FIELD";
export const UPDATE_APP_ID = "UPDATE_APP_ID";
export const CLEAR_SERVICE_TYPE_OPTIONS = "CLEAR_SERVICE_TYPE_OPTIONS";
export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
export const UPDATE_PARTNER_MEMBER_ID = "UPDATE_PARTNER_MEMBER_ID";
export const UPDATE_USAGE_ID = "UPDATE_USAGE_ID";
export const UPDATE_TOW_REASON = "UPDATE_TOW_REASON";
export const UPDATE_TOW_DISTANCE = "UPDATE_TOW_DISTANCE";
export const UPDATE_CURRENT_MILEAGE = "UPDATE_CURRENT_MILEAGE";
export const UPDATE_BUSINESS_NAME = "UPDATE_BUSINESS_NAME";
export const UPDATE_RENTAL_DETAIL = "UPDATE_RENTAL_DETAIL";

export function updateSessionId(data){
  return { type: UPDATE_SESSION_ID, payload: data};
}
export function updateDisablementAddress(data){
  return { type: UPDATE_DISABLEMENT_ADDRESS, payload: data};
}
export function clearDisablementAddress(){
  return { type: CLEAR_DISABLEMENT_ADDRESS };
}
export function updateDestinationAddress(data){
  return { type: UPDATE_DESTINATION_ADDRESS, payload: data};
}
export function removeDestinationAddress(){
  return { type: REMOVE_DESTINATION_ADDRESS };
}
export function updateAdditionalComments(data){
  return { type: UPDATE_ADDITIONAL_COMMENTS, payload: data};
}

export function updateFirstName(data){
  return { type: UPDATE_FIRST_NAME, payload: data};
}

export function updateLastName(data){
  return { type: UPDATE_LAST_NAME, payload: data};
}

export function updateOperatorId(data){
  return { type: UPDATE_OPERATOR_ID, payload: data};
}

export function updatePpuFlag(data){
  return { type: UPDATE_PPU_FLAG, payload: data};
}

export function updateLoggedInFlag(data){
  return { type: UPDATE_LOGGED_IN_FLAG, payload: data};
}

export function updateCCardInfo(data){
  return { type: UPDATE_CCARD_INFO, payload: data};
}

export function updateAdditionalFields(data){
  return { type: UPDATE_ADDITIONAL_FIELDS, payload: data};
}

export function removeAdditionalField(data){
  return { type: REMOVE_ADDITIONAL_FIELD, payload: data};
}

export function updateAppId(data){
  return { type: UPDATE_APP_ID, payload: data};
}

export function clearCCardInfo(data){
  return { type: CLEAR_CCARD_INFO, payload: data};
}

export function updateVehicle(data){
  return { type: UPDATE_VEHICLE, payload: data};
}

export function updateSmsOptIn(data){
  return { type: UPDATE_SMS_OPT_IN, payload: data};
}

export function updateEmail(data){
  return { type: UPDATE_EMAIL, payload: data};
}

export function updateContactNumber(data){
  return { type: UPDATE_CONTACT_NUMBER, payload: data};
}

export function updateServiceTypeOptions(data){
  return { type: UPDATE_SERVICE_TYPE_OPTIONS, payload: data};
}

export function clearServiceTypeOptions() {
  return { type: CLEAR_SERVICE_TYPE_OPTIONS };
}

export function updatePromoCode(data) {
  return { type: UPDATE_PROMO_CODE, payload: data};
}

export function updatePartnerMemberId(data) {
  return { type: UPDATE_PARTNER_MEMBER_ID, payload: data};
}

export function updateUsageId(data) {
  return { type: UPDATE_USAGE_ID, payload: data};
}

export function updateTowReason(data) {
  return { type: UPDATE_TOW_REASON, payload: data};
}

export function updateTowDistance(data) {
  return { type: UPDATE_TOW_DISTANCE, payload: data};
}

export function updateCurrentMileage(data) {
  return { type: UPDATE_CURRENT_MILEAGE, payload: data};
}

export function updateBusinessName(data) {
  return { type: UPDATE_BUSINESS_NAME, payload: data};
}

export function updateRentalDetail(data) {
  return {type: UPDATE_RENTAL_DETAIL, payload: data};
}
