import React, {Component, Fragment } from 'react';
import { TimelineMax } from 'gsap';

class TrackStatus extends Component {
  state = {
    status: this.props.status,
    animationFired: false
  }

  componentDidMount() {
    this.trackertl = new TimelineMax({paused: true});

    const {
      trackertl,
    } = this;

    trackertl.from(`#line-${this.props.id}`, 0.5, {scaleY: 0});
  }

  componentDidUpdate() {
    if (this.props.status === 'complete' && this.props.status !== this.state.status) {
      this.setState({ status: 'complete' }, () => this.trackerLineAnimation());
    }

    if (this.props.status === 'inprogress' && this.props.status !== this.state.status)  {
      this.setState({
        status: 'inprogress'
      })
    }
  }

  trackerLineAnimation = () => {
    this.trackertl.play(1, false);
  }


  render() {
    let tick = '';
    let circle = '';
    let line = '';

    switch (this.props.status) {
      case 'complete':
        tick = '#95d600';
        circle = '#95d600';
        line = '#95d600';
        break;

      case 'inprogress':
        tick = '#0033a0';
        circle = '#e3e9ed';
        line = '#e3e9ed';
        break;

      default:
        tick = '#e3e9ed';
        circle = '#e3e9ed';
        line = '#e3e9ed';
        break;
    }

    return(
      <svg id="trackStatus" viewBox='0 0 80 32'>

        <g id='Page-1' fill='none' fillRule='evenodd'>
          <g id='trackStatus' transform='translate(2 2)'>
            {this.props.status === 'complete' ?
              <path d='M20.5792835,7.9516349 L10.2634468,18.2674716 C10.1104348,18.4204837 9.90669594,18.5044739 9.69048332,18.5044739 L9.68715697,18.5044739 C9.47094435,18.5044739 9.26720554,18.4204837 9.11502511,18.2674716 L4.35585271,13.5091308 C4.03984965,13.1922962 4.03984965,12.679207 4.35585271,12.3623724 L5.12922862,11.589828 C5.43525264,11.283804 5.97079466,11.2846356 6.27598709,11.5906596 L9.68882014,15.0026611 L18.6599807,6.03150052 C18.9651731,5.72630809 19.5007152,5.72630809 19.8059076,6.03150052 L20.5792835,6.80487643 C20.7322955,6.95788843 20.8162858,7.16079566 20.8162858,7.37867146 C20.8162858,7.59571566 20.7322955,7.79862289 20.5792835,7.9516349 L20.5792835,7.9516349 Z M12.5004158,0 C5.59658051,0 0,5.59658051 0,12.4995842 C0,19.4034195 5.59658051,25 12.5004158,25 C19.4034195,25 25,19.4034195 25,12.4995842 C25,5.59658051 19.4034195,0 12.5004158,0 L12.5004158,0 Z'
                    id='tick' stroke={tick} fill={tick} />
              : this.props.status === 'inprogress' ?
                <circle id='circle' stroke={tick} strokeWidth='5' fill={tick} cx='12' cy='12' r='12' />
                :
                <Fragment>
                  <circle id='circle' stroke={tick} strokeWidth='5' fill={tick} cx='12' cy='12' r='12' />
                  <text x="3" y="26.043" style={{fontSize: 28 + 'px', fontFamily: 'AllState, Helvetica Neue, Helvetica, Arial, sans-serif', fontWeight: 'bold', fill: '#94a1b0'}} transform="matrix(0.408691, 0, 0, 0.419308, 7.298552, 6.122035)">{this.props.number}</text>
                </Fragment>
            }
            {!this.props.lastItem &&
            <g transform="matrix(0, 1, -1, 0, 105.091751, -0.064929)">
              <path d='M12.25,25.6551724 L12.25,81' id='linegrey' stroke={line} strokeWidth='6'
                    strokeLinecap='square' />
              <path d='M12.25,25.6551724 L12.25,81' id={`line-${this.props.id}`} stroke={line} strokeWidth='6'
                    strokeLinecap='square' />
            </g>
            }
            <circle id='circle' stroke={circle} strokeWidth='6' fill='transparent' cx='12'
                    cy='12' r='12' />
          </g>
        </g>
      </svg>
    )
  }
}

export default TrackStatus;
