import React, { Component } from 'react';

class LyftLogo extends Component {

    render() {
        return(

            <svg id='Lyft_Logo' viewBox="0 0 820 560" xmlns="http://www.w3.org/2000/svg">
                <g id="Page-1" fill="none" fillRule="evenodd">
                    <g id="Lyft_Logo_Pink" transform="translate(0 -1)" fill="#FF00BF" fillRule="nonzero">
                        <path d="M310,293.8 C310,298.8 307.6,303.6 303.5,306.4 C287.4,317.7 270,306.3 270,291 L270,131 L150,131 L150,331 C150,386.1 194.9,431 250,431 C273.8,431 296.9,422.5 315,407 C313.1,421.8 305,434 291.4,442.5 C278.7,450.5 262.1,454.7 243.5,454.7 C224.3,454.7 204.8,450.2 187.1,441.7 C187.1,441.7 183.9,440.2 179.9,437.9 L179.9,544.5 C206.6,555.3 235.9,561 264.2,561 C309,561 349.9,547 379.4,521.5 C412.5,493 430,451.3 430,401 L430,131 L310,131 L310,293.8 Z"
                        id="Shape" />
                        <path d="M120,331 L120,11 L0,11 L0,311 C0,393.4 54.4,431 105,431 C120.1,431 135.2,427.5 148.7,421.2 C150.7,420.3 153.9,418.3 153.9,418.3 C153.9,418.3 150.9,415.1 149.6,413.7 C130.4,391.8 120,363.4 120,331 Z"
                        id="Shape" />
                        <path d="M820,251 L820,131 L777.2,131 C759.3,36.7 660.2,-25.3 560.6,12 C505.1,32.7 460,99 460,158.3 L460,424.2 L460,426 L460,431 L464.8,431 C464.9,431 464.9,431 465,431 C465.1,431 465.1,431 465.2,431 C496.1,429.7 525,416.6 546.5,394.2 C568.1,371.7 580,342.2 580,311.1 L630,311.1 L630,191 L580,191 L580,158 C580,145.2 586.8,133.2 598,127 C628.7,109.9 660,131.9 660,161 L660,271 C660,314.5 676.4,354.9 706.2,384.8 C734.7,413.3 773.3,429.7 814.8,431 C814.8,431 814.9,431 814.9,431 C814.9,431 814.9,431 815,431 L819.9,431 L819.9,426 L819.9,421 L819.9,311 C819.9,311 819.9,311 819.9,311 C797.5,310.9 779.9,292.9 779.9,271 L779.9,251 L820,251 Z"
                        id="Shape" />
                    </g>
                </g>
            </svg>

    )}
}

export default LyftLogo; 
