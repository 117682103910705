import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Alert,
  Box,
  Button,
  Divider,
  Dropdown,
  Flex,
  FormOptionGroup,
  Grid,
  Headline,
  Icon,
  Sticky,
  Type,
  Utility,
} from "mesh-component-library";
import { PlusIcon, CloseIcon } from "mesh-icon-library";

import { ContinueAsPpuModal } from "../../components/continue-as-ppu-modal/ContinueAsPpuModal";

import { determineLocale, useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import CONSTANTS, {
  CANADA_COUNTRY_CODE,
  en_CA,
  FUEL_SERVICE_ID,
  GALLON,
  GAS,
  LITER,
  supportedColors,
  SUSPENDED_VEHICLE_TO_CALL_CENTER,
  TOW_SERVICE_ID,
  UNSUPPORTED_EQUIPMENT_CLASSES,
  USA_COUNTRY_CODE
} from "../../app-consts/appConstants";
import {
  getServiceRequestPayload,
  getCommonBenefits,
  getServiceInfo,
  getDestination,
  getPartnerDetails,
} from "../../selector";
import titleCase from "../../utils/titleCase";
import {
  clearCCardInfo,
  updateCCardInfo,
  updateLoggedInFlag,
  updatePpuFlag,
  updateServiceTransition,
  updateServiceTypeOptions,
  updateVehicle,
  updateVehicleInfo,
} from "../../action";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import {
  sendFuelTypeToJacada,
  sendVehicleToJacada,
} from "../../jacada/sendJacadaEventData";
import { datadogRum } from "@datadog/browser-rum";
import { generatePath } from '../../utilities/RedirectUtils';
import { sendJacadaRequest } from "../../api";
import { USER_OUT_OF_CLAIMS } from "../../app-consts/errorMessageConstants";
import { TimelineMax } from "gsap";
import { getTranslatedColors } from "../../utils/translatedTextUtils";

const VehicleSelect = (props) => {
  const getTranslatedText = useLocale();
  const history = useHistory();

  const dispatch = useDispatch();
  const serviceRequestPayload = useSelector(getServiceRequestPayload);
  const commonBenefits = useSelector(getCommonBenefits);
  const serviceInfo = useSelector(getServiceInfo);
  const destination = useSelector(getDestination);
  const vehicleValue = useSelector((state) => state.vehicleValue);
  const partnerDetails = useSelector(getPartnerDetails);

  const [fuelType, setFuelType] = useState(
    commonBenefits?.vehicles?.length === 1
      ? titleCase(commonBenefits.vehicles[0]?.fuelType)
      : ""
  );
  const [selectedVehicleIndex, setSelectedVehicleIndex] = useState(
    vehicleValue.selectedVehicleIndex
  );
  const [showSuspendedCoverageModal, setShowSuspendedCoverageModal] =
    useState(false);
  const [
    showSuspendedCoverageNonPPUModal,
    setShowSuspendedCoverageNonPPUModal,
  ] = useState(false);
  const [continueToPpu, setContinueToPpu] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleColor, setVehicleColor] = useState(
    serviceRequestPayload?.serviceRequest?.vehicle?.color ||
      serviceRequestPayload?.serviceRequest?.vehicles?.color ||
      ""
  );

  const fuelCostCovered = commonBenefits?.experience.fuelCostCovered;
  const unitOfMeasure = commonBenefits?.experience.unitOfMeasure;
  const addAVehicle = commonBenefits?.experience.addAVehicle;

  useEffect(() => {
    document.title = "Vehicle Select | Roadside Assistance";
    let vehicles = determineVehicleList();
    if (vehicles) {
      setVehicleList(vehicles);
      if (vehicles.length === 0) {
        addAnotherVehicle();
      }
      if (vehicles.length === 1) {
        let vehicleVal = vehicleList[0] || vehicles[0];
        onSelectVehicle(0, vehicleVal);
      }
    }
  }, []);

  const determineVehicleList = () => {
    const { addedVehicleFlag } = vehicleValue;
    const { vehicles } = commonBenefits;
    const { serviceRequest } = serviceRequestPayload;
    const { experience } = partnerDetails;
    if (addedVehicleFlag) {
      const vehicleList = [...vehicles];
      vehicleList.push(serviceRequest.vehicle || serviceRequest.vehicles);
      if (experience.supportRentalOrBorrowed && vehicleList.length > 0) {
        vehicleList[vehicleList.length - 1].suspendIndicator = "N";
      }
      return vehicleList;
    } else {
      return vehicles;
    }
  };

  const addAnotherVehicle = () => {
    history.push(generatePath("add-vehicle"));
  };

  const colorIsSupported = (vehicleColor) => {
    return (
      supportedColors.filter(
        (colorObject) =>
          colorObject.text.toLowerCase() === vehicleColor.toLowerCase()
      ).length > 0
    );
  };

  const callCallCenterBtn = () => {
    const phone = partnerDetails.phone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' + phone;
  };

  const suspendedBtnCancel = () => {
    setShowSuspendedCoverageModal(false);
    setShowSuspendedCoverageNonPPUModal(false);
    setSelectedVehicleIndex("");
    setVehicleColor("");
  };

  const suspendedBtnGuest = () => {
    setShowSuspendedCoverageModal(false);
    setContinueToPpu(true);
  };

  const onSelectVehicle = (index, vehicle) => {
    if (
      vehicle.suspendIndicator === "Y" &&
      partnerDetails.experience.ppuEnabled
    ) {
      setShowSuspendedCoverageModal(true);
    } else if (
      vehicle.suspendIndicator === "Y" &&
      !partnerDetails.experience.ppuEnabled
    ) {
      setShowSuspendedCoverageNonPPUModal(true);
    }
    let vehicleColor = "";
    if (vehicle.color && colorIsSupported(vehicle.color)) {
      vehicleColor = vehicle.color.trim();
    }
    setSelectedVehicleIndex(index);
    setVehicleColor(vehicleColor);
  };

  const onConfirm = () => {
    const ppuFuelPrice = localStorage.getItem("ppuFuelPrice");
    const {
      experience: { coverageFollowsVehicle },
    } = commonBenefits;
    let vehicle = cloneDeep(vehicleList[selectedVehicleIndex]);

    if (continueToPpu && vehicle?.suspendIndicator === "Y") {
      dispatch(updatePpuFlag("Y"));
      dispatch(updateLoggedInFlag("N"));
      if (
        serviceInfo.id === TOW_SERVICE_ID &&
        destination?.towInfo?.totalTowCost &&
        Number(destination?.towInfo?.totalTowCost > 0) &&
        partnerDetails.experience.milesBased
      ) {
        let totalTowCost =
          Number(serviceInfo.originalCost) +
          Number(destination.towInfo.totalTowCost);
        dispatch(updateCCardInfo({ transactionAmount: totalTowCost }));
      } else if (
        serviceInfo.id === TOW_SERVICE_ID &&
        partnerDetails.experience.dollarBased
      ) {
        dispatch(
          updateServiceTransition({
            serviceType: serviceInfo.value,
            declineType: SUSPENDED_VEHICLE_TO_CALL_CENTER,
          })
        );
        return history.push(
          generatePath("ServiceTransition", partnerDetails.partnerCode)
        );
      } else {
        dispatch(
          updateCCardInfo({
            transactionAmount: serviceInfo.originalCost,
          })
        );
      }
    } else if (vehicle?.suspendIndicator === "N") {
      if (serviceInfo.id === FUEL_SERVICE_ID && ppuFuelPrice) {
        dispatch(updatePpuFlag("Y"));
        dispatch(updateCCardInfo({ transactionAmount: ppuFuelPrice }));
      } else if (
        serviceInfo.id === TOW_SERVICE_ID &&
        Number(destination.towInfo.totalTowCost) > 0
      ) {
        dispatch(updatePpuFlag("Y"));
        dispatch(
          updateCCardInfo({
            transactionAmount: destination.towInfo.totalTowCost,
          })
        );
      } else {
        dispatch(updatePpuFlag("N"));
        dispatch(clearCCardInfo());
      }
      dispatch(updateLoggedInFlag("Y"));
    }
    vehicle.color = vehicleColor ? titleCase(vehicleColor) : "";
    vehicle.equipmentClass = vehicle.equipmentClass || "Light";
    sendVehicleToJacada(vehicle);
    const vehicleBenefitsUsedUp =
      coverageFollowsVehicle &&
      vehicle.claimCount >= vehicle.maxClaimCount &&
      !vehicle.suspendIndicator;
    if (UNSUPPORTED_EQUIPMENT_CLASSES.includes(vehicle.equipmentClass.toLowerCase())) {
      dispatch(
        updateServiceTransition({
          declineType: CONSTANTS.MUST_CALL_FOR_WEIGHT_CLASS,
        })
      );
      return history.push(generatePath("ServiceTransition"));
    }
    if (vehicleBenefitsUsedUp) {
      dispatch(
        updateServiceTransition({
          errorMessageContent: USER_OUT_OF_CLAIMS,
        })
      );
      return history.push(generatePath("ServiceTransition"));
    }

    if (serviceInfo.id === FUEL_SERVICE_ID && fuelType) {
      dispatch(updateServiceTypeOptions({ gas: fuelType }));
    }
    if (
      vehicleValue.selectedVehicleIndex !== "" &&
      vehicleValue.selectedVehicleIndex > selectedVehicleIndex
    ) {
      dispatch(updateVehicleInfo({ addedVehicleFlag: false }));
    }
    datadogRum.addAction("Vehicle Information Confirmed");
    dispatch(
      updateVehicleInfo({
        selectedVehicleIndex: selectedVehicleIndex,
      })
    );
    confirmVehicleInfo(vehicle);
    history.push(generatePath("location"));
  };

  const confirmVehicleInfo = (payload) => {
    const caranimationtl = new TimelineMax({ paused: true });
    caranimationtl.play(0, false);
    let eventsData = [
      {
        name: "color_name",
        value: payload.color,
      },
    ];

    eventsData.push({
      name: "equpmnt_clss_id",
      value: 1,
    });

    let jacadaRequestData = {
      externalSystemId: localStorage.getItem("conversationId"),
      events: eventsData,
    };
    sendJacadaRequest(jacadaRequestData);
    if (payload.rentalorborrowedflag) {
      const sortedVehiclesByHighestCoverage = cloneDeep(
        commonBenefits.vehicles
      );
      sortedVehiclesByHighestCoverage
        .sort((a, b) => a.coverageAmount.localeCompare(b.coverageAmount))
        .reverse();
      payload.consumervehicleid =
        sortedVehiclesByHighestCoverage[0]?.vehicleID || "";
    }

    dispatch(updateVehicle(payload));
    if (serviceInfo.id === FUEL_SERVICE_ID && payload.fuelType) {
      dispatch(updateServiceTypeOptions({ gas: payload.fuelType }));
    }
  };

  const selectFuelType = (event) => {
    setFuelType(event.currentTarget.value);
    sendFuelTypeToJacada(event);
  };

  const isButtonEnabled = () => {
    if (selectedVehicleIndex !== undefined && vehicleColor && vehicleColor !== getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE)) {
      if (serviceInfo.id === FUEL_SERVICE_ID) {
        return !!fuelType;
      }
      return true;
    }
  };

  const determineFuelAmount = () => {
    const { experience, services } = commonBenefits;
    const quantity =
      experience.supportedRegions[0] === "CA"
        ? services?.filter((service) => service.type === "Gas")[0].fuelQtyCan
        : services?.filter((service) => service.type === "Gas")[0].fuelQty;
    return quantity <= 0 ? 2 : quantity;
  };

  const fuelServiceCoverages = commonBenefits?.services?.find((service) => service.type === GAS)?.coverages;

  return (
    <Utility.Div data-dd-privacy="allow" id="confirm-vehicle-container">
      <ContinueAsPpuModal
        active={showSuspendedCoverageModal}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTINUE_PPU
        )}
        primaryButton={{
          testId: "confirm-vehicle-coverage-suspend-continue",
          value: getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_AS_GUEST),
          onClick: suspendedBtnGuest,
        }}
        closeButton={{
          testId: "confirm-vehicle-coverage-suspend-cancel",
          onClick: suspendedBtnCancel,
        }}
      />
      <ContinueAsPpuModal
        active={showSuspendedCoverageNonPPUModal}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.COVERAGE_SUSPENDED_CONTACT_REPRESENTATIVE
        )}
        primaryButton={{
          testId: "confirm-vehicle-coverage-contact-test-call",
          value: getTranslatedText(TRANSLATION_CONSTANTS.CALL),
          onClick: callCallCenterBtn,
        }}
        closeButton={{
          testId: "confirm-vehicle-contact-rep-cancel",
          onClick: suspendedBtnCancel,
        }}
      />
      <Grid
        id="vehicleSelect"
        utils={{
          hrLeft: "md",
          hrRight: "md",
          vrTop: "md",
        }}
        verticalAlign="middle"
      >
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Grid.Column>
            <Flex alignItems="stretch" justifyContent="between" isBlock={true}>
              <Headline
                title={
                  <Type
                    variant="brand"
                    align="left"
                    size="3xl"
                    weight="semibold"
                    lineHeight="md"
                    data-testid="confirm-vehicle-title"
                  >
                    {getTranslatedText(
                      TRANSLATION_CONSTANTS.CONFIRM_VEHICLE_INFORMATION
                    )}
                  </Type>
                }
              />
              <Utility.Div
                onClick={() => {
                  history.push(generatePath("location"));
                }}
                data-testid="confirm-vehicle-close-icon"
              >
                <Icon icon={CloseIcon} size={1.75} />
              </Utility.Div>
            </Flex>
          </Grid.Column>
          <Divider
            thickness="thick"
            utils={{
              vrBottom: "md",
            }}
          />
          {!isEmpty(vehicleList) && (
            <Grid.Column>
              <FormOptionGroup
                label={getTranslatedText(TRANSLATION_CONSTANTS.VEHICLE)}
                id="terms"
                type="radio"
                required={true}
                options={vehicleList.map((vehicle, index) => {
                  return {
                    id: `radio-${index}`,
                    name: "terms",
                    text: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
                    value: `${vehicle.year} ${vehicle.make} ${vehicle.model}`,
                    checked: index === selectedVehicleIndex,
                    "data-testid": `confirm-vehicle-radio-${index}`,
                    onChange: () => {
                      onSelectVehicle(index, vehicle);
                    },
                  };
                })}
              />
            </Grid.Column>
          )}
          {selectedVehicleIndex !== undefined && (
            <Grid.Column
              utils={{
                vrBottom: "md",
              }}
            >
              <Dropdown
                defaultOption={{
                  text: "",
                  value: getTranslatedText(TRANSLATION_CONSTANTS.SELECT_ONE),
                }}
                id="color"
                label={getTranslatedText(TRANSLATION_CONSTANTS.COLOR)}
                name="color"
                onChange={(event) => {
                  setVehicleColor(event.target.value);
                }}
                value={vehicleColor.toUpperCase()}
                options={getTranslatedColors(getTranslatedText)}
                required
                sort="asc"
                data-testid="confirm-vehicle-color"
              />
            </Grid.Column>
          )}
          {serviceInfo.id !== FUEL_SERVICE_ID && (
            <Divider
              thickness="thick"
              utils={{
                vrBottom: "md",
              }}
            />
          )}
          {serviceInfo.id === FUEL_SERVICE_ID &&
            selectedVehicleIndex !== undefined && (
              <React.Fragment>
                <Grid.Column>
                  <FormOptionGroup
                    label={getTranslatedText(TRANSLATION_CONSTANTS.FUEL_TYPE)}
                    id="helpmechoose"
                    type="radio"
                    required={true}
                    options={[
                      {
                        id: "helpmechoose1",
                        name: "helpmechoose",
                        text: getTranslatedText(TRANSLATION_CONSTANTS.UNLEADED),
                        value: "Unleaded",
                        checked: fuelType === "Unleaded",
                        "data-testid": "confirm-vehicle-fuel-unleaded",
                        onChange: (event) => {
                          selectFuelType(event);
                        },
                      },
                      {
                        id: "helpmechoose2",
                        name: "helpmechoose",
                        text: getTranslatedText(TRANSLATION_CONSTANTS.DIESEL),
                        value: "Diesel",
                        checked: fuelType === "Diesel",
                        "data-testid": "confirm-vehicle-fuel-diesel",
                        onChange: (event) => {
                          selectFuelType(event);
                        },
                      },
                    ]}
                  />
                </Grid.Column>
                {commonBenefits.experience?.fuelCostCovered && !isEmpty(fuelServiceCoverages) && (
                  <Grid.Column>
                    <FuelAlert
                      fuelServiceCoverages={fuelServiceCoverages}
                    />
                  </Grid.Column>
                )}
                <Grid.Column>
                  <Divider thickness="thick" />
                </Grid.Column>
              </React.Fragment>
          )}
          {serviceInfo.id === FUEL_SERVICE_ID &&
            selectedVehicleIndex !== undefined &&
            !fuelCostCovered && (
              <Grid.Column>
                <Type variant="dark">
                  {`${getTranslatedText(
                    TRANSLATION_CONSTANTS.DELIVER_UP_TO
                  )} ${determineFuelAmount()} ${unitOfMeasure}. ${getTranslatedText(
                    TRANSLATION_CONSTANTS.COST_FUEL_NOT_INCLUDED
                  )}`}
                </Type>
              </Grid.Column>
            )}
          {addAVehicle && (
            <Grid.Column
              utils={{
                vrBottom: "md",
              }}
            >
              <Button
                size='lg'
                hasUpgrade
                isHollow
                shape="square"
                onClick={addAnotherVehicle}
              >
                <Icon icon={PlusIcon} />{" "}
                <Type variant="darker" weight="bold">
                  {getTranslatedText(TRANSLATION_CONSTANTS.ADD_VEHICLE)}
                </Type>
              </Button>
            </Grid.Column>
          )}
        </Grid.Column>
      </Grid>
      <Sticky
        portalContainer="#confirm-vehicle-container"
        stickToBody={false}
        stickToBottom
      >
        <Grid
          utils={{
            noMargin: true,
          }}
        >
          <Grid.Column
            col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
            offset={{ md: 4, lg: 4, xl: 4 }}
          >
            <Box
              hasBorder={false}
              utils={{
                noMargin: true,
              }}
            >
              <Button
                hasUpgrade
                shape="pill"
                size="lg"
                onClick={onConfirm}
                disabled={!isButtonEnabled()}
                utils={{
                  fullWidth: true,
                }}
                data-testid="confirm-vehicle-button"
              >
                {lowerCase(
                  getTranslatedText(TRANSLATION_CONSTANTS.CONFIRM_VEHICLE)
                )}
              </Button>
            </Box>
          </Grid.Column>
        </Grid>
      </Sticky>
    </Utility.Div>
  );
};

export const FuelAlert = ({fuelServiceCoverages}) => {
  const getTranslatedText = useLocale();
  const locale = determineLocale();
  let status = "";
  let quantity = 0;
  let unit = "";
  let coverage = {};
  if (locale === en_CA) {
    coverage = fuelServiceCoverages?.find(coverage => coverage?.country === CANADA_COUNTRY_CODE);
    status = coverage?.status;
    quantity = coverage?.quantity;
    unit = LITER;
  } else {
    coverage = fuelServiceCoverages?.find(coverage => coverage?.country === USA_COUNTRY_CODE);
    status = coverage?.status;
    quantity = coverage?.quantity;
    unit = GALLON;
  }

  return (
    <Alert
      variant="information"
      size="md"
      title={
        <Type
          align="left"
        >
          {`You'll receive ${quantity ? quantity : 2} ${unit}${(quantity && quantity < 2) ? '' : 's'} of fuel.`}
        </Type>
      }
      children={
        <Type
          align="left"
        >
          {status?.toLowerCase() === "covered" ? getTranslatedText(TRANSLATION_CONSTANTS.VAS_FUEL_COVERED) : getTranslatedText(TRANSLATION_CONSTANTS.VAS_FUEL_NOT_COVERED)}
        </Type>
      }
    >
    </Alert>
  )
}

export default VehicleSelect;
