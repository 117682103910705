export default function editPhone(inputString = "", isRemove) {
    let inputOnlyNumber = inputString.replace(/\s/g, "").replace("+1", "").replace("(", "").replace("(", "").replace(")", "").replace(/-/g, "");
    if (inputOnlyNumber.length > 10) {
      inputOnlyNumber = inputOnlyNumber.slice(0, 10);
    }
    if (inputOnlyNumber.length === 10) {
      const number = inputOnlyNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3'
      );
      return number;
    }
    if (inputString[0] === '1' || inputString[0] === '+') {
      inputString = inputString.slice(1, inputString.length - 1);
    }
    //Below logic for Handling backspace
    if (isRemove && (inputString.length === 7 || inputString.length === 8)) {
      inputString = inputString.slice(0, 7);
    }
    else if (isRemove && (inputString.length === 4 || inputString.length === 3)) {
      inputString = inputString.slice(0, 3);
    }

    return inputString;
}
