import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Grid,
  FormField,
  Icon,
  Tooltip,
  Flex,
  Type,
  Button,
  Alert,
} from "mesh-component-library";
import { QuestionIcon } from "mesh-icon-library";
import CONSTANTS from "../../app-consts/appConstants";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { errorMessageTranslatedText } from "../../utils/translatedTextUtils";
import { getCommonBenefits } from "../../selector";

const MotorNumberLookup = ({
  partnerDetails,
  dispatchCommonBenefitsLookup,
  showModal,
  closeModal,
  enhancedAuthPartner,
  setHasUsedAlternate,
  setCurrentLookup,
  setPrimaryLookupAttempted,
  primaryLookupAttempted,
  alternateLookupAttempted,
  currentLookup,
}) => {
  const {
    appId,
    experience: {
      lookupMethods: {
        components: { primary },
      },
      lookupMethodRules: { primary:primaryRule, alternate:alternateRule }
    },
  } = partnerDetails;
  const isPrimaryComponent = primary === currentLookup;
  const regex = isPrimaryComponent ? primaryRule?.motorVehicleNumber?.regex : alternateRule?.motorVehicleNumber?.regex;
  const errorMessage = isPrimaryComponent ? primaryRule?.motorVehicleNumber?.errorMessage : alternateRule?.motorVehicleNumber?.errorMessage;
  const maxLength = isPrimaryComponent ? primaryRule?.motorVehicleNumber?.maxLength : alternateRule?.motorVehicleNumber?.maxLength;
  const [motorVehicleNumber, setMotorVehicleNumber] = useState("");
  const [motorVehicleNumberHasError, setMotorVehicleNumberHasError] = useState(false);
  const getTranslatedText = useLocale();
  const commonBenefits = useSelector(getCommonBenefits);

  const showErrorMessage = commonBenefits.errorMessage === CONSTANTS.INVALID_MVA_NUMBER;

  const continueClick = () => {
    let urlParams = new URLSearchParams(window.location.search);
    const arsTokenParameter =
      urlParams.get("ARS_TOKEN") || urlParams.get("ars_token");
    const user = {};
    user.memberId = motorVehicleNumber;
    user.phoneLookup = false;
    user.appId = appId;
    user.lookupType = "motorNumberLookup";
    if (enhancedAuthPartner) {
      user.arsToken = arsTokenParameter;
    }
    setPrimaryLookupAttempted(primaryLookupAttempted + 1);
    dispatchCommonBenefitsLookup(user);
  };

  const onMotorVehicleNumberChange = (e) => {
    const motorVehicleNumber = e.target?.value;
    let isValid = true;
    if (regex && motorVehicleNumber) {
      const stringToRegex = new RegExp(regex);
      isValid = stringToRegex.test(motorVehicleNumber);
    }
    setMotorVehicleNumber(motorVehicleNumber);
    setMotorVehicleNumberHasError(!isValid);
  };

  const onSubmit = () => {
    const confirmText = `${getTranslatedText(
      TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER
    )}:`;
    const content = (
      <div>
        <p>
          <span>{confirmText}</span> {motorVehicleNumber}
        </p>
      </div>
    );
    const options = {
      titleHtml: content,
      yesNoSelected,
    };

    showModal(options);
  };

  const yesNoSelected = async ({ target: { value } }) => {
    closeModal();
    if (value === "yes") {
      continueClick();
    }
  };

  const handleOnKeyDownMotorVehicleNumber = (e) => {
    if (
      e.keyCode !== CONSTANTS.DELETE &&
      e.keyCode !== CONSTANTS.BACKSPACE &&
      e.target.value !== undefined &&
      (e.keyCode === CONSTANTS.EXPONENTIAL ||
        e.keyCode === CONSTANTS.DECIMALPOINT)
    ) {
      e.preventDefault();
    }
  };

  const searchByRaInsteadSelected = () => {
    setHasUsedAlternate(true);
    setCurrentLookup("rentalAgreementLookup");
  };

  const displayNotFoundMessage = () => {
    return (
      showErrorMessage && (
        <Grid
          utils={{
            vrTop: "sm",
          }}
        >
          <Grid.Column>
            <Alert
              title={
                "Sorry, we are having difficulty looking up your information."
              }
              variant="critical"
            />
          </Grid.Column>
        </Grid>
      )
    );
  };

  return (
    <>
      {displayNotFoundMessage()}
      <Grid verticalAlign="middle">
        <Grid.Column>
          <Type
            id="we-need-some-info-to-find-your-benefits"
            element="h1"
            weight="thin"
            variant="darker"
            align="left"
            size="4xl"
            utils={{
              vrBottom: "lg",
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)}
          </Type>
        </Grid.Column>
        <Grid.Column
          utils={{
            vrBottom: "sm",
          }}
        >
          <FormField
            size="lg"
            type="number"
            inputMode="numeric"
            id="motorVehicleNumberId"
            name="motorVehicleNumberId"
            label={
              <Flex
                alignItems="stretch"
                justifyContent="between"
                isBlock={true}
              >
                <Type size="l" weight="bold" emphasis="information">
                  {getTranslatedText(
                    TRANSLATION_CONSTANTS.MOTOR_VEHICLE_NUMBER
                  )}
                </Type>
                <Tooltip
                  id="mva-tooltip"
                  size="lg"
                  text={getTranslatedText(
                    TRANSLATION_CONSTANTS.PLEASE_ENTER_YOUR_MVA
                  )}
                >
                  <Icon icon={QuestionIcon} color="#0033A0" size={1.2} />
                </Tooltip>
              </Flex>
            }
            labelProps={{
              style: {
                width: "100%",
              },
            }}
            errorText={getTranslatedText(
              errorMessageTranslatedText(errorMessage)
            )}
            value={motorVehicleNumber}
            onKeyDown={handleOnKeyDownMotorVehicleNumber}
            onChange={onMotorVehicleNumberChange}
            hasError={motorVehicleNumberHasError}
            required
            maxLength={maxLength}
            data-testid="mva-field"
          />
        </Grid.Column>
        <Grid.Column>
          <Button
            size="lg"
            hasUpgrade
            id="lookup-continue"
            disabled={!motorVehicleNumber || motorVehicleNumberHasError}
            onClick={onSubmit}
            data-testid="motor-vehicle-number-continue"
            utils={{
              fullWidth: true,
            }}
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
          </Button>
        </Grid.Column>
      </Grid>
      {alternateLookupAttempted < 2 && (
        <>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type element="span" size="4xl" variant="darker" weight="normal">
                - {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -
              </Type>
            </Grid.Column>
          </Grid>
          <Grid
            utils={{
              vrBottom: "md",
            }}
          >
            <Grid.Column>
              <Type
                element="span"
                size="lg"
                variant="information"
                weight="bold"
                onClick={searchByRaInsteadSelected}
              >
                {getTranslatedText(
                  TRANSLATION_CONSTANTS.SEARCH_BY_RENTAL_AGREEMENT
                )}
              </Type>
            </Grid.Column>
          </Grid>
        </>
      )}
    </>
  );
};

export default MotorNumberLookup;
