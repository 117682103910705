import React from 'react';
import ErrorIcon from '../svg/errorIcon';
import {TRANSLATION_CONSTANTS} from "../../app-consts/translations";
import {useLocale} from "../../contexts/LocaleContext";

export const GoneOnArrival = () => {
  const getTranslatedText = useLocale()
  return (
    <div className="l-grid__col">
      <div className="l-container-home__center">
        <div className="l-container-home__incomplete">
          <div className="l-container-home__incomplete--icon"><ErrorIcon/>
          </div>
          <h5 className="l-container-home__incomplete--title">
            <b>{getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_INCOMPLETE)}</b>
            <p>{getTranslatedText(TRANSLATION_CONSTANTS.GONE_ON_ARRIVAL)}</p>
          </h5>
        </div>
      </div>
    </div>
  )
}
