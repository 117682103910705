import {
  ERROR_GETTING_NEAR_BY_DESTINATIONS,
  NO_TOW_LOCATIONS_FOUND,
  REFRESH_REDUCERS,
  RESET_NEAR_BY_DESTINATIONS,
  UPDATE_NEAR_BY_DESTINATIONS,
  ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS,
  ADD_MORE_NEAR_BY_DESTINATIONS
} from './../action'

const initialState = {
  places: [],
  destinations: [],
  error: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case NO_TOW_LOCATIONS_FOUND:
    case ERROR_GETTING_NEAR_BY_DESTINATIONS:
      return {destinations: [], error: true}
    case UPDATE_NEAR_BY_DESTINATIONS:
      return {places: [...action.payload.places], destinations: [...action.payload.destinations], error: false};
    case ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS:
      return {destinations: [...state.destinations], error: true};
    case ADD_MORE_NEAR_BY_DESTINATIONS:
      const newDestinations = action.payload.newDestinations?.filter(destination => destination);
      return { ...state, destinations: [...state.destinations, ...newDestinations], error: false};
    case REFRESH_REDUCERS:
      return {...initialState};
    case RESET_NEAR_BY_DESTINATIONS:
      return {...initialState};
    default:
      return state;
  }
}
