import React from 'react';
import Loader from "../loader";

class Spinner extends React.Component {

  render() {
    return (
      <div className="c-overlay has-loader is-fixed c-container-warning">
        {this.props.children}
        <Loader data-testid="loader" className="c-loader--xl c-loader--position"/>
      </div>)
  }
}

export default Spinner;
