import React from "react";

const TriageStep = ({
  step = 1,
  title = "",
  content = "",
  titleClass = "",
  contentClass = "",
}) => {
  return (
    <div className="triage-step-main d-flex flex-row text-start mb-3">
      <div
        className="triage-step-icon me-1"
        data-testid={`triage-step-icon-${step}`}
      >
        <div className="triage-step-icon-text">{step}</div>
      </div>
      <div className="flex-grow-1 flex-column ms-2">
        <div
          className={`triage-step-title text-start mb-2 ${titleClass}`}
          data-testid={`triage-step-title-${step}`}
        >
          {title}
        </div>
        <div
          className={`triage-step-content text-start ${contentClass}`}
          data-testid={`triage-step-content-${step}`}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
};

export default TriageStep;
