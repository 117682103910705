import {
  REFRESH_REDUCERS,
  REQUESTED_MAKE_MODEL,
  REQUESTED_MAKE_MODEL_FAILED,
  REQUESTED_MAKE_MODEL_SUCCESS,
  RESET_MAKE_MODEL
} from './../action';

const initialState = {
  request : {},
  payload : [],
  error : false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUESTED_MAKE_MODEL:
      return {...state, request: action.request, payload:[]};
    case REQUESTED_MAKE_MODEL_SUCCESS:
      return {...state, request: {}, payload: action.payload};
    case REQUESTED_MAKE_MODEL_FAILED:
      return {...state, request :{}, payload: action.payload, error:true};
    case REFRESH_REDUCERS:
      return {...initialState};
    case RESET_MAKE_MODEL:
      return {...initialState};
    default:
      return state;
  }
}
