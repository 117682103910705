import {datadogRum} from '@datadog/browser-rum'
import {DEFAULT_CLOSEAT, DEFAULT_OPENFROM} from "../app-consts/appConstants";
import titleCase, {upperCaseFirstWord} from "./titleCase";
import { flow, keyBy, mapValues } from "lodash";
import { StateList } from '../utilities/StateList';
import { startCase, lowerCase } from 'lodash';

export const isSupportedRegion = (googleLocation, supportedRegions) => {
  const locationCountry = googleLocation.address_components.filter(
    (component)=>component.types.includes("country"))[0]?.short_name
  return supportedRegions.includes(locationCountry)
}

export const formatAddress = (addressInfo, isTowToDestinationAddress = true, towToCustomDestinationList, address, partnerCode, towToLocationType) => {
  try{
    const formattedAddress = {};
    const addressDetails = parseAddressComponents(addressInfo.address_components)

    let fieldErrors = []

    formattedAddress.addressLine1 = getStreetInfoFromAddressComponents(addressDetails)
    if(addressDetails["neighborhood,political"]) formattedAddress.addressLine2 = addressDetails["neighborhood,political"];
    formattedAddress.city = getCityFromAddressComponents(addressDetails);
    formattedAddress.state = formatState(addressDetails["administrative_area_level_1,political"]);
    formattedAddress.zipCode = addressDetails["postal_code"];
    formattedAddress.country = addressDetails["country,political"];

    const {lat, lng} = getLatLngAsValue(addressInfo.geometry.location)
    formattedAddress.latitude = lat;
    formattedAddress.longitude = lng;

    if(addressInfo.dealerId) formattedAddress.dealerId = addressInfo.dealerId
    if(addressInfo.name) formattedAddress.bizName = addressInfo.name
    if (towToCustomDestinationList?.length > 0 && !towToLocationType) {
      let selectedDestinationByBusinessName = towToCustomDestinationList.filter((destination) => {
        return destination.searchableName === address;
      })
      formattedAddress.dealerId = selectedDestinationByBusinessName[0].dealerId
      formattedAddress.bizName = getDealerDisplayName((selectedDestinationByBusinessName[0]?.dealerDisplayName || selectedDestinationByBusinessName[0]?.name), partnerCode)
    }

    let requiredFields = ['city', 'state', 'zipCode', 'country', 'latitude', 'longitude', 'addressLine1']
    if((isTowToDestinationAddress || towToCustomDestinationList?.length > 0) && !towToLocationType){
      requiredFields = requiredFields.concat(['dealerId', 'bizName'])
    }

    requiredFields.forEach((field) => {
      if(!formattedAddress[field] || !formattedAddress[field].toString().length){
        if(field === 'zipCode') fieldErrors.push('zip code')
        else if(field === 'bizName' && isTowToDestinationAddress) fieldErrors.push('business name')
        else if(field === 'dealerId' && isTowToDestinationAddress) fieldErrors.push('Dealer Id')
        else fieldErrors.push(field)
      }
    })

    if(fieldErrors.length){
      datadogRum.addError(new Error(`Missing address fields for address`), { fieldErrors, addressInfo });
      let errorMessage = createReadableErrorMessageForMissingFields(fieldErrors)
      formattedAddress.error = {errorMessage: errorMessage}
    }
    return formattedAddress

  } catch(error){
    window.datadogRum?.addError(new Error(`formatAddress error ${error}`))
    return {error: {errorMessage: `Could not populate all required fields ${error}`, missingFields: []}}
  }
}
export const getDealerDisplayName = (displayName, partnerCode) => {
  let dealerDisplayName = '';
  if (displayName?.includes("-") && partnerCode === "PEP") {
    /* if block gets executed when the partnerCode is PEP.
       The below logic splits displayName by '-', removes last part and returns remaining string.
       For ex.: displayName = "Pep Boys Lancaster/Pa-Lancaster,PA"
       return value will be "Pep Boys Lancaster/Pa"
       We don't want to remove special characters from dealerDisplayName for PEP Boys.
    */
    let splitVal = displayName?.toLowerCase().split('-');
    splitVal.pop();
    dealerDisplayName = splitVal.map((x) => titleCase(x)).join("-");
  } else {
    /* else block gets executed for DriveSure currently and ANY NEW PARTNER WE ONBOARD WITH TOWTOENABLED AS TRUE.
       We had an issue with DriveSure towToLocation which had special characters in name. So, we decided to use startCase and lowerCase lodash functions which
       converts first letter of a word to uppercase and rest of the characters in the word to lowercases and also removes any special characters present in the
       displayName passed.
       For ex.: DriveSure destination.name is coming as "SERVCO TOYOTA HONOLULU SERVICE MÄPUNAPUNA"
       return value will be "Servco Toyota Honolulu Service Ma Punapuna"
    */
    dealerDisplayName = startCase(lowerCase(displayName))
  }
  return dealerDisplayName;
}
const parseAddressComponents = (addressComponents, property = 'short_name') => {
  const currentAddr = flow(
    x => keyBy(x, "types"),
    x => mapValues(x, property)
  )(addressComponents);
  return currentAddr;
}

export const getStreetInfoFromAddressComponents = (addressDetails) => {
  if(addressDetails['street_address']) return addressDetails['street_address']

  if (addressDetails['route']) {
    let streetInfo = ''
    if(addressDetails['street_number']) streetInfo += (addressDetails['street_number'] + ' ')
    streetInfo += (addressDetails['route'])
    if(addressDetails["subpremise"]) streetInfo += (', ' + addressDetails["subpremise"])
    return streetInfo.trim()
  }

  if (addressDetails.name) return addressDetails.name
  if (addressDetails.vicinity) return addressDetails.vicinity.split(",")[0]
}

export const getCityFromAddressComponents = (addressDetails) => {
  let addressComponentKeys = Object.keys(addressDetails)

  let findKey = (addressComponentKeys, type1, type2) => {
    return addressComponentKeys.find((key) => {
      let types = key.split(',')
      if(type2) {
        return types.includes(type1) && types.includes(type1)
      } else {
        return types.includes(type1)
      }
    })
  }

  let cityKey = findKey(addressComponentKeys, 'locality') ||
    findKey(addressComponentKeys, 'administrative_area_level_3') ||
    findKey(addressComponentKeys, 'sublocality', 'sublocality_level_1') ||
    findKey(addressComponentKeys, 'neighborhood')

  return addressDetails[cityKey]
}

export const getLatLngAsValue = (location) => {
  let {lat, lng} = location
  if(lat instanceof Function){
    lat = lat()
    lng = lng()
  }

  return {lat, lng}
}

export const setOpenFromCloseAtToDefaultIfMissing = (destinations) => {
  return destinations.map(destination => {
    let openfrom = destination?.opening_hours?.openfrom
    let closeat = destination?.opening_hours?.closeat
    let opening_hours = {}
    if(!openfrom) {
      openfrom = DEFAULT_OPENFROM
    }
    if(!closeat) {
      closeat = DEFAULT_CLOSEAT
    }
    opening_hours.openfrom = openfrom;
    opening_hours.closeat = closeat;
    destination.opening_hours = opening_hours;
    return destination;
  })
}

export const addSearchableNameForDestination = (destinations, partnerCode) => {
    return destinations.map(destination => {
      let name = getDealerDisplayName((destination?.dealerDisplayName || destination?.name), partnerCode);
      let address = titleCase(destination?.address);
      destination.name = name;
      destination.searchableName = name + ', ' + address;
      return destination;
    })
}

const createReadableErrorMessageForMissingFields = (fieldErrors) => {
  let readableErrors = '';
  for(let int = 0; int < fieldErrors.length; int++) {
    if(fieldErrors.length === 1) {
      readableErrors = readableErrors.concat(`${upperCaseFirstWord(fieldErrors[int].toString())} is missing.`);
    } else if(int === fieldErrors.length - 1) {
      readableErrors = readableErrors.concat(`and ${fieldErrors[int].toString()} are missing.`);
    } else if (int === 0 ) {
      readableErrors = readableErrors.concat(`${upperCaseFirstWord(fieldErrors[int].toString())}, `);
    } else readableErrors = readableErrors.concat(`${fieldErrors[int].toString()}, `);
  }
  return `Invalid Address: ${readableErrors}`
}

export const getResultByTypeAndDistanceToCoords = (originatingCoords, geocodingResult) => {
  const filteredResults = geocodingResult.filter(result => {
    return result.types.includes("route")
      || result.types.includes("establishment")
      || result.types.includes("street_address")
      || result.types.includes("premise")
  })

  if (filteredResults.length === 0) {
    return geocodingResult[0]
  }

  let distanceToResults
  try {
    distanceToResults = filteredResults
      .map(result => {
        result.distanceFromOrigin = window.google.maps.geometry.spherical.computeDistanceBetween(originatingCoords, result.geometry.location)
        return result
      })
      .sort((a, b) => a.distanceFromOrigin - b.distanceFromOrigin)
  } catch (e) {
    return geocodingResult[0]
  }

  return distanceToResults[0]
}

export const formatState = (state = "") => {
  if (!state) {
    return;
  }
  if (state.length > 2) {
    const stateFound = StateList.find((element) => {
      return element.text === state.toUpperCase();
    });
    return stateFound?.value;
  } else {
    return state;
  }
};
