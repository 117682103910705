
import React from "react";
import { Button, Flex, Icon, Modal, Type, Utility } from "mesh-component-library";
import { CloseIcon } from "mesh-icon-library";
import lowerCase from "lodash/lowerCase";
import { useLocale } from "../../contexts/LocaleContext";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";

export const ContinueAsPpuModal = ({
    active = false,
    content = "",
    primaryButton = {
        testId: "continue-as-ppu-primary",
        value: "",
        onClick: () => { },
    },
    closeButton = {
        testId: "continue-as-ppu-close",
        onClick: () => { },
    },
}) => {
    const getTranslatedText = useLocale();
    return (
        <Modal hideTrigger isActive={active} isFlushMobile>
            <Modal.Header
                title={
                    <Type variant="inherit" weight="bold">
                        {getTranslatedText(TRANSLATION_CONSTANTS.WE_ARE_SORRY)}
                    </Type>
                }
                hasCloseButton={false}
                isInverse
            >
                <Utility.Div onClick={closeButton.onClick}>
                    <Icon icon={CloseIcon} color="#FFFFFF" size={1.75} />
                </Utility.Div>
            </Modal.Header>
            <Modal.Body variant="cool-gray">
                <Type variant="dark" size="xl" lineHeight="lg">
                    {content}
                </Type>
            </Modal.Body>
            <Modal.Footer>
                <Flex
                    direction="column"
                    alignItems="middle"
                    utils={{
                        hrRight: "sm",
                    }}
                >
                    <Utility.Div utils={{ fullWidth: true }}>
                        <Button
                            size='lg'
                            isPrimary
                            shape="pill"
                            onClick={primaryButton.onClick}
                            utils={{ fullWidth: true, vrBottom: "md" }}
                            data-testid={primaryButton.testId}
                        >
                            {lowerCase(primaryButton.value)}
                        </Button>
                    </Utility.Div>
                    <Utility.Div utils={{ fullWidth: true }}>
                        <Button
                            size='lg'
                            shape="pill"
                            variant="muted"
                            onClick={closeButton.onClick}
                            utils={{ fullWidth: true }}
                            data-testid={closeButton.testId}
                        >
                            {lowerCase(getTranslatedText(TRANSLATION_CONSTANTS.CANCEL))}
                        </Button>
                    </Utility.Div>
                </Flex>
            </Modal.Footer>
        </Modal>
    );
};