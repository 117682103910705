import React, {Component } from 'react'; 

class Provider extends Component {

 render() {
    return(
    
<svg xmlns='http://www.w3.org/2000/svg' id="provider" width='46' height='46' viewBox='0 0 46 46'>
    <g fill='none' fillRule='evenodd'>
        <path fill='#4E5969' d='M34.34 30.373c-.845 0-1.55-.608-1.744-1.423a1.936 1.936 0 0 1-.058-.446c0-1.031.807-1.871 1.801-1.871.994 0 1.801.84 1.801 1.87 0 .15-.02.293-.053.432-.188.823-.898 1.438-1.748 1.438zm-3.304-7.668H26.12c-.78 0-1.199-.435-1.199-1.058 0-.621.064-.682.12-1.244.055-.557.18-1.183.6-1.183h4.142c.66 0 .861.43 1.208 1.088 0 0 .43.784.797 1.464.29.534-.153.933-.753.933zm-9.06.276h-4.174v-1.64a.58.58 0 0 0-.183-.423l-6.052-5.676 10.408 4.65v3.089zm-5.574 7.392c-.81 0-1.488-.56-1.715-1.324a1.923 1.923 0 0 1-.087-.545c0-1.031.808-1.871 1.802-1.871.993 0 1.801.84 1.801 1.87 0 .163-.026.317-.063.467-.201.805-.901 1.403-1.738 1.403zM8.674 14.055v6.328a1.068 1.068 0 0 0-.464.875c0 .368.19.68.464.874v.65c0 .498-.39.903-.87.903a.877.877 0 0 1-.791-.521.541.541 0 0 0-.733-.274.58.58 0 0 0-.264.762c.324.715 1.026 1.179 1.788 1.179 1.087 0 1.972-.92 1.972-2.049v-.614a1.07 1.07 0 0 0 .514-.91 1.07 1.07 0 0 0-.514-.91v-5.242l6.922 6.49v1.385h-4.21c-.823 0-1.191.504-1.191 1.005 0 0 .005.748.005 1.184 0 .117-.083.22-.216.378-.165.195-.37.437-.37.808v1.119c0 1.066.562 1.583 1.717 1.583h1.119c.252 1.398 1.432 2.46 2.85 2.46 1.452 0 2.648-1.116 2.86-2.563l.353-.001h11.521c.101 0 .22 0 .342-.002.212 1.45 1.408 2.567 2.861 2.567 1.46 0 2.66-1.128 2.863-2.587H38.277c.84 0 1.744-.48 1.744-1.526l.016-.845c.008-.437-.104-1.06-.308-1.345-.113-.16-.247-.643-.257-1.08-.013-.55-.427-1.084-.943-1.216l-4.43-1.137c-.366-.093-.84-.456-1.037-.792l-1.442-2.473c-.264-.45-.866-.803-1.374-.803H25.04c-.54 0-1.018.455-1.064 1.013L23.7 22.02a1.035 1.035 0 0 1-.622.77v-3.276a.574.574 0 0 0-.333-.526l-13.8-6.164a.54.54 0 0 0-.683.216.59.59 0 0 0 .094.734l.319.28z'
        />
        <rect width='43.444' height='43.444' x='1.278' y='1.278' stroke='#4E5969'
        strokeWidth='2.556' rx='21.722' />
    </g>
</svg>
)
}
}

export default Provider;

