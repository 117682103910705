import { SERVICE_REQUEST_INFO_REQUESTED, 
    SERVICE_REQUEST_INFO_SUCCESS, 
    SERVICE_REQUEST_INFO_FAILED,REFRESH_REDUCERS,REFRESH_SERVICE_REQUEST_INFO } from './../action';

const initialState = {
  request : {},
  response: {},  
  loading: false,
  error: false
};

export default function(state=initialState, action){
    switch(action.type){
        case SERVICE_REQUEST_INFO_REQUESTED:
            return {...state, request: action.payload, loading: true};
        case SERVICE_REQUEST_INFO_SUCCESS:
            return {...state, response: action.payload, loading: false};
        case SERVICE_REQUEST_INFO_FAILED:
            return {...state, loading: false, error: true};
        case REFRESH_REDUCERS:
            return {...state,request: {}, response:{},loading: false,};
        case REFRESH_SERVICE_REQUEST_INFO:
            return {...state,request: {}, response:{},loading: false,};
        default:
            return state;
    }
}