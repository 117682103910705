import { PROVIDER_INFO_REQUESTED,
    PROVIDER_INFO_SUCCESS,
    PROVIDER_INFO_FAILED,REFRESH_REDUCERS,REFRESH_PROVIDER_INFO } from './../action';

const initialState = {
  request : {},
  response: {},
  loading: false,
  error: false
};

export default function(state=initialState, action){
    switch(action.type){
        case PROVIDER_INFO_REQUESTED:
            return {...state, request: action.payload, loading: true};
        case PROVIDER_INFO_SUCCESS:
            return {...state, response: action.payload, loading: false};
        case PROVIDER_INFO_FAILED:
            return {...state, loading: false, error: true, unauthorized: action.payload};
        case REFRESH_REDUCERS:
            return {...state,request: {}, response:{},loading: false,};
        case REFRESH_PROVIDER_INFO:
            return {...state,request: {}, response:{},loading: false,};
        default:
            return state;
    }
}
