import React, { Component } from 'react';
import { connect } from 'react-redux';
import CreditCardIcons from '../creditcard-icons';

class CreditCardInfo extends Component {

        constructor(props) {
            super(props);
            this.showCreditCardInfo = this.showCreditCardInfo.bind(this);
        }

        showCreditCardInfo(){
            let {maskedCardNumber, cardExpirationDate, cardBrand} = this.props.epcsStatus.response;
            return (
                <div className="cc-info-block">
                    <span className="cc-info-text">{`**** ${maskedCardNumber.substr(maskedCardNumber.length-4)}`}</span>
                    <span className="cc-info-text">{`${cardExpirationDate.substr(0,2)}/${cardExpirationDate.substr(-2)}`}</span>
                    <span className="cc-info-text"><CreditCardIcons creditCard={cardBrand} /></span>
                    <span className="cc-info-btn"><a tabIndex="0" onClick={this.props.enableIframe} onKeyDown={this.props.enableIframe}>Edit</a></span>
                </div>
            );
        }

        render(){
            return (
                <div>
                    {this.props.epcsStatus.response.maskedCardNumber?this.showCreditCardInfo():""}
                </div>
            )
        }
    }

function mapStateToProps(state) {
  return {
    epcsStatus : state.epcsStatus
  };
}

export default connect(mapStateToProps,{})(CreditCardInfo);

