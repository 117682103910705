import React, {useState} from 'react';
import FormField from '../../form-field';
import Form from '../../form';
import FormValidators from "../../../utilities/form-validators";
import {hasSpecialChars, isOnlyNumbers} from "../../../app-consts/appConstants";
import {sendJacadaRequest} from "../../../api";
import Alert from "../../alert";
import LookUpFormHeader from '../Components/LookUpFormHeader';
import {sendVinToJacada} from "../../../jacada/sendJacadaEventData";
import {useLocale} from "../../../contexts/LocaleContext";
import {TRANSLATION_CONSTANTS} from "../../../app-consts/translations";
import useQuery from '../../../hooks/useQuery'
import { Button } from 'mesh-component-library';

const VerifyVehicle = ({dispatchCommonBenefitsLookup, partnerDetails, closeModal, showModal, milesBased, setCurrentLookup}) => {
  const { appId } = partnerDetails;
  const [vin, setVin] = useState(localStorage.getItem('vin') || '');
  const [vinHasError, setVinHasError] = useState(false);
  const [mileage, setMileage] = useState(localStorage.getItem('mileage') || '');
  const [mileageHasError, setMileageHasError] = useState(false);
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  const [vehicleNotFound] = useState(localStorage.getItem("licensePlateLookupResultsFailure"));
  const getTranslatedText = useLocale();
  const { ars_token } = useQuery();

  const displayLicensePlateOption = partnerDetails.experience?.lookupMethods?.components?.primary !== partnerDetails.experience?.lookupMethods?.components?.alternate
    && (!localStorage.getItem('vin') || !ars_token);

  const isVinValid = (vinInput) => {
    return !hasSpecialChars.test(vinInput) && vinInput.length === 17;
  }

  const vinOnBlur = ({target: {value}}) => {
    setVinHasError(!isVinValid(value));
    sendVinToJacada(value.toUpperCase());
  }

  const vinOnChange = ({target: {value}}) => {
    setVinHasError(false);
    let vinIsValid = isVinValid(value);
    let mileageIsValid = isMileageValid(mileage);
    let continueButtonDisabled = !(vinIsValid && mileageIsValid);
    const formattedValue = value.toUpperCase();
    setVin(formattedValue);
    setContinueButtonDisabled(continueButtonDisabled);
  }

  const vinOnKeyPress = (event) => {
    const isAlphaNumericVal = FormValidators.allowOnlyAlphaNumericInput(event);
    setVinHasError(!isAlphaNumericVal);
  }

  const mileageOnChange = ({target: {value}}) => {
    let vinIsValid = isVinValid(vin);
    let mileageIsValid = isMileageValid(value);
    let continueButtonDisabled = !(vinIsValid && mileageIsValid);

    setMileage(value);
    setContinueButtonDisabled(continueButtonDisabled);
    setMileageHasError(!mileageIsValid);
  }

  const isMileageValid = (value) => {
    return isOnlyNumbers.test(value);
  }

  const mileageOnKeyPress = (event) => {
    const isNumberVal = FormValidators.allowOnlyNumbersInput(event);
    setMileageHasError(!isNumberVal);
  }

  const displayVehicleNotFoundMessage = () => {
    let message = !!localStorage.getItem('updateAttempts') ?
      "Sorry, we are having difficulty looking up your vehicle information."
      :
      "Sorry, we can't find a vehicle matching your license plate."

    return (
      <div className="l-container-verifyVehicle c-alert--vehicleNow">
        <Alert type="error">
          <b>{message}</b>
        </Alert>
      </div>
    );
  }

  const onSubmit = () => {
    const content = <div>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.VIN)}:</span> {vin}</p>
      <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.ODOMETER)}:</span> {mileage}</p>
    </div>
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  }

  const yesNoSelected = ({target: {value}}) => {
    if(value==='yes'){
      requestBenefits()
    } else {
      closeModal()
    }
  }
  const requestBenefits = () => {
    const formattedVin = vin.toUpperCase();
    localStorage.setItem('memberId', formattedVin);
    localStorage.setItem('mileage', mileage);

    const formdata = {
      vin: formattedVin,
      mileage
    };

    const user = {
      phoneLookup: false,
      appId,
      formdata,
      lookupType:'vinMileage',
      arsToken: ars_token || ''
    };

    let eventsData = [];
    eventsData.push(
      {
        'name': 'odometer_nbr',
        'value': mileage
      }
    );

    if (!milesBased) {
      eventsData.push(
        {
          'name': 'odometer_unit_name',
          'value': 'Kilometers'
        });
    } else {
      eventsData.push(
        {
          'name': 'odometer_unit_name',
          'value': 'miles'
        });
    }


    let jacadaRequestData = {
      'externalSystemId': localStorage.getItem('conversationId'),
      'events': eventsData
    }
    sendJacadaRequest(jacadaRequestData);
    dispatchCommonBenefitsLookup(user);
    closeModal();
    localStorage.setItem('vin', formattedVin);
  }
  const searchByLicensePlateNumber = () => {
    setCurrentLookup('licensePlateLookup')
  }

  return (
    <React.Fragment>
      {vehicleNotFound ? displayVehicleNotFoundMessage() : null}
      <LookUpFormHeader titleText={getTranslatedText(TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO)} id={'please-provide-your-vehicle-info'}/>
      <Form className="c-container-lookup__form">
        <FormField
          label={getTranslatedText(TRANSLATION_CONSTANTS.VIN)}
          data-testid="vehicle-vin-value"
          id="vin"
          name="VIN"
          className=""
          errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_VALID_VIN)}
          minLength={17}
          maxLength={17}
          value={vin}
          onChange={vinOnChange}
          onKeyDown={vinOnKeyPress}
          hasError={vinHasError}
          tabIndex="1"
          onBlur={vinOnBlur}
          infoPopup={getTranslatedText(TRANSLATION_CONSTANTS.VIN_FIND_INSTRUCTIONS)}
          required/>

        <FormField
          label={getTranslatedText(TRANSLATION_CONSTANTS.ODOMETER)}
          id="mileage"
          name="mileage"
          errorText={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_VALID_MILEAGE)}
          maxLength={7}
          value={mileage}
          onChange={mileageOnChange}
          hasError={mileageHasError}
          onKeyDown={mileageOnKeyPress}
          tabIndex="2"
          infoPopup={getTranslatedText(TRANSLATION_CONSTANTS.CAPTURE_ACTUAL_MILEAGE)}
          required/>
        <Button
          size='lg'
          hasUpgrade
          id="lookup-continue"
          data-testid="continue-with-vin"
          disabled={continueButtonDisabled}
          onClick={onSubmit}
          utils={{
            fullWidth: true
          }}>
          {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
        </Button>
        {displayLicensePlateOption &&
          <div>
            <div className="or-text u-align-center u-vr-2-top" >- {getTranslatedText(TRANSLATION_CONSTANTS.OR)} -</div>
            <div className="u-align-center u-vr-2-top" >
              <a onClick={searchByLicensePlateNumber}>{getTranslatedText(TRANSLATION_CONSTANTS.SEARCH_BY_LICENSE_PLATE)}</a>
            </div>
          </div>
        }
      </Form>
    </React.Fragment>
  )

}

export default VerifyVehicle;
