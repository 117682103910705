import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class SelectablePanel extends Component {

  static propTypes = {
    text: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    ariaLabel: PropTypes.string,
    value: PropTypes.string,
    checked: PropTypes.bool,
    serviceclass: PropTypes.string,
    containerclass: PropTypes.string,
    pricingLabel: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    text: '',
    type: 'checkbox',
    className: '',
    name: '',
    ariaLabel: '',
    value: '',
    checked: false,
    containerclass: "",
    pricingLabel: "",
    disabled: false
  };

  render() {
    const {
      children,
      className,
      defaultChecked,
      text,
      serviceclass,
      price,
      type,
      name,
      ariaLabel,
      value,
      checked,
      errorMessage,
      metaMessage,
      mileageBenefits,
      onClick,
      onKeyDown,
      id,
      role,
      location,
      containerclass,
      pricingLabel,
      disabled
    } = this.props;

    return (

        <label
          htmlFor={ id }
          className={`c-selectable-panel ${disabled ? `is-disabled` : ''} ${className}`}>
          <input
            onClick={onClick}
            onKeyDown={onKeyDown}
            role={role}
            type={ type }
            ref={ input => { this.input = input; } }
            id={ id }
            name={ name }
            aria-label={ ariaLabel }
            className="c-selectable-panel__input"
            value={ value }
            defaultChecked={ defaultChecked || checked } 
            disabled={disabled}/>
          <div className={(location ? "flex-container selectable-location " : "flex-container ") + containerclass }>
          <span id={'modal-'+id} className={`c-selectable-panel__bd ${serviceclass} ${className}`}>{ text }</span>
           {children}
          <div className="flex-item-4">
            {!mileageBenefits ?
              <div className={`c-selectable-panel__pr ${className}`}>{pricingLabel}{price && ` $${price}`}</div>
              : null
            }
            {mileageBenefits &&
              <div className="c-selectable-panel__mi">{ mileageBenefits }</div>
            }
            {errorMessage &&
              <div className="c-selectable-panel__er">{errorMessage}</div>
            }
          </div>

         </div>
         {metaMessage &&
            <span className="c-selectable-panel__mt">{metaMessage}</span>
          }
        </label>

      );
  }
}
