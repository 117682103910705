import _isNil from "lodash/isNil";
import { AMAZON_CONNNECT_ID } from "../app-consts/appConstants";

export const injectAmazonConnect = (
  amazonConnect = {},
  afterVaChatLoaded = (data) => {}
) => {
  const url = amazonConnect?.url || "";
  const configId = amazonConnect.configId || "";
  const amazonConnectScriptElement =
    document.getElementById(AMAZON_CONNNECT_ID);
  if (_isNil(amazonConnectScriptElement) && url && configId && !getVaChat()) {
    const script = document.createElement("script");
    script.async = true;
    script.defer = true;
    script.src = url;
    script.id = AMAZON_CONNNECT_ID;
    script.onload = () => {
      const vaChat = getVaChat();
      vaChat.config({
        id: configId,
        hideChatIcon: true,
        company: "NA",
      });
      afterVaChatLoaded({ loaded: true });
    };
    document.body.appendChild(script);
  }
  if (!_isNil(amazonConnectScriptElement) && url && configId && getVaChat()) {
    afterVaChatLoaded({ loaded: true });
  }
};

export const getVaChat = () => {
  if (_isNil(window.vaChat)) {
    return undefined;
  }
  return window.vaChat;
};
