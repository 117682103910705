import { useEffect, useState } from "react";
import { breakpoints } from "mesh-component-library/dist/styles";

const calcScreenBreakpoint = () => {
  const screenWidth = window.innerWidth ?? breakpoints.sm[0];
  let currentBreakpoint = "sm";
  if (screenWidth < breakpoints.sm[1]) {
    currentBreakpoint = "sm";
  }
  if (screenWidth >= breakpoints.md[0] && screenWidth <= breakpoints.md[1]) {
    currentBreakpoint = "md";
  }
  if (screenWidth > breakpoints.lg[0] && screenWidth <= breakpoints.lg[1]) {
    currentBreakpoint = "lg";
  }
  if (screenWidth > breakpoints.xl[0]) {
    currentBreakpoint = "xl";
  }
  return currentBreakpoint;
};

export const useScreenBreakpoint = () => {
  const [screenBreakpoint, setScreenBreakpoint] = useState(
    calcScreenBreakpoint()
  );

  useEffect(() => {
    const handleResize = () => {
      const currentBreakpoint = calcScreenBreakpoint();
      if (currentBreakpoint !== screenBreakpoint) {
        setScreenBreakpoint(currentBreakpoint);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [screenBreakpoint]);

  return screenBreakpoint;
};
