import React, { useState } from 'react'
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import {
    Grid,
    Type,
    FormField,
    Flex,
    Button,
    Autocomplete
} from 'mesh-component-library';
import { StateList } from "../../utilities/StateList";
import titleCase from "../../utils/titleCase";

const PolicyNumberStateCodeLookup = ({ dispatchCommonBenefitsLookup, showModal, closeModal, currentLookup }) => {
    const [stateCode, setStateCode] = useState('');
    const [policyNumber, setPolicyNumber] = useState('');

    const getTranslatedText = useLocale();

    const handleChange = (event, selectedState) => {
        if (selectedState?.selectedItem) {
            setStateCode(selectedState?.selectedItem)
        } else {
            setStateCode('')
        }
    }

  const validateCompleteForm = () => {
    let policyNumberAvailable = policyNumber?.length > 0;
    let stateCodeSelected = stateCode?.text?.length > 0;
    if (policyNumberAvailable && stateCodeSelected) {
      return !policyNumberAvailable && !stateCodeSelected;
    }
    return true;
  };

    const onSubmit = () => {
        let content = <div>
                {policyNumber?.length > 0 ? <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER)}:</span> {policyNumber}</p> : ""}
                <p><span>State:</span> {titleCase(stateCode.text)}</p>
            </div>;

        const options = {
            titleHtml: content,
            yesNoSelected,
        }
        showModal(options);
    }

    const yesNoSelected = async ({ target: { value } }) => {
        closeModal()
        if (value === "yes") {
            continueClick()
        }
    }

    const continueClick = () => {
        const user = {};
        user.phoneLookup = false;
      if (policyNumber?.length > 0) {
        user.memberId = policyNumber;
        user.details = {
          stateCode: stateCode.value
        };
      }
        user.lookupType = currentLookup;
        dispatchCommonBenefitsLookup(user);
    };

    return (
        <>
            <Grid
                utils={{
                    vrBottom: "md"
                }}>
                <Grid.Column>
                    <Type
                        id="we-need-some-info-to-find-your-benefits"
                        element="h1"
                        weight="semibold"
                        align="left"
                        size="6xl"
                        lineHeight="md"
                        utils={{
                            vrTop: "md"
                        }}>
                        {getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)}
                    </Type>
                </Grid.Column>
            </Grid>
                <>
                    <Grid utils={{
                        vrBottom: "md"
                    }}>
                        <Grid.Column>
                            <FormField
                                size="md"
                                id="policyNumber"
                                label={<Flex justifyContent="between" isBlock={true}>
                                    <Type size="l" weight="bold" emphasis="information">
                                        {getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER)}
                                    </Type></Flex>}
                                value={policyNumber}
                                onChange={(event) => setPolicyNumber(event.target.value.trim())}
                                utils={{
                                    vrTop: "xs",
                                    vrBottom: "xs"
                                }}
                                required={true}
                            />
                        </Grid.Column>
                    </Grid>
                    <Grid utils={{
                        vrBottom: "md"
                    }}>
                        <Grid.Column>
                            <Autocomplete
                                id="stateCode"
                                label={<Type size="l" weight="bold" emphasis="information">
                                    {getTranslatedText(TRANSLATION_CONSTANTS.STATE)}
                                </Type>}
                                errorText={getTranslatedText(TRANSLATION_CONSTANTS.SELECT_A_STATE)}
                                placeholder={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_STATE)}
                                value={stateCode.text}
                                suggestions={StateList}
                                clearBtn={true}
                                onChange={handleChange}
                                required={true}
                            />
                        </Grid.Column>
                    </Grid>
                </>
            <Grid utils={{
                vrBottom: "md"
            }}>
                <Grid.Column>
                    <Button
                        hasUpgrade
                        id="policy-state-continue"
                        disabled={validateCompleteForm()}
                        onClick={onSubmit}
                        utils={{ fullWidth: true }}>
                        {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                    </Button>
                </Grid.Column>
            </Grid>
        </>
    )
};

export default PolicyNumberStateCodeLookup;
