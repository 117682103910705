import React from 'react'

const LocationPin = ({pinColor, locationColor}) => {

  return (
    <svg id="location-pin" viewBox="2 -12 16 90">
      <g fill="none" stroke="none" strokeWidth="1">
        <g id="LocationSVG" transform="translate(1.000000, 2.000000)">
          <g id="Pin" fill={pinColor} transform="translate(0.000000, 40.000000)">
            <path d="M8.9455076,12.2553191 C7.11683891,12.2553191 5.63482067,10.7733009 5.63482067,8.9455076 C5.63482067,7.11683891 7.11683891,5.63482067 8.9455076,5.63482067 C10.7733009,5.63482067 12.2553191,7.11683891 12.2553191,8.9455076 C12.2553191,10.7733009 10.7733009,12.2553191 8.9455076,12.2553191 L8.9455076,12.2553191 Z M11.7764863,0.466577508 C11.0516717,0.226723404 10.4354043,0.134808511 10.4354043,0.134808511 C9.94956839,0.0525227964 9.45410334,0 8.9455076,0 C8.43603647,0 7.94057143,0.0525227964 7.45473556,0.134808511 C7.45473556,0.134808511 6.83846809,0.226723404 6.11452888,0.466577508 C2.56661398,1.65534347 0,5.00279635 0,8.94463222 C0,12.3393556 1.94947112,14.6100912 4.01274164,17.0138845 C5.73636474,19.0211307 7.51951368,21.0975319 8.29772644,24.0134225 C8.37651064,24.3066748 8.64175076,24.5106383 8.9455076,24.5106383 C9.24926444,24.5106383 9.51450456,24.3066748 9.59241337,24.0134225 C10.3715015,21.0975319 12.1537751,19.0211307 13.8773982,17.0138845 C15.9415441,14.6100912 17.8910152,12.3393556 17.8910152,8.94463222 C17.8910152,5.00279635 15.3235258,1.65534347 11.7764863,0.466577508 L11.7764863,0.466577508 Z"/>
          </g>
          <path id="Line" d="M9.5,18 L9.5,36" stroke="#B9C6D3" strokeDasharray="2,3" strokeLinecap="round"/>
          <g id="Location" transform="translate(1.000000, 0.000000)">
            <circle id="Oval-8" cx="8" cy="8" fill={locationColor} r="4.63157895"/>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LocationPin
