import React from "react";
import { Button, Flex, Grid, Icon, Type } from "mesh-component-library";
import { CheckmarkIcon, CreditCardIcon } from "mesh-icon-library";

import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";

const TowTransition = ({
  type,
  towCost,
  towToEnabled,
  towUnsupported,
  membership,
  onClickTowTransition,
  handleContinueWithSoftService,
  onClickCallCustomerService,
  displayCode,
  milesBased,
  dollarBased,
  towCoveredMiles,
  towCoverageAmount,
}) => {
  const getTranslatedText = useLocale();

  let service;
  switch (type) {
    case 'Lockout':
      service = {
        type:
          {
            title: TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS,
            content: towToEnabled ? TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN_TOW_ENABLED : TRANSLATION_CONSTANTS.SINCE_KEYS_LOST_BROKEN,
          }
      }
      break;

    case 'Lockout-Trunk':
      service = {
        type:
          {
            title: TRANSLATION_CONSTANTS.NO_WORRIES_HAPPENS,
            content: TRANSLATION_CONSTANTS.CHECKED_TRUNK_RELEASE_HATCH,
          }
      }
      break;

    case 'Tire Change':
      service = {
        type:
          {
            title: TRANSLATION_CONSTANTS.WITHOUT_A_SPARE_TOW_VEHICLE,
            content: towToEnabled ? TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE_TOW_ENABLED : TRANSLATION_CONSTANTS.NEED_A_NEW_TIRE,
          }
      }
      break;

    case 'Jump Start':
      service = {
        type:
          {
            title: TRANSLATION_CONSTANTS.BATTER_MOST_LIKELY_PROBLEM,
            content: towToEnabled ? TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE_TOW_ENABLED : TRANSLATION_CONSTANTS.CONTINUE_TO_TOW_VEHICLE,
          }
      }
      break;

    default:
      service = {
        type:
          {
            title: TRANSLATION_CONSTANTS.DEFAULT,
            content: TRANSLATION_CONSTANTS.DEFAULT,
          }
      }
  }

  const hasCoverageAmount = !!towCoverageAmount && !!dollarBased;
  const hasCoverageMiles = !!towCoveredMiles && !!milesBased;

  return (
    <Flex
      id="continue-to-tow-content"
      direction="column"
      justifyContent="between"
      style={{
        height: `calc(100dvh - 200px)`,
      }}
      utils={{
        hrLeft: "md",
        hrRight: "md",
        vrTop: "md",
        vrBottom: "md",
      }}
    >
      <Grid
        utils={{
          noMargin: true,
          fullWidth: true,
        }}
      >
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          <Type element="div" align="left" size="7xl" weight="semibold">
            {getTranslatedText(service.type.title)}
          </Type>
          <Type
            element="div"
            align="left"
            utils={{
              vrTop: "md",
            }}
          >
            {getTranslatedText(service.type.content, displayCode)}
          </Type>
        </Grid.Column>

        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
          utils={{
            vrTop: "sm",
          }}
        >
          {membership && (
            <>
              <Icon icon={CheckmarkIcon} size={1.5} accent="information" />
              <Type element="div" align="center">
                {hasCoverageAmount || hasCoverageMiles ? getTranslatedText(TRANSLATION_CONSTANTS.COVERS_UPTO) : getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_COVERED)}
              </Type>
              <Type
                element="div"
                align="center"
                size="3xl"
                weight="semibold"
                variant="brand"
              >
                {hasCoverageAmount && `$${towCoverageAmount}`}
                {hasCoverageMiles && `${towCoveredMiles} ${getTranslatedText(TRANSLATION_CONSTANTS.MILES)}`}
              </Type>
            </>
          )}
          {!membership && (
            <>
              <Icon icon={CreditCardIcon} size={1.5} color="coolGray-700" />
              <Type element="div" align="center">
                {getTranslatedText(TRANSLATION_CONSTANTS.STARTS_AT)}
              </Type>
              <Type
                element="div"
                align="center"
                size="3xl"
                weight="semibold"
                variant="brand"
              >
                {`$${towCost}`}
              </Type>
            </>
          )}
        </Grid.Column>
      </Grid>
      <Grid
        utils={{
          noMargin: true,
          fullWidth: true,
        }}
      >
        <Grid.Column
          col={{ sm: 12, md: 4, lg: 4, xl: 4 }}
          offset={{ md: 4, lg: 4, xl: 4 }}
        >
          {!towUnsupported ? (
            <Button
              size="lg"
              hasUpgrade
              id="continue-to-tow"
              onClick={() => onClickTowTransition()}
              utils={{
                fullWidth: true,
              }}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE_TO_TOW, displayCode)}
            </Button>
          ) : (
            <Button
              size="lg"
              hasUpgrade
              id="button-to-call"
              onClick={() => onClickCallCustomerService()}
              utils={{
                fullWidth: true,
              }}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.CALL_FOR_ASSISTANCE)}
            </Button>
          )}
          {type === "Lockout" && (
            <Button
              size="lg"
              hasUpgrade
              variant="muted"
              id="just-unlock-vehicle"
              onClick={() => handleContinueWithSoftService(false, "Lockout")}
              utils={{
                fullWidth: true,
                vrTop: "lg",
              }}
            >
              {getTranslatedText(
                TRANSLATION_CONSTANTS.NO_THANKS_UNLOCK_MY_VEHICLE,
                displayCode
              )}
            </Button>
          )}
        </Grid.Column>
      </Grid>
    </Flex>
  );
};
export default TowTransition;
