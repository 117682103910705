import {
  CLEAR_DESTINATION,
  REFRESH_REDUCERS,
  UPDATE_DESTINATION,
  INVALID_DESTINATION_ADDRESS
} from './../action';

const initialState = {
  error: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_DESTINATION:
      return {};
    case UPDATE_DESTINATION:
      return {...action.payload};
    case INVALID_DESTINATION_ADDRESS:
      return {error: true}
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}
