import {REFRESH_REDUCERS, UPDATE_SERVICEINFO} from './../action';

const initialState = {value: ''};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_SERVICEINFO:
      return Object.assign({}, state, action.payload);
    case REFRESH_REDUCERS:
      return {...initialState};
    default:
      return state;
  }
}
