import React from "react";
import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations";
import TriageStep from "../../../components/triage-step";
import { useLocale } from "../../../contexts/LocaleContext";

const JumpStartTriageSteps = () => {
  const getTranslatedText = useLocale();
  return (
    <>
      <TriageStep
        step={1}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_ONE_CONTENT
        )}
      />
      <TriageStep
        step={2}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_TWO_CONTENT
        )}
      />
      <TriageStep
        step={3}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_THREE_CONTENT
        )}
      />
      <TriageStep
        step={4}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FOUR_CONTENT
        )}
      />
      <TriageStep
        step={5}
        title={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_TITLE
        )}
        content={getTranslatedText(
          TRANSLATION_CONSTANTS.TRIAGE_STEPS_JUMP_START_STEP_FIVE_CONTENT
        )}
      />
    </>
  );
};

export default JumpStartTriageSteps;
