const isGMCanadaPartnerCode = (partnercode) => {
   const GM = ["GMI","CCD"];
   if(GM.indexOf(partnercode) !== -1){
    return true;
   }
   else {
    return false;
   }
}

export default isGMCanadaPartnerCode;
