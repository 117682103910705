import { takeEvery, put, call, select, all } from 'redux-saga/effects';

import {
  PEP_SESSION,
  PEP_SESSION_SUCCESS,
  PEP_SESSION_FAILED,
  UPDATE_SESSION_ID,
  UPDATE_PPU_FLAG,
  UPDATE_FIRST_NAME,
  UPDATE_LAST_NAME
} from '../action';
import { createMemberSessionOpenApi, sendJacadaRequest } from '../api';
import {datadogRum} from "@datadog/browser-rum";
import {ErrorSource} from "@datadog/browser-core";
import { getPartnerDetails, isPayPerUseSelector } from '../selector';

export function* pepSession(action) {
  const partnerDetails = yield select(getPartnerDetails);
  const isPayPerUse = yield select(isPayPerUseSelector);
  let { partnerCode, experience, route } = partnerDetails;
  const type = experience.lookupMethods.sessionType.primary;

  const { appId } = action.payload;
  const memberId = null;
  const details = action.payload.details;
  const omniAssistFlow = route === "OmniAssist";
  const phone = localStorage.getItem('phone');
  const phoneLookup = action.payload.phoneLookup;
  let sessionId = '';

  try {
    const options = {
      memberId,
      partnerCode,
      type,
      createSessionRequest: null,
      phoneLookup,
      appId,
      details
    };

    const sessionResponse = yield call(createMemberSessionOpenApi, route, options);
    if (sessionResponse.status === 200) {
      const sessionId = sessionResponse.data.id;
      localStorage.setItem('benefitSession', sessionId);
      yield all([
        put({type: UPDATE_SESSION_ID, payload: sessionId}),
        put({type: UPDATE_PPU_FLAG, payload: "Y"}),
        put({type: PEP_SESSION_SUCCESS, sessionResponse}),
        put({type: UPDATE_FIRST_NAME, payload: details.firstName}),
        put({type: UPDATE_LAST_NAME, payload: details.lastName})
      ])
    } else {
      localStorage.removeItem('benefitSession');
      datadogRum.addError(new Error(`Session API returned non-2xx status for AppID: ${appId} / Partner Code: ${partnerCode}.`), undefined, ErrorSource.NETWORK)
      yield put({type: PEP_SESSION_FAILED});
    }
  } catch (e) {
    datadogRum.addError(e, undefined, ErrorSource.CUSTOM)
    yield put({type: PEP_SESSION_FAILED, 'payload': e});
  }

  if (omniAssistFlow) {
    try {
      let jacadaReqData = {};
      let conversationId = localStorage.getItem('conversationId');
      let eventsData = [];
      eventsData.push(
        {
          'name': 'mbr_id',
          'value': ''
        },
        {
          'name': 'partnr_cd',
          'value': partnerCode
        },
        {
          'name': 'sessionId',
          'value': sessionId
        },
        {
          'name': 'disblmnt_prmotn_cd',
          'value': '010164899'
        },
        {
          'name': 'original_ani_nbr',
          'value': phone ?? ""
        },
        {
          'name': 'job_id',
          'value': localStorage.getItem('jobId')
        }
      );

      jacadaReqData = {
        'externalSystemId': conversationId,
        'events': eventsData
      }
      const jacadaResponse = yield call(sendJacadaRequest, jacadaReqData);
      if (jacadaResponse.status === 200) {
        datadogRum.addAction(`${partnerCode}-jacada-authentication`);
      } else {
        const error = new Error(`Jacada returned ${jacadaResponse.status} status for Conversation ID: ${conversationId}, Request: ${JSON.stringify(jacadaResponse)}.`)
        datadogRum.addError(error, undefined, ErrorSource.CUSTOM)
      }
    } catch (e) {
      console.error("Jacada event failure")
      datadogRum.addError(e, undefined, ErrorSource.CUSTOM)
    }
  }
}

export function* watchPepSessionSaga(){
  yield takeEvery(PEP_SESSION, pepSession);
}
