import React from "react";

const LineSeparator = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="327"
      height="1"
      viewBox="0 0 327 1"
      fill="none"
    >
      <line y1="0.75" x2="327" y2="0.75" stroke="#CED7E0" strokeWidth="0.5" />
    </svg>
  );
};

export default LineSeparator;
