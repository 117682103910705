import React, { Component } from 'react';

class RightArrow extends Component {

    render() {
        let fill = '#0033A0';

        if (this.props.fillColor) {
            fill = this.props.fillColor;
        }

        return(

            <svg id="rightArrow" height="16" width="10" viewBox="0 0 10 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.552 8L.224 14.328a.77.77 0 0 0 0 1.086l.363.362a.769.769 0 0 0 1.085 0l6.872-6.87.361-.363a.769.769 0 0 0 0-1.086L1.672.224a.769.769 0 0 0-1.085 0L.224.586a.77.77 0 0 0 0 1.086L6.552 8z" fill={fill} fillRule="evenodd"/>
            </svg>

    )}
}

export default RightArrow; 
