export const PEP_SESSION = "PEP_SESSION";
export const PEP_SESSION_SUCCESS = "PEP_SESSION_SUCCESS";
export const PEP_SESSION_FAILED = "PEP_SESSION_FAILED";
export const REFRESH_REDUCERS = "REFRESH_REDUCERS";

export function pepSession(data){
    return { type: PEP_SESSION,payload:data };
}

export function pepSessionSuccess(data){
    return { type: PEP_SESSION_SUCCESS, payload: data};
}

export function pepSessionFailed(){
    return { type: PEP_SESSION_FAILED};
}

export function clearPepSession(){
    return { type: REFRESH_REDUCERS};
}
