import React, {useState} from 'react';
import ModalDialog from "../../components/modal-dialog";
import ButtonGroup from "../../components/button-group";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";

export const ConfirmationModal = (props) => {
  const getTranslatedText = useLocale();
  let {isActive, yesNoSelected, header, titleHtml} = props
  const [isButtonChecked, setIsButtonChecked] = useState(false);

  const onClose = () => {
    setIsButtonChecked(false)
  }

  return (
    <ModalDialog
      id="license-plate-confirmation-modal"
      className="license-plate-confirmation-modal"
      title={<div className={'u-align-center'}>{header}</div>}
      hideTrigger
      showCloseBtn={false}
      isActive={isActive}
      onClose={onClose}
    >
      <div className="l-grid">
        <div data-dd-privacy="mask" className="l-grid__col l-grid__col--11 l-grid__col--offset-1 plate-text-container">
          {titleHtml}
        </div>
        <div className="l-grid__col l-grid__col--6 l-grid__col--offset-1 button-group" data-dd-privacy="allow">
          <ButtonGroup
            type="radio"
            name="verify-plate-info"
            id="verify-plate-info"
            isFluid={true}
            readOnly={true}
            onChange={yesNoSelected}
            options={[
              {id: 'yes', text: getTranslatedText(TRANSLATION_CONSTANTS.YES), value: 'yes', checked: isButtonChecked},
              {id: 'no', text: getTranslatedText(TRANSLATION_CONSTANTS.NO), value: 'no', checked: isButtonChecked}
            ]}
          />
        </div>
      </div>
    </ModalDialog>
  )
}
