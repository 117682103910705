import React, { Component } from 'react';

class ErrorIcon extends Component {

    render() {
        return(

            <svg id="errorIcon" width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                <g id='Express' fill='none' fillRule='evenodd'>
                    <g id='Tracking-Rescue---GOA' transform='translate(-30 -118)' fill='#CF102D'>
                        <g id='Info-Panel-Mobile' transform='translate(16 90)'>
                            <path d='M26,28 C32.6272582,28 38,33.3727418 38,40 C38,46.6272582 32.6272582,52 26,52 C19.3727418,52 14,46.6272582 14,40 C14,33.3727418 19.3727418,28 26,28 Z M25.8973599,45.5636425 C24.9776114,45.5636425 24.2098599,46.3080952 24.2098599,47.2511425 C24.2098599,48.1930804 24.9776114,48.9375331 25.8973599,48.9375331 C26.8171085,48.9375331 27.5848599,48.1930804 27.5848599,47.2511425 C27.5848599,46.3080952 26.8171085,45.5636425 25.8973599,45.5636425 Z M25.8125,43.3588316 C26.4018188,43.3588316 26.9440777,42.4058679 27.0200153,41.2400648 L27.4948929,33.8676973 C27.569761,32.7029638 26.8125237,31.75 25.8125,31.75 C24.8124763,31.75 24.055239,32.7029638 24.1301071,33.8676973 L24.6049847,41.2400648 C24.6809223,42.4058679 25.2231812,43.3588316 25.8125,43.3588316 Z'
                            id='06006alertS' />
                        </g>
                    </g>
                </g>
            </svg>

    )}
}

export default ErrorIcon; 
