import React, { useLayoutEffect } from "react";
import { FUEL_DELIVERY, JUMP_START, LOCKOUT, TIRE_CHANGE, TOW } from "../../../app-consts/appConstants";
import { TRANSLATION_CONSTANTS } from "../../../app-consts/translations";
import ModalDialog from "../../../components/modal-dialog";
import { useLocale } from "../../../contexts/LocaleContext";

const PricingInfoModal = ({
  showModal = false,
  handleOnClose = () => {},
  serviceDetails = [],
  ...restProps
}) => {
  const getTranslatedText = useLocale();

  useLayoutEffect(() => {
    if (showModal) window.scrollTo(0, 0);
  }, [showModal]);

  const getServiceCostByValue = (value = "") => {
    if(!showModal || !serviceDetails) {
      return "";
    }
    const serviceDetail = serviceDetails.find(serviceDetail => serviceDetail?.value === value);
    return serviceDetail?.cost ? `$${serviceDetail?.cost}` : "" ;
  }

  return (
    <div className="pricing-info-modal-container">
      <ModalDialog
        id="pricingInfoModal"
        title={"Pricing Information"}
        hideTrigger={true}
        showCloseBtn={true}
        isActive={showModal}
        onClose={() => handleOnClose()}
        footer={
          <button
            className="btn btn-info"
            onClick={() => handleOnClose()}
            disabled={false}
            aria-label="Ok Button"
          >
            {getTranslatedText(TRANSLATION_CONSTANTS.OK)}
          </button>
        }
      >
        <div className="d-flex flex-column justify-content-start p-2">
          <div className="text-start mb-3">
            <strong>{getTranslatedText(TRANSLATION_CONSTANTS.AVERAGE_ESTIMATED_CHARGES)}</strong>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>Jump Start: {getServiceCostByValue(JUMP_START)}</div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>Tire Change: {getServiceCostByValue(TIRE_CHANGE)}</div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>Lock Out: {getServiceCostByValue(LOCKOUT)}</div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>Fuel Delivery: {getServiceCostByValue(FUEL_DELIVERY)}</div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>{`${getTranslatedText(TRANSLATION_CONSTANTS.WINCH)}: $338`}</div>
          </div>
          <div className="d-flex flex-row mb-1">
            <div>{`${getTranslatedText(TRANSLATION_CONSTANTS.TOW_SERVICES)}: ${getServiceCostByValue(TOW)} (2 way tow cost range at $638)`}</div>
          </div>
          <div className="d-flex flex-row mb-3">
            <div className="text-start">
              {`${getTranslatedText(TRANSLATION_CONSTANTS.LOST_KEY_TOWING)}: $669 ($350 ${getTranslatedText(TRANSLATION_CONSTANTS.KEY_COST_AVG_TOW_COST)} ${getServiceCostByValue(TOW)})`}
            </div>
          </div>
          <div className="text_left mb-3">
            {getTranslatedText(TRANSLATION_CONSTANTS.ADDITIONAL_COSTS)}
          </div>
          <div className="text_left mb-3">
            {getTranslatedText(TRANSLATION_CONSTANTS.TOWING_CHARGES_NOT_APPLY)}
          </div>
          <div className="text_left mb-3">
            Any questions regarding Roadside service fees should be directed to
            Customer Service.
          </div>
          <div className="text_left mb-1">
          {getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_CHARGES)}
          </div>
        </div>
      </ModalDialog>
    </div>
  );
};

export default PricingInfoModal;
