import React from 'react';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import MainButton from '../../components/mainbutton';
import { useLocale } from '../../contexts/LocaleContext';
const HelpContinue = (
  {
    header,
    content,
    handleClick,
    btntext,
    call,
    partnerPhone,
    partnerCode
  }
) => {
  const getTranslatedText = useLocale();

  const handlePhoneCall = () => {
    const phone = partnerPhone || localStorage.getItem('callbackNumber');
    window.location.href = 'tel:' + phone;
  }

  return (
      <div className="help-container l-container-home">
        <div className="help-content">
          <h1 className="l-container-home__hd u-vr-3 u-vr-3-top u-hr-2 u-hr-2-left"> {header}</h1>
          <p> {content} </p>
          {call === 'true' ?
              <a tabIndex="0" id="we-can-help-call" className="btn btn--secondary" onClick={handlePhoneCall} onKeyDown={handlePhoneCall}>
                {getTranslatedText(TRANSLATION_CONSTANTS.CALL)}
              </a>
            :
            <MainButton className="btn btn--secondary" handleClick={ handleClick } text={btntext} >  </MainButton>
          }
        </div>
      </div>
  );
}

export default HelpContinue;
