import { useReducer } from "react";
import {
  diffInhours,
  formatDateAsString,
  roundToNearestMin,
  timeStaggeredRangeInHours,
} from "../../utils/timeUtil";

export const SERVICE_ACTION_TYPES = {
  SET_SELECTED_DATE: "SET_SELECTED_DATE",
  SET_SELECTED_TIME: "SET_SELECTED_TIME",
};

const getTimeList = (date = new Date()) => {
  const startTimeToNearest30 = roundToNearestMin(date, 30);
  const endDateTime = new Date(date);
  endDateTime.setHours(21, 0, 0, 0);
  const timeOptions = timeStaggeredRangeInHours(
    startTimeToNearest30,
    endDateTime
  );
  return {
    selectedTime: "",
    timeOptions,
  };
};

const serviceTimeInitialState = (currentDateTime = new Date()) => {
  const maxDate = new Date(currentDateTime);
  maxDate.setDate(currentDateTime.getDate() + 7);
  if (currentDateTime.getHours() < 7) {
    const stubDate = new Date(currentDateTime);
    stubDate.setHours(7, 0, 0, 0);
    const totalHoursBeforeSeven = diffInhours(currentDateTime, stubDate);
    if (totalHoursBeforeSeven > 4) {
      currentDateTime.setHours(7, 0, 0, 0);
    } else {
      currentDateTime.setHours(7 + (4 - totalHoursBeforeSeven), 0, 0, 0);
    }
  } else if (currentDateTime.getHours() >= 16) {
    currentDateTime.setDate(currentDateTime.getDate() + 1);
    currentDateTime.setHours(7, 0, 0, 0);
  } else if (
    currentDateTime.getHours() >= 7 &&
    currentDateTime.getHours() < 16
  ) {
    currentDateTime.setHours(currentDateTime.getHours() + 4);
  }
  const minDate = new Date(currentDateTime);
  const selectedDate = formatDateAsString(currentDateTime);
  const initialState = {
    selectedDate,
    minDate,
    maxDate,
    ...getTimeList(currentDateTime),
  };
  return initialState;
};

export const useServiceTimeReducer = ({ currentDateTime}) => {
  const serviceTimeReducer = (state, action) => {
    switch (action.type) {
      case SERVICE_ACTION_TYPES.SET_SELECTED_DATE:
        const { selectedDateObj = new Date() } = action;
        const stubDate = new Date();
        stubDate.setHours(0, 0, 0, 0);
        if (stubDate.getTime() === selectedDateObj.getTime()) {
          const newDateTime = new Date();
          return serviceTimeInitialState(newDateTime);
        }
        selectedDateObj.setHours(7, 0, 0, 0);
        return {
          ...state,
          selectedDate: formatDateAsString(selectedDateObj),
          ...getTimeList(selectedDateObj),
        };

      case SERVICE_ACTION_TYPES.SET_SELECTED_TIME:
        return {
          ...state,
          selectedTime: action.selectedTime,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(
    serviceTimeReducer,
    serviceTimeInitialState(currentDateTime)
  );
  return [state, dispatch];
};
