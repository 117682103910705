import React, { useState } from 'react'
import CONSTANTS, { ZIP_LENGTH_REGEX } from "../../app-consts/appConstants";
import { regexUtil } from "../../utils/regexUtil";
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import FormValidators from "../../utilities/form-validators";
import {
  Grid,
  Type,
  FormField,
  Flex,
  Tooltip,
  Icon,
  Button,
  Autocomplete,
  Alert
} from 'mesh-component-library';
import { QuestionIcon } from "mesh-icon-library";
import { StateList } from "../../utilities/StateList";
import titleCase from "../../utils/titleCase";
import { useSelector } from 'react-redux';
import { hasSpecialChars } from "../../app-consts/appConstants";

const LastNameStateZipLookup = ({ dispatchCommonBenefitsLookup, showModal, closeModal, currentLookup }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [zip, setZip] = useState('');
  const [zipHasError, setZipHasError] = useState(false);
  const [stateCode, setStateCode] = useState('');
  const partnerDetails = useSelector(state => state.partnerDetails.partnerDetails);
  const [policyNumber, setPolicyNumber] = useState('');
  const [vin, setVin] = useState('');
  const [vinHasError, setVinHasError] = useState(false);
  const primary = partnerDetails.experience?.lookupMethods?.components?.primary;

  const getTranslatedText = useLocale();

  const handleZipBackspace = (e) => {
    if (e.keyCode !== CONSTANTS.DELETE && e.keyCode !== CONSTANTS.BACKSPACE && e.target.value !== undefined && e.target.value.toString().length >= e.target.maxLength) {
      e.preventDefault();
    } else {
      setZipHasError(true);
    }
  };

  const handleZipChange = (event) => {
    const { value } = event.target;
    let isValid = regexUtil(ZIP_LENGTH_REGEX, value);
    setZip(value);
    setZipHasError(!isValid);
  };


  const handleVinChange = (event) => {
    let val = event.target.value;
    let isValid = isVinValid(val);
    let formattedValue = val.toUpperCase();
    setVin(formattedValue);
    setVinHasError(!isValid)
  }

  const vinOnKeyPress = (event) => {
    const isAlphaNumericVal = FormValidators.allowOnlyAlphaNumericInput(event);
    setVinHasError(!isAlphaNumericVal);
  }

  const isVinValid = (vinInput) => {
    return !hasSpecialChars.test(vinInput) && vinInput.length === 17;
  }

  const handleChange = (event, selectedState) => {
    if (selectedState?.selectedItem) {
      setStateCode(selectedState?.selectedItem)
    } else {
      setStateCode('')
    }
  }

  const validateCompleteForm = () => {
    if (currentLookup === primary) {
      return !(lastName &&
        stateCode &&
        (!zipHasError && zip.length === 5));
    } else {
      let vinAvailable = vin?.length === 17;
      let policyNumberAvailable = policyNumber?.length > 0;
      if (vinAvailable && !policyNumberAvailable) {
        return vinHasError;
      } else if (!vinAvailable && policyNumberAvailable) {
        return !policyNumberAvailable;
      } else if (vinAvailable && !vinHasError && policyNumberAvailable) {
        return false;
      }
      return true;
    }
  };
  const onSubmit = () => {
    let content = '';
    if (currentLookup === primary) {
      content = <div>
        {firstName?.length > 0 ? <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}:</span> {firstName}</p> : ""}
        <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}:</span> {lastName}</p>
        <p><span>State:</span> {titleCase(stateCode.text)}</p>
        <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.ZIP)}:</span> {zip}</p>
      </div>
    } else {
      content = <div>
        {policyNumber?.length > 0 ? <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER)}/ {getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NUMBER)}:</span> {policyNumber}</p> : ""}
        {vin?.length > 0 && !(vinHasError) ? <p><span>{getTranslatedText(TRANSLATION_CONSTANTS.VIN)}:</span> {vin}</p> : ""}
      </div>
    }
    const options = {
      titleHtml: content,
      yesNoSelected,
    }

    showModal(options);
  }

  const yesNoSelected = async ({ target: { value } }) => {
    closeModal()
    if (value === "yes") {
      continueClick()
    }
  }

  const continueClick = () => {
    const user = {};
    user.phoneLookup = false;
    if (currentLookup === primary) {
      user.details = {
        firstName: firstName,
        lastName: lastName,
        stateCode: stateCode.value,
        zip: zip,
      };
    } else {
      if (policyNumber?.length > 0) {
        user.memberId = policyNumber;
      }
      if (vin?.length > 0 && !(vinHasError)) {
        user.vin = vin;
      }
    }
    user.lookupType = currentLookup !== primary ? 'MemberNumberVin' : 'LastNameStateZip';
    dispatchCommonBenefitsLookup(user);
  };

  return (
    <>
      <Grid>
        <Grid.Column>
          <Type
            id="we-need-some-info-to-find-your-benefits"
            element="h1"
            weight="semibold"
            align="left"
            size="6xl"
            lineHeight="md"
            utils={{
              vrTop: "md"
            }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.NEED_INFO_BENEFITS)}
          </Type>
        </Grid.Column>
      </Grid>
      {currentLookup !== primary &&
        <Alert
          title={
            <Type
              align="left"
              weight="normal"
              size="xl"
              lineHeight="sm">
                {getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NOT_UNIQUELY_FOUND_MESSAGE)}
            </Type>
          }
          alertMessages={[]}
          variant="critical"
          isActive={currentLookup !== primary}
          utils={{
            vrTop: "md",
          }}
        >
      </Alert>}
        { currentLookup === primary &&
          <>
            <Grid>
              <Grid.Column>
                <FormField
                  size="md"
                  id="firstName"
                  name="firstName"
                  data-testid="firstName-field"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value.trim())}
                  errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_FIRST_NAME)}
                  label={<Type size="l" weight="bold" emphasis="information" isBlock={true} >
                    {getTranslatedText(TRANSLATION_CONSTANTS.FIRST_NAME)}
                  </Type>}
                  labelProps={{
                    style: {
                      width: "100%"
                    }
                  }}
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column>
              <FormField
              size="md"
              id="lastName"
              name="lastName"
              data-testid="lastName-field"
              value={lastName}
              onChange={(event) => setLastName(event.target.value.trim())}
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.PLEASE_ENTER_LAST_NAME)}
              required
              label={<Flex justifyContent="between" isBlock={true}>
              <Type size="l" weight="bold" emphasis="information">
            {getTranslatedText(TRANSLATION_CONSTANTS.LAST_NAME)}
              </Type>
              <Tooltip
              id="mva-tooltip"
              size="lg"
              text={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_INFO_AS_ON_ACCOUNT)}
              >
              <Icon icon={QuestionIcon} color="#0033A0"
              size={1.2} />
              </Tooltip>
              </Flex>}
              labelProps={{
              style: {
              width: "100%"
            }
            }}
              />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column utils={{ vrBottom: "xs" }}>
              <Autocomplete
              id="stateCode"
              data-testid="stateCode-field"
              label={<Type size="l" weight="bold" emphasis="information">
            {getTranslatedText(TRANSLATION_CONSTANTS.STATE)}
              </Type>}
              errorText={getTranslatedText(TRANSLATION_CONSTANTS.SELECT_A_STATE)}
              placeholder={getTranslatedText(TRANSLATION_CONSTANTS.ENTER_STATE)}
              value={stateCode.text}
              suggestions={StateList}
              clearBtn={true}
              onChange={handleChange}
              required
              />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column utils={{ vrBottom: "sm" }}>
              <FormField
              size="md"
              type="number"
              inputMode="numeric"
              maxLength={5}
              id="zipCode"
              label={<Flex justifyContent="between" isBlock={true}>
              <Type size="l" weight="bold" emphasis="information">
            {getTranslatedText(TRANSLATION_CONSTANTS.ZIP_CODE)}
              </Type></Flex>}
              errorText={<Flex justifyContent="between" isBlock={true}>
            {getTranslatedText(TRANSLATION_CONSTANTS.ENTER_DIGIT_ZIP_CODE)}
              </Flex>}
              hasError={zipHasError}
              value={zip}
              onKeyDown={handleZipBackspace}
              onChange={handleZipChange}
              required
              />
              </Grid.Column>
            </Grid>
          </>
        }
        {currentLookup !== primary &&
          <>
            <Grid>
              <Grid.Column>
                <Type
                  id="provide-one-of-following"
                  element="h1"
                  weight="semibold"
                  align="left"
                  size="3xl"
                  utils={{
                    vrTop: "xs"
                  }}>
                    {getTranslatedText(TRANSLATION_CONSTANTS.PROVIDE_MESSAGE)}
                </Type>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column utils={{ vrBottom: "sm" }}>
                <FormField
                  size="md"
                  id="policyNumber"
                  label={<Flex justifyContent="between" isBlock={true}>
                    <Type size="l" weight="bold" emphasis="information">
                      {getTranslatedText(TRANSLATION_CONSTANTS.POLICY_NUMBER)}/ {getTranslatedText(TRANSLATION_CONSTANTS.MEMBER_NUMBER)}
                    </Type></Flex>}
                  value={policyNumber}
                  onChange={(event) => setPolicyNumber(event.target.value.trim())}
                  utils={{
                    vrTop: "xs"
                  }}
                  required
                />
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column utils={{ vrBottom: "sm" }}>
                <FormField
                  size="md"
                  minLength={17}
                  maxLength={17}
                  id="vin"
                  label={<Flex justifyContent="between" isBlock={true}>
                    <Type size="l" weight="bold" emphasis="information">
                      {getTranslatedText(TRANSLATION_CONSTANTS.VIN)}
                    </Type></Flex>}
                  errorText={<Flex justifyContent="between" isBlock={true}>
                    {getTranslatedText(TRANSLATION_CONSTANTS.ENTER_VALID_VIN)}
                  </Flex>}
                  hasError={vinHasError}
                  value={vin}
                  onKeyDown={vinOnKeyPress}
                  onChange={handleVinChange}
                  required
                />
              </Grid.Column>
            </Grid>
          </>
        }
        <Grid>
        <Grid.Column>
          <Button
            hasUpgrade
            id="last-state-zip-policy-continue"
            data-testid="last-state-zip-policy-continue"
            disabled={validateCompleteForm()}
            onClick={onSubmit}
            utils={{ fullWidth: true }}>
            {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
          </Button>
        </Grid.Column>
      </Grid>
    </>
  )
};

export default LastNameStateZipLookup;
