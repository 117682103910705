import {
  UPDATE_CURRENT_LOCATION,
  REFRESH_REDUCERS,
  CLEAR_CURRENT_LOCATION,
  CLEAR_LOCATION_ERROR
} from './../action'

const initialState = {
  addressLine1: "",
  city: "",
  zipCode: "",
  country: "",
  state: "",
  latitude: 0,
  longitude: 0,
  formatted_address: "",
  error: {
    errorMessage: ""
  },
  geometry: {},
  address_components: [],
  place_id: "",
  types: [],
  setFromLocationServices: true,
  deniedUsingLocation: false,
  locationPromptClicked: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_LOCATION:
      return {
        ...state,
        ...action.payload
      };
    case CLEAR_LOCATION_ERROR:
      return {
        ...state,
        error: {
          errorMessage: ""
        }
      };
    case CLEAR_CURRENT_LOCATION:
      let initialCurrentLocationState = {...initialState}
      let userDeniedLocationFlag = state.deniedUsingLocation
      return {
        ...initialCurrentLocationState,
        setFromLocationServices: false,
        deniedUsingLocation: userDeniedLocationFlag,
        locationPromptClicked: state.locationPromptClicked
      };
    case REFRESH_REDUCERS:
      return {...initialState, locationPromptClicked: state.locationPromptClicked};
    default:
      return state;
  }
}
