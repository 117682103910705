import React from 'react';
import PropTypes from 'prop-types';
import FormField from '../form-field';

/**
 * Creates a React MaskedFormField component
 * that uses a mandatory mask to mask the input's value
 *
 * @class MaskedFormField
 * @extends React.PureComponent
 * @constructor
 * @param {Object} props
 */
export default class MaskedFormField extends React.PureComponent {
  constructor(props) {
    super(props);

    /**
     * The formField element contained
     * within masked form field.
     *
     * @property maskedFormField.formField
     * @type {HTMLInputElement}
     * @default null
     */
    this.formField = null;

    const value = props.defaultValue || props.value || '';
    const maskedValue = MaskedFormField.mask(value, props.mask);

    /**
     * The maskedFormInput's internal state
     * @property maskedFormField.state
     * @type {Object}
     */
    this.state = {
      /**
       * The position of the cursor within the input.
       * This is used only if the input has focus.
       *
       * @property maskedFormField.cursorPosition
       * @type {Number}
       * @default 0
       */
      cursorPosition: 0,
      /**
       * The pattern to mask the input's value to
       *
       * @property maskedFormField.mask
       * @type {String}
       * @default ''
       */
      mask: props.mask,
      /**
       * The masked input's internal masked value
       *
       * @property maskedFormField.value
       * @type {String}
       * @default ''
       */
      value: maskedValue,
      /**
       * The previous masked value;
       * updated everytime to value changes.
       *
       * @property maskedFormField.value
       * @type {String}
       * @default ''
       */
      _prevMaskedValue: maskedValue,
    };
  }

  /**
   * Defines the accepted prop
   * types for the masked input
   *
   * @property MaskedFormField.propTypes
   * @type {Object}
   * @static
   */
  static propTypes = {
    mask: PropTypes.string.isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onMask: PropTypes.func,
    className: PropTypes.string,
  }

  /**
   * Defines the accepted props
   * for the masked input.
   *
   * @property MaskedFormField.defaultProps
   * @type {Object}
   * @static
   */
  static defaultProps = {
    /**
     * The provided value for the masked input;
     * this would be provided by a parent component.
     *
     * @property MaskedFormField.defaultProps.value
     * @type {String}
     * @default null
     */
    value: null,
    /**
     * The placeholder for the masked input.
     *
     * @property MaskedFormField.defaultProps.placeholder
     * @type {String}
     * @default ''
     */
    placeholder: '',
    /**
     * onChange callback.
     *
     * @method MaskedFormField.defaultProps.onChange
     * @param {Event} event - change event
     */
    onChange: function(event) {},
    /**
     * onMask callback.
     *
     * @method MaskedFormField.defaultProps.onMask
     * @param {Object} details
     * @param {String} details.value
     */
    onMask: function(details) {},
    /**
     * className modifier/addition.
     *
     * @property MaskedFormField.defaultProps.className
     * @type {String}
     * @default ''
     */
    className: '',
  }

  /**
   * Updates the states `value` property
   * is the `value` property has changed in `nextProps`.
   *
   * @method maskedFormField.componentWillReceiveProps
   * @param {Object} nextProps
   */
  UNSAFE_componentWillReceiveProps(nextProps) {
    const value = nextProps.value === null ? this.state.value : nextProps.value;
    const maskedValue = MaskedFormField.mask(value, this.state.mask);

    this.setState({
      value: maskedValue,
      _prevMaskedValue: maskedValue,
    });
  }

  /**
   * Handle the input's input event;
   * change the cursorPosition and mask the value;
   *
   * @method maskedFormField._onInput
   * @protected
   * @param {Object} event
   */
  _onInput = event => {
    const target = event.target;
    const value = target.value;
    const cursorPosition = target.selectionStart;
    const maskedValue = MaskedFormField.mask(value, this.state.mask);
    const newCursorPosition = MaskedFormField.getNewCursorPosition(
      cursorPosition, value, maskedValue, this.state._prevMaskedValue
    );

    this.setState({
      value: maskedValue,
      _prevMaskedValue: maskedValue,
      cursorPosition: newCursorPosition
    });

    this.props.onChange(event);
    this.props.onMask({
      value: maskedValue,
      pureValue: MaskedFormField.getPureValue(maskedValue),
    });
  }

  /**
   * Render the masked input
   *
   * @method maskedFormField.render
   * @return {HTMLElement}
   * @param {Object}
   */
  render() {
    const {
      className,
      onMask,
      helpText,
      placeholder,
      ...other
    } = this.props;

    const {
      cursorPosition,
      value,
    } = this.state;

    const _onInput = this._onInput;

    return (
      <FormField
        className={ className }
        { ...other }
        helpText={ helpText || '' }
        placeholder={ placeholder }
        ref={ (formField) => {
          if (!formField) {
            return;
          }
          this.formField = formField.formField;
          // if the formField contains focus, set its cursor position
          if (this.formField !== document.activeElement) {
            return;
          }
          this.formField.setSelectionRange(cursorPosition, cursorPosition);
        } }
        value={ value }
        onInput={ _onInput }
        isMaskedInput={ true } />
    )
  }

  /**
   * Accepts a value and a mask and return
   * a value masked in the provided form (mask)
   *
   * @method MaskedFormField.mask
   * @param {String} value
   * @param {String} mask
   * @return {String} maskedValue
   * @static
   */
  static mask(value, mask) {
    if (!value || !mask) {
        return '';
    }

    const validChars = [];
    const maskLength = mask.length;
    const valueLength = value.length;
    let mi = 0;
    let vi = 0;

    while (mi < maskLength && vi < valueLength) {
        const maskChar = mask.charAt(mi);
        const valChar = value.charAt(vi);
        const pattern = MaskedFormField.PATTERNS[maskChar];

        if (pattern) {
            if (valChar.match(pattern)) {
                validChars.push(valChar);
                mi++;
            }
            vi++;
        } else {
            validChars.push(maskChar);
            if (maskChar === valChar) {
                vi++;
            }
            mi++;
        }
    }

    return validChars.join('');
  }

  /**
   * Returns the cursor offset needed
   * to account for the mask separators.
   *
   * @method MaskedFormField.getSeparatorsOffset
   * @param {Number} cursorPosition
   * @param {String} value
   * @returns {Number} separatorsOffset
   * @static
   */
  static getSeparatorsOffset(cursorPosition, value) {
    let currentCursorPosition = cursorPosition;
    let char = value.charAt(currentCursorPosition);
    let separatorsOffset = 0;

    while (MaskedFormField.isCharSeparator(char)) {
      currentCursorPosition++;
      char = value.charAt(currentCursorPosition);
      separatorsOffset++;
    }

    return separatorsOffset;
  }

  /**
   * Returns the cursor offset needed
   * to account the the mask separators.
   *
   * @method MaskedFormField.getNewCursorPosition
   * @param {Number} cursorPosition current cursor position
   * @param {String} value
   * @param {String} maskedValue
   * @param {String} prevMaskedValue
   * @returns {Number} cursorPosition new cursor position
   * @static
   */
  static getNewCursorPosition(cursorPosition, value, maskedValue, prevMaskedValue) {
    // if we're working with the same value or the input only have 1 digit, return early
    if (
      maskedValue === prevMaskedValue ||
      (maskedValue.length < prevMaskedValue.length && value.length !== 1)
    ) {
      return cursorPosition;
    }

    const separatorsOffset = MaskedFormField.getSeparatorsOffset(
      cursorPosition - 1, maskedValue
    );

    return cursorPosition + separatorsOffset;
  }

  /**
   * Returns a boolean that determines
   * whether or not that provided character
   * is a separator.
   *
   * @method MaskedFormField.isCharSeparator
   * @param {String} char
   * @returns {Boolean}
   * @static
   */
  static isCharSeparator(char) {
    if (!char) {
      return false;
    }

    return Object.keys(MaskedFormField.PATTERNS).filter(function(key) {
      const pattern = MaskedFormField.PATTERNS[key];

      return char.match(pattern);
    }).length === 0;
  }

  /**
   * Returns a pure value with no separators
   *
   * @method MaskedFormField.getPureValue
   * @param {String} value
   * @returns {String}
   * @static
   */
  static getPureValue(value) {
    /* Create array of characters and trim whitespace */
    let charArr = value.split('').filter(item => item.trim() !== '');
    let pureValue;

    for (let i = 0; i < value.length; i++) {
      let isSeparator = MaskedFormField.isCharSeparator(charArr[i]);

      if(isSeparator) {
        charArr.splice(i, 1);
      }
    }

    pureValue = charArr.join('');

    return pureValue;
  }

  /**
   * The patterns used by the masked input.
   *
   * @property MaskedFormField.PATTERNS
   * @type {Object}
   * @static
   */
  static PATTERNS = {
    'A': /[A-Z]/,
    'a': /[a-z]/,
    'x': /[A-Za-z]/,
    '0': /[0-9]/,
    '*': /[A-Za-z0-9]/,
  }
}
