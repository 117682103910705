import React, {Component } from 'react'; 

class DetailedPin extends Component {

 render() {
    return(
    
<svg width='20' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g id='Symbols' fill='none' fillRule='evenodd'>
        <path d='M12,12 C10.1601918,12 8.66914571,10.5488571 8.66914571,8.75914286 C8.66914571,6.96857143 10.1601918,5.51742857 12,5.51742857 C13.8389275,5.51742857 15.3299736,6.96857143 15.3299736,8.75914286 C15.3299736,10.5488571 13.8389275,12 12,12 L12,12 Z M14.8482239,0.456857143 C14.118994,0.222 13.4989725,0.132 13.4989725,0.132 C13.0101771,0.0514285714 12.5116939,0 12,0 C11.4874254,0 10.9889422,0.0514285714 10.5001468,0.132 C10.5001468,0.132 9.88012526,0.222 9.1517761,0.456857143 C5.58224875,1.62085714 3,4.89857143 3,8.75828571 C3,12.0822857 4.96134651,14.3057143 7.03718563,16.6594286 C8.77130835,18.6248571 10.5653195,20.658 11.3482728,23.5131429 C11.4275369,23.8002857 11.6943928,24 12,24 C12.3056072,24 12.5724631,23.8002857 12.6508465,23.5131429 C13.4346805,20.658 15.2278109,18.6248571 16.9619337,16.6594286 C19.0386535,14.3057143 21,12.0822857 21,8.75828571 C21,4.89857143 18.4168705,1.62085714 14.8482239,0.456857143 L14.8482239,0.456857143 Z'
        id='06032mappointF' fill='#0033A0' />
    </g>
</svg>
)
}
}

export default DetailedPin;

