import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '../form/FormControl';
import Hint from '../hint';
import CONSTANTS from '../../app-consts/appConstants';
import Label from './../label';
import classnames from 'classnames';

const DISABLED_CLASS = CONSTANTS.HTML_CLASS.DISABLED;
const ERROR_CLASS = CONSTANTS.HTML_CLASS.ERROR;
const WARNING_CLASS = CONSTANTS.HTML_CLASS.WARNING;
const ACTIVE_CLASS = CONSTANTS.HTML_CLASS.ACTIVE;
const FOCUS_CLASS = CONSTANTS.HTML_CLASS.FOCUS;

export default class ButtonGroup extends FormControl {

  state = {
    /**
     * Does the ButtonGroup contain an error?
     *
     * @property ButtonGroup.state.hasError
     * @type {Boolean}
     * @default false
     */
    hasError: this.props.hasError === null ? false : this.props.hasError,
    /**
     * Does the ButtonGroup have a warning?
     *
     * @property ButtonGroup.state.hasWarning
     * @type {Boolean}
     * @default false
     */
    hasWarning: this.props.hasWarning === null ? false : this.props.hasWarning,
    hasFocus: false,
  };

  static propTypes = Object.assign({
    className: PropTypes.string,
    hasError: PropTypes.bool,
    hasWarning: PropTypes.bool,
    isFluid: PropTypes.bool,
    leftAlignedLabel: PropTypes.bool,
    errorText: PropTypes.string,
    warningText: PropTypes.string,
    successText: PropTypes.string,
    helpText: PropTypes.string,
    required: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func
  }, FormControl.propTypes)

  static defaultProps = Object.assign({
    className: '',
    hasError: false,
    hasWarning: false,
    isFluid: false,
    leftAlignedLabel: false,
    /**
     * Help text that displays under the ButtonGroup.
     *
     * @property ButtonGroup.defaultProps.helpText
     * @type {String}
     * @default ''
     */
    helpText: '',
    /**
     * Determines if the ButtonGroup is a required field
     *
     * @property ButtonGroup.defaultProps.required
     * @type {Boolean}
     * @default false
     */
    required: false,
    /**
     * Text to display when the ButtonGroup
     * encounters an error or is in its hasError state.
     *
     * @property ButtonGroup.defaultProps.errorText
     * @type {String}
     * @default ''
     */
    errorText: ''
  }, FormControl.defaultProps)

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasError, hasWarning } = nextProps;

    this.setState((prevState) => ({
      hasError: hasError === null ? prevState.hasError : hasError,
      hasWarning: hasWarning === null ? prevState.hasWarning : hasWarning,
    }));
  }

  /**
   * Validate the ButtonGroup and return
   * a validator object, which is a
   * snapshot of the ButtonGroup's state.
   *
   * @method ButtonGroup.validate
   * @returns {Object}
   */
  validate = () => {
    let value;
    const id = this.id;
    const { name, required, type } = this.props;
    const validType = type === 'checkbox' || type === 'radio';
    const inputs = [...this.buttonGroup.getElementsByTagName('input')];
    const checked = inputs.filter(input => { return input.checked || input.defaultChecked });

    if(checked.length > 0){
      value = type === 'checkbox' ? checked.map(input => { return input.value }) : checked[0].value;
    }

    const valid = validType && required && checked.length > 0 ? true : false;
    const hasError = !valid;
    const validatorObj = this.props.onValidate({
      hasError,
      value,
    });

    if(validType) {
      this.setState({ hasError });
    }

    return {
      ...validatorObj,
      id,
      name,
      value,
      required,
      type,
      valid,
    };
  }

  handleFocus = (event) => {
    const { onFocus } = this.props;

    this.setState({hasFocus: true});
    if (onFocus) { onFocus(event) }
  }

  handleBlur = (event) => {
    const { onBlur } = this.props;

    this.setState({hasFocus: false});
    if (onBlur) { onBlur(event) }
  }

  /**
   * Handle the conditional rendering of the Hint component
   *
   * @method ButtonGroup._hintGenerator
   * @param  {Event} event - change event
   * @private
   */
  _hintGenerator = () => {
    const {
      errorText,
      id,
      required,
    } = this.props;

    if(!required) {
      return <Hint id={`${id}_error`} text={ errorText } icon="error-solid" type="error" />
    } else {
      return <Hint id={`${id}_error`} text={ errorText } icon="error-solid" type="error" />
    }
  }

  render() {
    const {
      className,
      isFluid,
      label,
      required,
      helpText,
      errorText,
      warningText,
      successText,
      hasError,
      hasWarning,
      options,
      leftAlignedLabel,
      validators,
      onValidate,
      _form,
      _formValidateOnBlur,
      ...other
    } = this.props;

    const {
      hasFocus
    } = this.state;

    const id = this;

    const _hasWarning = this.state.hasWarning;
    const _hasError = this.state.hasError;

    const handleFocus = this.handleFocus;
    const handleBlur = this.handleBlur;

    const btnGroup = <div ref={ buttonGroup => { this.buttonGroup = buttonGroup; } }
                          className={ classnames(
                            'c-button-group',
                            { 'c-button-group--fluid': isFluid, },
                            { [className]: className, },
                            { [ERROR_CLASS]: _hasError, },
                            { [WARNING_CLASS]: _hasWarning, },
                            { [FOCUS_CLASS]: hasFocus, },
                          ) } role="group">
      { options.map((option, i) => {
        return this.createOption(Object.assign({ handleFocus, required, handleBlur }, other, option), `control-item-${i}`);
      }) }
    </div>;

    return (
      leftAlignedLabel
        ? <fieldset>
          <ul className="l-h-list l-h-list--middle">
            <li>{ createLegend(label, required, leftAlignedLabel) }</li>
            <li>{ btnGroup }</li>
          </ul>
          { (_hasError) ? this._hintGenerator() : '' }
          { (!_hasError || !_hasWarning ? <Hint id={`${id}_hint`} text={ helpText } /> : '') }
          { (successText ? <Hint icon="check-circle" type="success" id={`${id}_success`} text={ successText } /> : '') }
          { (_hasWarning ? <Hint type="warning" id={`${id}_warning`} text={ warningText } /> : '') }
        </fieldset>
        : <fieldset>
          <div className="u-vr">{ createLegend(label, required) }</div>
          { btnGroup }
          { (_hasError) ? this._hintGenerator() : '' }
          { (!_hasError || !_hasWarning ? <Hint id={`${id}_hint`} text={ helpText } /> : '') }
          { (successText ? <Hint icon="check-circle" type="success" id={`${id}_success`} text={ successText } /> : '') }
          { (_hasWarning ? <Hint type="warning" id={`${id}_warning`} text={ warningText } /> : '') }
        </fieldset>
    );
  }

  createOption = ({
                    text,
                    type,
                    name,
                    value,
                    disabled,
                    isActive,
                    _hasWarning,
                    _hasError,
                    handleFocus,
                    required,
                    handleBlur,
                    ...other
                  }, key) => {
    if (type === 'checkbox' || type === 'radio') {
      return (
        <label
          key={ key }
          className={ classnames(
            'c-button-group__item',
            { [DISABLED_CLASS]: disabled }
          ) }>
          <input
            { ...other }
            required={ required }
            disabled={ disabled }
            type={ type }
            name={ name }
            value={ value }
            onFocus={ handleFocus }
            onBlur={ handleBlur }
            className="c-button-group__item__input" 
            data-testid={`button-group-${value}`}
            />
          <span className="c-button-group__item__text">{ text }</span>
          <span className="c-button-group__item__outline" />
        </label>
      );
    }

    return (
      <button
        data-testid={`button-group-${text}`}
        key={ key }
        { ...other }
        type={ type ? type : 'button' }
        disabled={ disabled }
        className={ classnames(
          'c-button-group__item',
          { [ACTIVE_CLASS]: isActive },
          { [DISABLED_CLASS]: disabled }
        ) }
        onFocus={ handleFocus }
        onBlur={ handleBlur }>
        { text }
        <span className="c-button-group__item__outline" />
      </button>
    );
  }
}

function createLegend(label, required, leftAlignedLabel) {
  if (label) {
    return (
      <legend>
        <Label
          className={ leftAlignedLabel ? 'c-label--lg' : '' }
          text={ label }
          required= { required } />
      </legend>
    );
  }

  return;
}
