import React from 'react';
import PropTypes from 'prop-types';
import generateID from './../../utilities/generateID';
import TowTruck from '../../assets/towtruck1.svg';
import CurrentLocation from '../../assets/currentLocation.svg';
import DestinationImg from '../../assets/towtruck.svg';


export class TrackerMap extends React.Component {

  constructor(props) {
    super(props);

    this.map = null;
    this.markers = [];
    this.id = props.id || generateID();
    this.directionsDisplay = new window.google.maps.DirectionsRenderer({suppressMarkers: true});
  }

  static propTypes = {
    mapOptions: PropTypes.object.isRequired,
  }

  static defaultProps = {
    mapOptions: {},
    myLocation: '',
    towTruckLocation: '',
  }

  componentDidMount() {
    this.initMap();
  }

  componentDidUpdate(prevProps) {
    let {towTruckLocation, destinationLocation} = this.props
    const prevLat = prevProps.towTruckLocation ? prevProps.towTruckLocation.lat : null;
    const prevLng = prevProps.towTruckLocation ? prevProps.towTruckLocation.lng : null;
    const currentLat = towTruckLocation ? towTruckLocation.lat : null;
    const currentLng = towTruckLocation ? towTruckLocation.lng : null;
    if (prevLat !== currentLat || prevLng !== currentLng) {
      this.clearMarkers();
      this.drawTowTruckRoute();
      this.addDestinationPin(destinationLocation)
    }
  }

  initMap = () => {
    const {mapOptions, destinationLocation} = this.props;
    if (mapOptions.center) {
      const mapElement = document.getElementById(`map-${this.id}`);
      this.map = new window.window.google.maps.Map(mapElement, mapOptions);
      this.drawTowTruckRoute()
    }

    this.addDestinationPin(destinationLocation)
  }

  addDestinationPin = (destination) => {
    if (destination.lat && destination.lng) {
      let dropPin = {
        lat: destination.lat,
        lng: destination.lng,
      }

      this.makeMarker(dropPin, DestinationImg, 'Destination');
    }
  }

  clearMarkers = () => {
    this.markers.forEach(marker => marker.setMap(null))
    this.markers = [];
  }

  drawTowTruckRoute = () => {
    const {myLocation, towTruckLocation} = this.props;

    this.directionsDisplay.setMap(this.map);

    const request = {
      origin: towTruckLocation.lat + ',' + towTruckLocation.lng,
      destination: myLocation.lat + ',' + myLocation.lng,
      travelMode: 'DRIVING',
      unitSystem: window.google.maps.UnitSystem.IMPERIAL
    };
    let directionsService = new window.google.maps.DirectionsService();
    directionsService.route(request, (result, status) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(result);
        const leg = result.routes[0].legs[0];
        this.makeMarker(leg.end_location, CurrentLocation, 'Your Location');
        this.makeMarker(leg.start_location, TowTruck, 'Tow Truck Location');
      }
    });
  }

  makeMarker = (position, icon, title) => {
    const marker = new window.google.maps.Marker({
      position: position,
      map: this.map,
      icon: icon,
      title: title
    });

    this.markers.push(marker)
    return marker;
  }

  createTemplate(style, mapId) {
    return (
      <div className="l-grid">
        <div className="l-grid__col l-grid__col--tracker l-grid__col--12">
          <div className='l-grid l-grid__tracker' style={style}>
            <div data-testid="trackerMap" className="l-grid__col l-grid__col--12"
                 id={mapId}>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {minHeight} = this.props;
    const {id} = this;
    const style = {height: minHeight}
    const mapId = `map-${id}`;

    return (
      <div className="c-google-map">
        {this.createTemplate(style, mapId)}
      </div>
    );
  }
}

export default TrackerMap
