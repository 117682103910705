import {maxBy} from 'lodash';
export default function getAmcOrJJCoverageAmount(commonBenefits) {
  if(commonBenefits.amcCoverageAmount) {
    return commonBenefits.amcCoverageAmount.split(".")[0];
  }
  else if (commonBenefits.partnerCode === "JJ" || commonBenefits.partnerCode === "ENC") {
     // AMC stacked - calculate highest coverage
     let maxCoverageVehicle = maxBy(commonBenefits.vehicles,
       (vehicle) => vehicle.suspendIndicator !== 'Y' && parseFloat(vehicle.coverageAmount)
     );
     return maxCoverageVehicle.coverageAmount?.split(".")[0];
  }
  return ""
}
