import { css } from '@emotion/react';
import styled from '@emotion/styled';

const serviceTimeStyles = css`
  height: 100%;
  width: 100%;
  min-height: 667px;
  position: relative;
  overflow: hidden;

  & > h1 {
    color: #2F3847;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    font-style: normal;
    margin-bottom: 1.5rem;
  }

  & > div {
      display: inline-block;
      margin-top: 1.5rem;
      padding: 0px 16px 0px 16px;
      max-width: 375px;
  }

  & #null_hint > div {
    text-align: left;
  }
`;

const StyledServiceTime = styled('div')(serviceTimeStyles);

export default StyledServiceTime;

