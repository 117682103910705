import React from "react";

const CarTopView = ({selectedTires = []}) =>
  <svg width='128' height='278' viewBox='0 0 128 278'
       xmlns='http://www.w3.org/2000/svg'>
    <g id='Rescue' fill='none' fillRule='evenodd'>
      <g id='Tire-Change---Triage' transform='translate(-656 -255)'>
        <g id='sedan-top-view' transform='translate(656 258)'>
          <path
            d='M12.2102857,256.545803 C12.2102857,256.545803 14.0281905,264.693544 26.5721905,267.429634 C26.5721905,267.429634 57.0598095,275.591932 97.639619,268.011944 C97.639619,268.011944 115.221333,263.954163 115.221333,256.763403 L115.221333,33.0529803 C115.221333,11.7174535 99.831619,6.42762817 99.831619,6.42762817 C99.831619,6.42762817 68.6544762,-6.59236056 29.8110476,6.42762817 C29.8110476,6.42762817 11.351619,11.2868507 12.2102857,33.0529803 L12.2102857,256.546569 L12.2102857,256.545803 Z'
            id='Shape' fill='#FFF' fillRule='nonzero'/>
          <path
            d='M86.6110476,195.875245 L90.456381,222.934265 C90.5988523,223.751752 90.1222831,224.550291 89.3379048,224.808383 C65.0270476,231.367031 59.5032381,229.259989 37.9253333,224.574693 C37.1355304,224.310627 36.6771839,223.483549 36.8693333,222.669161 L41.5474286,195.653048 C41.6391367,195.11553 41.9777187,194.653061 42.4608773,194.405369 C42.9440359,194.157677 43.5150971,194.153816 44.0015238,194.394952 C49.2373333,196.994659 64.1607619,202.572575 84.2681905,194.516011 C84.7580382,194.319212 85.3105605,194.35906 85.7674692,194.624141 C86.2243779,194.889221 86.535258,195.350285 86.6110476,195.875245 Z M102.648381,202.695166 L93.2868571,195.362659 C92.2948571,194.584969 91.5801905,194.979561 91.6975238,196.237656 L99.664,220.721487 C100.033524,221.92978 100.517333,221.901431 100.739048,220.656361 C100.739048,220.656361 101.414095,216.875944 102.648381,202.695166 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M100.645333,236.223189 L100.645333,255.132169 C100.645333,255.132169 68.8685714,267.3216 26.7862857,255.132169'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M90.704,138.736856 L100.784,79.3067718 C67.4278095,62.5293521 31.6624762,78.3796732 31.6624762,78.3796732 C31.0258219,78.7298949 30.3382858,78.9770854 29.6251429,79.1121577 C29.8019048,79.7956056 29.9184762,80.4936113 29.9733333,81.1977465 L37.304381,138.09938 C37.343796,138.666408 37.6557759,139.178455 38.14024,139.471259 C38.6247042,139.764063 39.2207395,139.800811 39.7371429,139.569713 C39.7371429,139.569713 58.9142857,132.316124 88.8213333,139.955876 C89.2328157,140.06325 89.6701998,139.992704 90.0276517,139.761307 C90.3851036,139.52991 90.6300561,139.158745 90.7032381,138.737623 L90.704,138.736856 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M24.704,17.4723606 C24.704,17.4723606 21.4887619,19.9954479 21.7988571,30.6969239 C21.8144486,31.2619657 22.2154106,31.7415816 22.7662564,31.8540911 C23.3171022,31.9666005 23.8723518,31.6822896 24.1059048,31.1681352 C26.3017143,26.2414873 30.0304762,18.9833014 33.5078095,17.4723606 L41.752381,12.8261408 C42.2189703,12.5636389 42.4582066,12.0237066 42.3402891,11.4992916 C42.2223715,10.9748765 41.7754081,10.5909834 41.2419048,10.5558986 C36.9638095,10.3183775 29.8057143,11.0608225 24.704,17.4731268 L24.704,17.4723606 Z M104.259048,17.4723606 C104.259048,17.4723606 107.474286,19.9954479 107.16419,30.6969239 C107.148599,31.2619657 106.747637,31.7415816 106.196791,31.8540911 C105.645945,31.9666005 105.090696,31.6822896 104.857143,31.1681352 C102.661333,26.2414873 98.9325714,18.9833014 95.4552381,17.4723606 L87.2106667,12.8253746 C86.7440773,12.5628727 86.504841,12.0229404 86.6227585,11.4985254 C86.7406761,10.9741103 87.1876395,10.5902172 87.7211429,10.5551324 C91.9992381,10.3176113 99.1573333,11.0600563 104.259048,17.4723606 Z'
            id='Shape' fill='#C1DEFA' fillRule='nonzero'/>
          <path
            d='M103.060571,74.0590873 L101.772952,36.4525972 L91.8422857,20.7126085 C91.8422857,20.7126085 81.1070476,12.8414648 64.0914286,13.7892507 C64.0914286,13.7892507 45.4735238,13.9501521 34.9935238,21.7883493 L26.6262857,36.2932282'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M38.0586667,26.5426028 L35.7973333,61.2329465 M89.168,24.6485634 L91.4293333,59.3381408'
            id='Shape' stroke='#001E60' strokeWidth='0.616'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M92.4868571,262.959639 C92.4868571,262.959639 102.540952,259.753104 105.023238,258.295797 M23.136,258.295797 L36.2841905,262.959639'
            id='Shape' stroke='#FD9D30' strokeWidth='3.072'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M25.4681905,143.197656 C25.0258099,142.906968 24.4541895,142.907525 24.0123706,143.199077 C23.5705517,143.490628 23.3425225,144.01775 23.431619,144.541566 L25.304381,192.836507 C25.367619,194.0992 26.2887619,194.578839 27.351619,193.903054 L34.6049524,189.285949 C35.7458252,188.465554 36.4526376,187.166944 36.5249524,185.758377 L33.7257143,149.200811 C33.5484148,147.815243 32.7104256,146.603651 31.480381,145.954434 L25.4681905,143.197656 Z M95.3798095,139.250208 C95.2312381,140.506006 96.0198095,141.050772 97.1321905,140.4608 L102.977524,137.358468 C104.162555,136.643973 104.908355,135.375715 104.96,133.9872 L104.23619,91.5582648 C104.216381,90.2940394 103.99619,90.2741183 103.74781,91.5130592 L95.3798095,139.250208 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M101.234286,223.364868 C101.234286,223.364868 101.795048,220.371335 102.648381,202.695166 M104.998095,136.285792 C105.29981,110.748439 105.099429,89.7056 105.099429,89.7056 M25.9329524,194.319099 C24.7100952,184.162389 23.6266667,159.414986 23.3881905,142.243741'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M36.6300952,224.049848 C36.6300952,224.049848 62.1577143,234.345239 90.6537143,224.326445 M36.6300952,224.049848 C36.6300952,224.049848 62.1577143,234.345239 90.6537143,224.326445 M29.5710476,79.3067718 C29.5710476,79.3067718 63.7958095,61.4267944 100.783238,79.3067718'
            id='Shape' stroke='#001E60' strokeWidth='1.804'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M120.39619,33.0997183 C120.39619,33.1962592 120.403048,33.2866704 120.403048,33.3832113 L120.403048,68.0329465 L122.795429,67.9578592 C125.395048,67.9578592 127.502476,66.0500282 127.502476,63.6978028 L127.502476,37.3597746 C127.502476,35.0075493 122.79619,33.0997183 122.79619,33.0997183 L120.39619,33.0997183 Z'
            id='passengerFront'
            fill={selectedTires.includes('frontRight') ? '#ff9e16' : '#001E60'}
            fillRule='nonzero'/>
          <path
            d='M6.9127619,33.4904789 C6.90742857,33.3571606 6.9112381,33.2315042 6.90742857,33.0997183 L5.22514286,33.0997183 C5.22514286,33.0997183 0.518095238,35.0075493 0.518095238,37.3597746 L0.518095238,63.6978028 C0.518095238,66.0500282 2.6247619,67.9578592 5.22438095,67.9578592 L6.93104762,68.011493 L6.912,33.4904789 L6.9127619,33.4904789 Z'
            id='driverFront'
            fill={selectedTires.includes('frontLeft') ? '#ff9e16' : '#001E60'}/>
          <path
            d='M120.403048,183.709566 L120.403048,218.643561 L122.795429,218.568473 C125.395048,218.568473 127.502476,216.660642 127.502476,214.308417 L127.502476,187.970389 C127.502476,185.617397 122.79619,183.710332 122.79619,183.710332 L120.403048,183.709566 Z'
            id='passengerRear'
            fill={selectedTires.includes('backRight') ? '#ff9e16' : '#001E60'}/>
          <path
            d='M6.99504762,183.709566 L5.22514286,183.709566 C5.22514286,183.709566 0.518857143,185.617397 0.518857143,187.969623 L0.518857143,214.308417 C0.518857143,216.660642 2.62552381,218.568473 5.22514286,218.568473 L7.01409524,218.624406 L6.99504762,183.709566 Z'
            id='driverRear'
            fill={selectedTires.includes('backLeft') ? '#ff9e16' : '#001E60'}/>
          <path
            d='M26.5721905,267.429634 C26.5721905,267.429634 57.0598095,275.591932 97.639619,268.011944 C97.639619,268.011944 115.221333,263.954163 115.221333,256.763403 L115.221333,33.0529803 C115.221333,11.7174535 99.831619,6.42762817 99.831619,6.42762817 C99.831619,6.42762817 68.6544762,-6.59236056 29.8110476,6.42762817 C29.8110476,6.42762817 11.351619,11.2868507 12.2102857,33.0529803 L12.2102857,256.546569 C12.2102857,256.545803 14.0281905,264.693544 26.5721905,267.429634 Z'
            id='Shape' stroke='#001E60' strokeWidth='5.954'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M33.2190476,139.250208 C33.367619,140.506006 32.5790476,141.050772 31.4666667,140.4608 L25.6220952,137.358468 C24.4370638,136.643973 23.6912638,135.375715 23.639619,133.9872 L24.3626667,91.5582648 C24.3824762,90.2940394 24.6026667,90.2741183 24.8510476,91.5130592 L33.2198095,139.250208 L33.2190476,139.250208 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M23.6,136.285792 C23.2990476,110.748439 23.4994286,89.7056 23.4994286,89.7056'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M103.083429,143.197656 C103.525809,142.906968 104.09743,142.907525 104.539248,143.199077 C104.981067,143.490628 105.209097,144.01775 105.12,144.541566 L103.247238,192.836507 C103.183238,194.0992 102.262095,194.578839 101.2,193.903054 L93.9466667,189.285949 C92.8057079,188.46563 92.098871,187.166975 92.0266667,185.758377 L94.8259048,149.200811 C95.0032043,147.815243 95.8411935,146.603651 97.0712381,145.954434 L103.083429,143.197656 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M102.618667,194.319099 C103.841524,184.162389 104.92419,159.414986 105.163429,142.243741'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
          <path
            d='M26.6697143,202.695166 L36.0312381,195.362659 C37.0232381,194.584969 37.7379048,194.979561 37.6205714,196.237656 L29.6540952,220.721487 C29.2845714,221.92978 28.8007619,221.901431 28.5790476,220.656361 C28.5790476,220.656361 27.904,216.875944 26.6697143,202.695166 Z'
            id='Shape' fill='#C2DCF3' fillRule='nonzero'/>
          <path
            d='M28.0838095,223.364868 C28.0838095,223.364868 27.5230476,220.371335 26.6697143,202.695166'
            id='Shape' stroke='#001E60' strokeWidth='1.848'
            strokeLinecap='round' strokeLinejoin='round'
          />
        </g>
      </g>
    </g>
  </svg>

export default CarTopView;
