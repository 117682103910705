
export const regexUtil = (regex, value) => {
    const isValid = regex.test(value);
    return isValid;
};

export default {regexUtil}

export const allowLetters = (content) => {
  return content?.replace(/[^a-z]/gi, "")
}

export const allowNumbers = (content) => {
  return content?.replace(/[^0-9]/g, "")
}

export const allowValidUrlCharacters = (url) => {
  return url?.replace(/[^a-z0-9-_./:?=&]/gi, "")
}

export const allowLettersNumbersAndDashes = (content) => {
  return content?.replace(/[^a-z0-9-]/gi, "")
}

export const allowNumbersAndDashes = (content) => {
  return content?.replace(/[^0-9-]/g, "")
}

export const allowValidCompanyNameCharacters = (content) => {
  return content?.replace(/[^0-9a-z- ']/gi, "")
}

export const allowValidNameCharacters = (content) => {
  return content?.replace(/[^a-z-'. ]/gi, "")
}

export const convertToBoolean = (content) => {
  if(!content) {
    return false
  }
  const regexToBeTrue = new RegExp(/^(1|true)$/, "i");
  const regexToBeFalse = new RegExp(/^(0|false)$/, "i");
  return regexUtil(regexToBeTrue, content) || !regexUtil(regexToBeFalse, content);
};

export const validateDateFormat = (content) => {
  const enUS = () => {
    const regex = new RegExp(
      /^(1[0-2]|0[1-9])\/(3[01]|[12][0-9]|0[1-9])\/[0-9]{4}$/
    );
    return content ? regexUtil(regex, content) : false;
  };
  return {
    enUS,
  };
};

export const validateTimeFormat = (content) => {
  const enUS = () => {
    const regex = new RegExp(/^(1[0-2]|0?[1-9]):([0-5]?[0-9])( [AP]M)?$/);
    return content ? regexUtil(regex, content) : false;
  };
  return {
    enUS,
  };
};
