import React from "react";
import { useSelector } from "react-redux";
import {
  getCommonBenefitsStatus,
  getPartnerDetails,
  hasBenefitsSelector,
  hasPepSessionSelector,
} from "../../selector";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import CONSTANTS from "../../app-consts/appConstants";
import Icon from "../icon";
import { Box, Flex, Price, Type } from "mesh-component-library";
import _isEmpty from "lodash/isEmpty";

const Benefits = ({
  benefitsAmt,
  message,
  handleOnClick,
  showServiceBasedBanner,
  showOmniDarkBanner
}) => {
  const getTranslatedText = useLocale();
  const hasBenefits = useSelector(hasBenefitsSelector);
  const commonBenefitsStatus = useSelector(getCommonBenefitsStatus);
  const hasPepSession = useSelector(hasPepSessionSelector);
  const { displayCode = "" } = useSelector(getPartnerDetails);

  const handleOnClickContent = (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (showPricingInfoNoBenefitsMessage) {
      handleOnClick();
    }
  };

  const NoBenefitsAvailable = ({ children: subChildren }) => {
    return (
      <div className="l-container-member">
        <Icon
          icon="flag-circle"
          className="l-container-home__error--icon"
          size={1.5}
          fill={"#ff9e16"}
        />
        <div
          className="c-container-member--content"
          onClick={handleOnClickContent}
          data-testid="no-benefits-content"
        >
          {subChildren}
        </div>
      </div>
    );
  };

  const DarkBanner = ({ children: subChildren }) => {
    return (
      <Box
        hasBorder={false}
        variant="dark"
        utils={{
          hrRight: "md",
          hrLeft: "md",
          vrBottom: "md",
        }}
      >
        <Type
          element="div"
          size="l"
          align="left"
          lineHeight="md"
          utils={{
            textColor: "#FFFFFF",
          }}
        >
          {subChildren}
        </Type>
      </Box>
    );
  };

  const showBenefitsMessage =
    hasBenefits &&
    !showServiceBasedBanner &&
    commonBenefitsStatus !== CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO;
  const showPricingInfoNoBenefitsMessage =
    hasBenefits &&
    commonBenefitsStatus === CONSTANTS.MUST_SHOW_NO_BENEFITS_PRICING_INFO;
  const showDefaultNoBenefitsMessage = !hasBenefits && !hasPepSession && !showOmniDarkBanner;
  const showServiceBasedBannerMessage = hasBenefits && showServiceBasedBanner;
  const showPepBannerMessage = !hasBenefits && hasPepSession;
  const showOmniDarkBannerBenefits = showBenefitsMessage && showOmniDarkBanner;
  const showOmniDarkBannerNonBenefits = !showBenefitsMessage && showOmniDarkBanner;

  return (
    <div>
      {(showBenefitsMessage || showOmniDarkBannerBenefits) && !_isEmpty(benefitsAmt) && (
        <Box
          hasBorder={false}
          variant="dark"
          utils={{
            hrRight: "md",
            hrLeft: "md",
            vrBottom: "md",
          }}
        >
          <Flex justifyContent="between" alignItems="middle">
            <Type
              element="div"
              size="xl"
              weight="semibold"
              utils={{
                textColor: "#FFFFFF",
              }}
            >
              {getTranslatedText(TRANSLATION_CONSTANTS.COVERAGE_UP_TO)}
            </Type>
            <Price
              amount={benefitsAmt}
              inline
              isFixedDecimal
              size="xl"
              color="green"
              suffix={
                <Type
                  element="span"
                  size="xl"
                  utils={{
                    textColor: "#3CE4A5",
                  }}
                >
                  {getTranslatedText(TRANSLATION_CONSTANTS.PER_RESCUE)}
                </Type>
              }
              utils={{
                vrTop: "sm",
              }}
            />
          </Flex>
        </Box>
      )}
      {showDefaultNoBenefitsMessage && (
        <NoBenefitsAvailable>
          <div className="c-container-member--title">
            <h2>
              {getTranslatedText(TRANSLATION_CONSTANTS.NO_BENEFITS_AVAILABLE)}
            </h2>
          </div>
          <p>{message}</p>
        </NoBenefitsAvailable>
      )}
      {showPricingInfoNoBenefitsMessage && (
        <NoBenefitsAvailable>
          <div className="c-container-member--title">
            <h2>
              {getTranslatedText(
                TRANSLATION_CONSTANTS.DID_NOT_PURCHASE_COVERAGE
              )}
            </h2>
          </div>
          <p
            dangerouslySetInnerHTML={{
              __html: getTranslatedText(
                TRANSLATION_CONSTANTS.MEMBER_NOT_FOUND_PRICING_INFO
              ),
            }}
          />
        </NoBenefitsAvailable>
      )}
      {showOmniDarkBannerNonBenefits && (
          <DarkBanner>
            {message}
          </DarkBanner>
      )}
      {showServiceBasedBannerMessage && (
        <DarkBanner>
          {getTranslatedText(TRANSLATION_CONSTANTS.SERVICE_BASED_BANNER)}
        </DarkBanner>
      )}
      {showPepBannerMessage && (
        <DarkBanner>
          {getTranslatedText(TRANSLATION_CONSTANTS.PEP_BANNER, displayCode)}
        </DarkBanner>
      )}
    </div>
  );
};

export default Benefits;
