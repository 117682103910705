import React, {useState} from "react";
import Lockout from '../../components/svg/Lockout';
import FormOption from '../form-option';
import Form from "../form";
import {sendJacadaRequest} from '../../api';
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import { useLocale } from "../../contexts/LocaleContext";
import { Button } from 'mesh-component-library';
const LockoutTriage = ({updateSoftServiceDetails, towScreenDispatcher}) => {
  const [btnDisabled, setButtonDisabled] = useState(true)
  const [lockoutType, setLockoutType] = useState("")
  const getTranslatedText = useLocale();

  const handleButtonClick = () => {

    if (lockoutType === 'vehicle') {
      sendJacadaRequest({
        'externalSystemId': localStorage.getItem('conversationId') || "not available",
        'events': [{'name': 'event_id', 'value': 34}]
      });
    }
    const isToTow = lockoutType === "lost" || lockoutType === "trunk";
    updateSoftServiceDetails(isToTow, 'Lockout', {towReason: 'mechanical_key'});
    towScreenDispatcher(lockoutType === "trunk");
  }

  return (
    <>
      <div className="l-container-triage">
        <div className="c-triage">
          <div className="c-triage--svg">
            <Lockout/>
          </div>
          <div className="c-triage--content">
            <h1 id="where-are-your-keys">{getTranslatedText(TRANSLATION_CONSTANTS.WHERE_ARE_YOUR_KEYS)}</h1>
            <div className="c-triage--content-form">
              <Form onChange={(event) => {
                setButtonDisabled(false)
                setLockoutType(event.target.value)
              }}>
                <FormOption
                  id="in-my-vehicle"
                  type="radio"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.IN_MY_VEHICLE)}
                  value="vehicle"
                  name="options"/>

                <FormOption
                  id="in-my-trunk"
                  type="radio"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.IN_MY_TRUNK)}
                  value="trunk"
                  name="options"/>

                <FormOption
                  id="lost-broken"
                  type="radio"
                  label={getTranslatedText(TRANSLATION_CONSTANTS.LOST_BROKEN)}
                  value="lost"
                  name="options"/>
                  <br/>
                <Button
                  size='lg'
                  hasUpgrade
                  id="where-are-your-keys-continue"
                  disabled={btnDisabled}
                  onClick={handleButtonClick}
                  utils={{
                    fullWidth: true
                  }}>
                  {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LockoutTriage
