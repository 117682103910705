import {takeEvery, put, call} from 'redux-saga/effects';
import {datadogRum} from "@datadog/browser-rum";
import {
  REQUESTED_MAKE_MODEL,
  requestMakeModelFailed,
  requestMakeModelSuccess
} from './../action';
import {getMakeModelApi} from './../api';

export function* getMakeModel(action) {
    const {response, error} = yield call(getMakeModelApi, action.request.year);
    if(response) {
      const payload = yield response.data;
      yield put(requestMakeModelSuccess(payload));
    } else{
      datadogRum.addError(new Error(`Received error message from getMakeModel call ${error}`))
      const payload = error.headers["correlation-id"];
      yield put(requestMakeModelFailed(payload));
    }
}

export function* watchGetMakeModelSaga() {
  yield takeEvery(REQUESTED_MAKE_MODEL, getMakeModel);
}
