export const SERVICE_REQUEST_INFO_REQUESTED = "SERVICE_REQUEST_INFO_REQUESTED";
export const SERVICE_REQUEST_INFO_SUCCESS = "SERVICE_REQUEST_INFO_SUCCESS";
export const SERVICE_REQUEST_INFO_FAILED = "SERVICE_REQUEST_INFO_FAILED";
export const REFRESH_SERVICE_REQUEST_INFO = "REFRESH_SERVICE_REQUEST_INFO";

export function dispatchServiceRequestInfo(payload){
    return { type: SERVICE_REQUEST_INFO_REQUESTED, payload };
}

export function serviceRequestInfoSuccess(data){
    return { type: SERVICE_REQUEST_INFO_SUCCESS, payload: data};
}

export function refreshServiceRequestInfo(){
    return { type: REFRESH_SERVICE_REQUEST_INFO};
}
