import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocale } from '../../contexts/LocaleContext';
import { TRANSLATION_CONSTANTS } from '../../app-consts/translations';
import {
    Grid,
    Type,
    FormField,
    Flex,
    Button,
    Tooltip,
    Icon
} from 'mesh-component-library';
import { QuestionIcon } from 'mesh-icon-library';
import { generatePath } from '../../utilities/RedirectUtils';
import { updateCurrentMileage, updateServiceTransition } from '../../action';
import { getExpirationMileageByIndex } from '../../selector';
import { UNABLE_TO_COMPLETE_SERVICE } from '../../app-consts/errorMessageConstants';


const OdometerLookup = ({ history, showModal, closeModal }) => {
    const [odometer, setOdometer] = useState('');

    const getTranslatedText = useLocale();
    const expirationMileage = useSelector((state) => getExpirationMileageByIndex(state, 0));
    const dispatch = useDispatch();

    const handleChange = (event) => {
        setOdometer(event.target.value.trim());
    }

    const validateCompleteForm = () => {
        let odometerAvailable = odometer?.length > 0 && odometer.length <= 7;
        if (odometerAvailable) {
            return !odometerAvailable;
        }
        return true;
    };

    const continueClick = () => {
        if (parseInt(odometer) <= expirationMileage) {
            dispatch(updateCurrentMileage(parseInt(odometer)));
            history.push(generatePath('home'));
        } else {
            dispatch(
                updateServiceTransition({
                    errorMessageContent: UNABLE_TO_COMPLETE_SERVICE
                })
            );
            history.push(generatePath("ServiceTransition"));
        }
    };


    return (
        <>
            <Grid
                utils={{
                    vrBottom: "md"
                }}>
                <Grid.Column>
                    <Type
                        id=""
                        element="h1"
                        weight="semibold"
                        align="left"
                        size="6xl"
                        lineHeight="md"
                        utils={{
                            vrTop: "md"
                        }}>
                        {getTranslatedText(TRANSLATION_CONSTANTS.PROVIDE_VEHICLE_INFO)}
                    </Type>
                </Grid.Column>
            </Grid>
            <Grid utils={{
                vrBottom: "md"
            }}>
                <Grid.Column>
                    <FormField
                        size="md"
                        id=""
                        label={<Flex alignItems="stretch" justifyContent="between" isBlock={true}>
                            <Type size="l" weight="bold" emphasis="information">
                                {getTranslatedText(TRANSLATION_CONSTANTS.ODOMETER)}
                            </Type>
                            <Tooltip
                                id="mileage-tooltip"
                                size="lg"
                                text={getTranslatedText(TRANSLATION_CONSTANTS.CAPTURE_ACTUAL_MILEAGE)}
                            >
                                <Icon icon={QuestionIcon} color="#0033A0"
                                    size={1.2} />
                            </Tooltip>
                        </Flex>}
                        labelProps={{
                            style: {
                                width: "100%"
                            }
                        }}
                        value={odometer}
                        onChange={handleChange}
                        utils={{
                            vrTop: "xs",
                            vrBottom: "xs"
                        }}
                        required
                        pattern="d*"
                        inputMode="numeric"
                        maxLength={7}
                        type="number"
                        data-testid="odometer"
                    />
                </Grid.Column>
            </Grid>
            <Grid utils={{
                vrBottom: "md"
            }}>
                <Grid.Column>
                    <Button
                        hasUpgrade
                        id=""
                        disabled={validateCompleteForm()}
                        onClick={continueClick}
                        utils={{ fullWidth: true }}>
                        {getTranslatedText(TRANSLATION_CONSTANTS.CONTINUE)}
                    </Button>
                </Grid.Column>
            </Grid>
        </>
    )
};

export default OdometerLookup;
