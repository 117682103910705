import {
  COMMON_BENEFITS_FAILED,
  COMMON_BENEFITS_START,
  BENEFITS_LOOKUP_START,
  COMMON_BENEFITS_SUCCESS,
  NO_COMMON_BENEFITS,
  LAST_SUCCESSFUL_SESSION_TYPE,
  REFRESH_REDUCERS,
  REQUESTED_PARTNER_DETAILS_SUCCESS,
  NO_COMMON_BENEFITS_WITH_PREVIOUS,
  CLEAR_PREVIOUS_BENEFITS
} from './../action';
import titleCase from "../utils/titleCase";
import getBenefitAmount from "../utils/getBenefitAmount";

const initialState = {
  experience: {},
  services: [],
  vehicles: [],
  operators: [],
  maxClaimCount: '',
  usageCount: '',
  partnerCode: '',
  memberId: '',
  promoCode: '',
  policyNumber: '',
  towToDealershipRequired: false,
  loading: undefined,
  benefitsReady: false,
  error: false,
  errorMessage : '',
  lastSuccessfulSessionType: 'BENEFITS'
};

export default function(state= initialState, action){
  switch(action.type){
    case COMMON_BENEFITS_START:
    case BENEFITS_LOOKUP_START:
      return {...state, loading: true, benefits:false, error: false, errorMessage:'', lookupType: action.payload.lookupType};
    case COMMON_BENEFITS_SUCCESS:
      const coverageAmount = getBenefitAmount(action.payload);
      const availableVehicles = sanitizeVehicleList(action.payload?.vehicles);
      return {...state,  ...action.payload, coverageAmount, vehicles: availableVehicles, loading: false, benefits:true, error: false, errorMessage:'', noBenefits: false};
    case COMMON_BENEFITS_FAILED:
      return {...state, loading: false, error: true, errorMessage: action.payload? action.payload.message : '', unauthorized: action.payload};
    case REQUESTED_PARTNER_DETAILS_SUCCESS:
      if (!action.payload.experience?.benefitsEnabled && action.payload.route !== 'wd') {
        return {...state, loading: false, error: false, noBenefits: true};
      }
      return state;
    case NO_COMMON_BENEFITS:
      return {...state, ...action.payload.response, loading: false, error: false, benefits: false, errorMessage: action.payload.message || '', noBenefits : true};
    case NO_COMMON_BENEFITS_WITH_PREVIOUS:
      return {...state, ...action.payload.response, loading: false, error: false, benefits: false, errorMessage: action.payload.message || '', noBenefits : true, previousBenefits: { ...state }};
    case CLEAR_PREVIOUS_BENEFITS:
      const { previousBenefits = {}, ...restState } = state
      return { ...restState };
    case LAST_SUCCESSFUL_SESSION_TYPE:
      return {...state, lastSuccessfulSessionType: action.payload }
    case REFRESH_REDUCERS:
      return { ...initialState };
    default:
      return state;
  }
}

function sanitizeVehicleList(vehicles) {
  if (!vehicles) return [];
  const allVehicles = vehicles.filter((vehicle) => {
    return vehicle.make && vehicle.model && vehicle.year
  })
  return allVehicles.map((vehicle) => {
    return {
      ...vehicle,
      make: titleCase(vehicle.make),
      model: titleCase(vehicle.model),
      equipmentClass: vehicle.equipmentClass ? vehicle.equipmentClass : "Light",
      color: vehicle.color ? titleCase(vehicle.color) : ""
    }
  });
}
