import {
  REFRESH_REDUCERS,
  REQUESTED_EPCS_INITIALIZE,
  REQUESTED_EPCS_INITIALIZE_FAILED,
  REQUESTED_EPCS_INITIALIZE_SUCCESS
} from './../action';

const initialState = {
  initialize: {},
  loading: false,
  error: false,
  success:false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case REQUESTED_EPCS_INITIALIZE:
        return {...state, loading: true};
    case REQUESTED_EPCS_INITIALIZE_SUCCESS:
        return {...state, initialize: action.payload, loading: false, success: true};
    case REQUESTED_EPCS_INITIALIZE_FAILED:
        return {...state, loading: false, error: true};
    case REFRESH_REDUCERS:
        return {...initialState};
    default:
        return state;
  }
}
