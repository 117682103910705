
const isHandlerEnabled = (config = {}) => {
  return !(config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled)
}


const isPartnerCodeInsteadOfDisplayCode = (config = {}) => {
  return (config.hasOwnProperty('partnerCode') && config.partnerCode.length > 0)
}

const partnerCodeHandler = (request) => {
  if(isHandlerEnabled(request)) {
    if(isPartnerCodeInsteadOfDisplayCode(request)){
      request.headers['partnerCode'] = request.partnerCode
    }
    else{
    request.headers['partnerCode'] = window.location.pathname.split('/')[2]
    }
  }
  return request
}

export const splashPageHandler = (response) => {
  const HTML_CONTENT_TYPE = "text/html";
  const responseContentType = response.headers["content-type"];
  if(responseContentType === HTML_CONTENT_TYPE) {
    const responseData = response.data;
    const parser = new DOMParser();
    const splashPage = parser.parseFromString(responseData, HTML_CONTENT_TYPE);
    const splashMessage = splashPage.getElementById("splash-message");
    if(splashMessage && splashMessage.textContent === "Our site is currently down for maintenance. Please check back later.") {
      location.reload();
    }
  }
  return response;
}

export default partnerCodeHandler;
