// Ripped straight out of the react-router examples
import { useLocation } from 'react-router';
import queryString from 'query-string';

// A custom hook that builds on useLocation to parse
// the query string for you.

export default function useQuery() {
  return new queryString.parse(useLocation().search);
}
