import {maxBy} from 'lodash';
export default function getBenefitAmount(commonBenefits) {
  if(commonBenefits.amcCoverageAmount) {
    return commonBenefits.amcCoverageAmount;
  }
  const coverageInServices = commonBenefits?.services?.filter(service => service.type === 'Tow')[0]?.coverageAmount;
  if(coverageInServices && window.location.pathname.split("/")[3] === 'location') {
    return coverageInServices;
  }

  // By flipping === 'N' to !== 'Y' we cover vehicles that may not have the indicator
  let maxCoverageVehicle = maxBy(commonBenefits.vehicles,
    (vehicle) => vehicle.suspendIndicator !== 'Y' && parseFloat(vehicle.coverageAmount)
  );

  let benefitAmount = maxCoverageVehicle?.coverageAmount?.split(".")[0]
  if (benefitAmount === "9999990" && commonBenefits.partnerCode === "ENC") {
    benefitAmount = 0;
  }
  return benefitAmount || 0;
}
