import React from "react";

const CarIllustrationDuplicateRescue = () => {
  return (
    <svg
      width="100%"
      height="226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipRule="url(#clip0_11842_2494)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M249.746 130.23C249.746 177.359 182.202 215.565 98.8828 215.565C15.5629 215.565 -51.9807 177.359 -51.9807 130.23C-51.9807 83.0996 15.5629 44.8938 98.8828 44.8938C182.202 44.8938 249.746 83.0996 249.746 130.23Z"
          fill="#E5F0FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-172.987 216.942L514.598 217.867L463.631 129.951L-10.8204 97.8716L-177.073 147.789L-172.987 216.942Z"
          fill="#E5F0FA"
        />
        {/* <mask
          id="mask0_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="64"
          y="36"
          width="356"
          height="177"
        >
          <path
            d="M419.997 212.889H64.2506V36.291H419.997V212.889Z"
            fill="#E5F0FA"
          />
        </mask> */}
        <g mask="url(#mask0_11842_2494)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M419.997 180.545C419.997 260.215 340.361 324.8 242.124 324.8C143.888 324.8 64.2506 260.215 64.2506 180.545C64.2506 100.876 143.888 36.291 242.124 36.291C340.361 36.291 419.997 100.876 419.997 180.545Z"
            fill="#E5F0FA"
          />
        </g>
        {/* <mask
          id="mask1_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="262"
          y="68"
          width="313"
          height="145"
        >
          <path
            d="M574.936 212.889H262.303V68.3469H574.936V212.889Z"
            fill="#E5F0FA"
          />
        </mask> */}
        <g mask="url(#mask1_11842_2494)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M568.303 296.436C543.5 374.328 456.378 405.945 373.71 367.054C291.042 328.164 244.133 233.494 268.936 155.602C293.739 77.7101 380.862 46.0931 463.53 84.9837C546.198 123.874 593.106 218.545 568.303 296.436Z"
            fill="#E5F0FA"
          />
        </g>
        {/* <mask
          id="mask2_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="-200"
          y="42"
          width="378"
          height="171"
        >
          <path
            d="M177.124 212.889H-199.538V42.946H177.124V212.889Z"
            fill="#E5F0FA"
          />
        </mask> */}
        <g mask="url(#mask2_11842_2494)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M177.124 187.2C177.124 266.869 92.8055 331.454 -11.2066 331.454C-115.219 331.454 -199.538 266.869 -199.538 187.2C-199.538 107.531 -115.219 42.946 -11.2066 42.946C92.8055 42.946 177.124 107.531 177.124 187.2Z"
            fill="#E5F0FA"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M199.998 130.759C199.998 171.466 170.334 204.464 133.74 204.464C97.1471 204.464 67.4825 171.466 67.4825 130.759C67.4825 90.0521 97.1471 57.053 133.74 57.053C170.334 57.053 199.998 90.0521 199.998 130.759Z"
          fill="#E5F0FA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.145 154.293C180.145 154.293 150.231 119.3 92.3448 119.3C92.3448 119.3 46.4948 120.397 25.3252 106.895C25.3252 106.895 1.82565 90.3635 -33.5278 102.476C-33.5278 102.476 -87.5285 126.702 -112.781 119.637C-112.781 119.637 -237.1 92.0454 -284.496 117.618C-284.496 117.618 -368.802 134.085 -459.71 134.758C-459.71 134.758 -534.297 132.683 -533.133 218.166L198.016 217.493L180.145 156.985"
          fill="#E5F0FA"
        />
        <path
          d="M369.584 196.83H376.711"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M368.212 193.54H378.971"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M369.659 190.072H377.615"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M368.393 187.139H378.609"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M369.659 183.849H378.157"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M371.738 180.382H375.264"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M371.467 177.626H376.078"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M374.578 174.336H373.238"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M373.908 174.514V200.979"
          stroke="#C3DDF4"
          strokeWidth="1.23917"
          strokeLinecap="round"
        />
        <path
          d="M43.6221 144.446H62.8532"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M39.8447 141.181H65.3712"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M43.3932 137.241H63.6541"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M43.0499 133.076H62.3952"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M46.8273 129.587H59.8773"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M44.538 126.097H62.1665"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M46.713 121.932H60.335"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M48.5444 117.542H58.6177"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M46.9419 113.828H59.877"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M48.5444 109.662H59.3047"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M51.1773 105.272H55.6416"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M50.8337 101.782H56.672"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M54.7728 97.6177H53.0763"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M53.9246 97.8431V160.542"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M126.124 150.934H140.7"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M123.261 148.459H142.609"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M125.95 145.473H141.308"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M125.69 142.316H140.354"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M128.553 139.671H138.444"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M126.818 137.026H140.18"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M128.466 133.869H138.791"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M129.855 130.541H137.49"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M128.64 127.725H138.445"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M129.855 124.569H138.011"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M131.851 121.241H135.234"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M131.59 118.596H136.016"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M134.576 115.439H133.289"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M133.933 115.61V163.135"
          stroke="#C3DDF4"
          strokeWidth="1.18948"
          strokeLinecap="round"
        />
        <path
          d="M67.7887 142.875H87.0198"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M64.0112 139.611H89.5378"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M67.5597 135.671H87.8207"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M67.2164 131.506H86.5618"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M70.9939 128.016H84.0438"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M68.7045 124.527H86.333"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M70.8795 120.362H84.5016"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M72.7109 115.972H82.7843"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M71.1085 112.257H84.0435"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M72.7109 108.092H83.4713"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M75.3438 103.702H79.8081"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M75.0003 100.212H80.8386"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M78.9394 96.0475H77.2429"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          d="M78.0911 96.2723V158.972"
          stroke="#C3DDF4"
          strokeWidth="1.56923"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.9225 105.408C14.9225 111.462 10.6306 116.37 5.33597 116.37C0.0419822 116.37 -4.24991 111.462 -4.24991 105.408C-4.24991 99.3539 0.0419822 94.4464 5.33597 94.4464C10.6306 94.4464 14.9225 99.3539 14.9225 105.408Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.55582 101.299C4.55582 104.792 1.08869 107.624 -3.18839 107.624C-7.46546 107.624 -10.9326 104.792 -10.9326 101.299C-10.9326 97.8064 -7.46546 94.975 -3.18839 94.975C1.08869 94.975 4.55582 97.8064 4.55582 101.299Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.102063 109.786C1.14924 111.962 0.350421 114.518 -1.6834 115.494C-3.7166 116.47 -6.21378 115.496 -7.26158 113.319C-8.30877 111.142 -7.50994 108.587 -5.47612 107.611C-3.44292 106.635 -0.945738 107.609 0.102063 109.786Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.8812 109.372C17.929 111.549 17.1295 114.104 15.0963 115.08C13.0625 116.056 10.5653 115.083 9.51815 112.906C8.47035 110.729 9.2698 108.173 11.303 107.198C13.3362 106.221 15.834 107.195 16.8812 109.372Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.1958 104.614C21.243 106.79 20.4436 109.346 18.4104 110.322C16.3772 111.297 13.88 110.324 12.8322 108.148C11.785 105.97 12.5838 103.415 14.6177 102.439C16.6509 101.463 19.148 102.437 20.1958 104.614Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.42129 93.9339C6.46909 96.1106 5.26127 98.8624 2.72394 100.08C0.185986 101.298 -2.71954 100.521 -3.76734 98.3435C-4.81452 96.1668 -3.60673 93.415 -1.0694 92.1977C1.46793 90.9797 4.37411 91.7571 5.42129 93.9339Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.6312 101.35C19.6312 104.378 16.581 106.833 12.8186 106.833C9.05616 106.833 6.00604 104.378 6.00604 101.35C6.00604 98.3222 9.05616 95.8672 12.8186 95.8672C16.581 95.8672 19.6312 98.3222 19.6312 101.35Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0124 98.9493C15.0124 103.023 12.2174 106.325 8.77005 106.325C5.32207 106.325 2.52713 103.023 2.52713 98.9493C2.52713 94.876 5.32207 91.5739 8.77005 91.5739C12.2174 91.5739 15.0124 94.876 15.0124 98.9493Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.9451 133.326H6.32428V115.664H3.9451V133.326Z"
          fill="#C3DDF4"
        />
        <path
          d="M-3.39606 113.486L-0.496063 119.072L4.47604 121.14"
          stroke="#C3DDF4"
          strokeWidth="1.05243"
        />
        <path
          d="M5.92596 119.485L10.6905 118.451L12.3481 113.899"
          stroke="#C3DDF4"
          strokeWidth="1.05243"
        />
        <path
          d="M113.975 138.393V162.992"
          stroke="#C3DDF4"
          strokeWidth="1.81351"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M121.52 135.969C121.52 141.149 118.033 145.348 113.732 145.348C109.432 145.348 105.945 141.149 105.945 135.969C105.945 130.788 109.432 126.59 113.732 126.59C118.033 126.59 121.52 130.788 121.52 135.969Z"
          fill="#C3DDF4"
        />
        <path
          d="M165.871 147.03V171.63"
          stroke="#C3DDF4"
          strokeWidth="1.81351"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M173.416 144.607C173.416 149.787 169.929 153.986 165.628 153.986C161.328 153.986 157.841 149.787 157.841 144.607C157.841 139.426 161.328 135.228 165.628 135.228C169.929 135.228 173.416 139.426 173.416 144.607Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M559.961 207.623L549.79 183.756L533.637 171.258L515.149 164.503L466.276 165.803L435.456 175.956L416.931 196.73L347.249 213.362C347.249 213.362 347.036 214.201 350.683 214.172C368.012 214.035 526.314 214.682 559.966 215.244C560.528 215.253 559.714 207.664 559.961 207.623Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M559.961 207.623L549.79 183.756L533.637 171.258L515.149 164.503L466.276 165.803L435.456 175.956L416.931 196.73L347.249 213.362C347.249 213.362 347.036 214.201 350.683 214.172C368.012 214.035 526.314 214.682 559.966 215.244C560.528 215.253 559.714 207.664 559.961 207.623Z"
          stroke="#C3DDF4"
          strokeWidth="7.84615"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-222.964 205.693L-195.65 194.901L-180.344 187.381L-155.744 187.283L-134.626 167.245L-88.733 172.818L-60.679 155.601L-3.86182 135.558L24.1922 148.089L81.7199 162.073L111.981 195.262L116.821 206.922C116.821 206.922 115.561 216.527 113.114 216.177C52.5693 207.539 -160.727 208.567 -222.973 209.39C-223.949 209.404 -222.534 205.749 -222.964 205.693Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M-222.964 206.693L-195.65 195.901L-180.344 188.381L-155.744 188.283L-134.626 168.245L-88.733 173.818L-60.679 156.601L-3.86182 136.558L24.1922 149.089L81.7199 163.073L109.708 192.616L116 203.04C114.345 200.34 118.906 210.39 116 210.39C51.4297 210.39 -159.385 210.39 -221.888 210.39C-222.866 210.39 -222.534 206.749 -222.964 206.693Z"
          stroke="#C3DDF4"
          strokeWidth="15.5118"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M197.056 182.882L168.036 170.384L134.825 163.629L105.806 164.929L84.6419 175.082L52.5308 197.707L26.3284 209.404C26.3284 209.404 147.709 208.755 220.883 209.435C221.893 209.444 220.429 206.79 220.874 206.749L197.056 182.882Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M221.402 209.246L197.583 185.379L168.563 172.881L135.352 166.126L106.333 167.426L85.1691 177.579L53.058 200.204L26.8556 211.901C26.8556 211.901 148.236 211.252 221.41 211.932C222.42 211.941 220.956 209.287 221.402 209.246Z"
          stroke="#C3DDF4"
          strokeWidth="13.4426"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M78.4118 169.105C68.771 165.769 62.5441 158.486 64.5032 152.839C66.4628 147.193 75.8665 145.32 85.5079 148.656C95.1488 151.992 101.376 159.275 99.4166 164.922C97.4576 170.569 88.0533 172.442 78.4118 169.105Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9766 140.905C23.9766 147.857 17.8387 153.492 10.2669 153.492C2.69565 153.492 -3.44226 147.857 -3.44226 140.905C-3.44226 133.952 2.69565 128.316 10.2669 128.316C17.8387 128.316 23.9766 133.952 23.9766 140.905Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.2458 134.223C12.1527 140.097 2.27523 146.492 -10.8161 148.508C-23.9068 150.524 -35.2547 147.397 -36.1616 141.523C-37.0686 135.65 -27.1911 129.254 -14.0997 127.238C-1.00841 125.222 10.3388 128.349 11.2458 134.223Z"
          fill="#C3DDF4"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M287.535 87.1917C287.535 79.8193 281.995 73.8419 275.161 73.8419C268.326 73.8419 262.785 79.8193 262.785 87.1917C262.785 94.5641 268.326 100.541 275.161 100.541C281.995 100.541 287.535 94.5641 287.535 87.1917Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M293.863 92.4838C293.863 88.714 291.031 85.658 287.536 85.658C284.041 85.658 281.208 88.714 281.208 92.4838C281.208 96.2536 284.041 99.3095 287.536 99.3095C291.031 99.3095 293.863 96.2536 293.863 92.4838Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M258.625 92.4838C258.625 88.714 255.792 85.658 252.298 85.658C248.803 85.658 245.969 88.714 245.969 92.4838C245.969 96.2536 248.803 99.3095 252.298 99.3095C255.792 99.3095 258.625 96.2536 258.625 92.4838Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M272.834 90.0296C272.834 84.3879 268.594 79.8148 263.364 79.8148C258.135 79.8148 253.895 84.3879 253.895 90.0296C253.895 95.6714 258.135 100.244 263.364 100.244C268.594 100.244 272.834 95.6714 272.834 90.0296Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M243.833 101.464H300.389C302.304 101.464 303.855 99.7899 303.855 97.7242C303.855 95.6598 302.304 93.9853 300.389 93.9853H243.833C241.919 93.9853 240.368 95.6598 240.368 97.7242C240.368 99.7899 241.919 101.464 243.833 101.464Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M80.4827 66.6165C80.4827 61.3987 76.2471 57.1686 71.0223 57.1686C65.7975 57.1686 61.5618 61.3987 61.5618 66.6165C61.5618 71.8344 65.7975 76.0645 71.0223 76.0645C76.2471 76.0645 80.4827 71.8344 80.4827 66.6165Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M88.1269 69.0856C88.1269 65.7132 85.3894 62.9793 82.0124 62.9793C78.6349 62.9793 75.8974 65.7132 75.8974 69.0856C75.8974 72.4587 78.6349 75.1926 82.0124 75.1926C85.3894 75.1926 88.1269 72.4587 88.1269 69.0856Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M95.4127 70.7348C95.4127 68.0879 93.2639 65.9414 90.6129 65.9414C87.9625 65.9414 85.8132 68.0879 85.8132 70.7348C85.8132 73.3823 87.9625 75.5288 90.6129 75.5288C93.2639 75.5288 95.4127 73.3823 95.4127 70.7348Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.2433 68.6248C69.2433 64.6316 66.0023 61.3949 62.0045 61.3949C58.0067 61.3949 54.7657 64.6316 54.7657 68.6248C54.7657 72.6173 58.0067 75.854 62.0045 75.854C66.0023 75.854 69.2433 72.6173 69.2433 68.6248Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M61.5696 76.2287H97.872C99.065 76.2287 100.032 75.2625 100.032 74.0711C100.032 72.879 99.065 71.9135 97.872 71.9135H61.5696C60.3767 71.9135 59.4092 72.879 59.4092 74.0711C59.4092 75.2625 60.3767 76.2287 61.5696 76.2287Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M103.661 76.1226H105.555C106.576 76.1226 107.404 75.2958 107.404 74.2772C107.404 73.2573 106.576 72.4318 105.555 72.4318H103.661C102.64 72.4318 101.813 73.2573 101.813 74.2772C101.813 75.2958 102.64 76.1226 103.661 76.1226Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.1758 76.604H49.2001C50.2213 76.604 51.0486 75.7773 51.0486 74.7586C51.0486 73.7387 50.2213 72.9132 49.2001 72.9132H48.1758C47.1552 72.9132 46.3279 73.7387 46.3279 74.7586C46.3279 75.7773 47.1552 76.604 48.1758 76.604Z"
          fill="white"
        />
        <path
          d="M0 224H5.11517"
          stroke="#000066"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          d="M19 224H35.3511"
          stroke="#000066"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14 224C14 225.104 13.1045 226 12 226C10.8955 226 10 225.104 10 224C10 222.896 10.8955 222 12 222C13.1045 222 14 222.896 14 224Z"
          fill="#000066"
        />
        <path
          d="M47 224H169.933H905.976"
          stroke="#000066"
          strokeWidth="4"
          strokeLinecap="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M251.718 203.165C251.718 192.373 261.208 181.091 275.469 181.572C286.302 181.938 296.127 192.373 296.127 203.165C296.127 203.231 296.117 203.295 296.116 203.362H297.293L297.32 203.334H302.229C304.196 203.334 305.805 201.731 305.805 199.773V187.122C305.805 185.614 304.848 184.326 303.511 183.809C302.545 171.327 286.866 169.309 286.866 169.309L250.761 163.756L225.67 140.807C182.2 136.359 131.678 139.313 131.678 139.313C124.77 139.011 120.521 148.747 120.521 148.747L113.72 165.52L114.235 182.918C108.368 185.546 111.888 195.768 111.888 195.768C112.768 201.318 117.755 201.61 117.755 201.61L128.467 202.414C128.866 191.973 137.959 182.484 148.537 182.117C163.31 181.604 171.806 192.373 171.806 203.165H251.718Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M118.55 175.198H122.825C125.08 175.198 126.925 173.36 126.925 171.116V171.01C126.925 168.764 125.08 166.928 122.825 166.928H118.55V175.198Z"
          fill="#F2542A"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M199.108 169.123L199.108 169.125C199.126 169.511 199.288 169.859 199.552 170.111C199.576 170.134 199.61 170.145 199.635 170.167C199.749 170.261 199.866 170.352 200.005 170.41C200.177 170.482 200.365 170.523 200.563 170.523H207.905C208.104 170.523 208.292 170.483 208.464 170.41C208.605 170.352 208.723 170.26 208.838 170.164C208.862 170.143 208.893 170.133 208.916 170.111C209.195 169.845 209.374 169.475 209.374 169.061C209.374 168.257 208.713 167.599 207.905 167.599H200.563C199.756 167.599 199.095 168.257 199.095 169.061C199.095 169.083 199.107 169.102 199.108 169.123Z"
          fill="#000066"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.052 168.478C149.052 168.891 149.23 169.262 149.51 169.528C149.533 169.552 149.567 169.563 149.592 169.584C149.706 169.679 149.824 169.77 149.962 169.827C150.134 169.899 150.322 169.94 150.521 169.94H157.863C158.061 169.94 158.249 169.899 158.421 169.827C158.56 169.77 158.677 169.679 158.791 169.584C158.816 169.563 158.85 169.552 158.874 169.528C159.153 169.262 159.331 168.891 159.331 168.478C159.331 167.675 158.671 167.016 157.863 167.016H150.521C149.713 167.016 149.052 167.675 149.052 168.478Z"
          fill="#000066"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M298.642 197.287C298.642 198.782 299.871 200.004 301.372 200.004H302.601V194.57H301.372C299.871 194.57 298.642 195.793 298.642 197.287Z"
          fill="#F2542A"
        />
        <path
          d="M306.254 186.149H299.931"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.471 184.149H114.471"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M294.725 180.494H299.077C297.132 176.692 296.11 171.797 285.228 172.623C285.228 172.623 290.446 176.731 294.725 180.494Z"
          fill="#C1DEFA"
        />
        <path
          d="M170.258 182.7L164.503 176.046L135.294 175.875L129.624 182.033"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M296.631 186.138L285.463 176.389L263.814 175.704L255.309 183.915"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M240.599 164.598L223.378 148.351C223.15 148.136 222.852 148.011 222.538 148L194.222 146.974C193.827 146.963 193.446 147.127 193.19 147.428C192.935 147.73 192.833 148.131 192.914 148.517L196.38 165.074C196.503 165.66 197.017 166.084 197.619 166.095L239.69 166.812H239.712C240.235 166.812 240.707 166.497 240.906 166.014C241.106 165.524 240.984 164.961 240.599 164.598Z"
          fill="#C1DEFA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M186.542 148.056C186.454 147.425 185.913 146.953 185.272 146.95L156.687 146.808H156.68C156.186 146.808 155.735 147.09 155.519 147.534L147.887 163.305C147.697 163.699 147.72 164.162 147.95 164.534C148.18 164.906 148.584 165.136 149.023 165.145L187.531 165.882H187.555C187.923 165.882 188.274 165.725 188.519 165.45C188.769 165.169 188.884 164.794 188.832 164.422L186.542 148.056Z"
          fill="#C1DEFA"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M145.847 147.47C145.615 147.093 145.204 146.862 144.759 146.859L130.558 146.758H130.548C130.041 146.758 129.58 147.055 129.372 147.516L122.701 162.319C122.525 162.71 122.557 163.164 122.786 163.527C123.015 163.89 123.412 164.115 123.843 164.127L137.189 164.454C137.201 164.454 137.212 164.454 137.224 164.454C137.711 164.454 138.158 164.18 138.377 163.744L145.902 148.714C146.1 148.318 146.08 147.848 145.847 147.47Z"
          fill="#C1DEFA"
        />
        <path
          d="M193.712 192.981L192.853 165.27L189.76 147.48"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M150.758 147.48L142.683 164.244L142.511 172.112"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        {/* <mask
          id="mask3_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="191"
          y="144"
          width="51"
          height="23"
        >
          <path
            d="M192.503 145.803C192.248 146.105 192.146 146.507 192.227 146.892L195.693 163.448C195.816 164.035 196.331 164.459 196.932 164.47L239.003 165.186H239.025C239.548 165.186 240.02 164.872 240.218 164.388C240.419 163.899 240.297 163.336 239.911 162.973L222.691 146.726C222.463 146.511 222.165 146.387 221.851 146.375L193.535 145.349C193.52 145.348 193.506 145.348 193.491 145.348C193.115 145.348 192.749 145.513 192.503 145.803Z"
            stroke="white"
            strokeWidth="2.05369"
            strokeLinecap="round"
          />
        </mask> */}
        <g mask="url(#mask3_11842_2494)">
          <path
            d="M204.856 156.141L193.195 165.034"
            stroke="white"
            strokeWidth="2.05369"
            strokeLinecap="round"
          />
        </g>
        {/* <mask
          id="mask4_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="191"
          y="144"
          width="50"
          height="22"
        >
          <path
            d="M192.503 145.803C192.248 146.105 192.146 146.507 192.227 146.892L195.693 163.448C195.816 164.035 196.331 164.459 196.932 164.47L239.003 165.186H239.025C239.548 165.186 240.02 164.872 240.218 164.388C240.419 163.899 240.297 163.336 239.911 162.973L222.691 146.726C222.463 146.511 222.165 146.387 221.851 146.375L193.535 145.349C193.52 145.348 193.506 145.348 193.491 145.348C193.115 145.348 192.749 145.513 192.503 145.803Z"
            stroke="white"
            strokeWidth="1.02685"
            strokeLinecap="round"
          />
        </mask> */}
        <g mask="url(#mask4_11842_2494)">
          <path
            d="M206.593 159.275L198.716 165.547"
            stroke="white"
            strokeWidth="1.02685"
            strokeLinecap="round"
          />
        </g>
        {/* <mask
          id="mask5_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="146"
          y="144"
          width="44"
          height="22"
        >
          <path
            d="M155.176 145.994L147.543 161.766C147.353 162.159 147.376 162.622 147.606 162.995C147.836 163.367 148.24 163.597 148.68 163.606L187.187 164.342H187.212C187.58 164.342 187.93 164.185 188.176 163.911C188.426 163.63 188.54 163.254 188.488 162.882L186.198 146.517C186.11 145.885 185.569 145.414 184.929 145.41L156.343 145.268"
            stroke="white"
            strokeWidth="2.05369"
            strokeLinecap="round"
          />
        </mask> */}
        <g mask="url(#mask5_11842_2494)">
          <path
            d="M166.662 142.903L155.001 151.796"
            stroke="white"
            strokeWidth="2.05369"
            strokeLinecap="round"
          />
        </g>
        {/* <mask
          id="mask6_11842_2494"
          style="mask-type:luminance"
          maskUnits="userSpaceOnUse"
          x="146"
          y="144"
          width="44"
          height="21"
        >
          <path
            d="M155.176 145.994L147.543 161.766C147.353 162.159 147.376 162.622 147.606 162.995C147.836 163.367 148.24 163.597 148.68 163.606L187.187 164.342H187.212C187.58 164.342 187.93 164.185 188.176 163.911C188.426 163.63 188.54 163.254 188.488 162.882L186.198 146.517C186.11 145.885 185.569 145.414 184.929 145.41L156.343 145.268"
            stroke="white"
            strokeWidth="1.02685"
            strokeLinecap="round"
          />
        </mask> */}
        <g mask="url(#mask6_11842_2494)">
          <path
            d="M169.43 144.497L161.552 150.769"
            stroke="white"
            strokeWidth="1.02685"
            strokeLinecap="round"
          />
        </g>
        <path
          d="M196.837 165.459L240.969 166.176"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M147.817 164.51L188.386 165.246"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M122.817 163.51L137.413 163.878"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M250.711 162.697L225.67 141.322C182.201 136.875 131.677 139.828 131.677 139.828C124.77 139.526 120.521 149.262 120.521 149.262L113.72 166.035L114.235 183.433C108.368 186.061 111.888 196.284 111.888 196.284C112.768 201.833 117.755 202.125 117.755 202.125L128.467 202.93C128.866 192.489 137.958 183 148.537 182.632C163.31 182.119 171.806 192.888 171.806 203.68H251.718C251.718 192.888 261.208 181.606 275.469 182.087C286.302 182.453 296.126 192.888 296.126 203.68C296.126 203.747 296.117 203.811 296.117 203.877H297.293L297.32 203.849H302.229C304.196 203.849 305.805 202.247 305.805 200.288V187.637C305.805 186.13 304.847 184.842 303.511 184.325C303.567 179.038 301.639 175.418 299.077 172.944"
          stroke="#000066"
          strokeWidth="4.62063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.109 205.664C136.109 213.468 142.463 219.795 150.302 219.795C158.141 219.795 164.496 213.468 164.496 205.664C164.496 197.86 158.141 191.533 150.302 191.533C142.463 191.533 136.109 197.86 136.109 205.664Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.222 205.552C260.222 213.356 266.577 219.683 274.416 219.683C282.255 219.683 288.61 213.356 288.61 205.552C288.61 197.748 282.255 191.421 274.416 191.421C266.577 191.421 260.222 197.748 260.222 205.552Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.109 205.612C136.109 213.416 142.463 219.743 150.302 219.743C158.141 219.743 164.496 213.416 164.496 205.612C164.496 197.807 158.141 191.481 150.302 191.481C142.463 191.481 136.109 197.807 136.109 205.612Z"
          stroke="#000066"
          strokeWidth="7.70081"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.222 205.5C260.222 213.304 266.577 219.631 274.416 219.631C282.255 219.631 288.61 213.304 288.61 205.5C288.61 197.696 282.255 191.369 274.416 191.369C266.577 191.369 260.222 197.696 260.222 205.5Z"
          stroke="#000066"
          strokeWidth="7.70081"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M294.659 140.129C287.813 139.553 281.848 143.741 281.848 143.741L250.711 162.697"
          stroke="#000066"
          strokeWidth="4.62063"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M298.435 174.499C293.504 169.736 286.504 168.743 286.504 168.743L250.4 164.206"
          stroke="#000066"
          strokeWidth="1.54009"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M82.5756 212.938V220.982H93.6502H71.5011"
          stroke="#000066"
          strokeWidth="3.60785"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.2854 207.641H89.7263L82.3051 194.658L75.2854 207.641ZM94.9379 213.724H70.2136C69.1513 213.724 68.1683 213.157 67.6269 212.233C67.0851 211.309 67.0653 210.165 67.575 209.223L79.2696 187.595C79.7973 186.619 80.8084 186.013 81.9075 186.013H82.5758C83.6492 186.013 84.6416 186.592 85.1787 187.532L97.5408 209.16C98.079 210.101 98.0797 211.261 97.5426 212.202C97.0052 213.144 96.0125 213.724 94.9379 213.724Z"
          fill="#F9FAFD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.2854 207.641H89.7263L82.3051 194.658L75.2854 207.641ZM94.9379 213.724H70.2136C69.1513 213.724 68.1683 213.157 67.6269 212.233C67.0851 211.309 67.0653 210.165 67.575 209.223L79.2696 187.595C79.7973 186.619 80.8084 186.013 81.9075 186.013H82.5758C83.6492 186.013 84.6416 186.592 85.1787 187.532L97.5408 209.16C98.079 210.101 98.0797 211.261 97.5426 212.202C97.0052 213.144 96.0125 213.724 94.9379 213.724Z"
          stroke="#F9FAFD"
          strokeWidth="2.57704"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M81.9075 189.054H82.5758L94.9376 210.682H70.2136L81.9075 189.054Z"
          stroke="#FF9316"
          strokeWidth="6.01753"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_11842_2494">
          <rect
            width="1440"
            height="226"
            fill="white"
            transform="translate(-533)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CarIllustrationDuplicateRescue;
