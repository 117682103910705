import titleCase from './titleCase';

export const getUniqueDriverList = ({drivers, newDriverFirstName, newDriverLastName}) => {
  let set = new Set();
  if(drivers && drivers?.length > 0) {
    if (newDriverFirstName && newDriverLastName) {
      let newDriverFullName = newDriverFirstName + " " + newDriverLastName;
      set.add(JSON.stringify({text: titleCase(newDriverFullName), value: newDriverFullName, firstname: newDriverFirstName, lastname: newDriverLastName}))
    }
    drivers.forEach((driver) => {
      let firstName, lastName, operatorId;
      if (newDriverFirstName && newDriverLastName) {
        firstName = driver.firstname;
        lastName = driver.lastname;
      } else {
        firstName = driver.firstName;
        lastName = driver.lastName;
        operatorId = driver.operatorId;
      }
      let fullName = firstName + " " + lastName;
      set.add(JSON.stringify({text: titleCase(fullName), value: fullName, firstname: firstName, lastname: lastName, operatorid: operatorId}));
    });

    return Array.from(set).map(d => JSON.parse(d));
  }
  return Array.from(set);
};

export const doesReduxDriverListHaveBenefitsOperators = (benefitsOperators, reduxDriverList) => {
  return benefitsOperators?.every(benefitsOperator =>
    reduxDriverList?.find(driverListDriver =>
      driverListDriver?.firstname === benefitsOperator?.firstName &&
      driverListDriver?.lastname === benefitsOperator?.lastName
    )
  )
}
