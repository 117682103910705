import React, {Component} from 'react';
import {connect} from 'react-redux';
import { Loader } from "mesh-component-library";
import {refreshEpcsStatus, requestEpcsStatus} from '../../action';
import _isEmpty from 'lodash/isEmpty';
import _isNil from "lodash/isNil";
import CreditCardInfo from '../creditcard-info';
import { EPCS_SCRIPT_ID } from '../../app-consts/appConstants';

class EpcsIframe extends Component {

  constructor(props) {
    super(props);

    this.state = {
      epcsScriptLoaded: !!props?.epcsScriptLoaded || false,
      iFrameLoadSuccessful: false,
      paymentIframeLoadFailure: false,
      epcsStatusRequest: {},
    };
  }

  componentDidMount() {
    this.loadEpcsScript()
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.removeEpcsScript();
    this.props.onRef(undefined);
  }

  onLoadHandler = (e) => {
    // eslint-disable-next-line no-undef
    let epcsFrame = new Aic.Epcs.Controller({
      applicationID: this.props.epcsInitialize.iframeInstanceId,
      targetOrigin: this.props.epcsInitialize.targetOrigin,
      proxyUrl: this.props.epcsInitialize.proxyEndpoint,
      loggingServiceUrl: this.props.epcsInitialize.loggingServiceUrl.replace("&amp;", "&"),
      submitTimeout: 500000,

      submitCompleteCallback: (event) => {
        if (event.success) {
          this.props.refreshEpcsStatus();
          this.props.requestEpcsStatus(this.state.epcsStatusRequest);
        } else {
          if (event.hasValidationErrors) {
            this.props.onValidationError();
          }
        }
      },
      registerFrameCompleteCallback: this.registerFrameCompleteCallback
    });
    epcsFrame.registerFrame(e.target);
    this.setState({ epcsFrame });
  };

  registerFrameCompleteCallback = (ev) => {
    if (!ev.isVerified) {
      this.setState({ paymentIframeLoadFailure: true });
      // console.log("Iframe LOAD failed!! :(", ev);
      this.removeEpcsScript()
    } else {
      this.setState({
        paymentIframeLoadFailure: false,
        iFrameLoadSuccessful: true,
      });
      // console.log("Iframe LOAD success :D");
    }
  };

  reloadIframeHandler = () => {
    this.removeEpcsScript();
    this.loadEpcsScript();
    this.setState({
      paymentIframeLoadFailure: false,
    });
  };

  removeEpcsScript = () => {
    const epcsScriptElement = document.getElementById(EPCS_SCRIPT_ID);
    if (epcsScriptElement !== null) {
      document.getElementsByTagName("head")[0].removeChild(epcsScriptElement);
    }
    if (
      !!this.props.epcsInitialize.iframeInstanceId &&
      !_isNil(this.state.epcsFrame)
    ) {
      this.state.epcsFrame.unregisterFrame(
        this.props.epcsInitialize.iframeInstanceId
      );
    }
  };

  loadEpcsScript = () => {
    if (!!this.props.epcsInitialize.jsUrl) {
      let epcsScriptTag = this.getLoadedEpcsScript();
      const epcsScriptElement = document.getElementById(EPCS_SCRIPT_ID);
      if (_isNil(epcsScriptTag) && _isNil(epcsScriptElement)) {
        let tag = document.createElement("script");
        tag.id = EPCS_SCRIPT_ID;
        tag.async = false;
        tag.src = this.props.epcsInitialize.jsUrl;
        tag.onload = () => {
          this.setState({
            epcsScriptLoaded: true
          })
        }
        document.getElementsByTagName("head")[0].appendChild(tag);
      }
    }
  };

  getLoadedEpcsScript = () => {
    let epcsScriptTag = null;
    let scripts = document.getElementsByTagName("script");
    Array.prototype.slice.call(scripts).forEach((scriptTag) => {
      if (scriptTag.src.toLowerCase() ===
        this.props.epcsInitialize.jsUrl.toLowerCase()) {
        epcsScriptTag = scriptTag;
        return;
      }
    });
    return epcsScriptTag;
  };

  handleEditCCSubmit = (zipcode, serviceType, amount, callId) => {
    let epcsStatusRequest = {
      "epcsIFrameInstanceId": this.props.epcsInitialize.iframeInstanceId,
      "billingZip": zipcode,
      "serviceType" : serviceType,
      "amount": amount,
      "callId": callId
    };
    this.setState({
      epcsStatusRequest
    })
    this.state.epcsFrame.submit();
  }

  showEpcsIframe = () => {
    if (_isEmpty(this.props.epcsStatus.response)) {
      return (
        <div>
          {this.state.epcsScriptLoaded ?
            <iframe id={this.props.epcsInitialize.iframeInstanceId}
                    data-test-id="epcs-iframe"
                    name="payment0"
                    title="payment-view"
                    scrolling="no"
                    frameBorder="0"
                    tabIndex="4"
                    width="100%"
                    height="215px"
                    src={this.props.epcsInitialize.iframeSource.replace("&amp;", "&")}
                    className="component-label i-frame"
                    onLoad={this.onLoadHandler}/> 
              : <Loader size="lg"/>}
        </div>
      );
    } else {
      return (
        <CreditCardInfo enableIframe={this.enableIframe}/>
      );
    }
  }

  enableIframe = () => {
    this.props.refreshEpcsStatus();
  }

  render() {
    return (
      <div>
        {this.showEpcsIframe()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    epcsStatus: state.epcsStatus
  };
}

export default connect(mapStateToProps, {
  requestEpcsStatus,
  refreshEpcsStatus
})(EpcsIframe);
