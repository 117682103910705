export const REQUEST_GOOGLE_NEAR_BY_SEARCH = "REQUEST_GOOGLE_NEAR_BY_SEARCH";
export const REQUEST_GOOGLE_NEAR_BY_SEARCH_SUCCESS = "REQUEST_GOOGLE_NEAR_BY_SEARCH_SUCCESS";
export const NO_TOW_LOCATIONS_FOUND = "NO_TOW_LOCATIONS_FOUND";
export const ERROR_GETTING_NEAR_BY_DESTINATIONS = "ERROR_GETTING_NEAR_BY_DESTINATIONS";

export const ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS = "ERROR_ADDING_NEW_NEAR_BY_DESTINATIONS";
export const REQUEST_GOOGLE_MORE_DETAILS_NEAR_BY_SEARCH = "REQUEST_GOOGLE_MORE_DETAILS_NEAR_BY_SEARCH";

export function requestGoogleNearbySearch(data){
    return { type: REQUEST_GOOGLE_NEAR_BY_SEARCH, payload: data };
}

export function requestAdditionalNearByDestinationsForDropdown(data){
    return { type: REQUEST_GOOGLE_MORE_DETAILS_NEAR_BY_SEARCH, payload: data };
}