import React, { useEffect, useState } from "react";
import { TRANSLATION_CONSTANTS } from "../../app-consts/translations";
import Alert from "../alert";
import { useLocale } from "../../contexts/LocaleContext";

const TriageStepsFixedAlert = ({ benefitsLoading, isAlternateLookup }) => {
  const getTranslatedText = useLocale();
  const [triageStepsFixed, setTriageStepsFixed] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("triageEventFixedBySteps") === "YES") {
      setTriageStepsFixed(true);
    }
  }, []);

  useEffect(() => {
    if (isAlternateLookup && !benefitsLoading) {
      localStorage.removeItem("triageEventFixedBySteps");
      setTriageStepsFixed(false);
    }
  }, [benefitsLoading]);

  return (
    triageStepsFixed && (
      <div style={{ margin: "1rem" }}>
        <Alert type="confirmation">
          {getTranslatedText(
            TRANSLATION_CONSTANTS.TRIAGE_STEPS_FIXED_ALERT_MESSAGE
          )}
        </Alert>
      </div>
    )
  );
};

export default TriageStepsFixedAlert;
